import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColDef,
  ColumnApi,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDataUpdatedEvent,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { WbsItemSearchConditionVO } from '../../../domain/value-object/WbsItemSearchConditionVo'
import { CustomEnumValue } from '../../../lib/commons/appFunction'
import Auth from '../../../lib/commons/auth'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { SearchConditionAndColumnState } from '../../../services/model/searchConditionAndColumnState'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { storedUiStateWithToolbarToggleAdaptor } from '../../components/toolbars/Toolbar/ToolbarToggle'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import TaskActualWorkDialog from '../../components/dialogs/TaskActualWorkDialog'
import { ROW_HEIGHT } from '../../containers/BulkSheet'
import { BulkSheetView } from '../../containers/BulkSheetView'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import {
  InputError,
  validateNodes,
} from '../../containers/BulkSheetView/lib/validation'
import { useProjectPrivateContext } from '../../context/projectContext'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { usePageState } from '../../hooks/usePageState'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { PageArea, PageProps } from '..'
import WbsItemSearchSearchConditionHeader from './components/Header/WbsItemSearchSearchConditionHeader'
import WbsItemSearchWbsHeader from './components/Header/WbsItemSearchWbsHeader'
import { useWbsItemSearchGridOptions } from './gridOptions'
import { useDialog } from './hooks/dialog'
import { useSelectColumnOptions } from './hooks/selectColumnOptions'
import {
  useWbsItemSearchSearchCondition,
  WbsItemSearchSearchCondition,
} from './hooks/useSearchCondition'
import { useWbsItemSearchData } from './hooks/wbsItemSearchData'
import { QuickFilterKeys, WbsItemSearchRow } from './wbsItemSearch'
import { useSelector } from 'react-redux'
import store, { AllState } from '../../../store'
import { FunctionLayer } from '../../../store/functionLayer'
import Immutable from 'immutable'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import { extractValuesFromResponse, toUrlQuery } from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { UiStateKey } from '../../../lib/commons/uiStates'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { UserProps } from '../../../lib/functions/user'
import { useExcel } from './hooks/excel'
import { ExcelExportDialog } from '../../containers/BulkSheet/ExcelExportDialog'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import {
  RestoreType,
  useBulkSheetState,
} from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import {
  getProjectPlanUuidByWbsItemUuid,
  getWbsItemUuidByProjectPlanUuid,
} from '../../../lib/functions/projectPlan'
import { useWbsItemSearchConditionSerializeService } from '../../../services/transform-service/wbsItemSearchConditionSerializeService'
import ProjectPlanNewBreadcrumb from '../ProjectPlanNew/components/Header/ProjectPlanNewBreadcrumb'
import { needToOpenInNewTab } from '../../router'
import { StoredWbsItemSearchCondition } from '../../../services/model/storedWbsItemSearchCondition'
import project from '../../../lib/functions/project'
import {
  ExtensionInfo,
  useEntityExtension,
} from '../../hooks/useEntityExtension'
import {
  FIELD_ATTCHMENT,
  FIELD_PARENT_WBS_ITEM_COL,
  FIELD_PATH_COL,
  FIELD_WBSITEM_ACTUALDATE_END,
  FIELD_WBSITEM_ACTUALDATE_START,
} from './gridOptions/columnDefs'
import {
  SearchConditionRestoreType,
  useRestoredSearchCondition,
} from './hooks/useRestoredSearchConditon'
import {
  useWbsItemSearchConditionStorageService,
  WbsItemSearchConditionStorageService,
} from '../../../services/storage-services/ui-states/wbsItemSearchConditionStorageService'
import { requireSave } from '../../../store/requiredSaveData'
import { useWbsItemAdditionalPropertiesOfBaseWbsItemTypes } from '../../hooks/useWbsItemAdditionalProperties'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import Loading from '../../components/process-state-notifications/Loading'

const EXCEL_EXPORT_EXCLUDE_COLIDS: string[] = [
  'uuid',
  'deliverableAttachmentSummary',
]

const CONVERT_COLUMN_FIELD_MAP = {
  attachment: FIELD_ATTCHMENT,
  'wbsItem.path': FIELD_PATH_COL,
  'parentWbsItem.displayName': FIELD_PARENT_WBS_ITEM_COL,
}
const CONVERT_COLUMN_FIELD_MAP_KEYS = Object.keys(CONVERT_COLUMN_FIELD_MAP)

const WbsItemSearchNew = projectPrivate(({ uuid }: PageProps) => {
  const { project } = useProjectPrivateContext()
  const functionLayer = useSelector<
    AllState,
    Immutable.Map<number, FunctionLayer>
  >(state => state.functionLayer.layers)
  const selectColumnOptions = useSelectColumnOptions(project.uuid)

  const loginUser: UserProps | undefined = Auth.getCurrentTenant()?.user
  const { extensions, clearExtensions } = useEntityExtension(
    uuid,
    project?.uuid
  )

  const searchConditionRepository = useWbsItemSearchConditionStorageService()

  const {
    initialized: searchConditionRestored,
    restoredSearchCondition,
    restoreType: searchConditionRestoreType,
  } = useRestoredSearchCondition(
    uuid,
    project?.uuid,
    searchConditionRepository.fetch
  )

  const {
    fetched: fetchedWbsItemAdditionalProperties,
    wbsItemAdditionalProperties,
  } = useWbsItemAdditionalPropertiesOfBaseWbsItemTypes()

  if (
    !project ||
    !selectColumnOptions ||
    !loginUser?.uuid ||
    !extensions ||
    !searchConditionRestored ||
    !fetchedWbsItemAdditionalProperties
  ) {
    return <></>
  }

  return (
    <WbsItemSearchContext
      applicationFunctionUuid={uuid}
      projectUuid={project.uuid}
      functionLayer={functionLayer}
      selectColumnOptions={selectColumnOptions}
      loginUserUuid={loginUser.uuid}
      extensions={extensions}
      restoredSearchCondition={restoredSearchCondition}
      searchConditionRepository={searchConditionRepository}
      searchConditionRestoreType={searchConditionRestoreType}
      wbsItemAdditionalProperties={wbsItemAdditionalProperties!}
    />
  )
})

type Props = {
  applicationFunctionUuid: string
  projectUuid: string
  functionLayer: Immutable.Map<number, FunctionLayer>
  selectColumnOptions: {
    [key: string]: CustomEnumValue[]
  }
  loginUserUuid: string
  extensions: ExtensionInfo
  restoredSearchCondition: WbsItemSearchSearchCondition
  searchConditionRepository: WbsItemSearchConditionStorageService
  searchConditionRestoreType: SearchConditionRestoreType
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity
}

type WbsItemSearchPageState = {
  workloadUnit: WorkloadUnit
  dateFormat: string
  rowHeight: number
}

const wbsItemSearchDefaultPageState: WbsItemSearchPageState = {
  workloadUnit: WorkloadUnit.HOUR,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  rowHeight: ROW_HEIGHT.SMALL,
}

const WbsItemSearchContext = ({
  applicationFunctionUuid,
  projectUuid,
  functionLayer,
  selectColumnOptions,
  loginUserUuid,
  extensions,
  restoredSearchCondition,
  searchConditionRepository,
  searchConditionRestoreType,
  wbsItemAdditionalProperties,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )
  const [rootProjectPlanUuid, setRootProjectPlanUuid] = useState<
    string | undefined
  >()
  const [refreshAfterUpdateState, setRefreshAfterUpdateState] =
    useState<boolean>(false)

  const bulkSheetViewRef = useRef<HTMLDivElement>(null)
  const gridOptionsRef = useRef<GridOptions>()
  const gridApi = useRef<GridApi>()
  const columnApi = useRef<ColumnApi>()
  const quickFilters = useRef<QuickFilterKeys[] | undefined>(undefined)

  const {
    searchCondition,
    saveSearchCondition,
    updateSearchCondition,
    overrideSearchCondition,
    resetSearchCondition,
  } = useWbsItemSearchSearchCondition(
    applicationFunctionUuid,
    projectUuid,
    restoredSearchCondition,
    searchConditionRepository
  )
  const restoreTypes = useMemo(
    () =>
      searchConditionRestoreType === 'URL_QUERY'
        ? (['EXPAND_ROW_IDS', 'COLUMN_STATE'] as RestoreType[])
        : undefined,
    [searchConditionRestoreType]
  )

  const bulkSheetState = useBulkSheetState(
    applicationFunctionUuid,
    projectUuid,
    UiStateKey.WbsItemSearchColumnAndFilterState,
    restoreTypes
  )

  const {
    totalDataSize,
    hitDataSize,
    count,
    data,
    refresh,
    refreshSingleRow,
    save,
    saveSingleRow,
    generateWbsItemDeltaRequest,
  } = useWbsItemSearchData({
    applicationFunctionUuid,
    projectUuid,
    searchCondition,
    extensionProperties: extensions.extensionProperties,
  })
  const prevData = useRef<WbsItemSearchRow[]>([])
  prevData.current = data
  const reload = useRef<Function>()
  reload.current = refresh
  const submit = useRef<Function>()
  submit.current = save
  const refreshSingleRowRef = useRef<Function>()
  refreshSingleRowRef.current = refreshSingleRow
  const saveSingleRowRef = useRef<Function>()
  saveSingleRowRef.current = saveSingleRow

  // Dealing with a bug that prevented the use of Excel Export
  useEffect(() => {
    if (hitDataSize === 0 && !!gridOptionsRef.current) {
      setLoading(false)
    }
  }, [hitDataSize, gridOptionsRef.current])

  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const dialog = useDialog()
  const columnSetting = useColumnSetting()
  const onExcelExport = useExcel(gridOptionsRef.current)

  const { workloadUnit, dateFormat, rowHeight, updatePageState } =
    usePageState<WbsItemSearchPageState>(
      applicationFunctionUuid,
      wbsItemSearchDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const onClickedStatusCell = useCallback(
    (target: EventTarget | undefined, row: WbsItemSearchRow | undefined) => {
      const getAgCellElement = (elem): any => {
        if (!elem || elem.className.toString().includes('sevend-ag-cell')) {
          return elem
        }
        return getAgCellElement(elem.parentElement)
      }
      const targetElement = getAgCellElement(target)
      if (!target || !row?.wbsItem) return
      dialog.openStatusPopper(
        targetElement,
        row.wbsItem,
        generateWbsItemDeltaRequest(row)!
      )
    },
    [dialog, generateWbsItemDeltaRequest]
  )

  const onClickedActualHourCell = useCallback(
    (row: WbsItemSearchRow | undefined) => {
      const uuid: string | undefined = row?.wbsItem?.uuid
      if (!uuid) return
      dialog.openActualResult(uuid)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog.openActualResult]
  )

  const reloadSingleRow = useCallback((wbsItemUuid: string | undefined) => {
    if (!refreshSingleRowRef.current) return
    refreshSingleRowRef.current(wbsItemUuid)

    if (gridApi.current) {
      let existsEditedRow: boolean = false
      gridApi.current.forEachNode((row: RowNode<WbsItemSearchRow>) => {
        if (row.data?.edited) existsEditedRow = true
      })
      if (existsEditedRow) store.dispatch(requireSave())
    }
  }, [])

  const submitSingleRow = useCallback(async (wbsItemUuid: string) => {
    if (saveSingleRowRef?.current) {
      await saveSingleRowRef.current(wbsItemUuid)
      refreshSingleRowRef?.current?.(wbsItemUuid)
    }
  }, [])

  const gridOptions = useWbsItemSearchGridOptions({
    projectUuid,
    selectColumnOptionMap: selectColumnOptions,
    onClickedStatusCell,
    onClickedActualHourCell,
    reloadSingleRow,
    submitSingleRow,
    extensions,
    wbsItemAdditionalProperties,
  })

  const refreshAll = useCallback(async () => {
    if (!gridOptionsRef.current) return
    setLoading(true)
    try {
      const refreshRows = reload.current ?? refresh
      await refreshRows(projectUuid)
    } finally {
      gridOptionsRef.current.context = {
        ...gridOptionsRef.current.context,
        errors: new InputError(),
      }
      setLoading(false)
      gridApi.current?.refreshCells({ force: true })
    }
  }, [projectUuid, refresh])

  const validateBeforeSubmit = useCallback((rows: WbsItemSearchRow[]) => {
    if (!gridOptionsRef.current) return
    const targetRowUuids = rows.filter(row => row.edited).map(row => row.uuid)
    const errors = validateNodes({
      gridApi: gridApi.current,
      columnApi: columnApi.current,
      uuids: targetRowUuids,
    })
    gridOptionsRef.current.context = {
      ...gridOptionsRef.current.context,
      errors,
    }
  }, [])

  const onSubmit = useCallback(async () => {
    if (!gridApi?.current) return
    setLoading(true)
    try {
      gridApi.current?.stopEditing()
      validateBeforeSubmit(prevData.current)
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              return prevData.current?.findIndex(d => d.uuid === id) + 1
            }),
          })
        )
        return
      }

      bulkSheetState.saveImmediately()

      // Save
      const [
        updateWbsItemResponse,
        updateSprintProductResponse,
        updateSprintBacklogResponse,
      ] = submit.current ? await submit.current() : await save()

      if (updateWbsItemResponse.hasWarning) {
        const messages = extractValuesFromResponse(
          updateWbsItemResponse.json,
          'messages'
        )
        handleWarning(messages, uuid => {
          const target = data.find(v => v.wbsItem?.uuid === uuid)
          return target?.wbsItem?.displayName
        })
      }

      if (
        !updateWbsItemResponse.hasError &&
        !updateWbsItemResponse.hasWarning &&
        !updateSprintProductResponse.hasError &&
        !updateSprintBacklogResponse.hasError
      ) {
        store.dispatch(
          addScreenMessage(applicationFunctionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    gridApi,
    gridOptions.context.errors,
    save,
    data,
    applicationFunctionUuid,
    refreshAll,
    bulkSheetState,
  ])

  // Search condition header Actions ---------------

  const onSearch = useCallback(() => {
    refreshAll()
    saveSearchCondition()
  }, [refreshAll, saveSearchCondition])

  // This is used when applying permanent ui state.
  const overrideSearchConditionAndSearch = useCallback(
    (newSearchCondition: WbsItemSearchConditionVO) => {
      overrideSearchCondition(newSearchCondition)
      setRefreshAfterUpdateState(true)
    },
    [overrideSearchCondition]
  )

  const applyStoredState = useCallback(
    (storedState: SearchConditionAndColumnState) => {
      overrideSearchConditionAndSearch(storedState.searchCondition)
      columnApi.current?.applyColumnState({
        state: storedState.columnState,
      })
    },
    [overrideSearchConditionAndSearch]
  )

  // Header Actions ---------------

  const onChangeWorkloadUnit = useCallback(
    (workloadUnit: WorkloadUnit) => {
      updatePageState({ workloadUnit })
    },
    [updatePageState]
  )

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialog.openCancelConfirmation()
    } else {
      refreshAll()
    }
  }, [dialog, hasChanged, refreshAll])

  const onChangeQuickFilters = useCallback((values: string[]) => {
    const filters: QuickFilterKeys[] = values.map(v => QuickFilterKeys[v])
    quickFilters.current = filters
    gridApi.current?.onFilterChanged()
    gridApi.current?.refreshCells({ force: true })
  }, [])

  const onDeleteFilteredColumn = useCallback((column: ColDef) => {
    if (!column || !gridApi.current) return
    const filterModel = gridApi.current?.getFilterModel()
    delete filterModel[column.colId || column.field || '']
    gridApi.current?.setFilterModel(filterModel)
  }, [])

  const resetFilters = useCallback(() => {
    gridApi.current?.setFilterModel([])
  }, [])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<WbsItemSearchRow>) => {
      columnApi.current?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    []
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    columnApi.current?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<WbsItemSearchRow>, sort: 'asc' | 'desc' | null) => {
      columnApi.current?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    []
  )

  const onChangeRowHeight = useCallback(
    (rowHeight: number) => {
      updatePageState({ rowHeight })
    },
    [updatePageState]
  )

  const onChangeDateFormat = useCallback(
    (dateFormat: string) => {
      updatePageState({ dateFormat })
    },
    [updatePageState]
  )

  // BulksSheetView Actions ---------------

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api?.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent<WbsItemSearchRow>) => {
      if (!_.isEmpty(bulkSheetState.filterState)) {
        gridApi.current?.setFilterModel(bulkSheetState.filterState)
      }
      if (!_.isEmpty(bulkSheetState.columnState)) {
        event.columnApi.applyColumnState({
          state: bulkSheetState.columnState,
          applyOrder: true,
        })
      }
      bulkSheetState.finishRestoring()
      setLoading(false)
    },
    [bulkSheetState]
  )

  const rememberColumnState = useCallback(() => {
    const columnState = columnApi.current?.getColumnState()
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [bulkSheetState])

  const onSortedColumnsStateChanged = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter(col => !!col.sort)
      .map(col => {
        return {
          ...e.api.getColumnDef(col.colId),
          colId: col.colId,
        }
      })
      .filter(v => !!v) as ColDef[]
    e.api.setSuppressRowDrag(0 < sortedColumns.length)

    const columnState = columnApi.current?.getColumnState()
    const sortedColumnState: { [colId: string]: ColumnState } = {}
    columnState &&
      columnState.forEach(state => {
        if (state.sort) {
          sortedColumnState[state.colId] = state
        }
      })
    const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
      return {
        colId: col.colId,
        field: col.field,
        headerName: col.headerName,
        sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
      }
    })

    setSortColumnsState(sortedColumnsState)
  }, [])

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [rememberColumnState, onSortedColumnsStateChanged]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [rememberColumnState, onSortedColumnsStateChanged]
  )

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<WbsItemSearchRow>) =>
      e.api?.refreshCells({
        columns: [
          'rowNumber',
          FIELD_WBSITEM_ACTUALDATE_START,
          FIELD_WBSITEM_ACTUALDATE_END,
        ],
        force: true,
      }),
    []
  )

  const onGridReady = useCallback(
    (event: GridReadyEvent<WbsItemSearchRow>) => {
      gridOptionsRef.current = gridOptions
      gridApi.current = event.api
      columnApi.current = event.columnApi
    },
    [gridOptions]
  )

  const doesExternalFilterPass = useCallback(
    (node: RowNode<WbsItemSearchRow>): boolean => {
      if (
        quickFilters.current?.includes(QuickFilterKeys.ACCOUNTABLE) &&
        node.data?.wbsItem.accountable?.uuid !== loginUserUuid
      ) {
        return false
      }
      if (
        quickFilters.current?.includes(
          QuickFilterKeys.RESPONSIBLE_OR_ASSIGNEE
        ) &&
        node.data?.wbsItem.responsible?.uuid !== loginUserUuid &&
        node.data?.wbsItem.assignee?.uuid !== loginUserUuid
      ) {
        return false
      }
      return true
    },
    [loginUserUuid, quickFilters]
  )

  const isExternalFilterPresent = useCallback(() => true, [])

  const initializeColumnState = useCallback(() => {
    columnApi.current?.resetColumnState()
    const filterModel = gridApi.current?.getFilterModel()
    const quick = filterModel?.['uuid']
    gridApi.current?.setFilterModel(quick ? { uuid: quick } : null)
  }, [])

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (!gridOptionsRef.current) return
    gridOptionsRef.current.context = {
      ...gridOptionsRef.current.context,
      workloadUnit,
      dateFormat,
      workloadUnitState,
    }
    gridApi.current?.refreshCells({ force: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workloadUnit, dateFormat, workloadUnitState])

  useEffect(() => {
    gridApi.current?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHeight])

  const convertColumnStateToNewColID = useCallback((colState: ColumnState) => {
    if (CONVERT_COLUMN_FIELD_MAP_KEYS.includes(colState.colId)) {
      return {
        ...colState,
        colId: CONVERT_COLUMN_FIELD_MAP[colState.colId],
      }
    }
    return colState
  }, [])

  const convertFilterStateToNewColId = useCallback(
    (filterState: { [key: string]: string }) => {
      const newFilterState = _.cloneDeep(filterState)
      CONVERT_COLUMN_FIELD_MAP_KEYS.forEach((key: string) => {
        if (key in filterState) {
          newFilterState[CONVERT_COLUMN_FIELD_MAP[key]] = filterState[key]
        }
      })
      return newFilterState
    },
    []
  )

  // Key bind ------
  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    []
  )

  // Bread crumb ------
  useEffect(() => {
    const fetchProjectPlanUuid = async () => {
      if (searchCondition.rootUuid) {
        const converted = await getProjectPlanUuidByWbsItemUuid(
          searchCondition.rootUuid
        )
        setRootProjectPlanUuid(converted)
      }
    }
    fetchProjectPlanUuid()
  }, [searchCondition.rootUuid])

  const getNewRootUuid = useCallback(
    async (
      newRootProjectPlanUuid: string | undefined
    ): Promise<string | undefined> => {
      if (!newRootProjectPlanUuid) return undefined
      const path = await getWbsItemUuidByProjectPlanUuid(newRootProjectPlanUuid)
      return path
    },
    []
  )

  const transformer = useWbsItemSearchConditionSerializeService()
  const switchRootWbsItem = useCallback(
    async (
      newRootProjectPlanUuid: string | undefined,
      openInNewTab?: boolean
    ) => {
      const newRootUuid = await getNewRootUuid(newRootProjectPlanUuid)
      const plainObject: StoredWbsItemSearchCondition & { rootUuid?: string } =
        transformer.fromVoToStoredObject(searchCondition)
      plainObject.rootUuid = newRootUuid
      const query = toUrlQuery(plainObject)

      if (openInNewTab) {
        const res = await project.getDetail({ uuid: projectUuid })
        const url = `${window.location.origin}/wbsItemSearch/${res.json.code}${
          query ? `?${query}` : ''
        }`
        window.open(url)
        return
      }
      updateSearchCondition({ rootUuid: newRootUuid })
      setRootProjectPlanUuid(newRootProjectPlanUuid)
      history.replaceState(undefined, '', `?${query}`)
    },
    [
      getNewRootUuid,
      updateSearchCondition,
      setRootProjectPlanUuid,
      transformer,
      projectUuid,
      searchCondition,
    ]
  )

  const onCloseSearchItemLabel = useCallback(() => {
    setRefreshAfterUpdateState(true)
  }, [])

  useEffect(() => {
    if (!refreshAfterUpdateState) return
    onSearch()
    setRefreshAfterUpdateState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAfterUpdateState])

  return (
    <PageArea>
      {functionLayer.size === 1 && (
        <SavePopper loading={loading} onSubmit={onSubmit} />
      )}
      <Loading isLoading={loading} elem={bulkSheetViewRef.current} />
      {rootProjectPlanUuid && (
        <ProjectPlanNewBreadcrumb
          rootProjectPlanUuid={rootProjectPlanUuid}
          onSelectBreadcrumb={(event, breadcrumb) =>
            switchRootWbsItem(breadcrumb.uuid, needToOpenInNewTab(event))
          }
        />
      )}
      <WbsItemSearchSearchConditionHeader
        searchCondition={searchCondition}
        search={onSearch}
        hit={hitDataSize}
        count={count}
        total={totalDataSize}
        onChange={updateSearchCondition}
        resetCondition={resetSearchCondition}
        functionUuid={applicationFunctionUuid}
        applyStoredState={applyStoredState}
        onCloseSearchItemLabel={onCloseSearchItemLabel}
        isLoading={loading}
      />
      <WbsItemSearchWbsHeader
        workloadUnit={workloadUnit}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        onCancel={onReload}
        isLoading={loading}
        onChangeQuickFilters={onChangeQuickFilters}
        quickFilters={quickFilters.current ?? []}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        sortColumnsState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        rowHeight={rowHeight}
        onChangeHeight={onChangeRowHeight}
        onClickExportExcel={dialog.openExcelExport}
        onClickFavoriteColumnFilterButton={dialog.openUiState}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <BulkSheetView
        suppressContextMenu={true}
        doesExternalFilterPass={doesExternalFilterPass}
        gridOptions={gridOptions}
        ref={bulkSheetViewRef}
        rowData={data}
        rowHeight={rowHeight}
        isExternalFilterPresent={isExternalFilterPresent}
        onCellDoubleClicked={dialog.closeStatusPopper}
        onColumnMoved={rememberColumnState}
        onColumnResized={rememberColumnState}
        onColumnVisible={onColumnVisible}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onRowDataUpdated={onRowDataUpdated}
        onSortChanged={onSortChanged}
      />
      {dialog.cancelConfirmation && (
        <CancelConfirmDialog
          open={true}
          onConfirm={() => {
            dialog.closeCancelConfirmation()
            refreshAll()
          }}
          onClose={dialog.closeCancelConfirmation}
        />
      )}
      {dialog.actualResult && (
        <TaskActualWorkDialog
          open={true}
          taskUuid={dialog.taskUuid ?? ''}
          closeHandler={dialog.closeActualResult}
        />
      )}
      <StatusChangePopper
        anchorEl={dialog.statusPopperParams.anchorEl}
        projectUuid={projectUuid}
        wbsItem={dialog.statusPopperParams.wbsItem}
        wbsItemDelta={dialog.statusPopperParams.wbsItemDelta}
        onClose={dialog.closeStatusPopper}
        onAfterUpdate={() =>
          reloadSingleRow(dialog.statusPopperParams.wbsItem?.uuid)
        }
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        columnApi={columnApi.current ?? undefined}
        gridApi={gridApi.current ?? undefined}
        close={columnSetting.close}
        height={bulkSheetViewRef.current?.offsetHeight}
        openSavedUiStateDialog={dialog.openUiState}
        initializeColumnState={initializeColumnState}
        applicationFunctionUuid={applicationFunctionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.columnState}
        offset={110}
      />
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={applicationFunctionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          sharable={false}
          currentUIState={{
            columnState: bulkSheetState.columnState,
            filterState: bulkSheetState.filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, column, filterState, filter } = uiState.UIState
            // convertColumnStateToNewColID: Convert old Col ID to new one because The Saved UI State is saved with the old ColID.
            const applyColumnState: ColumnState[] =
              columnState ?? column.map(convertColumnStateToNewColID)
            if (applyColumnState) {
              bulkSheetState.saveColumnState(applyColumnState)
              columnApi.current?.applyColumnState({
                state: applyColumnState,
                applyOrder: true,
              })
            }
            // convertFilterStateToNewColId: Convert old Col ID to new one because The Saved UI State is saved with the old ColID.
            const applyFilterState =
              filterState ?? convertFilterStateToNewColId(filter)
            if (applyFilterState) {
              bulkSheetState.saveFilterState(applyFilterState)
              gridApi.current?.setFilterModel(applyFilterState)
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
      {dialog.excelExport && (
        <ExcelExportDialog
          onClose={dialog.closeExcelExport}
          onSubmit={onExcelExport}
          columnApi={columnApi.current}
          excludeColIds={EXCEL_EXPORT_EXCLUDE_COLIDS}
        />
      )}
    </PageArea>
  )
}

export default WbsItemSearchNew
