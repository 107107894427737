import {
  ColDef,
  ColumnApi,
  GridApi,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community'
import _ from 'lodash'
import {
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import validator from '../../meta/validator'
import SelectVO from '../../../../vo/SelectVO'

export class InputError {
  protected errors: {
    [id: string]: {
      [field: string]: string
    }
  } = {}

  public add({
    id,
    field,
    message,
  }: {
    id: string
    field: string
    message: string
  }): void {
    if (!this.errors[id]) {
      this.errors[id] = {}
    }
    this.errors[id][field] = message
  }

  public remove({ id, field }: { id: string; field: string }) {
    if (!this.errors[id]) {
      return
    }
    delete this.errors[id][field]
    if (_.isEmpty(this.errors[id])) {
      delete this.errors[id]
    }
  }

  public removeErrorsById(id: string) {
    if (!this.errors[id]) {
      return
    }
    delete this.errors[id]
  }

  public hasMessage(): boolean {
    return 0 < Object.keys(this.errors).length
  }

  public toMessage(idConverter: (id) => string = _ => _) {
    const messages: string[] = []
    const map = {}
    Object.keys(this.errors).forEach(id => {
      map[idConverter(id)] = id
    })
    Object.keys(map)
      .sort((a, b) => (a === b ? 0 : Number(a) > Number(b) ? 1 : -1))
      .forEach(alt => {
        const id = map[alt]
        const row = this.errors[id]
        const fields = Object.keys(row)
        fields.forEach(field => {
          messages.push(`#${alt} > ${row[field]}`)
        })
      })
    return messages.join('\n')
  }
}

export const setError = (
  data,
  message,
  { context, node, colDef }: ICellRendererParams
) => {
  const errors: InputError = context.errors
  if (errors && data.edited) {
    if (message) {
      addInputError(errors, node.id, message, colDef)
    } else {
      errors.remove({ id: node.id ?? '', field: colDef?.field ?? '' })
    }
  }
  return message
}

export const addInputError = (
  errors: InputError,
  nodeId: string | undefined,
  message: string,
  colDef: ColDef | undefined
) => {
  errors.add({
    id: nodeId ?? '',
    field: colDef?.field ?? '',
    message: `${colDef?.headerName} > ${message}`,
  })
}

const getValidateValue = (
  gridApi: GridApi,
  node: RowNode,
  colId: string,
  uiMeta: FunctionProperty
) => {
  if (!gridApi) return
  const val = gridApi.getValue(colId, node)
  if (uiMeta?.propertyType === PropertyType.Select) {
    const target = uiMeta.valuesAllowed.find(v => v.value === val)
    return target ? SelectVO.fromCustomEnum(target) : val
  }
  return val
}

export const validateNodes = ({
  gridApi,
  columnApi,
  uuids,
}: {
  gridApi: GridApi | undefined | null
  columnApi: ColumnApi | undefined | null
  uuids: string[]
}): InputError => {
  const errors = new InputError()
  if (!gridApi || !columnApi) return errors

  const colIds = columnApi.getColumnState().map(colState => colState.colId)

  uuids.forEach(uuid => {
    const node: RowNode | undefined = gridApi.getRowNode(uuid)
    if (node === undefined) return
    colIds?.forEach(colId => {
      const colDef = columnApi.getColumn(colId)?.getColDef()
      if (!colDef || !colDef.cellRendererParams) return
      const uiMeta: FunctionProperty = colDef.cellRendererParams.uiMeta
      const val = getValidateValue(gridApi, node, colId, uiMeta)
      const err = uiMeta
        ? validator
            .validate(val, node.data, uiMeta, () => undefined)
            ?.getMessage()
        : undefined
      if (err) {
        addInputError(errors, node.id, err, colDef)
      }
    })
  })

  return errors
}
