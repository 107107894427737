import { useCallback, useEffect, useState } from 'react'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'

const taskTabSearchConditionFields = [
  'status',
  'scheduledStartDate',
  'scheduledEndDate',
  'actualStartDate',
  'actualEndDate',
] as const

export type MyWbsItemsTaskTabSearchCondition = Pick<
  WbsItemSearchConditionVO,
  (typeof taskTabSearchConditionFields)[number]
>

const taskTabDefaultSearchCondition = {
  types: [WbsItemType.TASK],
  status: [WbsItemStatus.TODO, WbsItemStatus.DOING, WbsItemStatus.REVIEW],
}

type UpdateWbsItemSearchSearchCondition = (
  updated: Partial<MyWbsItemsTaskTabSearchCondition>
) => void
type WbsItemSearchSearchConditionUseCases = {
  updateSearchCondition: UpdateWbsItemSearchSearchCondition
  resetSearchCondition: () => void
}

export const useTaskTabSearchCondition = (): {
  initialized: boolean
  searchCondition: WbsItemSearchConditionVO
} & WbsItemSearchSearchConditionUseCases => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [searchCondition, setSearchCondition] =
    useState<MyWbsItemsTaskTabSearchCondition>(taskTabDefaultSearchCondition)

  useEffect(() => {
    const restore = async () => {
      setInitialized(true)
    }
    restore()
  }, [])

  const updateSearchCondition = useCallback(
    (updated: Partial<MyWbsItemsTaskTabSearchCondition>) => {
      setSearchCondition(prev => ({
        ...prev,
        ...updated,
      }))
    },
    []
  )

  const resetSearchCondition = useCallback(() => {
    setSearchCondition(taskTabDefaultSearchCondition)
  }, [])

  return {
    initialized,
    searchCondition,
    updateSearchCondition,
    resetSearchCondition,
  }
}
