import { ICellRendererParams } from 'ag-grid-community'
import { StatusComboBox } from '../ComboBox/StatusComboBox'
import { ProgressIcon } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/ProgressIcon'
import { styled } from '@mui/material'

export const ProjectPlanNewStatusArea = styled('span')({
  width: '100%',
  height: '100%',
  padding: '0px 12px',
  gap: '6px',
  display: 'flex',
  justifyContent: 'inherit',
  alignItems: 'center',
  overflow: 'hidden',
  overflowX: 'auto',
  scrollbarWidth: 'none',
})
export const ProgressIconArea = styled('span')({
  minWidth: '20px',
  width: '20px',
  display: 'flex',
  justifyContent: 'center',
})

export const ProjectPlanNewStatusCellRenderer = (
  params: ICellRendererParams
) => {
  const { value, api, node, colDef, data } = params
  const wbsItem = data?.body?.wbsItem ?? data?.wbsItem

  return (
    <ProjectPlanNewStatusArea>
      <ProgressIconArea>
        <ProgressIcon wbsItem={wbsItem} fontSize={20} />
      </ProgressIconArea>
      <StatusComboBox
        value={value}
        options={[]}
        dropDownListOpen={false}
        onClick={() => {
          api.startEditingCell({
            rowIndex: node.rowIndex ?? 0,
            colKey: colDef?.colId ?? colDef?.field ?? '',
          })
        }}
      />
    </ProjectPlanNewStatusArea>
  )
}
