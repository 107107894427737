import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import TicketListApi, {
  TicketListDetail,
} from '../../../../lib/functions/ticketList'
import WbsItemApi, { WbsItemDetail } from '../../../../lib/functions/wbsItem'
import { DefaultTitle } from '../../../components/headers/HeaderBar/Title/DefaultTitle'
import { WbsItemIcon } from '../../../components/icons/WbsItemIcon'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'

interface ProgressReportTitleProps {
  title?: string
  wbsItemType?: WbsItemTypeVO
}

const ProgressReportTitle = ({
  title,
  wbsItemType,
}: ProgressReportTitleProps) => {
  return (
    <Box sx={{ display: 'flex', padding: '0 7px' }}>
      <WbsItemIcon type={wbsItemType} />
      <DefaultTitle title={title || ''} />
    </Box>
  )
}

export const ProgressReportWbsItemTitle = ({
  rootWbsItemUuid,
}: {
  rootWbsItemUuid?: string
}) => {
  const [state, setState] = useState<ProgressReportTitleProps>({})
  useEffect(() => {
    const updateState = async () => {
      if (!rootWbsItemUuid) {
        return
      }
      const wbsItem: WbsItemDetail = (
        await WbsItemApi.getDetail({ uuid: rootWbsItemUuid })
      ).json
      setState({
        title: wbsItem?.displayName || '',
        wbsItemType: new WbsItemTypeVO(wbsItem?.typeDto),
      })
    }
    updateState()
  }, [rootWbsItemUuid])
  return (
    <ProgressReportTitle title={state.title} wbsItemType={state.wbsItemType} />
  )
}

export const ProgressReportTicketListTitle = ({
  projectUuid,
  ticketListUuid,
}: {
  projectUuid?: string
  ticketListUuid?: string
}) => {
  const [state, setState] = useState<ProgressReportTitleProps>({})
  useEffect(() => {
    const updateState = async () => {
      if (!projectUuid || !ticketListUuid) {
        return
      }
      const ticketList: TicketListDetail = (
        await TicketListApi.findByUuid(ticketListUuid)
      ).json
      setState({
        title: ticketList?.wbsItem?.displayName || '',
        wbsItemType: new WbsItemTypeVO(ticketList?.wbsItem?.typeDto),
      })
    }
    updateState()
  }, [projectUuid, ticketListUuid])
  return (
    <ProgressReportTitle title={state.title} wbsItemType={state.wbsItemType} />
  )
}
