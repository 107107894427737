import { useCallback } from 'react'
import { MyWbsItemsTaskTabSearchCondition } from './useSearchCondition'

export const useOnSingleValueChangedGenerator = (
  onChange: (updated: Partial<MyWbsItemsTaskTabSearchCondition>) => void
) => {
  const onChangeValueGenerator = useCallback(
    (key: keyof MyWbsItemsTaskTabSearchCondition) => {
      return async (
        value: MyWbsItemsTaskTabSearchCondition[typeof key] | undefined
      ) => {
        onChange({ [key]: value })
      }
    },
    []
  )

  return {
    onChangeValueGenerator,
  }
}
