import EntitySearch from '../commons/entitySearch'
import store from '../../store'
import API, { APIResponse } from '../commons/api'
import { FunctionProperty } from '../commons/appFunction'
import WbsItemApi from './wbsItem'

export interface GetDeliverablesProps {
  projectUuid: string
  deliverable: {
    displayName: string
  }
  type: string
  offset?: number
  limit?: number
}

class Deliverable extends EntitySearch {
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: store.getState().project.selected,
        displayName: query,
        limit: query.length === 0 ? 10 : undefined,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  toResponse = (response: any) => ({
    uuid: response.uuid,
    code: response.code,
    name: `[${response.code}] ${response.displayName}`,
    displayName: response.displayName,
  })

  entitySearchApi = (
    props: GetDeliverablesProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    if (!props.limit) {
      props.limit = 10
    }
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/deliverables/find',
      props,
      true,
      signal
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await WbsItemApi.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }
}

export default new Deliverable()
