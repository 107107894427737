import _ from 'lodash'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { ProjectPlanCumulation } from '../../../../lib/functions/projectPlan'
import { NewWbsItemRow, ProjectPlanNewRow } from '../projectPlanNew'
import DateVO from '../../../../vo/DateVO'
import { WbsItemStatus } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'

const today = DateVO.now()

export class Progress {
  constructor(
    readonly c: ProjectPlanCumulation,
    readonly target: AggregateTarget,
    readonly field: AggregateField
  ) {}

  static of(
    c: ProjectPlanCumulation,
    context: {
      aggregateTarget: AggregateTarget
      aggregateField: AggregateField
    }
  ): Progress {
    return new Progress(c, context.aggregateTarget, context.aggregateField)
  }

  private get isWorkload(): boolean {
    return this.field === AggregateField.WBS_ITEM_WORKLOAD
  }

  private get isCount(): boolean {
    return this.field === AggregateField.WBS_ITEM_COUNT
  }

  private get isDeliverable(): boolean {
    return this.target === WbsItemType.DELIVERABLE
  }

  private get isTask(): boolean {
    return this.target === WbsItemType.TASK
  }

  private getRate = (
    numerator: number,
    denominator?: number
  ): number | undefined => {
    if (denominator === undefined || denominator === 0) return
    return numerator / denominator
  }

  public get total(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) {
        return (
          this.c.sumDeliverableEstimatedHour -
          this.c.sumDeliverableEstimatedHourDiscard
        )
      }
      if (this.isTask) {
        return this.c.sumTaskEstimatedHour - this.c.sumTaskEstimatedHourDiscard
      }
    } else if (this.isCount) {
      if (this.isDeliverable) {
        return this.c.countDeliverable - this.c.countStatusDeliverableDiscard
      }
      if (this.isTask) return this.c.countTask - this.c.countStatusTaskDiscard
    }
    return 0
  }

  public get scheduledToBeStarted(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableToBeStarted
      if (this.isTask) return this.c.sumTaskToBeStarted
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countDeliverableToBeStarted
      if (this.isTask) return this.c.countTaskToBeStarted
    }
    return 0
  }

  public get scheduledToBeCompleted(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableToBeCompleted
      if (this.isTask) return this.c.sumTaskToBeCompleted
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countDeliverableToBeCompleted
      if (this.isTask) return this.c.countTaskToBeCompleted
    }
    return 0
  }

  public get plannedValue(): number {
    return this.scheduledToBeCompleted
  }

  public get started(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) {
        return this.total - this.c.sumDeliverableEstimatedHourTodo
      }
      if (this.isTask) return this.total - this.c.sumTaskEstimatedHourTodo
    } else if (this.isCount) {
      if (this.isDeliverable) {
        return this.total - this.c.countStatusDeliverableTodo
      }
      if (this.isTask) return this.total - this.c.countStatusTaskTodo
    }
    return 0
  }

  public get completed(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableEstimatedHourDone
      if (this.isTask) return this.c.sumTaskEstimatedHourDone
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countStatusDeliverableDone
      if (this.isTask) return this.c.countStatusTaskDone
    }
    return 0
  }

  public get earnedValue(): number {
    return this.completed
  }

  public get preceding(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableEndPreceding
      if (this.isTask) return this.c.sumTaskEndPreceding
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countDeliverableEndPreceding
      if (this.isTask) return this.c.countTaskEndPreceding
    }
    return 0
  }

  public get startDelayed(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableStartDelayed
      if (this.isTask) return this.c.sumTaskStartDelayed
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countDeliverableStartDelayed
      if (this.isTask) return this.c.countTaskStartDelayed
    }
    return 0
  }

  public get delayed(): number {
    if (this.isWorkload) {
      if (this.isDeliverable) return this.c.sumDeliverableEndDelayed
      if (this.isTask) return this.c.sumTaskEndDelayed
    } else if (this.isCount) {
      if (this.isDeliverable) return this.c.countDeliverableEndDelayed
      if (this.isTask) return this.c.countTaskEndDelayed
    }
    return 0
  }

  public get remaining(): number {
    return this.total - this.earnedValue
  }

  public get scheduledStartRate(): number | undefined {
    return this.getRate(this.scheduledToBeStarted, this.total)
  }

  public get scheduledProgressRate(): number | undefined {
    return this.getRate(this.scheduledToBeCompleted, this.total)
  }

  public get startRate(): number | undefined {
    return this.getRate(this.started, this.total)
  }

  public get progressRate(): number | undefined {
    return this.getRate(this.completed, this.total)
  }

  private get bac(): number {
    if (this.isDeliverable) {
      return (
        this.c.sumDeliverableEstimatedHour -
        this.c.sumDeliverableEstimatedHourDiscard
      )
    }
    if (this.isTask) {
      return this.c.sumTaskEstimatedHour - this.c.sumTaskEstimatedHourDiscard
    }
    return 0
  }

  private get ev(): number {
    if (this.isDeliverable) return this.c.sumDeliverableEstimatedHourDone
    if (this.isTask) return this.c.sumTaskEstimatedHourDone
    return 0
  }

  private get pv(): number {
    if (this.isDeliverable) return this.c.sumDeliverableToBeCompleted
    if (this.isTask) return this.c.sumTaskToBeCompleted
    return 0
  }

  private get ac(): number {
    return this.c.sumActualHour
  }

  public get costVariance(): number {
    return this.ev - this.ac
  }

  public get costPerformanceIndex(): number | undefined {
    return this.getRate(this.ev, this.ac)
  }

  public get scheduleVariance(): number {
    return this.ev - this.pv
  }

  public get schedulePerformanceIndex(): number | undefined {
    return this.getRate(this.ev, this.pv)
  }

  public get estimateToComplete(): number | undefined {
    return this.getRate(this.bac - this.ev, this.costPerformanceIndex)
  }

  public get estimateAtCompletion(): number | undefined {
    if (!this.costPerformanceIndex) return undefined
    return this.bac / this.costPerformanceIndex
  }

  public get varianceAtCompletion(): number | undefined {
    if (!this.estimateAtCompletion) return undefined
    return this.bac - this.estimateAtCompletion
  }
}

export class ProgressDetail {
  constructor(readonly w: NewWbsItemRow, readonly field: AggregateField) {}

  static of(
    w: NewWbsItemRow,
    context: { aggregateField: AggregateField }
  ): ProgressDetail {
    return new ProgressDetail(w, context.aggregateField)
  }

  get value(): number {
    if (this.w.status === WbsItemStatus.DISCARD) return 0
    return (
      (this.field === AggregateField.WBS_ITEM_WORKLOAD
        ? this.w.estimatedHour
        : 1) ?? 0
    )
  }

  public get total(): number {
    return this.value
  }

  public get scheduledToBeStarted(): number {
    const scheduledStartDate = this.w.scheduledDate?.startDate
      ? new DateVO(this.w.scheduledDate.startDate)
      : undefined
    return scheduledStartDate && scheduledStartDate.isSameOrBefore(today)
      ? this.value
      : 0
  }

  public get scheduledToBeCompleted(): number {
    const scheduledEndDate = this.w.scheduledDate?.endDate
      ? new DateVO(this.w.scheduledDate.endDate)
      : undefined
    return scheduledEndDate && scheduledEndDate.isSameOrBefore(today)
      ? this.value
      : 0
  }

  public get completed(): number {
    const status = this.w.status
    return status === WbsItemStatus.DONE ? this.value : 0
  }

  public get startPreceding(): number {
    const status = this.w.status
    const scheduledStartDate = this.w.scheduledDate?.startDate
      ? new DateVO(this.w.scheduledDate.startDate)
      : undefined
    return ![WbsItemStatus.TODO, WbsItemStatus.DISCARD].includes(status!) &&
      scheduledStartDate &&
      today.isBefore(scheduledStartDate)
      ? this.value
      : 0
  }

  public get endPreceding(): number {
    const status = this.w.status
    const scheduledEndDate = this.w.scheduledDate?.endDate
      ? new DateVO(this.w.scheduledDate.endDate)
      : undefined
    return status === WbsItemStatus.DONE &&
      scheduledEndDate &&
      today.isBefore(scheduledEndDate)
      ? this.value
      : 0
  }

  public get startDelayed(): number {
    const status = this.w.status
    const scheduledStartDate = this.w.scheduledDate?.startDate
      ? new DateVO(this.w.scheduledDate.startDate)
      : undefined
    return status === WbsItemStatus.TODO &&
      scheduledStartDate &&
      scheduledStartDate.isBefore(today)
      ? this.value
      : 0
  }

  public get endDelayed(): number {
    const status = this.w.status
    const scheduledEndDate = this.w.scheduledDate?.endDate
      ? new DateVO(this.w.scheduledDate.endDate)
      : undefined
    return status !== WbsItemStatus.DONE &&
      scheduledEndDate &&
      scheduledEndDate.isBefore(today)
      ? this.value
      : 0
  }

  public get startUnplanned(): number {
    return !this.w.scheduledDate?.startDate ? this.value : 0
  }

  public get endUnplanned(): number {
    return !this.w.scheduledDate?.endDate ? this.value : 0
  }

  public get remaining(): number {
    const status = this.w.status
    return status !== WbsItemStatus.DONE ? this.value : 0
  }
}

export class ReportReCalculator {
  private static apply(
    row: ProjectPlanNewRow,
    ancestors: ProjectPlanNewRow[],
    { add }: { add: boolean }
  ): ProjectPlanNewRow[] {
    if (!row.body?.wbsItem) return []
    const projector = new CumulationProjector(row)
    const directParentUuid = directParentUuids(ancestors)
    return ancestors.map(a => {
      if (a.body?.cumulation) {
        const directChildren = directParentUuid.includes(a.uuid)
        a.body.cumulation = add
          ? projector.addTo(a.body.cumulation, directChildren)
          : projector.subtractFrom(a.body.cumulation, directChildren)
        a.body.cumulation = add
          ? projector.addCumulation(a.body.cumulation)
          : projector.subtractCumulation(a.body.cumulation)
      }
      return a
    })
  }

  public static add(
    row: ProjectPlanNewRow,
    ancestors: ProjectPlanNewRow[]
  ): ProjectPlanNewRow[] {
    return this.apply(row, ancestors, { add: true })
  }

  public static delete(
    row: ProjectPlanNewRow,
    ancestors: ProjectPlanNewRow[]
  ): ProjectPlanNewRow[] {
    return this.apply(row, ancestors, { add: false })
  }

  public static update(
    before: ProjectPlanNewRow,
    after: ProjectPlanNewRow,
    ancestors: ProjectPlanNewRow[]
  ): ProjectPlanNewRow[] {
    if (!before.body?.wbsItem || !after.body?.wbsItem) return []
    const deleteProjector = new CumulationProjector(before)
    const addProjector = new CumulationProjector(after)
    const directParentUuid = directParentUuids(ancestors)
    return ancestors.map(a => {
      if (a.body?.cumulation) {
        const directChildren = directParentUuid.includes(a.uuid)
        let c = deleteProjector.subtractFrom(a.body.cumulation, directChildren)
        a.body.cumulation = addProjector.addTo(c, directChildren)
      }
      return a
    })
  }
}

const directParentUuids = (ancestors: ProjectPlanNewRow[]): string[] => {
  let deliverable: string = ''
  let task: string = ''
  for (const p of ancestors.reverse()) {
    if (!task && p.body?.wbsItem?.wbsItemType?.isTask()) {
      task = p.uuid
    }
    if (!deliverable && p.body?.wbsItem?.wbsItemType?.isDeliverable()) {
      deliverable = p.uuid
    }
    if (task && deliverable) {
      break
    }
  }
  return [deliverable, task].filter(v => v)
}

class CumulationProjector {
  private w: NewWbsItemRow
  private c: ProjectPlanCumulation
  private progressHour: ProgressDetail
  private progressCount: ProgressDetail

  constructor(row: ProjectPlanNewRow) {
    this.w = row.body!.wbsItem
    this.c = row.body!.cumulation!
    this.progressHour = ProgressDetail.of(this.w, {
      aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
    })
    this.progressCount = ProgressDetail.of(this.w, {
      aggregateField: AggregateField.WBS_ITEM_COUNT,
    })
  }

  public subtractFrom(
    s: ProjectPlanCumulation,
    directChildren?: boolean
  ): ProjectPlanCumulation {
    const t: ProjectPlanCumulation = _.cloneDeep(s)
    const hour = this.w.estimatedHour ?? 0
    const estimatedAmount =
      this.w.status === WbsItemStatus.DISCARD ? 0 : this.w.estimatedAmount ?? 0
    const actualAmount =
      this.w.status === WbsItemStatus.DISCARD ? 0 : this.w.actualAmount ?? 0
    if (this.w.wbsItemType?.isDeliverable()) {
      t.sumDeliverableEstimatedHour -= hour
      t.countDeliverable -= 1
      switch (this.w.status) {
        case WbsItemStatus.TODO:
          t.sumDeliverableEstimatedHourTodo -= hour
          t.countStatusDeliverableTodo -= 1
          break
        case WbsItemStatus.DOING:
          t.sumDeliverableEstimatedHourDoing -= hour
          t.countStatusDeliverableDoing -= 1
          break
        case WbsItemStatus.REVIEW:
          t.sumDeliverableEstimatedHourReview -= hour
          t.countStatusDeliverableReview -= 1
          break
        case WbsItemStatus.DONE:
          t.sumDeliverableEstimatedHourDone -= hour
          t.countStatusDeliverableDone -= 1
          break
        case WbsItemStatus.DISCARD:
          t.sumDeliverableEstimatedHourDiscard -= hour
          t.countStatusDeliverableDiscard -= 1
          break
      }
      // Deliverable hour
      t.sumDeliverableToBeStarted -= this.progressHour.scheduledToBeStarted
      t.sumDeliverableStartPreceding -= this.progressHour.startPreceding
      t.sumDeliverableStartDelayed -= this.progressHour.startDelayed
      t.sumDeliverableStartUnplanned -= this.progressHour.startUnplanned
      t.sumDeliverableToBeCompleted -= this.progressHour.scheduledToBeCompleted
      t.sumDeliverableEndPreceding -= this.progressHour.endPreceding
      t.sumDeliverableEndDelayed -= this.progressHour.endDelayed
      t.sumDeliverableEndUnplanned -= this.progressHour.endUnplanned
      // Deliverable count
      t.countDeliverableToBeStarted -= this.progressCount.scheduledToBeStarted
      t.countDeliverableStartPreceding -= this.progressCount.startPreceding
      t.countDeliverableStartDelayed -= this.progressCount.startDelayed
      t.countDeliverableStartUnplanned -= this.progressCount.startUnplanned
      t.countDeliverableToBeCompleted -=
        this.progressCount.scheduledToBeCompleted
      t.countDeliverableEndPreceding -= this.progressCount.endPreceding
      t.countDeliverableEndDelayed -= this.progressCount.endDelayed
      t.countDeliverableEndUnplanned -= this.progressCount.endUnplanned
      // Amount
      t.sumDeliverableEstimatedAmount -= estimatedAmount
    } else if (this.w.wbsItemType?.isTask()) {
      const actualHour = this.w?.actualHour ?? 0
      t.sumTaskEstimatedHour -= hour
      t.countTask -= 1
      t.sumActualHour -= actualHour
      if (directChildren) {
        t.countTaskOfDirectChildren -= 1
      }
      switch (this.w.status) {
        case WbsItemStatus.TODO:
          t.sumTaskEstimatedHourTodo -= hour
          t.countStatusTaskTodo -= 1
          t.sumActualHourTodo -= actualHour
          break
        case WbsItemStatus.DOING:
          t.sumTaskEstimatedHourDoing -= hour
          t.countStatusTaskDoing -= 1
          t.sumActualHourDoing -= actualHour
          break
        case WbsItemStatus.REVIEW:
          t.sumTaskEstimatedHourReview -= hour
          t.countStatusTaskReview -= 1
          t.sumActualHourReview -= actualHour
          break
        case WbsItemStatus.DONE:
          t.sumTaskEstimatedHourDone -= hour
          t.countStatusTaskDone -= 1
          t.sumActualHourDone -= actualHour
          if (directChildren) {
            t.countTaskDoneOfDirectChildren -= 1
          }
          break
        case WbsItemStatus.DISCARD:
          t.sumTaskEstimatedHourDiscard -= hour
          t.countStatusTaskDiscard -= 1
          t.sumActualHourDiscard -= actualHour
          if (directChildren) {
            t.countTaskDiscardOfDirectChildren -= 1
          }
          break
      }
      t.sumTaskToBeStarted -= this.progressHour.scheduledToBeStarted
      t.sumTaskStartPreceding -= this.progressHour.startPreceding
      t.sumTaskStartDelayed -= this.progressHour.startDelayed
      t.sumTaskStartUnplanned -= this.progressHour.startUnplanned
      t.sumTaskToBeCompleted -= this.progressHour.scheduledToBeCompleted
      t.sumTaskEndPreceding -= this.progressHour.endPreceding
      t.sumTaskEndDelayed -= this.progressHour.endDelayed
      t.sumTaskEndUnplanned -= this.progressHour.endUnplanned
      // Task count
      t.countTaskToBeStarted -= this.progressCount.scheduledToBeStarted
      t.countTaskStartPreceding -= this.progressCount.startPreceding
      t.countTaskStartDelayed -= this.progressCount.startDelayed
      t.countTaskStartUnplanned -= this.progressCount.startUnplanned
      t.countTaskToBeCompleted -= this.progressCount.scheduledToBeCompleted
      t.countTaskEndPreceding -= this.progressCount.endPreceding
      t.countTaskEndDelayed -= this.progressCount.endDelayed
      t.countTaskEndUnplanned -= this.progressCount.endUnplanned
      // Amount
      t.sumTaskEstimatedAmount -= estimatedAmount
      t.sumTaskActualAmount -= actualAmount
    }
    return t
  }

  public addTo(
    s: ProjectPlanCumulation,
    directChildren?: boolean
  ): ProjectPlanCumulation {
    const t: ProjectPlanCumulation = _.cloneDeep(s)
    const hour = this.w.estimatedHour ?? 0
    const estimatedAmount =
      this.w.status === WbsItemStatus.DISCARD ? 0 : this.w.estimatedAmount ?? 0
    const actualAmount =
      this.w.status === WbsItemStatus.DISCARD ? 0 : this.w.actualAmount ?? 0
    if (this.w.wbsItemType?.isDeliverable()) {
      t.sumDeliverableEstimatedHour += hour
      t.countDeliverable += 1
      switch (this.w.status) {
        case WbsItemStatus.TODO:
          t.sumDeliverableEstimatedHourTodo += hour
          t.countStatusDeliverableTodo += 1
          break
        case WbsItemStatus.DOING:
          t.sumDeliverableEstimatedHourDoing += hour
          t.countStatusDeliverableDoing += 1
          break
        case WbsItemStatus.REVIEW:
          t.sumDeliverableEstimatedHourReview += hour
          t.countStatusDeliverableReview += 1
          break
        case WbsItemStatus.DONE:
          t.sumDeliverableEstimatedHourDone += hour
          t.countStatusDeliverableDone += 1
          break
        case WbsItemStatus.DISCARD:
          t.sumDeliverableEstimatedHourDiscard += hour
          t.countStatusDeliverableDiscard += 1
          break
      }
      // Deliverable hour
      t.sumDeliverableToBeStarted += this.progressHour.scheduledToBeStarted
      t.sumDeliverableStartPreceding += this.progressHour.startPreceding
      t.sumDeliverableStartDelayed += this.progressHour.startDelayed
      t.sumDeliverableStartUnplanned += this.progressHour.startUnplanned
      t.sumDeliverableToBeCompleted += this.progressHour.scheduledToBeCompleted
      t.sumDeliverableEndPreceding += this.progressHour.endPreceding
      t.sumDeliverableEndDelayed += this.progressHour.endDelayed
      t.sumDeliverableEndUnplanned += this.progressHour.endUnplanned
      // Deliverable count
      t.countDeliverableToBeStarted += this.progressCount.scheduledToBeStarted
      t.countDeliverableStartPreceding += this.progressCount.startPreceding
      t.countDeliverableStartDelayed += this.progressCount.startDelayed
      t.countDeliverableStartUnplanned += this.progressCount.startUnplanned
      t.countDeliverableToBeCompleted +=
        this.progressCount.scheduledToBeCompleted
      t.countDeliverableEndPreceding += this.progressCount.endPreceding
      t.countDeliverableEndDelayed += this.progressCount.endDelayed
      t.countDeliverableEndUnplanned += this.progressCount.endUnplanned
      // Amount
      t.sumDeliverableEstimatedAmount += estimatedAmount
    } else if (this.w.wbsItemType?.isTask()) {
      const actualHour = this.w?.actualHour ?? 0
      t.sumTaskEstimatedHour += hour
      t.countTask += 1
      if (directChildren) {
        t.countTaskOfDirectChildren += 1
      }
      t.sumActualHour += actualHour
      switch (this.w.status) {
        case WbsItemStatus.TODO:
          t.sumTaskEstimatedHourTodo += hour
          t.countStatusTaskTodo += 1
          t.sumActualHourTodo += actualHour
          break
        case WbsItemStatus.DOING:
          t.sumTaskEstimatedHourDoing += hour
          t.countStatusTaskDoing += 1
          t.sumActualHourDoing += actualHour
          break
        case WbsItemStatus.REVIEW:
          t.sumTaskEstimatedHourReview += hour
          t.countStatusTaskReview += 1
          t.sumActualHourReview += actualHour
          break
        case WbsItemStatus.DONE:
          t.sumTaskEstimatedHourDone += hour
          t.countStatusTaskDone += 1
          if (directChildren) {
            t.countTaskDoneOfDirectChildren += 1
          }
          t.sumActualHourDone += actualHour
          break
        case WbsItemStatus.DISCARD:
          t.sumTaskEstimatedHourDiscard += hour
          t.countStatusTaskDiscard += 1
          if (directChildren) {
            t.countTaskDiscardOfDirectChildren += 1
          }
          t.sumActualHourDiscard += actualHour
          break
      }
      // Task hour
      t.sumTaskToBeStarted += this.progressHour.scheduledToBeStarted
      t.sumTaskStartPreceding += this.progressHour.startPreceding
      t.sumTaskStartDelayed += this.progressHour.startDelayed
      t.sumTaskStartUnplanned += this.progressHour.startUnplanned
      t.sumTaskToBeCompleted += this.progressHour.scheduledToBeCompleted
      t.sumTaskEndPreceding += this.progressHour.endPreceding
      t.sumTaskEndDelayed += this.progressHour.endDelayed
      t.sumTaskEndUnplanned += this.progressHour.endUnplanned
      // Task count
      t.countTaskToBeStarted += this.progressCount.scheduledToBeStarted
      t.countTaskStartPreceding += this.progressCount.startPreceding
      t.countTaskStartDelayed += this.progressCount.startDelayed
      t.countTaskStartUnplanned += this.progressCount.startUnplanned
      t.countTaskToBeCompleted += this.progressCount.scheduledToBeCompleted
      t.countTaskEndPreceding += this.progressCount.endPreceding
      t.countTaskEndDelayed += this.progressCount.endDelayed
      t.countTaskEndUnplanned += this.progressCount.endUnplanned
      // Amount
      t.sumTaskEstimatedAmount += estimatedAmount
      t.sumTaskActualAmount += actualAmount
    }
    return t
  }

  public subtractCumulation(t: ProjectPlanCumulation): ProjectPlanCumulation {
    return ProjectPlanCumulation.minus(t, this.c)
  }

  public addCumulation(t: ProjectPlanCumulation): ProjectPlanCumulation {
    return ProjectPlanCumulation.sum([t, this.c])
  }
}
