import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { ICellEditorParams } from 'ag-grid-community'
import {
  Autocomplete,
  Avatar,
  InputBase,
  MenuItem,
  Popper,
  Typography,
} from '@mui/material'
import { Color } from '../../../../../styles/commonStyles'
import { getLabel, I18n } from '../../../../../lib/commons/i18nLabel'
import { moveToNextRow } from './index'

type EntityType = {
  uuid: string
  code?: string
  name?: string
  nameI18n?: I18n
  displayName?: string
  iconUrl?: string
}

const getEntityLabel = (entity?: EntityType): string => {
  if (!entity) return ''
  return getLabel(entity.nameI18n) ?? entity.name ?? entity.displayName ?? ''
}

export interface Props
  extends ICellEditorParams<EntityType | string | undefined> {
  entity?: string // Get options with this key from context
  fetch?: (data?) => Promise<EntityType[]> // Fetch options from data
  search?: (text: string, data?) => Promise<EntityType[]> // Server side filter
  optionsFilter?: (option: any, data: any) => boolean // option filter function. Return false if excluded.
}

export const EntitySearchCellEditor = forwardRef(
  (
    {
      entity,
      fetch,
      search,
      optionsFilter,
      value,
      api,
      context,
      colDef,
      column,
      rowIndex,
      data,
    }: Props,
    ref
  ) => {
    const [val, setVal] = useState<EntityType | undefined>(
      value?.uuid ?? value ?? undefined
    )
    const [text, setText] = useState('')
    const [options, setOptions] = useState<EntityType[]>(() => {
      if (entity && entity in context) {
        return optionsFilter
          ? context[entity].filter(o => optionsFilter(o, data)) || []
          : context[entity] || []
      }
      return value ? [value] : []
    })

    const firstValueSet = useRef(true)
    const firstTextSet = useRef(true)
    const inputRef = useRef<HTMLSelectElement>(null)

    const searchOptions = useMemo(
      () =>
        _.debounce(async v => {
          search && setOptions(await search(v, data))
        }, 300),
      []
    )

    const fetchOptions = useCallback(async () => {
      fetch && setOptions(await fetch(data))
    }, [])

    useEffect(() => {
      inputRef.current?.focus()
      fetch && fetchOptions()
    }, [])

    useEffect(() => {
      if (firstValueSet.current) {
        firstValueSet.current = false
        return
      }
      api.stopEditing()
      api.setFocusedCell(rowIndex, column)
    }, [val])

    useEffect(() => {
      if (firstTextSet.current) {
        firstTextSet.current = false
      } else if (search) {
        searchOptions(text)
      }
    }, [text])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (entity && typeof val === 'string') {
            return (context[entity] ?? []).find(v => v.uuid === val)
          }
          return val
        },
      }
    })

    return (
      <Autocomplete
        id={`entity-search-cell-editor-${colDef.field}-${rowIndex}`}
        value={val}
        // Input
        inputValue={text}
        onInputChange={(e, v, reason) => reason === 'input' && setText(v ?? '')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            if (api && rowIndex !== api.getLastDisplayedRow()) {
              setTimeout(() => moveToNextRow(api, rowIndex, column), 0)
            }
          }
        }}
        renderInput={params => (
          <InputBase
            inputRef={inputRef}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus={true}
          />
        )}
        // Option
        options={options}
        isOptionEqualToValue={(o, v) => o.uuid === v?.uuid}
        open={true}
        getOptionLabel={option => getEntityLabel(option)}
        filterOptions={(filterOptions, state) => {
          if (!state.inputValue || search) return filterOptions
          return filterOptions.filter(v =>
            getEntityLabel(v).includes(state.inputValue)
          )
        }}
        PopperComponent={params => {
          return (
            <Popper
              {...params}
              placement="bottom-start"
              modifiers={[
                { name: 'offset', options: { offset: () => [5, 5] } },
              ]}
              sx={{
                boxShadow: '0px 8px 10px 1px #00000020',
              }}
            />
          )
        }}
        renderOption={(params, option) => {
          return (
            <MenuItem
              {...params}
              key={`${colDef.field}-${rowIndex}-${option.uuid}`}
              value={option.uuid}
              sx={{
                display: 'flex',
                margin: '2px',
                height: '26px',
                borderRadius: '3px',
                '&:focus': {
                  color: Color.MAIN,
                },
                '&:hover': {
                  color: Color.MAIN,
                },
              }}
            >
              {option.iconUrl && (
                <Avatar
                  variant="circular"
                  src={option.iconUrl}
                  sx={{ margin: '0px 5px', width: 20, height: 20 }}
                />
              )}
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {getLabel(option.nameI18n) ??
                  option.name ??
                  option.displayName ??
                  ''}
              </Typography>
            </MenuItem>
          ) as ReactNode
        }}
        // Event
        onChange={(e, option) => option && setVal(option)}
      />
    )
  }
)
