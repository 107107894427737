import React, {
  forwardRef,
  useEffect,
  useState,
  createRef,
  useCallback,
  useMemo,
} from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { WbsItemStatus } from '../../../../../domain/entity/WbsItemEntity'
import { getWbsItemStatusLabel } from '../../../../../lib/functions/wbsItem'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, ClickAwayListener, Popper, styled } from '@mui/material'
import clsx from 'clsx'
import store from '../../../../../store'
import {
  addKeyBindListeners,
  removeKeyBindListeners,
  updateKeyBindListeners,
} from '../../../../../store/keyBind'
import usePrevious from '../../../../hooks/usePrevious'

interface StyledDropDownProps {
  bgColor: string
  textColor: string
  borderColor: string
  isOpen?: boolean
}

const DropDownContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}))

const KEY_BIND_ID: string = 'STATUS_COMBO_BOX_KEYBIND_ID'
const BUTTON_SHADOW_COLOR_ALPHA_HEX = '4D'
const DropDownButton = styled(Button)<StyledDropDownProps>(
  ({ bgColor, textColor, borderColor }) => ({
    display: 'flex',
    minWidth: '77px',
    height: '22px',
    padding: '4px 6px 4px 8px',
    justifyContent: 'space-between',
    flexShrink: 0,
    alignItems: 'center',
    borderRadius: '4px',
    fontFamily: 'Noto Sans JP',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%',
    backgroundColor: bgColor,
    color: textColor,
    border: borderColor,
    boxShadow: `0px 1px 2px 0px ${colorPalette.monotone[4]}${BUTTON_SHADOW_COLOR_ALPHA_HEX}`,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colorPalette.monotone[2],
      borderColor: colorPalette.monotone[2],
    },
  })
)

const DropDownMenuItem = styled('div')<StyledDropDownProps>(
  ({ bgColor, textColor, borderColor }) => ({
    margin: '3px',
    display: 'flex',
    width: '77px',
    height: '22px',
    padding: '4px 6px 4px 8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Noto Sans JP',
    gap: '6px',
    borderRadius: '4px',
    backgroundColor: bgColor,
    color: textColor,
    border: borderColor,
    '&:hover': {
      backgroundColor: colorPalette.monotone[2],
      borderColor: colorPalette.monotone[2],
    },
    '&.Mui-selected': {
      backgroundColor: `${colorPalette.monotone[2]} !important`,
      borderColor: `${colorPalette.monotone[2]} !important`,
    },
  })
)

const Icon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '14px',
  height: '14px',
  flexShrink: 0,
  color: colorPalette.monotone[4],
})

// TODO
// Possibility to keep color in DB in the future
const getOptionStyles = (value: string) => {
  switch (value) {
    case WbsItemStatus.TODO:
      return {
        backgroundColor: `${colorPalette.monotone[1]}`,
        textColor: `${colorPalette.monotone[4]}`,
        borderColor: `1px solid ${colorPalette.monotone[2]}`,
      }
    case WbsItemStatus.DOING:
      return {
        backgroundColor: `${colorPalette.yellow[1]}`,
        textColor: `${colorPalette.yellow[4]}`,
        borderColor: `1px solid ${colorPalette.yellow[2]}`,
      }
    case WbsItemStatus.REVIEW:
      return {
        backgroundColor: `${colorPalette.green[1]}`,
        textColor: `${colorPalette.green[7]}`,
        borderColor: `1px solid ${colorPalette.green[2]}`,
      }
    case WbsItemStatus.DONE:
      return {
        backgroundColor: `${colorPalette.skyBlue[1]}`,
        textColor: `${colorPalette.skyBlue[7]}`,
        borderColor: `1px solid ${colorPalette.skyBlue[2]}`,
      }
    case WbsItemStatus.DISCARD:
      return {
        backgroundColor: `${colorPalette.monotone[3]}`,
        textColor: `${colorPalette.monotone[0]}`,
        borderColor: `1px solid ${colorPalette.monotone[3]}`,
      }
    default:
      return {
        backgroundColor: `${colorPalette.monotone[0]}`,
        textColor: `${colorPalette.monotone[10]}`,
        borderColor: `${colorPalette.monotone[10]}`,
      }
  }
}

interface StatusComboBoxProps {
  value: string
  options: CustomEnumValue[]
  onChange?: (newValue: string) => void
  onClick?: () => void
  dropDownListOpen?: boolean
}

export const StatusComboBox = forwardRef<HTMLDivElement, StatusComboBoxProps>(
  ({ value, options, onChange, dropDownListOpen, onClick }) => {
    const [styles, setStyles] = useState(getOptionStyles(value))
    const buttonRef = createRef<HTMLButtonElement>()
    const [anchor, setAnchor] = useState<Element | undefined>(undefined)
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(dropDownListOpen ?? false)

    useEffect(() => {
      if (!value) return
      setStyles(getOptionStyles(value))
    }, [value])

    useEffect(() => {
      buttonRef.current && setAnchor(buttonRef.current)
    }, [buttonRef])

    const handleClickAway = () => {
      if (isOpen) {
        setIsOpen(false)
      }
    }

    useEffect(() => {
      const initialIndex = options.findIndex(option => option.value === value)
      setSelectedIndex(initialIndex)
    }, [value, options])

    const updateStatus = useCallback(() => {
      if (selectedIndex !== null) {
        onChange?.(options[selectedIndex].value)
        setIsOpen(false)
      }
    }, [selectedIndex, onChange])

    const moveDown = useCallback(() => {
      setSelectedIndex(prevIndex =>
        prevIndex === null || prevIndex === options.length - 1
          ? 0
          : prevIndex + 1
      )
    }, [options])

    const moveUp = useCallback(() => {
      setSelectedIndex(prevIndex =>
        prevIndex === null || prevIndex === 0
          ? options.length - 1
          : prevIndex - 1
      )
    }, [options])

    const keyBindListeners = useMemo(
      () => [
        { key: 'enter', fn: updateStatus, stopDefaultBehavior: true },
        { key: 'down', fn: moveDown, stopDefaultBehavior: true },
        { key: 'up', fn: moveUp, stopDefaultBehavior: true },
      ],
      [updateStatus, moveUp, moveDown]
    )

    const prevOpen = usePrevious(isOpen)
    useEffect(() => {
      if (isOpen) {
        if (!prevOpen) {
          store.dispatch(addKeyBindListeners(keyBindListeners, KEY_BIND_ID))
        } else {
          store.dispatch(updateKeyBindListeners(keyBindListeners, KEY_BIND_ID))
        }
      } else {
        store.dispatch(removeKeyBindListeners(KEY_BIND_ID))
      }
    }, [keyBindListeners, isOpen, prevOpen])

    // Prevent scrolling of the grid when the Popper is open
    useEffect(() => {
      const agBodyViewport = document.querySelector(
        '.ag-body-viewport'
      ) as HTMLElement
      if (agBodyViewport) {
        if (isOpen) {
          agBodyViewport.style.overflow = 'hidden'
        } else {
          agBodyViewport.style.overflow = ''
        }
      }
    }, [isOpen])

    if (!value) {
      return null
    }

    // Using List does not allow the CellEditor combo box to be open at all times.
    // That's why I'm making it with buttons and poppers instead of a List.
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <DropDownContainer>
          <DropDownButton
            bgColor={styles.backgroundColor}
            textColor={styles.textColor}
            borderColor={styles.borderColor}
            onClick={onClick}
            ref={buttonRef}
          >
            {getWbsItemStatusLabel(value as WbsItemStatus)}
            <Icon>
              {isOpen ? (
                <ExpandLess sx={{ width: '14px', height: '14px' }} />
              ) : (
                <ExpandMore sx={{ width: '14px', height: '14px' }} />
              )}
            </Icon>
          </DropDownButton>
          {anchor && (
            <Popper
              open={isOpen}
              anchorEl={anchor}
              sx={{
                width: '83px',
                borderRadius: '4px',
                backgroundColor: colorPalette.monotone[0],
                boxShadow:
                  'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
                display: isOpen ? 'block' : 'none',
                zIndex: 10,
              }}
            >
              {options.map((option, index) => {
                const optionStyles = getOptionStyles(option.value)
                const isSelected = index === selectedIndex
                return (
                  <span key={option.value} style={{ cursor: 'pointer' }}>
                    <DropDownMenuItem
                      key={option.value}
                      bgColor={optionStyles.backgroundColor}
                      textColor={optionStyles.textColor}
                      borderColor={optionStyles.borderColor}
                      className={clsx({ 'Mui-selected': isSelected })}
                      onClick={() => {
                        setSelectedIndex(index)
                        onChange?.(option.value)
                        setIsOpen(false)
                      }}
                    >
                      {getWbsItemStatusLabel(option.value as WbsItemStatus)}
                    </DropDownMenuItem>
                  </span>
                )
              })}
            </Popper>
          )}
        </DropDownContainer>
      </ClickAwayListener>
    )
  }
)
