import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  AggregateColumn,
  getWbsItemProgressLog,
  WbsItemProgressLogResponse,
  WbsItemProgressLogResponseData,
  WbsItemProgressLogResponseWbsItemData,
} from '../../../../../lib/functions/wbsProgressLog'
import { TimeGrain } from '../../../../../lib/functions/report'
import { WbsItemStatus } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  AggregateField,
  WbsItemType,
} from '../../../../../domain/entity/WbsItemEntity'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { AggregateRoot } from '../../hooks/useMasterScheduleParameter'
import { TwoDimensionalPoint } from '../../model/chart'
import { aggregateByDateTicks } from '../../utils/chart'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { useWorkloadUnit } from '../../../../hooks/useWorkloadUnit'

export type ProgressLine = TwoDimensionalPoint<Date, number>

export const useProgressReportData = (
  project: ProjectDetail,
  xTicks: Date[],
  root: AggregateRoot | undefined,
  aggregateField: AggregateField,
  workloadUnit: WorkloadUnit,
  teamUuid?: string
): {
  data:
    | {
        deliverableScheduledLog: ProgressLine[]
        deliverableActualLog: ProgressLine[]
        taskScheduledLog: ProgressLine[]
        taskActualLog: ProgressLine[]
      }
    | undefined
  fetching: boolean
} => {
  const [fetching, setFetching] = useState<boolean>(true)
  // Data
  const [progressLog, setProgressLog] = useState<WbsItemProgressLogResponse>([])
  const [data, setData] = useState<{
    deliverableScheduledLog: ProgressLine[]
    deliverableActualLog: ProgressLine[]
    taskScheduledLog: ProgressLine[]
    taskActualLog: ProgressLine[]
  }>()

  // Fetch data
  useEffect(() => {
    const endFetching = _.debounce(() => {
      setFetching(false)
    }, 300)
    const fetch = async () => {
      setFetching(true)
      const response = await getWbsItemProgressLog({
        projectUuid: project.uuid,
        rootWbsItemUuid: root?.wbsItemUuid,
        teamUuid,
        timeGrain: TimeGrain.DAY,
        aggregate: false,
        wbsItemStatusList: [
          WbsItemStatus.TODO,
          WbsItemStatus.DOING,
          WbsItemStatus.REVIEW,
          WbsItemStatus.DONE,
        ],
        aggregateBy: [
          {
            wbsItemType: WbsItemType.DELIVERABLE,
            aggregateColumn: [
              AggregateColumn.SCHEDULED_END_DATE,
              AggregateColumn.ACTUAL_END_DATE,
            ],
          },
          {
            wbsItemType: WbsItemType.TASK,
            aggregateColumn: [
              AggregateColumn.SCHEDULED_END_DATE,
              AggregateColumn.ACTUAL_END_DATE,
            ],
          },
        ],
      })
      setProgressLog(response.json as WbsItemProgressLogResponse)
      endFetching()
    }
    fetch()
  }, [project.uuid, root?.wbsItemUuid, teamUuid])

  const { convertWorkloadFromHourToSelectedUnit } =
    useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (fetching) return
    const findLog = (
      col: AggregateColumn,
      type: WbsItemType
    ): WbsItemProgressLogResponseData[] => {
      return (
        progressLog.find(
          v => v.key.aggregateColumn === col && v.key.type.value === type
        )?.data || []
      )
    }
    const deliverableScheduled = findLog(
      AggregateColumn.SCHEDULED_END_DATE,
      WbsItemType.DELIVERABLE
    )
    const deliverableActual = findLog(
      AggregateColumn.ACTUAL_END_DATE,
      WbsItemType.DELIVERABLE
    )
    const taskScheduled = findLog(
      AggregateColumn.SCHEDULED_END_DATE,
      WbsItemType.TASK
    )
    const taskActual = findLog(
      AggregateColumn.ACTUAL_END_DATE,
      WbsItemType.TASK
    )
    if (
      deliverableScheduled.length === 0 &&
      deliverableActual.length === 0 &&
      taskScheduled.length === 0 &&
      taskActual.length === 0
    ) {
      setData(undefined)
      return
    }

    const sum = (d: WbsItemProgressLogResponseWbsItemData[]): number => {
      switch (aggregateField) {
        case AggregateField.WBS_ITEM_WORKLOAD:
          return convertWorkloadFromHourToSelectedUnit(
            _.sum(
              d
                .filter(v => v.status !== WbsItemStatus.DISCARD)
                .map(v => v.workload)
            )
          )
        case AggregateField.WBS_ITEM_COUNT:
          return d.filter(v => v.status !== WbsItemStatus.DISCARD).length
      }
    }
    const deserialize = (
      logs: WbsItemProgressLogResponseData[],
      xTicks: Date[],
      getSum: (_: WbsItemProgressLogResponseWbsItemData[]) => number
    ): ProgressLine[] => {
      return aggregateByDateTicks(
        logs.map(l => ({ date: l.dimTime, value: l.wbsItemDatas })),
        xTicks,
        (matches: WbsItemProgressLogResponseWbsItemData[][]) =>
          getSum(matches.flat())
      )
    }
    const cumSum = (lines: ProgressLine[]): ProgressLine[] => {
      return lines
        .filter(v => v.x)
        .map(line => {
          const cum = lines.filter(v => v.x <= line.x)
          return {
            x: line.x,
            y: cum.map(v => v.y).reduce((a, b) => a + b, 0),
          }
        })
    }
    const ds = cumSum(deserialize(deliverableScheduled, xTicks, sum))
    const da = cumSum(deserialize(deliverableActual, xTicks, sum))
    const ts = cumSum(deserialize(taskScheduled, xTicks, sum))
    const ta = cumSum(deserialize(taskActual, xTicks, sum))
    setData({
      deliverableScheduledLog: ds,
      deliverableActualLog: da,
      taskScheduledLog: ts,
      taskActualLog: ta,
    })
  }, [
    progressLog,
    xTicks,
    fetching,
    aggregateField,
    convertWorkloadFromHourToSelectedUnit,
  ])

  return {
    data,
    fetching,
  }
}
