import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  CellClickedEvent,
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessCellForExportParams,
  RowDataUpdatedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import moment from 'moment'
import _ from 'lodash'
import { Box, Tabs, Tab } from '@mui/material'
import { styled } from '@mui/system'
import { PageArea, PageProps } from '..'
import store, { AllState } from '../../../store'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import { UiStateKey } from '../../../lib/commons/uiStates'
import {
  CustomEnumCode,
  getCustomEnumValues,
  getMultiCustomEnumValues,
  getAllCustomEnumValuesByCode,
} from '../../../lib/functions/customEnumValue'
import ProjectMemberApi, {
  ProjectMemberDetail,
} from '../../../lib/functions/projectMember'
import TeamApi from '../../../lib/functions/team'
import SprintApi from '../../../lib/functions/sprint'
import { parse } from '../../../lib/commons/i18nLabel'
import Loading from '../../components/process-state-notifications/Loading'
import TaskActualWorkDialog from '../../components/dialogs/TaskActualWorkDialog'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import { TimerListPopper } from '../../components/poppers/TimerListPoper'
import { BulkSheetView } from '../../containers/BulkSheetView'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { exportExcel } from '../../containers/BulkSheet/excel'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import {
  DisplayUnit,
  ShiftType,
} from '../../containers/BulkSheetView/components/header/datePicker/TermSelector'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { DateTerm } from '../../../utils/date'
import DateVO from '../../../vo/DateVO'
import MyWbsItemsWbsHeader from './components/Header/MyWbsItemsWbsHeader'
import {
  useMyWbsItemTaskTabGridOptions,
  useMyWbsItemDeliverableTabGridOptions,
  useMyWbsItemWatcherTabGridOptions,
} from './gridOptions'
import { refreshDynamicColumnDef } from './gridOptions/myWbsItemTaskTabGridOptions'
import { useDialog, usePopper, usePageState } from './hooks'
import { useMyWbsItemsData, MyWbsItemTabType } from './hooks/myWbsItemsData'
import { MyWbsItemRow, MyWbsItemRowBody } from './myWbsItems'
import { connect } from 'react-redux'
import { FunctionLayer } from '../../../store/functionLayer'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import {
  addCopyURLAndNameMenuItems,
  addCopyURLMenuItems,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import { useTimerPopper } from './hooks/timerPopper'
import { useTmpFeatureFlag } from './hooks/featureFlag'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import { useTaskTabSearchCondition } from './hooks/useSearchCondition'

type PathProps = {
  code?: string
  term?: string
}

type StateProps = {
  functionLayers: Immutable.Map<number, FunctionLayer>
}

type Props = PageProps & RouteComponentProps<PathProps> & StateProps

const taskTabPinnedTopRowData: MyWbsItemRow = {
  isTotalRow: true,
  wbsItemUuid: '',
  uuid: '',
}

const allyProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const TabArea = styled('div')({
  height: '100%',
})

const TabPanel = ({ children, tabIndex, currentTabIndex }) => {
  return (
    <TabArea
      role="tabpanel"
      hidden={tabIndex !== currentTabIndex}
      id={`simple-tabpanel-${currentTabIndex}`}
      aria-labelledby={`simple-tab-${currentTabIndex}`}
    >
      <Box sx={{ height: '100%' }}>{children}</Box>
    </TabArea>
  )
}

const MyWbsItems = (props: Props) => {
  const functionUuid = props.uuid

  const taskTabRef = useRef<HTMLDivElement>(null)
  const deliverableTabRef = useRef<HTMLDivElement>(null)
  const watcherTabRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const {
    initialized,
    searchCondition: taskTabSearchCondition,
    updateSearchCondition,
    resetSearchCondition,
  } = useTaskTabSearchCondition()
  const {
    taskTabData,
    deliverableTabData,
    watcherTabData,
    refresh,
    save,
    generateUpdateWbsItemDeltaRequest,
  } = useMyWbsItemsData(initialized, taskTabSearchCondition, functionUuid)
  const submit = useRef<Function>()
  submit.current = save

  const dialog = useDialog()
  const popper = usePopper()
  const timerListPopper = useTimerPopper()
  const columnSetting = useColumnSetting()
  const pageState = usePageState(functionUuid)
  const taskTabBulkSheetState = useBulkSheetState(
    functionUuid,
    UiStateKey.MyWbsItemsTaskTab
  )
  const deliverableTabBulkSheetState = useBulkSheetState(
    functionUuid,
    UiStateKey.MyWbsItemsDeliverableTab
  )
  const watcherTabBulkSheetState = useBulkSheetState(
    functionUuid,
    UiStateKey.MyWbsItemsWatcherTab
  )

  const reload = useRef<Function>()
  reload.current = refresh

  const prevTaskTabData = useRef<MyWbsItemRow[]>([])
  prevTaskTabData.current = taskTabData
  const prevDeliverableTabData = useRef<MyWbsItemRow[]>([])
  prevDeliverableTabData.current = deliverableTabData
  const prevWatcherTabData = useRef<MyWbsItemRow[]>([])
  prevWatcherTabData.current = watcherTabData

  const taskTabGridOptions = useMyWbsItemTaskTabGridOptions()
  const deliverableTabGridOptions = useMyWbsItemDeliverableTabGridOptions()
  const watcherTabGridOptions = useMyWbsItemWatcherTabGridOptions()

  const [taskTabFilteredColumns, setTaskTabFilteredColumns] = useState<
    ColDef[]
  >([])
  const [deliverableTabFilteredColumns, setDeliverableTabFilteredColumns] =
    useState<ColDef[]>([])
  const [watcherTabFilteredColumns, setWatcherTabFilteredColumns] = useState<
    ColDef[]
  >([])

  const [taskTabSortColumnsState, setTaskTabSortColumnsState] = useState<
    SortedColumnState[]
  >([])
  const [deliverableTabSortColumnsState, setDeliverableTabSortColumnsState] =
    useState<SortedColumnState[]>([])
  const [watcherTabSortColumnsState, setWatcherTabSortColumnsState] = useState<
    SortedColumnState[]
  >([])

  const [dateTerm, setDateTerm] = useState<DateTerm>()

  const [tab, setTab] = useState<MyWbsItemTabType>(MyWbsItemTabType.TASK)

  const hasChanged = useCallback((): boolean => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return (prevTaskTabData.current || taskTabData).some(
          d => d.added || d.edited
        )
      case MyWbsItemTabType.DELIVERABLE:
        return (prevDeliverableTabData.current || deliverableTabData).some(
          d => d.added || d.edited
        )
      case MyWbsItemTabType.WATCHER:
        return (prevWatcherTabData.current || watcherTabData).some(
          d => d.added || d.edited
        )
    }
  }, [taskTabData, deliverableTabData, watcherTabData, tab])

  const getGridOptionsByTabType = useCallback(
    (tabType?: MyWbsItemTabType) => {
      switch (tabType ? tabType : tab) {
        case MyWbsItemTabType.TASK:
          return taskTabGridOptions
        case MyWbsItemTabType.DELIVERABLE:
          return deliverableTabGridOptions
        case MyWbsItemTabType.WATCHER:
          return watcherTabGridOptions
      }
    },
    [taskTabGridOptions, deliverableTabGridOptions, watcherTabGridOptions, tab]
  )

  const refreshDynamicColumn = useCallback(
    async (startDate?, endDate?) => {
      if (!startDate || !endDate || tab !== MyWbsItemTabType.TASK) return
      await refreshDynamicColumnDef({
        api: taskTabGridOptions.api,
        dateTerm: {
          startDate: startDate.formatForApi(),
          endDate: endDate.formatForApi(),
        },
      })
    },
    [taskTabGridOptions, tab]
  )

  const getTermList = useCallback((startDate, endDate): string[] => {
    if (!startDate || !endDate) {
      return []
    }
    const startDateVO = new DateVO(startDate)
    const endDateVO = new DateVO(endDate)
    const diff =
      moment(endDateVO.toDate()).diff(moment(startDateVO.toDate()), 'days') + 1
    return Array.from({ length: diff }).map((_, index) =>
      moment(startDateVO.toDate()).add(index, 'day').format('YYYY-MM-DD')
    )
  }, [])

  const shiftDisplayTerm = useCallback(
    (shiftType: ShiftType, displayUnit?: DisplayUnit) => {
      if (!taskTabGridOptions) return
      const dateList = getTermList(dateTerm?.startDate, dateTerm?.endDate)
      let startDate = new DateVO()
      let endDate = new DateVO()
      const oldStartDate = new DateVO(dateList[0])
      const unit = displayUnit ? displayUnit : pageState.displayUnit
      if (!oldStartDate) shiftType = ShiftType.THIS_TERM
      switch (shiftType) {
        case ShiftType.THIS_TERM:
          const now = DateVO.now()
          if (unit === DisplayUnit.WEEK) {
            startDate = now.getFirstDayOfWeek().addDays(1)
            endDate = startDate.getLastDayOfWeek().addDays(1)
          } else if (unit === DisplayUnit.MONTH) {
            startDate = now.getFirstDayOfMonth()
            endDate = startDate.getLastDayOfMonth()
          }
          break
        case ShiftType.FORWARD:
          if (unit === DisplayUnit.WEEK) {
            startDate = oldStartDate.addWeeks(1)
            endDate = startDate.getLastDayOfWeek().addDays(1)
          } else if (unit === DisplayUnit.MONTH) {
            startDate = oldStartDate.addMonths(1)
            endDate = startDate.getLastDayOfMonth()
          }
          break
        case ShiftType.BACKWARD:
          if (unit === DisplayUnit.WEEK) {
            startDate = oldStartDate.subtractWeeks(1)
            endDate = startDate.getLastDayOfWeek().addDays(1)
          } else if (unit === DisplayUnit.MONTH) {
            startDate = oldStartDate.subtractMonths(1)
            endDate = startDate.getLastDayOfMonth()
          }
          break
      }
      refreshDynamicColumn(startDate, endDate)
      setDateTerm({
        startDate: startDate.formatForApi(),
        endDate: endDate.formatForApi(),
      })
    },
    [
      dateTerm?.endDate,
      dateTerm?.startDate,
      getTermList,
      pageState.displayUnit,
      refreshDynamicColumn,
      taskTabGridOptions,
    ]
  )

  const refreshAll = useCallback(
    async (tabType?: MyWbsItemTabType) => {
      setLoading(true)
      try {
        await (reload.current ?? refresh)(tabType ? tabType : tab)
      } finally {
        const gridOptions = getGridOptionsByTabType()
        gridOptions.context = {
          ...gridOptions.context,
          errors: new InputError(),
        }
        setLoading(false)
      }
    },
    [refresh, getGridOptionsByTabType, tab]
  )

  const measuringTimer = useRef(store.getState().measuringTimer)
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const current = store.getState().measuringTimer
      if (measuringTimer.current !== current) {
        measuringTimer.current = current
        refreshAll()
      }
    })
    return () => unsubscribe()
  }, [refreshAll, measuringTimer])

  useEffect(() => {
    const gridOptions = getGridOptionsByTabType()
    gridOptions.context = {
      ...gridOptions.context,
      refreshAll: refreshAll,
    }
  }, [getGridOptionsByTabType, refreshAll])

  useEffect(() => {
    shiftDisplayTerm(ShiftType.THIS_TERM)
  }, [taskTabBulkSheetState.initialized])

  useEffect(() => {
    getGridOptionsByTabType().api?.resetRowHeights()
  }, [pageState.rowHeight, getGridOptionsByTabType])

  useEffect(() => {
    const gridOptions = getGridOptionsByTabType()
    gridOptions.context = {
      ...gridOptions.context,
      dateFormat: pageState.dateFormat,
    }
    gridOptions.api?.refreshCells()
  }, [pageState.dateFormat, getGridOptionsByTabType])

  const getRef = () => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return taskTabRef
      case MyWbsItemTabType.DELIVERABLE:
        return deliverableTabRef
      case MyWbsItemTabType.WATCHER:
        return watcherTabRef
    }
  }

  const getData = useCallback(() => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return taskTabData
      case MyWbsItemTabType.DELIVERABLE:
        return deliverableTabData
      case MyWbsItemTabType.WATCHER:
        return watcherTabData
    }
  }, [taskTabData, deliverableTabData, watcherTabData, tab])

  const getBulkSheetStateByTabType = useCallback(
    (tabType?: MyWbsItemTabType) => {
      switch (tabType ? tabType : tab) {
        case MyWbsItemTabType.TASK:
          return taskTabBulkSheetState
        case MyWbsItemTabType.DELIVERABLE:
          return deliverableTabBulkSheetState
        case MyWbsItemTabType.WATCHER:
          return watcherTabBulkSheetState
      }
    },
    [
      taskTabBulkSheetState,
      deliverableTabBulkSheetState,
      watcherTabBulkSheetState,
      tab,
    ]
  )

  const getFilteredColumns = () => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return taskTabFilteredColumns
      case MyWbsItemTabType.DELIVERABLE:
        return deliverableTabFilteredColumns
      case MyWbsItemTabType.WATCHER:
        return watcherTabFilteredColumns
    }
  }

  const getSortColumnsState = () => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return taskTabSortColumnsState
      case MyWbsItemTabType.DELIVERABLE:
        return deliverableTabSortColumnsState
      case MyWbsItemTabType.WATCHER:
        return watcherTabSortColumnsState
    }
  }

  const getColumnSettingUiStateKey = () => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return UiStateKey.BulkSheetUIStateColumnAndFilterMyWbsItemsTaskTab
      case MyWbsItemTabType.DELIVERABLE:
        return UiStateKey.BulkSheetUIStateColumnAndFilterMyWbsItemsDeliverableTab
      case MyWbsItemTabType.WATCHER:
        return UiStateKey.BulkSheetUIStateColumnAndFilterMyWbsItemsWatcherTab
    }
  }

  const setFilteredColumns = useCallback(
    (colDefs: ColDef[]) => {
      switch (tab) {
        case MyWbsItemTabType.TASK:
          setTaskTabFilteredColumns(colDefs)
          break
        case MyWbsItemTabType.DELIVERABLE:
          setDeliverableTabFilteredColumns(colDefs)
          break
        case MyWbsItemTabType.WATCHER:
          setWatcherTabFilteredColumns(colDefs)
          break
      }
    },
    [tab]
  )

  const setSortColumnsState = useCallback(
    (state: SortedColumnState[]) => {
      switch (tab) {
        case MyWbsItemTabType.TASK:
          setTaskTabSortColumnsState(state)
          break
        case MyWbsItemTabType.DELIVERABLE:
          setDeliverableTabSortColumnsState(state)
          break
        case MyWbsItemTabType.WATCHER:
          setWatcherTabSortColumnsState(state)
          break
      }
    },
    [tab]
  )

  const fetchStatusOptions = useCallback(async () => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchSubStatusOptions = useCallback(async () => {
    const response = await getAllCustomEnumValuesByCode({
      customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const projects = useMemo(() => {
    return new Set(
      getData()
        .map(d => d.body?.wbsItem.projectUuid)
        .filter(
          (project): project is string =>
            project !== undefined && typeof project === 'string'
        )
    )
  }, [getData])

  const fetchPriorityOptions = useCallback(async () => {
    if (projects.size === 0) return []
    const response = await getMultiCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuids: Array.from(projects),
    })
    const optionMap = new Map()
    projects.forEach(project => {
      const options = response.json[project]
      optionMap.set(
        project,
        options.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
      )
    })
    return optionMap
  }, [projects])

  const fetchDifficultyOptions = useCallback(async () => {
    if (projects.size === 0) return []
    const response = await getMultiCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_DIFFICULTY,
      groupUuids: Array.from(projects),
    })
    const optionMap = new Map()
    projects.forEach(project => {
      const options = response.json[project]
      optionMap.set(
        project,
        options.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
      )
    })
    return optionMap
  }, [projects])

  const fetchTeamOptions = useCallback(async () => {
    if (projects.size === 0) return []
    const response = await TeamApi.getAllCrossProjects(Array.from(projects))
    return response
  }, [projects])

  const fetchProjectMemberOptions = useCallback(async () => {
    if (projects.size === 0) return []
    const response = await ProjectMemberApi.getProjectMembersCrossProjects(
      Array.from(projects)
    )
    return response.map((member: ProjectMemberDetail) => {
      return {
        projectUuid: member.projectUuid,
        ...member.user,
      }
    })
  }, [projects])

  const fetchSprintOptions = useCallback(async () => {
    if (projects.size === 0) return []
    const response = await SprintApi.getAllCrossProjects(Array.from(projects))
    return response
  }, [projects])

  const fetchSelectColumnOptions = useCallback(async () => {
    const [status, substatus, priority, difficulty, team, member, sprint] =
      await Promise.all([
        fetchStatusOptions(),
        fetchSubStatusOptions(),
        fetchPriorityOptions(),
        fetchDifficultyOptions(),
        fetchTeamOptions(),
        fetchProjectMemberOptions(),
        fetchSprintOptions(),
      ])
    const gridOptions = getGridOptionsByTabType()
    getGridOptionsByTabType().context = {
      ...gridOptions.context,
      status,
      substatus,
      priority,
      difficulty,
      team,
      member,
      sprint,
    }
    gridOptions.api?.refreshCells({ force: true })
  }, [
    fetchStatusOptions,
    fetchSubStatusOptions,
    fetchPriorityOptions,
    fetchDifficultyOptions,
    getGridOptionsByTabType,
    fetchTeamOptions,
    fetchProjectMemberOptions,
    fetchSprintOptions,
  ])

  useEffect(() => {
    // Initial data fetch
    fetchSelectColumnOptions()
  }, [fetchSelectColumnOptions])

  const getCurrentData = useCallback(() => {
    switch (tab) {
      case MyWbsItemTabType.TASK:
        return prevTaskTabData.current || taskTabData
      case MyWbsItemTabType.DELIVERABLE:
        return prevDeliverableTabData.current || deliverableTabData
      case MyWbsItemTabType.WATCHER:
        return prevWatcherTabData.current || watcherTabData
    }
  }, [taskTabData, deliverableTabData, watcherTabData, tab])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const gridOptions = getGridOptionsByTabType()
      gridOptions.api?.stopEditing()

      if (gridOptions.context.errors?.hasMessage()) {
        const data = getCurrentData()
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              return data.findIndex(d => d.uuid === id) + 1
            }),
          })
        )
        return
      }

      const [
        response,
        sprintProductResponse,
        sprintBacklogResponse,
        actualWorkResponse,
      ] = submit.current
        ? await submit.current(
            getTermList(dateTerm?.startDate, dateTerm?.endDate),
            tab
          )
        : await save(getTermList(dateTerm?.startDate, dateTerm?.endDate), tab)
      if (!response) return
      if (
        !response.hasError &&
        !response.hasWarning &&
        !sprintProductResponse.hasError &&
        !sprintBacklogResponse.hasError &&
        !actualWorkResponse.hasError
      ) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    dateTerm?.startDate,
    dateTerm?.endDate,
    functionUuid,
    getGridOptionsByTabType,
    getTermList,
    refreshAll,
    save,
    tab,
    getCurrentData,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialog.openCancelConfirm()
    } else {
      refreshAll()
    }
  }, [hasChanged, dialog, refreshAll])

  const onCancelConfirm = useCallback(() => {
    dialog.closeCancelConfirm()
    refreshAll()
  }, [dialog, refreshAll])

  const onConfirmTabTransition = useCallback(() => {
    dialog.closeTabTransitionConfirm()
    setTab(dialog.tab!)
    refreshAll(tab)
    refreshAll(dialog.tab)
  }, [dialog, refreshAll, tab])

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<MyWbsItemRow>) =>
      e.api?.refreshCells({ columns: ['rowNumber'], force: true }),
    []
  )

  const featureFlag = useTmpFeatureFlag()
  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      const { field } = e.colDef
      const getAgCellElement = (elem): any => {
        if (!elem || elem.className.toString().includes('sevend-ag-cell')) {
          return elem
        }
        return getAgCellElement(elem.parentElement)
      }
      const target = getAgCellElement(e.event!.target)
      const isTotalRow = e.data.isTotalRow
      if (e.data.added) return
      if (field === 'body.wbsItem.status') {
        if (isTotalRow || !target) return
        popper.open(
          target,
          e.data.body!.wbsItem,
          generateUpdateWbsItemDeltaRequest(e.data, tab)
        )
      }
      if (field === 'body.wbsItem.actualHour') {
        const w = e.data.body?.wbsItem
        if (!w || !w.wbsItemType.isTask()) return
        dialog.openActualResult(w.uuid)
      }
      const body: MyWbsItemRowBody | undefined = e.data.body
      if (field === 'body.timer.actualHour.today') {
        if (isTotalRow || !target) return
        timerListPopper.open(target, body!.wbsItem, body!.timer!)
      }
      if (featureFlag && moment(field, 'YYYY-MM-DD', true).isValid()) {
        if (isTotalRow || !target) return
        timerListPopper.open(target, body!.wbsItem, body!.timer!, field)
      }
    },
    [
      featureFlag,
      popper,
      generateUpdateWbsItemDeltaRequest,
      tab,
      dialog,
      timerListPopper,
    ]
  )

  const rememberColumnState = useCallback(() => {
    const columnState = getGridOptionsByTabType().columnApi?.getColumnState()
    columnState && getBulkSheetStateByTabType().saveColumnState(columnState)
  }, [getBulkSheetStateByTabType, getGridOptionsByTabType])

  const onGridReady = useCallback(
    (tabType: MyWbsItemTabType) => {
      if (!_.isEmpty(getBulkSheetStateByTabType(tabType).columnState)) {
        getGridOptionsByTabType(tabType).columnApi?.applyColumnState({
          state: getBulkSheetStateByTabType(tabType).columnState,
          applyOrder: false,
        })
      }
      setTimeout(() => setLoading(false), 2000)
    },
    [getBulkSheetStateByTabType, getGridOptionsByTabType]
  )

  const onFirstDataRendered = useCallback(
    e => {
      getGridOptionsByTabType().columnApi?.applyColumnState({
        state: getBulkSheetStateByTabType().columnState,
        applyOrder: false,
      })
      getBulkSheetStateByTabType().finishRestoring()
      setLoading(false)
    },
    [getBulkSheetStateByTabType, getGridOptionsByTabType]
  )

  const workloadUnitState = useWorkloadUnit(pageState.workloadUnit)
  useEffect(() => {
    taskTabGridOptions.context = {
      ...taskTabGridOptions.context,
      workloadUnit: pageState.workloadUnit,
      workloadUnitState,
    }
    deliverableTabGridOptions.context = {
      ...deliverableTabGridOptions.context,
      workloadUnit: pageState.workloadUnit,
      workloadUnitState,
    }
    watcherTabGridOptions.context = {
      ...watcherTabGridOptions.context,
      workloadUnit: pageState.workloadUnit,
      workloadUnitState,
    }
    getGridOptionsByTabType().api?.refreshCells()
  }, [
    pageState.workloadUnit,
    workloadUnitState,
    getGridOptionsByTabType,
    taskTabGridOptions,
    deliverableTabGridOptions,
    watcherTabGridOptions,
  ])

  useEffect(() => {
    taskTabGridOptions.context = {
      ...taskTabGridOptions.context,
      decimalScaleUnit: pageState.decimalScaleUnit,
    }
    taskTabGridOptions.api?.refreshCells()
  }, [pageState.decimalScaleUnit, getGridOptionsByTabType, taskTabGridOptions])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      getBulkSheetStateByTabType().saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [getBulkSheetStateByTabType, setFilteredColumns]
  )

  const onSortedColumnsStateChanged = useCallback(
    (e: SortChangedEvent) => {
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)

      const columnState = getGridOptionsByTabType().columnApi?.getColumnState()
      const sortedColumnState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedColumnState[state.colId] = state
          }
        })
      const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
        }
      })

      setSortColumnsState(sortedColumnsState)
    },
    [getGridOptionsByTabType, setSortColumnsState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [onSortedColumnsStateChanged, rememberColumnState]
  )

  const onDeleteFilteredColumn = useCallback(
    (column: ColDef) => {
      const gridOptions = getGridOptionsByTabType()
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    },
    [getGridOptionsByTabType]
  )

  const resetFilters = useCallback(() => {
    const gridOptions = getGridOptionsByTabType()
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [getGridOptionsByTabType])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      const gridOptions = getGridOptionsByTabType()
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [getGridOptionsByTabType]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    const gridOptions = getGridOptionsByTabType()
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [getGridOptionsByTabType])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      const gridOptions = getGridOptionsByTabType()
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [getGridOptionsByTabType]
  )

  const onChangeTerm = useCallback(
    (displayUnit: DisplayUnit) => {
      pageState.setDisplayUnit(displayUnit)
      shiftDisplayTerm(ShiftType.THIS_TERM, displayUnit)
    },
    [pageState, shiftDisplayTerm]
  )

  const getCustomExportValue = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined
      const colId: string = params.column.getColId()
      if (colId === 'body.tags') {
        return params.node?.data?.body?.tags?.map(v => v.name)
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(
    colIds => {
      const gridOptions = getGridOptionsByTabType()
      exportExcel({
        fileNamePrefix: 'my_wbs_items',
        gridOptions,
        exportColIds: colIds,
        shouldRowBeSkipped: params =>
          !params.node.data.body || !params.node.displayed,
        getCustomExportValue,
      })
    },
    [getCustomExportValue, getGridOptionsByTabType]
  )

  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    if (
      !params.node ||
      !params.node.data ||
      !params.node.data.body ||
      !params.node.data.body.wbsItem
    ) {
      return []
    }

    const wbsItem = params.node.data.body.wbsItem
    const items: (string | MenuItemDef)[] = [
      addCopyURLMenuItems(wbsItem),
      addCopyURLAndNameMenuItems(wbsItem),
    ]
    return items
  }, [])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    [dateTerm]
  )

  const onResetColumn = useCallback(() => {
    const gridOptions = getGridOptionsByTabType()
    gridOptions.columnApi?.resetColumnState()
  }, [getGridOptionsByTabType])

  const onChangeTab = (event, newTab) => {
    if (hasChanged()) {
      dialog.openTabTransitionConfirm(newTab)
    } else {
      setTab(newTab)
      refreshAll(newTab)
    }
  }
  if (!taskTabBulkSheetState.initialized) {
    return <Loading isLoading={true} />
  }
  return (
    <PageArea>
      {props.functionLayers.size === 1 && (
        <SavePopper loading={loading} onSubmit={onSubmit} />
      )}
      <MyWbsItemsWbsHeader
        onChangeWorkloadUnit={pageState.setWorkloadUnit}
        workloadUnit={pageState.workloadUnit}
        onChangeDecimalScaleUnit={pageState.setDecimalScaleUnit}
        decimalScaleUnit={pageState.decimalScaleUnit}
        rowHeight={pageState.rowHeight}
        onChangeHeight={pageState.setRowHeight}
        onClickExportExcel={dialog.openExcel}
        filteredColumns={getFilteredColumns()}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        onReload={onReload}
        displayUnit={pageState.displayUnit}
        dateTerm={dateTerm}
        onChangeTerm={onChangeTerm}
        shiftDisplayTerm={shiftDisplayTerm}
        onClickColumnSettingButton={columnSetting.toggle}
        sortColumnsState={getSortColumnsState()}
        columnSettingOpen={columnSetting.isOpen}
        currentFormat={pageState.dateFormat}
        onChangeDateFormat={(value: string) => pageState.setDateFormat(value)}
        tab={tab}
        searchCondition={taskTabSearchCondition}
        search={refreshAll}
        onChange={updateSearchCondition}
        resetCondition={resetSearchCondition}
      />
      <Tabs
        sx={{ minHeight: '25px' }}
        value={tab}
        onChange={onChangeTab}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ padding: '5px', minHeight: '20px' }}
          label={intl.formatMessage({ id: 'myWbsItems.tab.task' })}
          {...allyProps(0)}
        />
        <Tab
          sx={{ padding: '5px', minHeight: '20px' }}
          label={intl.formatMessage({ id: 'myWbsItems.tab.deliverable' })}
          {...allyProps(1)}
        />
        <Tab
          sx={{ padding: '5px', minHeight: '20px' }}
          label={intl.formatMessage({ id: 'myWbsItems.tab.watcher' })}
          {...allyProps(2)}
        />
      </Tabs>
      <TabPanel tabIndex={MyWbsItemTabType.TASK} currentTabIndex={tab}>
        <BulkSheetView
          gridOptions={taskTabGridOptions}
          ref={taskTabRef}
          rowData={taskTabData}
          getContextMenuItems={contextMenu}
          rowHeight={pageState.rowHeight}
          onCellClicked={onCellClicked}
          onCellDoubleClicked={() => {
            popper.close()
            timerListPopper.close()
          }}
          onColumnResized={rememberColumnState}
          onColumnMoved={rememberColumnState}
          onColumnVisible={rememberColumnState}
          onFilterChanged={onFilterChanged}
          onGridReady={() => onGridReady(MyWbsItemTabType.TASK)}
          onRowDataUpdated={onRowDataUpdated}
          onSortChanged={onSortChanged}
          onFirstDataRendered={onFirstDataRendered}
          pinnedTopRowData={[taskTabPinnedTopRowData]}
        />
      </TabPanel>
      <TabPanel tabIndex={MyWbsItemTabType.DELIVERABLE} currentTabIndex={tab}>
        <BulkSheetView
          gridOptions={deliverableTabGridOptions}
          ref={deliverableTabRef}
          rowData={deliverableTabData}
          getContextMenuItems={contextMenu}
          rowHeight={pageState.rowHeight}
          onCellClicked={onCellClicked}
          onCellDoubleClicked={popper.close}
          onColumnResized={rememberColumnState}
          onColumnMoved={rememberColumnState}
          onColumnVisible={rememberColumnState}
          onFilterChanged={onFilterChanged}
          onGridReady={() => onGridReady(MyWbsItemTabType.DELIVERABLE)}
          onRowDataUpdated={onRowDataUpdated}
          onSortChanged={onSortChanged}
          onFirstDataRendered={onFirstDataRendered}
        />
      </TabPanel>
      <TabPanel tabIndex={MyWbsItemTabType.WATCHER} currentTabIndex={tab}>
        <BulkSheetView
          gridOptions={watcherTabGridOptions}
          ref={watcherTabRef}
          rowData={watcherTabData}
          getContextMenuItems={contextMenu}
          rowHeight={pageState.rowHeight}
          onCellClicked={onCellClicked}
          onCellDoubleClicked={popper.close}
          onColumnResized={rememberColumnState}
          onColumnMoved={rememberColumnState}
          onColumnVisible={rememberColumnState}
          onFilterChanged={onFilterChanged}
          onGridReady={() => onGridReady(MyWbsItemTabType.WATCHER)}
          onRowDataUpdated={onRowDataUpdated}
          onSortChanged={onSortChanged}
          onFirstDataRendered={onFirstDataRendered}
        />
      </TabPanel>
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={getGridOptionsByTabType().columnApi ?? undefined}
        gridApi={getGridOptionsByTabType().api ?? undefined}
        height={getRef().current?.offsetHeight}
        openSavedUiStateDialog={dialog.openUiState}
        initializeColumnState={onResetColumn}
        applicationFunctionUuid={functionUuid}
        uiStateKey={getColumnSettingUiStateKey()}
        columnState={getBulkSheetStateByTabType().columnState}
        offset={120}
      />
      <Loading isLoading={loading} elem={getRef().current} />
      {dialog.actualResult && (
        <TaskActualWorkDialog
          open={true}
          taskUuid={dialog.taskUuid ?? ''}
          closeHandler={dialog.closeActualResult}
        />
      )}
      {timerListPopper.isOpen && (
        <TimerListPopper
          anchorEl={timerListPopper.anchorEl}
          timer={timerListPopper.timer}
          wbsItem={timerListPopper.wbsItem}
          onClose={timerListPopper.close}
          targetDate={timerListPopper.targetDate}
        />
      )}
      {dialog.excel && (
        <MultiSelectDialog
          onClose={dialog.closeExcel}
          onSubmit={(allList, selectedItem) => {
            const selectedColIds = selectedItem.map(col => col.value)
            onExportExcel(selectedColIds)
          }}
          dialogTitle={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'dialog.exceloutput.submit',
          })}
          allCheckBoxLabel={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
          })}
          getSelectList={() => {
            const gridOptions = getGridOptionsByTabType()
            return (gridOptions.columnApi?.getColumns() || [])
              .filter(
                column =>
                  ![
                    'uuid',
                    'drag',
                    'rowNumber',
                    'action',
                    'body.deliverableAttachmentSummary',
                  ].includes(column.getColId())
              )
              .map(column => ({
                value: column.getColId(),
                displayName: gridOptions.columnApi?.getDisplayNameForColumn(
                  column,
                  null
                ),
                defaultChecked:
                  column.isVisible() ||
                  [
                    'body.wbsItem.displayName',
                    'body.wbsItem.code',
                    'body.wbsItem.type',
                  ].includes(column.getColId()),
              })) as MultiSelectDialogSelection[]
          }}
          hideIcon={true}
        />
      )}
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={functionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={getColumnSettingUiStateKey()}
          sharable={false}
          currentUIState={{
            columnState: getBulkSheetStateByTabType().columnState,
            filterState: getBulkSheetStateByTabType().filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, filterState } = uiState.UIState
            const gridOptions = getGridOptionsByTabType()
            if (columnState) {
              getBulkSheetStateByTabType().saveColumnState(columnState)
              gridOptions.columnApi?.applyColumnState({
                state: columnState,
                applyOrder: true,
              })
            }
            if (filterState) {
              getBulkSheetStateByTabType().saveFilterState(filterState)
              gridOptions.api?.setFilterModel(filterState)
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
      <CancelConfirmDialog
        open={dialog.cancelConfirm}
        onConfirm={onCancelConfirm}
        onClose={dialog.closeCancelConfirm}
      />
      <CancelConfirmDialog
        open={dialog.tabTransitionConfirm}
        onConfirm={onConfirmTabTransition}
        onClose={dialog.closeTabTransitionConfirm}
      />
      <StatusChangePopper
        anchorEl={popper.anchorEl}
        wbsItem={popper.wbsItem}
        wbsItemDelta={popper.wbsItemDelta}
        onClose={popper.close}
        onAfterUpdate={_ => {
          refreshAll()
        }}
      />
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functionLayers: state.functionLayer.layers,
})

export default connect(mapStateToProps)(withRouter(MyWbsItems))
