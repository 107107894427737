import { DialogActions } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import TaskActualWorkList from '../tables/TaskActualWorkList'
import { injectIntl, WrappedComponentProps } from 'react-intl'

export interface TaskActualWorkDialogState {
  open: boolean
  taskUuid: string
}

interface Props extends WrappedComponentProps {
  open: boolean
  closeHandler: Function
  taskUuid: string
}

const TaskActualWorkDialog = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.closeHandler()}
      PaperProps={{
        sx: {
          overflowY: 'hidden',
        },
      }}
    >
      <DialogContent sx={{ width: '410px', minHeight: '210px' }}>
        <TaskActualWorkList taskUuid={props.taskUuid} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeHandler()} color="primary">
          {props.intl.formatMessage({ id: 'dialog.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default injectIntl(TaskActualWorkDialog)
