import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { StatusComboBox } from '../ComboBox/StatusComboBox'
import { filterValuesByCombination } from '../../../../../lib/commons/customEnum'
import objects from '../../../../../utils/objects'
import { ProgressIcon } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/ProgressIcon'
import {
  ProgressIconArea,
  ProjectPlanNewStatusArea,
} from '../CellRenderer/ProjectPlanNewStatusCellRenderer'

interface Props extends ICellEditorParams {
  customEnumCode?: string
  combinedValuePath?: (combinedEnumCode: string) => string
  valuesAllowed?: CustomEnumValue[]
  popperOpen?: boolean
}

export const ProjectPlanNewStatusCellEditor = forwardRef(
  (
    { customEnumCode, combinedValuePath, value, api, context, data }: Props,
    ref
  ) => {
    const [val, setVal] = useState(value ?? '')
    const [isEdited, setIsEdited] = useState(false)
    const wbsItem = data?.body?.wbsItem ?? data?.wbsItem

    let options: CustomEnumValue[] = []
    if (customEnumCode) {
      options = filterValuesByCombination(context[customEnumCode], code =>
        objects.getValue(data, combinedValuePath?.(code))
      )
    }

    useImperativeHandle(ref, () => ({
      getValue() {
        return val
      },
    }))

    useEffect(() => {
      if (isEdited) {
        api.stopEditing()
        setIsEdited(false)
      }
    }, [api, isEdited])

    if (!options) return <></>

    return (
      <ProjectPlanNewStatusArea>
        <ProgressIconArea>
          <ProgressIcon wbsItem={wbsItem} fontSize={20} />
        </ProgressIconArea>
        <StatusComboBox
          value={val}
          options={options}
          dropDownListOpen={true}
          onChange={newValue => {
            setVal(newValue)
            setIsEdited(true)
          }}
          onClick={() => {
            api.stopEditing()
          }}
        />
      </ProjectPlanNewStatusArea>
    )
  }
)
