import { SubmitType } from '../../containers/meta/ViewMeta'
import wbsItem, {
  getAttachmentType,
  WbsItemBasic,
} from '../../../lib/functions/wbsItem'
import {
  AttachmentHandlerParams,
  SingleSheetContext,
  SingleSheetKey,
  SingleSheetOptions,
} from '../../containers/SingleSheet'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import EntitySearchVO from '../../../vo/EntitySearchVO'
import TextVO from '../../../vo/TextVO'
import SelectVO from '../../../vo/SelectVO'
import { TableData } from '../../containers/SingleSheet/DataTable'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import CommentHeaderWbsItem, {
  mapTableDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import WbsItemTitle from '../../components/headers/HeaderBar/Title/WbsItemTitle'
import FavoriteIcon from '../../components/icons/FavoriteIcon'
import Auth from '../../../lib/commons/auth'
import ProjectPlanBreadcrumbs from '../../components/headers/HeaderBar/Breadcrumbs/ProjectPlanBreadcrumbs'
import WbsItemToolBar from '../WbsItem/WbsItemToolBar'
import MultiEntitySearchVO from '../../../vo/MultiEntitySearchVO'
import { toggleWatchers } from '../../../lib/functions/watchWbsItem'
import objects from '../../../utils/objects'
import { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { TicketData } from '../Ticket'
import DateVO from '../../../vo/DateVO'
import MultiLineTextVO from '../../../vo/MultiLineTextVO'
import { FetchRefinementResponse } from '../TicketsNew/dataManager/refinement'
import RefinementApi from '../../../lib/functions/refinement'

class RefinementData extends TableData {
  knownRefinement: boolean
  originalRefinement: EntitySearchVO
  detectionPhase: SelectVO
  environment: SelectVO
  domain: SelectVO
  featureType: SelectVO
  feature: TextVO
  detectEvent: MultiLineTextVO
  occurrenceProcedure: MultiLineTextVO
  testSpecification: TextVO
  testCase: TextVO
  importance: SelectVO
  impact: SelectVO
  desiredDeliveryDate: DateVO
  cause: SelectVO
  directCause: MultiLineTextVO
  rootCause: MultiLineTextVO
  internalManagement: boolean
  properDetectionPhase: SelectVO
  interimAction: MultiLineTextVO
  permanentAction: MultiLineTextVO
  documentsToBeCorrected: TextVO
  horizontalDeploymentTarget: boolean
  horizontalDeploymentContent: MultiLineTextVO

  ticket: TicketData

  getCode() {
    return this.ticket?.wbsItem?.code
  }

  getName() {
    return this.ticket?.wbsItem?.displayName
  }
}

export class RefinementConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: FetchRefinementResponse, dest) {
    const ticket = src.ticket
    const wbsItem = ticket.wbsItem
    return {
      ...dest,
      originalRefinement: src.originalRefinement
        ? new EntitySearchVO(
            src.originalRefinement.uuid,
            src.originalRefinement.displayName ||
              src.originalRefinement['name'], // for update in SingleSheet
            src.originalRefinement.code
          )
        : undefined,
      ticket: {
        ...dest.ticket,
        uuid: ticket.uuid,
        prevSiblingUuid: ticket.prevSiblingUuid,
        parentUuid: ticket.parentUuid,
        wbsItem: {
          ...dest.ticket.wbsItem,
          projectUuid: wbsItem?.projectUuid,
          uuid: wbsItem?.uuid,
          lockVersion: wbsItem?.lockVersion,
          wbsItemType: wbsItem?.typeDto
            ? new WbsItemTypeVO(wbsItem.typeDto)
            : undefined,
          watchers: new MultiEntitySearchVO(
            wbsItem?.watchers?.map(v => ({
              uuid: v.uuid,
              code: '',
              iconUrl: v.iconUrl,
              name: v.name,
            })) ?? []
          ),
          actualHour: wbsItem?.actualHour,
        },
      },
    }
  }

  convertSerialized({
    src,
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    delta?: any
  }) {
    const { ticket } = src
    const { wbsItem } = ticket
    return {
      after: {},
      delta: {
        input: {
          ...delta,
          originalRefinementTicketUuid: delta.originalRefinementUuid,
          uuid: src.uuid,
          ticket: {
            ...delta?.ticket,
            uuid: ticket.uuid,
            wbsItem: {
              ...delta?.ticket?.wbsItem,
              uuid: wbsItem.uuid,
              type: dest.ticket?.wbsItem?.type,
            },
          },
        },
        watchers: dest.ticket?.wbsItem?.watchers,
        sprints: delta?.ticket?.wbsItem?.sprintUuid
          ? {
              wbsItemUuid: wbsItem?.uuid,
              wbsItemLockVersion: wbsItem?.lockVersion,
              added: delta.ticket.wbsItem.sprintUuid.newValue,
              deleted: !delta.ticket.wbsItem.sprintUuid.newValue
                ? delta.ticket.wbsItem.sprintUuid.oldValue
                : undefined,
            }
          : undefined,
        tags: delta?.ticket?.wbsItem?.tags
          ? {
              wbsItemUuid: wbsItem?.uuid,
              tagUuids: dest.ticket?.wbsItem?.tags.map(v => v.uuid),
            }
          : undefined,
      },
    }
  }

  convertInitialData(dest: any, auxiliaries: any) {
    const parentWbsItem = auxiliaries?.parentWbsItem
    return {
      ...dest,
      parentWbsItem: parentWbsItem
        ? new EntitySearchVO(
            parentWbsItem.uuid,
            parentWbsItem.displayName,
            parentWbsItem.code
          )
        : undefined,
    }
  }
}

export class Refinement extends SingleSheetOptions<RefinementData> {
  getApplicationContext = (basic: WbsItemBasic) => {
    return {
      groupKeys: [basic.projectUuid, basic.ticketType, basic.ticketListUuid],
    }
  }
  selectKey = async (basic: WbsItemBasic) => {
    if (!basic.code) {
      throw new Error('Could not get code')
    }
    const refinementResponse = await RefinementApi.getDetailByWbsItemUuid({
      wbsItemUuid: basic.uuid,
    })
    return { uuid: refinementResponse?.json?.uuid } as SingleSheetKey
  }
  converterSpec = new RefinementConverterSpec()
  getCodeByData = (data: RefinementData): TextVO => {
    return data.ticket.wbsItem!.code!
  }
  // Update entity by ticket uuid, but use task uuid for tabs
  uuidForOptionalFunction = (data: RefinementData) => [
    data.ticket.wbsItem?.uuid || '',
    data.uuid || '',
  ]
  getToolbarProps = (ctx: SingleSheetContext<RefinementData>) => {
    return {
      wbsItemType: ctx.state.data.ticket.wbsItem?.wbsItemType,
    }
  }

  onUploadAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<RefinementData>): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    if (!ctx.state.data.ticket.wbsItem?.uuid) {
      return
    }
    await this.onUploadWbsItemAttachments({
      externalId,
      uuid: ctx.state.data.ticket.wbsItem.uuid,
      lockVersion: ctx.state.data.ticket.wbsItem.lockVersion,
      attachments,
      ctx,
    })
    return {
      uuid,
      lockVersion: lockVersion as number,
    }
  }

  onUploadWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<RefinementData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.ticket.wbsItem?.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.createAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachments,
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  onDeleteAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<RefinementData>): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    if (!ctx.state.data.ticket.wbsItem?.uuid) {
      return
    }
    await this.onDeleteWbsItemAttachments({
      externalId,
      uuid: ctx.state.data.ticket.wbsItem.uuid,
      lockVersion: ctx.state.data.ticket.wbsItem.lockVersion || 0,
      attachments,
      ctx,
    })
    return {
      uuid,
      lockVersion: lockVersion as number,
    }
  }

  onDeleteWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<RefinementData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.ticket.wbsItem?.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.deleteAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachmentItemUuids: attachments.map(v => v.uuid),
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  TitleComponent = props => {
    const type = props.data?.ticket?.wbsItem?.typeDto
    return (
      <WbsItemTitle
        path={'ticket.wbsItem.displayName'}
        data={props.data}
        icon={type?.iconUrl ? <img src={type.iconUrl} /> : undefined}
      />
    )
  }

  HeaderComponent = props => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FavoriteIcon
          key="header-favorite-icon"
          checked={(props.data.ticket.wbsItem?.watchers || []).some(
            v => v.uuid === Auth.getCurrentTenant()?.user?.uuid
          )}
          onClick={(checked: boolean) => {
            const watchers = props.data.ticket.wbsItem?.watchers || []
            toggleWatchers(watchers, 'ticket.wbsItem.watchers', checked)
          }}
        />
        <Box sx={{ marginLeft: '16px' }}>
          <ProjectPlanBreadcrumbs
            key="header-breadcrumb"
            rootUuid={props.data.ticket.wbsItem!.uuid!}
            itemName={props.data.ticket.wbsItem!.displayName}
          />
        </Box>
      </Box>
    )
  }

  toolBarItems = ctx => {
    if (ctx.state.submitType === SubmitType.Create) {
      return []
    }
    const item = ctx.state.data.ticket.wbsItem
    const status = item.status ? item.status.getValue() : WbsItemStatus.TODO
    const cumulation = ctx.state.cumulation
    const cellDefs = ctx.state.cellDefs

    return [
      <WbsItemToolBar
        key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}
        status={status}
        item={item}
        ctx={ctx}
        cumulation={cumulation}
        cellDefs={cellDefs}
        prefix={'ticket.wbsItem.'}
        ticketItem={ctx.state.data}
      />,
    ]
  }
  defaultLeftTabExternalId = 'refinement.ticket.wbsItem.description'
  ignoreDataChangeCheckKeys = ['deliverableAttachments']

  commentHeaderComponents = ctx => {
    if (ctx.state.submitType === SubmitType.Create) {
      return []
    }
    return [
      <CommentHeaderWbsItem
        key={'1'}
        wbsItem={mapTableDataForCommentHeader(
          ctx.state.data.ticket.wbsItem,
          ctx.state.data.ticket.ticketType
        )}
        onAfterUpdate={() => {
          ctx.refreshAndMergeUncommitData({})
        }}
        readonly={true}
      />,
    ]
  }

  isDateDelayed = (
    data: RefinementData,
    target:
      | 'scheduledStartDate'
      | 'scheduledEndDate'
      | 'actualStartDate'
      | 'actualEndDate'
  ): boolean => {
    const delayedJudgmentItem = data.ticket.wbsItem
    if (!delayedJudgmentItem) return false
    const today = new Date(Date.now())
    const scheduledDate = objects.getValue(delayedJudgmentItem, 'scheduledDate')
    const actualDate = objects.getValue(delayedJudgmentItem, 'actualDate')
    if (target === 'scheduledStartDate') {
      return (
        scheduledDate?.getValue().startDate! <= today &&
        actualDate?.getValue().startDate === undefined
      )
    } else if (target === 'scheduledEndDate') {
      return (
        scheduledDate?.getValue().endDate! <= today &&
        actualDate?.getValue().endDate === undefined
      )
    } else if (target === 'actualStartDate') {
      return (
        scheduledDate?.getValue().startDate! < actualDate?.getValue().startDate!
      )
    } else if (target === 'actualEndDate') {
      return (
        scheduledDate?.getValue().endDate! < actualDate?.getValue().endDate!
      )
    }
    return false
  }

  getDelayTarget = (externalId: string, target) => {
    if (externalId.includes('scheduledDate') && target === 'start') {
      return 'scheduledStartDate'
    } else if (externalId.includes('scheduledDate') && target === 'end') {
      return 'scheduledEndDate'
    } else if (externalId.includes('actualDate') && target === 'start') {
      return 'actualStartDate'
    } else if (externalId.includes('actualDate') && target === 'end') {
      return 'actualEndDate'
    }
    return undefined
  }

  getStyleRule = (
    externalId: string
  ):
    | ((data: RefinementData, target?: string) => CSSProperties | undefined)
    | undefined => {
    if (
      externalId.includes('scheduledDate') ||
      externalId.includes('actualDate')
    ) {
      return (data: RefinementData, target?: string) => {
        if (!target) return undefined
        const delayTarget = this.getDelayTarget(externalId, target)
        if (!delayTarget) return undefined
        const isDelayed = this.isDateDelayed(data, delayTarget)
        if (isDelayed) {
          return {
            color: '#ff6b50',
          }
        }
      }
    }
  }
}
