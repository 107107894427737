import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  GetContextMenuItemsParams,
  ICellEditorParams,
  NewValueParams,
  RowNode,
  StatusPanelDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRequestByRow,
  createRowByResponse,
  getWbsItemFunctionUuid,
  taskActualResultExists,
  WbsItemDeltaInput,
  WbsItemDetail,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import store from '../../../store'
import { ColumnType, columnTypes } from '../../containers/commons/AgGrid'
import { SprintStatus } from '../../../lib/functions/sprint'
import { getUrlQueryStringParams } from '../../../utils/urls'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '..'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import TicketApi, {
  CreateTicketInput,
  TicketListDetailTree,
  UpdateTicketDeltaBatchRequest,
  UpdateTicketDeltaInput,
} from '../../../lib/functions/ticket'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import Workload, {
  getWorkloadUnitValue,
  round,
  WorkloadUnit,
} from '../../../lib/functions/workload'
import { generateUuid } from '../../../utils/uuids'
import {
  Comment,
  commentListToSummary,
  CommentSummary,
} from '../../../store/comments'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import TicketListApi, {
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import ViewMeta from '../../containers/meta/ViewMeta'
import { formatDateTime } from '../../../utils/date'
import { openComment } from '../../../store/information'
import objects from '../../../utils/objects'
import { AttachmentSummary } from '../../../utils/attachment'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import { createWatchers } from '../ProjectPlan/projectPlanOptions'
import projectPlanFacadeAggregator, {
  EvmIndicator,
} from '../../containers/commons/AgGrid/lib/aggregator/projectPlanAggregator'
import DateVO from '../../../vo/DateVO'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { List } from 'immutable'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import { KeyBindListener } from '../../model/keyBind'
import { TagForWbsItem, createTagInputs } from '../../../lib/functions/tag'
import { dateVoService } from '../../../domain/value-object/DateVO'
import { WbsItemCodeFormat } from '../../../lib/functions/project'

// TODO: delete this file. [V6BWULRZ]

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class TicketListRow extends RowData {
  wbsItem: WbsItemRow = {}
  ticketType: string = ''
  cumulation?: ProjectPlanCumulation
  ticketList?: WbsItemDetail
  parentWbsItem?: WbsItemDetail
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
}

export class TicketListRowDataSpec extends RowDataSpec<
  TicketListDetailTree,
  TicketListRow
> {
  ticketType: string

  constructor(_ticketType: string) {
    super()
    this.ticketType = _ticketType
  }

  columnTypes(): { [key: string]: ColDef } {
    return {
      code: {
        editable: params => {
          return (
            params.data.isAdded &&
            store.getState().project.current?.wbsItemCodeFormat ===
              WbsItemCodeFormat.RANDOM
          )
        },
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
        },
      },
      path: {
        cellStyle: { direction: 'rtl' },
      },
    }
  }

  createNewRow(ctx: TicketListBulkSheetContext): TicketListRow {
    const wbsItemType = store
      .getState()
      .project.ticketTypes.find(v => v.code === this.ticketType)
    const project = store.getState().project
    const wbsItemCodeFormat = project.current?.wbsItemCodeFormat
    const generateCode =
      wbsItemCodeFormat === WbsItemCodeFormat.SEQUENTIAL
        ? project.current?.code + '-'
        : Math.random().toString(36).slice(-8).toUpperCase()
    const { dailyWorkHours, monthlyWorkDays } =
      store.getState().tenant.organization!
    return {
      uuid: generateUuid(),
      ticketType: this.ticketType,
      wbsItem: {
        uuid: '',
        code: generateCode,
        status: WbsItemStatus.TODO,
        type: WbsItemType.TASK,
        ticketType: this.ticketType,
        displayName: '',
        estimatedWorkload: Workload.from({
          standard: { dailyWorkHours, monthlyWorkDays },
        }),
        wbsItemType: wbsItemType,
        baseWbsItemType: wbsItemType,
      },
      ticketList: ctx.state.ticketList?.wbsItem,
      parentWbsItem: ctx.state.ticketList?.wbsItem,
      isAdded: false,
      isEdited: false,
    } as TicketListRow
  }

  overwriteRowItemsWithParents(params: {
    child: TicketListRow
    parent: TicketListRow
  }): TicketListRow {
    return params.child
  }

  importNewRow(
    v: TicketListDetailTree,
    ctx: TicketListBulkSheetContext
  ): boolean {
    if (!v.wbsItem.code) {
      const project = store.getState().project
      const wbsItemCodeFormat = project.current?.wbsItemCodeFormat
      const generateCode =
        wbsItemCodeFormat === WbsItemCodeFormat.SEQUENTIAL
          ? project.current?.code + '-'
          : Math.random().toString(36).slice(-8).toUpperCase()
      v.wbsItem.code = generateCode
    }
    const ticketType = store
      .getState()
      .project.ticketTypes.find(
        t => t.code === ctx.state.ticketList?.ticketType
      )
    ticketType && (v.wbsItem.typeDto = ticketType.toWbsItemTypeObject())
    v.ticketType = ticketType?.code
    ctx.state.ticketList &&
      (v.ticketList = {
        ...ctx.state.ticketList.wbsItem,
        uuid: ctx.state.ticketList.uuid,
      })
    v.wbsItem.type = WbsItemType.TASK
    const context = ctx.state.gridOptions.context
    v.wbsItem.estimatedHour = round(
      objects.getValue(v.wbsItem, 'estimatedWorkload') *
        (context?.workloadUnitState?.hoursPerSelectedUnit || 1)
    )
    return true
  }

  createRowByResponse(
    response: TicketListDetailTree,
    viewMeta: ViewMeta
  ): TicketListRow {
    const wbsItem = createRowByResponse(response.wbsItem, response.cumulation)
    return {
      ...response,
      wbsItem: wbsItem,
      createdAt: formatDateTime(response.createdAt),
      updatedAt: formatDateTime(response.updatedAt),
      extensions: viewMeta.deserializeEntityExtensions(
        response.extensions || []
      ),
    } as unknown as TicketListRow
  }
}

export interface TicketListProps extends BulkSheetSpecificProps {
  updateAggregationRowNodes: (nodes: RowNode[]) => void
}

export interface TicketListState extends BulkSheetState {
  ticketList?: TicketListDetail
  workloadUnit: WorkloadUnit
  tags?: TagForWbsItem[]
}

export interface TicketListBulkSheetContext
  extends BulkSheetContext<
    TicketListProps,
    TicketListDetailTree,
    TicketListRow,
    TicketListState
  > {}

export default abstract class TicketListOptions extends BulkSheetOptions<
  TicketListProps,
  TicketListDetailTree,
  TicketListRow,
  TicketListState
> {
  constructor(ticketType: string) {
    super()
    this.ticketType = ticketType
  }

  ticketType: string
  addable = true
  draggable = true
  enableExcelExport = true
  enableExcelImport = true
  displayNameField = 'wbsItem.displayName'
  uniqueColumn = 'ticket.list.wbsItem.code'
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: TicketListBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateURLCopyContextMenu(params, [
          ContextMenuItemId.COPY_URL,
          ContextMenuItemId.COPY_URL_AND_NAME,
        ]),
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params, [ContextMenuItemId.REMOVE_ROW]),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  updateDefaultState = async (
    s: TicketListState,
    applicationFunctionUuid: string
  ) => {
    const statusPanels =
      s.gridOptions.statusBar?.statusPanels || ([] as StatusPanelDef[])
    statusPanels.push({
      statusPanel: 'countWbsStatusComponent',
      align: 'left',
    })
    let pageState
    const pageStateProp = await this.getPageState(applicationFunctionUuid)
    if (pageStateProp && pageStateProp.value) {
      pageState = JSON.parse(pageStateProp.value)
    }
    const newState = {
      ...s,
      editable: false,
      aggregateTargetType: AggregateField.WBS_ITEM_WORKLOAD,
      workloadUnit: pageState ? pageState.workloadUnit : WorkloadUnit.HOUR,
    }
    objects.setValue(
      newState,
      'gridOptions.statusBar.statusPanels',
      statusPanels
    )
    return newState
  }
  getDefaultContext = (): any => ({
    aggregateTargetType: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.HOUR,
  })
  getRowsToRemove = (
    nodes: RowNode[],
    ctx: TicketListBulkSheetContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    let result: RowNode[] = []
    const canRemoveRow = (node: RowNode) => {
      const row: TicketListRow = node.data
      return !row.wbsItem.actualHour
    }
    nodes.forEach(node => {
      if (canRemoveRow(node)) {
        result.push(node)
      }
    })
    return { rows: result }
  }

  pinnedColumns = [
    'ticket.list.action',
    'ticket.list.ticketList',
    'ticket.list.parentPath',
    'ticket.list.parentWbsItem',
    'ticket.list.wbsItem.code',
    'ticket.list.wbsItem.status',
    'ticket.list.wbsItem.substatus',
    'ticket.list.ticketType',
    'ticket.list.wbsItem.displayName',
    'ticket.list.attachment',
  ]
  lockedColumns = [
    'ticket.list.action',
    'ticket.list.attachment',
    'ticket.list.wbsItem.code',
    'ticket.list.wbsItem.status',
    'ticket.list.wbsItem.substatus',
    'ticket.list.ticketType',
    'ticket.list.ticketList',
    'ticket.list.parentPath',
    'ticket.list.parentWbsItem',
  ]
  customColumnTypes = {
    'ticket.list.wbsItem.code': ['code'],
    'ticket.list.wbsItem.status': [ColumnType.wbsItemStatus],
    'ticket.list.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.actualDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'ticket.list.commentSummary.latestComment': [ColumnType.comment],
    'ticket.list.wbsItem.priority': ['priorityColumn'],
    'ticket.list.parentPath': ['path'],
  }
  customRenderers = {
    'ticket.list.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  getApplicationContext = (ctx: TicketListBulkSheetContext) => {
    const groupKeys: string[] = []
    if (!ctx.props.projectUuid) {
      return undefined
    }
    groupKeys.push(ctx.props.projectUuid)
    groupKeys.push(this.ticketType)
    const state = ctx.state
    if (!state) {
      return { groupKeys }
    }
    if (state.ticketList) {
      groupKeys.push(state.ticketList.uuid)
    }
    return { groupKeys }
  }
  protected getCustomRenderers = {
    'ticket.list.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  fieldRefreshedAfterMove = ['wbsItem.status']

  async getAll(state: TicketListState): Promise<APIResponse> {
    if (!state.ticketList?.uuid) {
      return successDummyResponse
    }
    return TicketApi.getByTicketListUuid({
      ticketListUuid: state.ticketList.uuid,
    })
  }

  getUpdatedRowAncestors = async (
    uuid: string
  ): Promise<TicketListDetailTree> => {
    return (await TicketApi.getDetail({ uuid })).json as TicketListDetailTree
  }
  onSubmit = async (
    ctx: TicketListBulkSheetContext,
    data: {
      added: TicketListRow[]
      edited: {
        before: TicketListRow
        after: TicketListRow
      }[]
      deleted: TicketListRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const request: UpdateTicketDeltaBatchRequest = {
      added: data.added.map(row => {
        return this.buildCreateRequestByRow(row, viewMeta, ctx)
      }),
      edited: data.edited.map(row => {
        return this.buildUpdateDeltaRequestByRow(row, viewMeta)
      }),
      deleted: data.deleted.map(row => this.buildDeleteRequestByRow(row)),
      watchers: createWatchers(data.added, data.edited),
      tags: createTagInputs(data.added, data.edited),
    }
    const response = await TicketApi.updateBatchDelta(request)

    // Update sprint backlogs
    const addedUuidMap = Object.fromEntries(
      response.json?.added.map(v => [v.uuid, v.wbsItemUuid])
    )
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      [
        ...data.added.map(v => ({ ...v.wbsItem, uuid: addedUuidMap[v.uuid] })),
        ...data.edited.map(v => v.after.wbsItem),
      ],
      Object.fromEntries(
        [...response.json.added, ...response.json.edited].map(v => [
          v.wbsItemUuid,
          v.wbsItemLockVersion,
        ])
      ),
      data.edited.map(v => v.before.wbsItem)
    )
    await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    return response
  }

  protected buildCreateRequestByRow(
    row: TicketListRow,
    viewMeta: ViewMeta,
    ctx: TicketListBulkSheetContext
  ): CreateTicketInput {
    const u = row.wbsItem
    const wbsItemInput = createRequestByRow(u, viewMeta, 'ticket.list.wbsItem')
    return {
      ...row,
      uuid: row.uuid,
      projectUuid: ctx.state.uuid,
      ticketType: this.ticketType,
      ticketListUuid: ctx.state.ticketList!.uuid,
      parentWbsItemUuid: row.parentWbsItem?.uuid,
      projectPlan: {
        projectUuid: ctx.state.uuid,
        uuid: generateUuid(),
        type: WbsItemType.TASK,
        wbsItem: wbsItemInput,
        productBacklogItem: false,
      },
      extensions: viewMeta.serializeEntityExtensionsForApi(row.extensions),
      prevSiblingUuid: row.prevSiblingUuid,
      parentUuid: row.parentUuid,
    } as CreateTicketInput
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: TicketListRow
      after: TicketListRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  protected buildUpdateDeltaRequestByRow(
    editedRow: {
      before: TicketListRow
      after: TicketListRow
    },
    viewMeta: ViewMeta
  ): UpdateTicketDeltaInput {
    const wbsItemInput = createDeltaRequestByRow(
      {
        before: editedRow.before.wbsItem,
        after: editedRow.after.wbsItem,
      },
      viewMeta,
      'ticket.list.wbsItem',
      {}
    )
    return {
      uuid: editedRow.after.uuid,
      ticketListUuid: {
        oldValue: editedRow.before.ticketList?.uuid,
        newValue: editedRow.after.ticketList?.uuid,
      },
      parentUuid: {
        oldValue: editedRow.before.parentUuid,
        newValue: editedRow.after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: editedRow.before.prevSiblingUuid,
        newValue: editedRow.after.prevSiblingUuid,
      },
      wbsItem: wbsItemInput,
      extensions: viewMeta.serializeEntityExtensionsDeltaForApi({
        oldExtensions: editedRow.before.extensions,
        newExtensions: editedRow.after.extensions,
      }),
    } as UpdateTicketDeltaInput
  }

  protected buildDeleteRequestByRow = (row: TicketListRow) => {
    return {
      wbsItemUuid: row.wbsItem.uuid!,
      wbsItemLockVersion: row.wbsItem.lockVersion!,
    }
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  onTicketListChanged(
    ticketList: TicketListDetail | undefined,
    ctx: TicketListBulkSheetContext
  ) {
    ctx.setState({ ticketList, editable: !!ticketList }, ctx.refreshGrid)
  }

  getOpenDetailSpec = async (row: TicketListRow): Promise<OpenDetailSpec> => {
    return {
      openInDialog: true,
      layer: {
        externalId: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
        code: row.wbsItem.code!,
      },
    }
  }

  getSearchCondition = (state: TicketListState) => {
    return {
      ticketListUuid: state.ticketList?.uuid ?? undefined,
      projectUuid: state.uuid,
    }
  }

  restoreSearchCondition = async (
    searchCondition: { ticketListUuid: string; projectUuid: string },
    ctx: TicketListBulkSheetContext
  ) => {
    let ticketListUuid = ''
    let urlParams = getUrlQueryStringParams()
    if (urlParams && urlParams.ticketList) {
      ticketListUuid = urlParams.ticketList
    } else if (searchCondition.projectUuid === ctx.state.uuid) {
      ticketListUuid = searchCondition.ticketListUuid
    }
    if (ticketListUuid) {
      const ticketListResponse = await TicketListApi.findByUuid(ticketListUuid)
      this.onTicketListChanged(ticketListResponse.json, ctx)
      return
    }
    this.onTicketListChanged(undefined, ctx)
  }

  pinnedTopRowData: TicketListRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      wbsItem: {},
      isTotal: true,
      isViewOnly: true,
    } as TicketListRow,
  ]

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: TicketListRow = params.data
    if (row && taskActualResultExists(row.wbsItem)) {
      return row.wbsItem.uuid
    }
    return undefined
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.estimatedStoryPoint') {
      return {
        wbsItemTypeFieldName: 'wbsItem.type',
        wbsItemStatusFieldName: 'wbsItem.status',
        storyPointWorkloadFieldName: 'wbsItem.estimatedStoryPoint',
      }
    }
    if (field === 'wbsItem.scheduledDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.scheduledDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.actualDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.actualDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.currentSprint') {
      return {
        validatePaste: value =>
          [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
            value.status!
          ),
      }
    }
  }

  public getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        isAggregate: true,
      }
    }
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: TicketListRow) =>
          row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    return undefined
  }

  getAttachmentCellRendererParams = () => {
    return {
      getAttachmentList: async (row: TicketListRow) => {
        if (row.wbsItem.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: TicketListRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = (ctx: TicketListBulkSheetContext) => {
    return {
      path: 'wbsItem.description',
      openComment: (row: TicketListRow, ctx: TicketListBulkSheetContext) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      addRowActions: (row: TicketListRow) => {
        ctx.addRow(row.uuid)
      },
      getCommentSummary: (row: TicketListRow) => row.commentSummary,
    }
  }
  isSetKeyBind: boolean = true
  getKeyBindListeners = (
    ctx: TicketListBulkSheetContext
  ): KeyBindListener[] => {
    return [
      {
        key: 'alt+shift+l',
        fn: () => {
          if (
            !!ctx.gridApi?.getSelectedNodes() &&
            ctx.gridApi?.getSelectedNodes().length > 0
          ) {
            ctx.addRow(
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid,
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent &&
                !ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.groupData
                ? ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.id
                : undefined
            )
          }
        },
      },
      {
        key: 'mod+shift+l',
        fn: () => {
          if (
            !!ctx.gridApi?.getSelectedNodes() &&
            ctx.gridApi?.getSelectedNodes().length > 0
          ) {
            ctx.addRow(
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid,
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid
            )
          }
        },
      },
      {
        key: 'alt+shift+d',
        fn: ctx.removeRowKeyEventListener,
      },
    ]
  }
  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'wbsItem.priority',
        'wbsItem.accountable',
        'wbsItem.responsible',
        'wbsItem.assignee',
        'wbsItem.difficulty',
      ].includes(field)
    ) {
      return 65
    }
    if (
      [
        'wbsItem.estimatedStoryPoint',
        'storyPoint.earnedValue',
        'wbsItem.estimatedWorkload',
        'wbsItem.actualHour',
        'wbsItem.earnedValue',
        'remaining',
        'preceding',
        'delayed',
        'plannedValue',
        'wbsItem.watchers',
      ].includes(field)
    ) {
      return 80
    }
    if (['wbsItem.code'].includes(field)) {
      return 100
    }
    if (field === 'wbsItem.status') {
      return 150
    }
    if (field === 'action') {
      return 135
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    if (field === 'wbsItem.tags') {
      return 100
    }
    return undefined
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (['wbsItem.earnedValue'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.EARNED_VALUE,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }
          if (nodeData.wbsItem.status === WbsItemStatus.DONE) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number(((Number(sum) || 0) / denominator).toFixed(2))
      }
    }
    if (['plannedValue'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.PLANNED_VALUE,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isSameOrBefore(
              DateVO.now()
            )
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['preceding'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.PRECEDING,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isAfter(
              DateVO.now()
            ) &&
            nodeData.wbsItem.status === WbsItemStatus.DONE
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['delayed'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.DELAYED,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isSameOrBefore(
              DateVO.now()
            ) &&
            nodeData.wbsItem.status !== WbsItemStatus.DONE
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['remaining'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.REMAINING,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (nodeData.wbsItem.status !== WbsItemStatus.DONE) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (field === 'wbsItem.estimatedWorkload') {
      return (params: ValueGetterParams) => {
        const data: TicketListRow = params.data
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        const format = (value: number | undefined) =>
          Number((Number(value) || 0) / denominator)
        if (data.isTotal) {
          let sum = 0
          if (!params.api) {
            return
          }
          params.api.forEachNode(node => {
            const nodeData = node.data
            if (nodeData.isTotal) {
              return
            }
            if (nodeData.wbsItem.status !== WbsItemStatus.DISCARD) {
              sum = sum + nodeData.wbsItem.estimatedWorkload.hour
            }
          })
          return format(sum)
        }
        return getWorkloadUnitValue(
          data.wbsItem.estimatedWorkload,
          params.context.workloadUnit
        )
      }
    }
    if (['wbsItem.actualHour'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: TicketListRow = params.data
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        const format = (value: number | undefined) =>
          Number(((Number(value) || 0) / denominator).toFixed(2))
        if (data.isTotal) {
          let sum = 0
          if (!params.api) {
            return
          }
          params.api.forEachNode(node => {
            const nodeData = node.data
            if (nodeData.isTotal) {
              return
            }
            if (nodeData.wbsItem.status !== WbsItemStatus.DISCARD) {
              sum = sum + (nodeData.wbsItem?.actualHour || 0)
            }
          })
          return format(sum)
        }
        return format(data.wbsItem?.actualHour)
      }
    }
    if (field === 'ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (field === 'wbsItem.tags') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.tags
      }
    }
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (field === 'wbsItem.estimatedWorkload') {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const unit = params.context.workloadUnit
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        let workload: Workload
        switch (unit) {
          case WorkloadUnit.MONTH:
            workload = Workload.from({
              month: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.DAY:
            workload = Workload.from({
              day: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.HOUR:
            workload = Workload.from({
              hour: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          default:
            workload = new Workload(0, 0, 0, {
              dailyWorkHours,
              monthlyWorkDays,
            })
        }
        params.data.wbsItem.estimatedWorkload = workload
        return true
      }
    }
    return undefined
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getOnCellValueChanged = (field: string, ctx: TicketListBulkSheetContext) => {
    if (
      [
        'wbsItem.status',
        'wbsItem.estimatedWorkload',
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualHour',
      ].includes(field)
    ) {
      return (params: NewValueParams) => {
        if (ctx.props.specificProps?.updateAggregationRowNodes) {
          ctx.props.specificProps?.updateAggregationRowNodes(
            ctx.getAllRowNodes()
          )
        }
      }
    }
    return undefined
  }

  onRowDataChanged = (ctx: TicketListBulkSheetContext) => {
    if (ctx.props.specificProps?.updateAggregationRowNodes) {
      ctx.props.specificProps?.updateAggregationRowNodes(ctx.getAllRowNodes())
    }
  }

  mergeRowCommentSummary = (
    targetRow: TicketListRow,
    comments: List<Comment> | undefined
  ): TicketListRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }
}
