import { useCallback, useEffect, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useStatusKanbanData } from './hooks/useKanbanData'
import {
  StatusKanbanSearchCondition,
  useStatusKanbanSearchCondition,
} from './hooks/useSearchCondition'
import { useProjectPrivateContext } from '../../context/projectContext'
import { CardInputError, EditableCardItem, SelectOption } from './Kanban'
import HeaderComponent from './components/Header'
import KanbanContext from './KanbanContext'
import styled from 'styled-components'
import { FunctionLayer } from '../../../store/functionLayer'
import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../../store'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import Loading from '../../components/process-state-notifications/Loading'
import { useSelectOptions } from './hooks/useSelectOptions'
import { useProjectPlanRootUuid } from './hooks/useProjectPlanRootUuid'
import { MessageLevel, addScreenMessage } from '../../../store/messages'
import { intl } from '../../../i18n'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useFilter } from './hooks/useFilter'
import _ from 'lodash'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import { ColumnContainer } from './ColumnContainer'

type StateProps = {
  functionLayers: Immutable.Map<number, FunctionLayer>
}

const Kanban = (props: PageProps) => {
  const { functionLayers } = useSelector<AllState, StateProps>(state => ({
    functionLayers: state.functionLayer.layers,
  }))
  const dispatch = useDispatch()
  const loadingRef = useRef<HTMLDivElement>(null)
  const refreshRef = useRef<Function>()
  const saveRef = useRef<Function>()
  const { projectUuid } = useProjectPrivateContext()

  const [errors, setErrors] = useState<CardInputError>(new CardInputError())
  const [projectMembersForFilter, setProjectMembersForFilter] = useState<
    SelectOption[]
  >([])
  const [prioritiesForFilter, setPrioritiesForFilter] = useState<
    SelectOption[]
  >([])

  const {
    initialized: searchConditionInitialized,
    searchCondition,
    updateSearchCondition,
    saveSearchCondition,
  } = useStatusKanbanSearchCondition(props.uuid, projectUuid)

  const { rootProjectPlanUuid } = useProjectPlanRootUuid(
    searchCondition.rootUuid
  )

  const { projectMembers, teams, priorities } = useSelectOptions(
    projectUuid,
    rootProjectPlanUuid
  )

  const {
    columns,
    setColumns,
    cards,
    updateCard,
    refresh,
    refreshSingle,
    save,
    isLoading,
    setIsLoading,
    sortCards,
  } = useStatusKanbanData({
    functionUuid: props.uuid,
    projectUuid,
    searchCondition,
    searchConditionInitialized,
    priorityOptions: priorities,
  })
  refreshRef.current = refresh
  saveRef.current = save

  const { updateFilterModel, filter, activeFilterModels } = useFilter()

  const onCancel = useCallback(() => {
    refreshRef.current && refreshRef.current()
    dispatch(doNotRequireSave())
  }, [])

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      if (errors.hasMessage()) {
        dispatch(
          addScreenMessage(props.uuid, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: intl.formatMessage({ id: 'kanban.error.requiredItems' }),
          })
        )
        return
      }

      const response = (await saveRef.current?.()) || (await save())
      if (response.hasWarning) {
        const messages = extractValuesFromResponse(response.json, 'messages')
        handleWarning(messages, uuid => cards[uuid])
      }

      if (!response.hasError && !response.hasWarning) {
        dispatch(
          addScreenMessage(props.uuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        dispatch(doNotRequireSave())
      }
    } finally {
      setIsLoading(false)
    }
    saveSearchCondition()
  }, [save, saveSearchCondition])

  const onChangeSearchCondition = useCallback(
    (updated: Partial<StatusKanbanSearchCondition>) => {
      updateSearchCondition(updated)
      dispatch(doNotRequireSave())
    },
    []
  )

  const onChangeCard = useCallback(
    (id: string, field: EditableCardItem, changed: any) => {
      updateCard(id, field, changed)
      dispatch(requireSave())
    },
    []
  )

  useEffect(() => {
    if (Object.keys(cards).length === 0) {
      return
    }
    if (projectMembersForFilter.length === 0) {
      const responsibleUuids = Object.values(cards)
        .map(v => v.content.responsible?.uuid)
        .filter(v => !!v)
      const uniqueUuids = _.uniq(responsibleUuids)
      setProjectMembersForFilter(
        projectMembers.filter(v => uniqueUuids.includes(v.value))
      )
    }
    if (prioritiesForFilter.length === 0) {
      const usedValues = Object.values(cards)
        .map(v => v.content.priority)
        .filter(v => !!v)
        .filter(v => v !== CUSTOM_ENUM_NONE)
      const uniqueValues = _.uniq(usedValues)
      setPrioritiesForFilter(
        priorities.filter(v => uniqueValues.includes(v.value))
      )
    }
  }, [cards, projectMembers, priorities])

  useKeyBind(
    [{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }],
    [onSubmit],
    props.uuid
  )

  return (
    <PageArea>
      {functionLayers.size === 1 && (
        <SavePopper loading={isLoading} onSubmit={onSubmit} />
      )}
      <KanbanContext.Provider
        value={{
          projectMembers,
          projectMembersForFilter,
          teams,
          priorities,
          prioritiesForFilter,
          errors,
        }}
      >
        <HeaderComponent
          searchCondition={searchCondition}
          onChange={onChangeSearchCondition}
          onCancel={onCancel}
          filterModels={activeFilterModels}
          updateFilterModel={updateFilterModel}
          applySort={config => sortCards(config)}
          rootProjectPlanUuid={rootProjectPlanUuid}
        />
        <LoadingRefDiv ref={loadingRef}>
          <ColumnContainer
            columns={columns}
            refreshSingle={refreshSingle}
            handleChangeCardContent={onChangeCard}
            filter={filter}
            cards={cards}
            setColumns={setColumns}
            save={onSubmit}
          />
        </LoadingRefDiv>
      </KanbanContext.Provider>
      <Loading isLoading={isLoading} elem={loadingRef.current} />
    </PageArea>
  )
}

export default projectPrivate(Kanban)

const LoadingRefDiv = styled('div')({
  height: 'inherit',
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
})
