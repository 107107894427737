import {
  addKeyBindListeners as addKeyBindListenersAction,
  updateKeyBindListeners as updateKeyBindListenersAction,
  removeKeyBindListeners as removeKeyBindListenersAction,
} from './../../store/keyBind'
import { KeyBindListener } from '../model/keyBind'
import { DependencyList, useCallback, useEffect, useMemo, useRef } from 'react'
import store from '../../store'

export const useKeyBind = (
  listeners: KeyBindListener[],
  _deps?: DependencyList,
  id?: string
) => {
  const deps = useMemo(() => _deps || [], [_deps])

  const addKeyBindListeners = useCallback(
    (listeners: KeyBindListener[], id: string | undefined) => {
      store.dispatch(addKeyBindListenersAction(listeners, id))
    },
    []
  )

  const updateKeyBindListeners = useCallback(
    (listeners: KeyBindListener[], id: string | undefined) => {
      store.dispatch(updateKeyBindListenersAction(listeners, id))
    },
    []
  )

  const removeKeyBindListeners = useCallback(() => {
    store.dispatch(removeKeyBindListenersAction())
  }, [])

  useEffect(() => {
    updateKeyBindListeners(listeners, id)
  }, deps)

  useEffect(() => {
    addKeyBindListeners(listeners, id)
    return () => removeKeyBindListeners()
  }, [])
}
