import { useMemo } from 'react'
import { intl } from '../../../i18n'
import { WorkloadUnit } from '../../../lib/functions/workload'
import AlertDialog from './AlertDialog'

type ExcelImportConfirmDialogProps = {
  open: boolean
  workloadUnit: WorkloadUnit
  onConfirm: () => void
  onClose: () => void
}

const ExcelImportConfirmDialog = ({
  open,
  workloadUnit,
  onConfirm,
  onClose,
}: ExcelImportConfirmDialogProps) => {
  const workloadUnitLabel = useMemo(() => {
    switch (workloadUnit) {
      case WorkloadUnit.HOUR:
        return intl.formatMessage({ id: 'workloadUnit.hour' })
      case WorkloadUnit.DAY:
        return intl.formatMessage({ id: 'workloadUnit.day' })
      case WorkloadUnit.MONTH:
        return intl.formatMessage({ id: 'workloadUnit.month' })
    }
  }, [workloadUnit])

  return (
    <AlertDialog
      isOpen={open}
      message={intl.formatMessage(
        { id: 'dialog.message.excelImport' },
        { workloadUnit: workloadUnitLabel }
      )}
      submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
      submitHandler={onConfirm}
      closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
      closeHandler={onClose}
    />
  )
}

export default ExcelImportConfirmDialog
