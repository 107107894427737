import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { CommentSummary } from '../../../store/comments'
import { AttachmentSummary } from '../../../utils/attachment'
import { ListRow } from '../../containers/BulkSheetView/model'
import { EntityExtensionValue } from '../../containers/meta/entityExtension'

export const MULTI_PROPERTY_DELIMITER: string = ','

export interface WbsItemSearchRow extends ListRow {
  wbsItem: WbsItemRow
  path?: string
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  typeIndex?: number
  ticketTypeIndex?: number
  parentWbsItem?: Partial<WbsItemRow>
  extensions?: EntityExtensionValue[]
}

export enum QuickFilterKeys {
  ACCOUNTABLE = 'ACCOUNTABLE',
  RESPONSIBLE_OR_ASSIGNEE = 'RESPONSIBLE_OR_ASSIGNEE',
}

export const WBS_ITEM_SEARCH_COUNT_LIMIT = 10000
