import { WbsItemStatus } from '../../domain/entity/WbsItemEntity'
import { dateTermVoService } from '../../domain/value-object/DateTermVO'
import { WbsItemSearchConditionVO } from '../../domain/value-object/WbsItemSearchConditionVo'

export type MyWbsItemSearchConditionApiRequest = {
  types?: string[]
  status?: WbsItemStatus[]
  scheduledStartDate?: DateTermApiRequest
  scheduledEndDate?: DateTermApiRequest
  actualStartDate?: DateTermApiRequest
  actualEndDate?: DateTermApiRequest
}

type DateTermApiRequest = {
  from?: string
  to?: string
}

export const useMyWbsItemSearchConditionApiRequestTransformService = (): {
  toApiRequest: (
    src: WbsItemSearchConditionVO
  ) => MyWbsItemSearchConditionApiRequest
} => {
  const toApiRequest = (
    src: WbsItemSearchConditionVO
  ): MyWbsItemSearchConditionApiRequest => {
    return {
      types: src.types,
      status: src.status,
      scheduledStartDate: src.scheduledStartDate
        ? dateTermVoService.format(src.scheduledStartDate)
        : undefined,
      scheduledEndDate: src.scheduledEndDate
        ? dateTermVoService.format(src.scheduledEndDate)
        : undefined,
      actualStartDate: src.actualStartDate
        ? dateTermVoService.format(src.actualStartDate)
        : undefined,
      actualEndDate: src.actualEndDate
        ? dateTermVoService.format(src.actualEndDate)
        : undefined,
    }
  }
  return { toApiRequest }
}
