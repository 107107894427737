import _ from 'lodash'
import {
  isRootNode,
  getAncestors as getAncestorNodes,
} from '../../commons/AgGrid'
import { Tree } from '../../../../lib/commons/tree'
import { BulkSheetState } from '../index'
import RowDataManager, { RowData } from './rowDataManager'
import objects from '../../../../utils/objects'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../pages'
import { RowNode } from 'ag-grid-community'
import store from '../../../../store'
import { WbsItemCodeFormat } from '../../../../lib/functions/project'

export default class ClientSideRowDataManager<
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> extends RowDataManager<T, R, S> {
  private data: R[] = []
  private removedRows: R[] = []

  public init = (trees: T[], replaceExisting: boolean = false) => {
    if (replaceExisting) {
      // Keep stored data
      this.data = this.data.filter(v => v.lockVersion)
    }
    const addable =
      typeof this.ctx.options.addable === 'function'
        ? this.ctx.options.addable(this.ctx.state)
        : this.ctx.options.addable
    if (trees.length === 0 && addable) {
      this.data = []

      const initialData = this.ctx.rowDataSpec.createNewRow(this.ctx)
      initialData.rowNumber = '1'
      initialData.treeValue = [initialData.uuid]
      initialData.isAdded = true
      this.data.push(initialData)
      return
    }

    const context = { index: 0 }
    trees.forEach(child =>
      this.recursiveAddRowData(child, [], replaceExisting, context)
    )
    if (this.ctx.rowDataSpec.topCustomRowData) {
      // TODO: enable to choose where to add custom row.
      this.data.unshift({
        ...this.ctx.rowDataSpec.topCustomRowData(),
        treeValue: [this.ctx.treeRoot.uuid],
        isViewOnly: true,
      })
    }
  }

  private recursiveAddRowData(
    tree: T,
    previousTreeValue: string[] = [],
    replaceExisting: boolean,
    context: { index: number }
  ) {
    let treeValue: string[] = []
    const treeProperty = this.ctx.viewMeta.functionMeta.treeProperty
    if (treeProperty) {
      treeValue = previousTreeValue.concat(tree.uuid)
    }
    let rowData = this.createRowByResponse(tree, this.ctx.viewMeta)
    rowData.treeValue = treeValue
    rowData.isAdded = false
    rowData.isEdited = false

    if (replaceExisting) {
      rowData.isEdited = true
      if (this.ctx.options.uniqueColumn) {
        const uniqueProp = this.ctx.viewMeta.functionMeta.properties.byId.get(
          this.ctx.options.uniqueColumn
        )!
        const keyValue = this.ctx.viewMeta.extractValue(rowData, uniqueProp)
        // TODO
        const project = store.getState().project
        const wbsItemCodeFormat = project.current?.wbsItemCodeFormat
        let originalIndex = -1
        if (keyValue) {
          if (
            (this.ctx.viewMeta.externalId === 'ticket.list.edit' ||
              'projectPlan.edit') &&
            WbsItemCodeFormat.SEQUENTIAL === wbsItemCodeFormat
          ) {
            originalIndex = this.data.findIndex(
              v =>
                this.ctx.viewMeta.extractValue(v, uniqueProp) === keyValue &&
                !v.isAdded
            )
          } else {
            originalIndex = this.data.findIndex(
              v => this.ctx.viewMeta.extractValue(v, uniqueProp) === keyValue
            )
          }
        }
        const original = this.data[originalIndex]
        if (original) {
          // First row data may not be a root when importing data
          if (previousTreeValue.length === 0) {
            previousTreeValue = original.treeValue
              ? original.treeValue.slice(0, original.treeValue.length - 1)
              : []
          }
          const row = Object.assign({}, original)
          row.treeValue = previousTreeValue.concat(original.uuid)
          row.isAdded = false
          row.isEdited = true
          row.lockVersion = original.lockVersion
          row.createdAt = original.createdAt
          row.createdBy = original.createdBy
          row.updatedAt = original.updatedAt
          row.updatedBy = original.updatedBy
          row.rowNumber = original.rowNumber
          if (!this.ctx.rowDataSpec.replaceRow(row, rowData)) {
            return
          }
          const properties = Array.from(
            this.ctx.viewMeta.functionMeta.properties.byId.values()
          )
          const updatableKeys = properties
            .filter(v => v.editableIfU.isTrue || v.editableIfU.operator)
            .map(v => this.ctx.viewMeta.makeDataPropertyName(v))
          updatableKeys.forEach(field => {
            const value = objects.getValue(rowData, field)
            if (value || typeof value === 'boolean') {
              objects.setValue(row, field, value)
            }
          })
          rowData = row
          this.data.splice(originalIndex, 1, rowData)
        } else {
          rowData.isAdded = true
          rowData.rowNumber = `${this.data.length + 1}`
          this.data.push(rowData)
        }
      } else {
        rowData.isAdded = true
        rowData.rowNumber = `${this.data.length + 1}`
        this.data.push(rowData)
      }
    } else {
      rowData.rowNumber = `${++context.index}`
      this.data.push(rowData)
      this.initialData[rowData.uuid] = _.cloneDeep(rowData)
    }
    tree.children = tree.children ? tree.children : []
    tree.children.forEach(child => {
      if (!this.ctx.rowDataSpec.addChild(rowData, child)) {
        return
      }
      this.recursiveAddRowData(
        child,
        rowData.treeValue,
        replaceExisting,
        context
      )
    })
  }

  getAllRows = () => {
    return this.data.concat()
  }

  getSingleRows = (uuid: string) => {
    return this.data.concat().find(d => d.uuid === uuid)
  }

  getIndexById = (id: string) => {
    return this.data.findIndex(d => d.uuid === id)
  }

  getPreviousSibling = (uuid: string): R | undefined => {
    const targetData: R | undefined = this.getDataById(uuid)
    if (!targetData) {
      // 指定されたidを保持するRowDataが存在しない
      return
    }

    let targetTreeValue = targetData.treeValue!.concat()
    targetTreeValue.pop()
    const siblings = this.data.filter(d => {
      let treeValue = d.treeValue!.concat()
      treeValue.pop()
      return treeValue.join() === targetTreeValue.join()
    })

    const previousSiblingIndex = siblings.findIndex(d => d.uuid === uuid) - 1
    if (previousSiblingIndex < 0) {
      // 指定されたidを保持するRowDataがルートである
      return
    }

    return siblings[previousSiblingIndex]
  }

  getParent = (uuid: string): R | undefined => {
    const targetData: R | undefined = this.getDataById(uuid)
    if (!targetData) {
      return
    }
    let targetTreeValue = targetData.treeValue!.concat()
    targetTreeValue.pop()
    return this.data.find(d => d.treeValue!.join() === targetTreeValue.join())
  }

  getGrandParent = (uuid: string): R | undefined => {
    const parentData = this.getParent(uuid)
    if (!parentData) {
      return
    }
    return this.getParent(parentData.uuid)
  }

  getSiblings = (uuid: string): R[] => {
    let siblings: R[] = []
    const parent = this.getParent(uuid)

    if (parent) {
      // case leaf node
      siblings = this.getChildren(parent.uuid)
    } else {
      // case root node
      this.ctx.gridApi!.forEachNode(rowNode => {
        if (rowNode.uiLevel === 0) {
          siblings.push(rowNode.data)
        }
      })
    }

    return siblings
  }

  getChildren = (uuid: string): R[] => {
    const rowNode = this.ctx.gridApi!.getRowNode(uuid)

    return rowNode && rowNode.childrenAfterGroup
      ? rowNode.childrenAfterGroup.map(childNode => childNode.data)
      : []
  }

  countChildren = (uuid: string): number => {
    const rowNode = this.ctx.gridApi!.getRowNode(uuid)
    // allLeafChildren has own row.
    return rowNode && rowNode.allLeafChildren
      ? rowNode.allLeafChildren.length - 1
      : 0
  }

  getAncestors = (uuid: string): R[] => {
    let ancestors: R[] = []
    let parentData: R | undefined = this.getParent(uuid)
    while (parentData) {
      ancestors.push(parentData)
      parentData = this.getParent(parentData.uuid)
    }
    return ancestors
  }

  private getDataById(uuid: string) {
    return this.data.concat().find(d => d.uuid === uuid)
  }

  addRowsTo = (
    rows: R[],
    params: { parentUuid?: string; prevSiblingUuid?: string }
  ): void => {
    // Get parent and previous sibling
    const parent = params.parentUuid
      ? this.getDataById(params.parentUuid)
      : undefined
    const prevSibling = params.prevSiblingUuid
      ? this.getDataById(params.prevSiblingUuid)
      : undefined
    // Fix row fields
    let prevSiblingUuid = prevSibling?.uuid
    rows.forEach(row => {
      if (parent && !isRootNode(parent.uuid)) {
        row.treeValue = [...parent.treeValue!, row.uuid]
        row.parentUuid = parent.uuid
      } else {
        row.treeValue = [row.uuid]
      }
      row.prevSiblingUuid = prevSiblingUuid
      prevSiblingUuid = row.uuid
      row.isAdded = true
    })

    // Refresh data
    let index = this.getIndexById(prevSibling?.uuid || parent?.uuid || '') + 1
    rows.forEach(row => {
      this.data.splice(index++, 0, row)
    })
    this.ctx.gridApi!.setRowData(this.data)
    this.refreshRowNumber(index - rows.length, -1)
    return
  }

  addRows = (rows: R | R[], addIndex: number, parentUuid?: string) => {
    if (!Array.isArray(rows)) {
      this.addSingleRow(rows, addIndex, parentUuid)
    } else {
      rows.forEach(row => this.addSingleRow(row, addIndex, parentUuid))
    }
  }

  private addSingleRow = (row: R, addIndex: number, parentUuid?: string) => {
    if (parentUuid && !isRootNode(parentUuid)) {
      row.treeValue = [...this.getDataById(parentUuid)!.treeValue!, row.uuid]
      row.parentUuid = parentUuid
    } else {
      row.treeValue = [row.uuid]
    }
    row.isAdded = true
    row.isEdited = true
    this.data.splice(addIndex, 0, row)
    this.ctx.gridApi!.setRowData(this.data)
    this.refreshRowNumber(addIndex, -1)
  }

  refreshRowNumber = (indexFrom: number, indexTo: number) => {
    let rowNumber = 0
    if (indexFrom > 0) {
      const prevRow = this.getPreviousDataRow(indexFrom)
      if (prevRow) {
        const startRowNumber = Number(prevRow.rowNumber)
        rowNumber = !Number.isNaN(startRowNumber) ? startRowNumber : 0
      }
    } else {
      indexFrom = 0
    }
    const max = Math.min(
      indexTo < 0 ? this.data.length : indexTo + 1,
      this.data.length
    )
    for (let i = indexFrom; i < max; i++) {
      this.data[i].rowNumber = `${++rowNumber}`
    }

    this.ctx.gridApi!.refreshCells({
      force: true,
      columns: ['rowNumber'],
    })
  }

  getPreviousDataRow = (rowIndex: number) => {
    let prevRow
    for (let i = rowIndex - 1; i >= 0; i--) {
      if (this.data[i].isViewOnly) {
        continue
      }
      prevRow = this.data[i]
      break
    }
    return prevRow
  }

  moveRows = (rows: R[], toUuid?: string) => {
    // TODO Implement it
  }

  moveRow = (row: R, toUuid?: string) => {
    const oldIndex = this.data.findIndex(d => d.uuid === row.uuid)
    const newIndex = this.data.findIndex(d => d.uuid === toUuid)
    if (oldIndex === newIndex) {
      return
    }
    this.data[oldIndex].isEdited = true
    this.data[newIndex].isEdited = true

    const moving = [row, ...this.getDescendants(row.uuid)]
    const excludeMoving = (r: R) => moving.findIndex(v => v.uuid === r.uuid) < 0
    let frontData: R[]
    let backData: R[]
    if (oldIndex < newIndex) {
      const prevRows = this.getDescendants(this.data[newIndex].uuid)
      frontData = [
        ...this.data.slice(0, newIndex),
        ...this.data.slice(newIndex, newIndex + prevRows.length + 1),
      ]
      backData = this.data.slice(newIndex + prevRows.length + 1)
    } else {
      frontData = this.data.slice(0, newIndex)
      backData = this.data.slice(newIndex)
    }
    this.data = [
      ...frontData.filter(excludeMoving),
      ...moving,
      ...backData.filter(excludeMoving),
    ]
    this.ctx.gridApi!.setRowData(this.data)
    this.refreshRowNumber(
      Math.min(newIndex - 1, oldIndex),
      Math.max(newIndex + 1, oldIndex)
    )
    this.setDataAfterMove(oldIndex, newIndex)
  }

  private getDescendants = (
    parentUuid: string | undefined, // undefined: root node
    skipUntilIndex: number = 0
  ) => {
    const parentIndex = this.data.findIndex(d => d.uuid === parentUuid)
    if (parentIndex < 0) {
      return this.data.slice(skipUntilIndex)
    }
    const parent = this.data[parentIndex]

    if (parent.treeValue!.length === 0) {
      // case if simple list not tree
      return []
    }

    const movedValue = parent.treeValue!.join()
    return this.data
      .slice(Math.max(parentIndex + 1, skipUntilIndex))
      .filter(d => {
        const v = d.treeValue!.slice(0, parent.treeValue!.length).join()
        return movedValue === v
      })
  }

  moveToChild = (
    data: R[],
    parentUuid: string,
    fromUuid?: string,
    addIndex?: number
  ) => {
    data[0].parentUuid = parentUuid
    const oldIndex = this.data.findIndex(d => d.uuid === fromUuid)
    const toIndex = this.data.findIndex(d => d.uuid === parentUuid)
    const newParentNode = this.ctx.gridApi!.getRowNode(parentUuid)
    let newIndex = addIndex
      ? addIndex
      : toIndex + (newParentNode?.allChildrenCount || 0) + 1

    this.data[oldIndex].isEdited = true

    const oldAncestorNodes = getAncestorNodes(
      this.ctx.gridApi!.getRowNode(data[0].uuid)
    )
    this.ctx.gridApi!.applyTransaction({ update: data })

    // Update manually because allLeafChildren is not updated correctly
    // Remove nodes with different parents and duplicate nodes from allLeafChildren
    const moveDataUuids = data.map(d => d.uuid)
    const findParent = (
      nodes: RowNode[],
      parentUuid: string,
      index: number
    ): boolean => {
      if (!nodes || nodes.length < index) return false
      for (let i = index; 0 <= i; i--) {
        if (nodes[i].data.uuid === parentUuid) {
          return true
        }
      }
      return false
    }
    oldAncestorNodes.forEach(node => {
      const checkedUuids = new Set()
      const removedUuids = new Set()
      node.allLeafChildren = node.allLeafChildren
        .map((child, index, children) => {
          if (!moveDataUuids.includes(child.data.uuid)) return child
          if (
            (index !== 0 &&
              !findParent(children, child.data.parentUuid, index - 1)) ||
            removedUuids.has(child.data.parentUuid)
          ) {
            removedUuids.add(child.data.uuid)
            return
          }
          if (checkedUuids.has(child.data.uuid)) return
          checkedUuids.add(child.data.uuid)
          return child
        })
        .filter(v => v) as RowNode[]
    })

    // Expand parent node.
    if (newParentNode && !newParentNode.expanded) {
      newParentNode.setExpanded(true)
    }
    this.setDataAfterMove(oldIndex, newIndex)
  }

  updateRow = (row: R) => {
    const index = this.data.findIndex(d => d.uuid === row.uuid)
    if (index === undefined) {
      return
    }
    this.data.splice(index, 1, { ...this.data[index], ...row })
    this.ctx.gridApi!.setRowData(this.data)
    const node = this.ctx.gridApi!.getRowNode(row.uuid)
    node && this.ctx.gridApi!.refreshCells({ rowNodes: [node], force: true })
  }

  private setDataAfterMove(oldIndex: number, newIndex: number) {
    if (
      !this.ctx.treeProperty &&
      this.ctx.columnApi!.getRowGroupColumns().length > 0
    ) {
      // Flat grid rows is grouped
      return
    }
    this.data = []
    this.ctx.gridApi!.forEachNode(v => {
      if (!v.data) {
        throw new Error(`data must not be null. rowIndex=${v.rowIndex}`)
      }
      this.data.push(v.data)
    })
    this.refreshRowNumber(Math.min(newIndex - 1, oldIndex), -1)
  }

  removeRows = (rows: R[]) => {
    let minIndex = Number.MAX_SAFE_INTEGER
    rows.forEach(target => {
      let index = this.data.findIndex(d => d.uuid === target.uuid)
      this.data.splice(index, 1)
      if (!target.isAdded) {
        if (!this.ctx.rowDataSpec.removeRow(target)) {
          return
        }
        this.removedRows.push(target)
      }
      minIndex = Math.min(index, minIndex)
    })
    this.ctx.gridApi!.applyTransaction({
      remove: rows,
    })
    this.refreshRowNumber(minIndex, -1)
  }

  getRemovedRows = () => {
    return this.removedRows.concat()
  }

  getDataForBatchUpdate = () => {
    const added: R[] = []
    const edited: {
      before: R
      after: R
    }[] = []
    const rows = this.getAllRows()
    const lowerLayer = this.ctx.props.functionLayers.get(
      this.ctx.props.functionLayers.size - 1
    )
    for (let row of rows) {
      if (!row.isEdited) {
        continue
      }
      // Clean up rows
      let rowNode = this.ctx.gridApi!.getRowNode(row.uuid)
      if (!rowNode) continue
      const columns = this.ctx.columnApi!.getColumns()
      columns &&
        columns.forEach(column => {
          const editable = column.getColDef().editable
          const type = column.getColDef().type
          const aggregateColumn =
            type &&
            (type === 'aggregateColumn' || type.includes('aggregateColumn'))
          if (
            aggregateColumn &&
            // @ts-ignore
            ((typeof editable === 'function' && !editable(rowNode)) ||
              (typeof editable === 'boolean' && !editable))
          ) {
            objects.setValue(row, column.getColDef().field!, undefined)
          }
        })
      row.parentUuid =
        rowNode.parent && rowNode.parent.data
          ? rowNode.parent.data.uuid
          : row.parentUuid
      if (row.uuid !== this.ctx.treeRoot?.uuid) {
        // Do not update previous sibling of tree root
        const sibling = rowNode.parent?.childrenAfterGroup || []
        let prevSiblingIndex =
          sibling.findIndex(child => child.data.uuid === row.uuid) - 1
        row.prevSiblingUuid =
          prevSiblingIndex >= 0
            ? sibling[prevSiblingIndex].data.uuid
            : undefined
      }
      if (row.isAdded) {
        added.push(row)
      } else {
        if (
          !(
            lowerLayer?.externalId ===
              APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM &&
            this.ctx.treeRoot &&
            this.ctx.treeRoot.uuid === row.uuid
          )
        ) {
          edited.push({
            before: this.initialData[row.uuid],
            after: { ...row },
          })
        }
      }
    }

    return {
      added: added,
      edited: edited,
      deleted: this.getRemovedRows(),
    }
  }

  getSingleRowDataForBatchUpdate = (uuid: string) => {
    const added: R[] = []
    const edited: {
      before: R
      after: R
    }[] = []
    const row = this.getSingleRows(uuid)
    const lowerLayer = this.ctx.props.functionLayers.get(
      this.ctx.props.functionLayers.size - 1
    )
    if (row && row.isEdited) {
      // Clean up rows
      let rowNode = this.ctx.gridApi!.getRowNode(row.uuid)
      if (rowNode) {
        const columns = this.ctx.columnApi!.getColumns()
        columns &&
          columns.forEach(column => {
            const editable = column.getColDef().editable
            const type = column.getColDef().type
            const aggregateColumn =
              type &&
              (type === 'aggregateColumn' || type.includes('aggregateColumn'))
            if (
              aggregateColumn &&
              // @ts-ignore
              ((typeof editable === 'function' && !editable(rowNode)) ||
                (typeof editable === 'boolean' && !editable))
            ) {
              objects.setValue(row, column.getColDef().field!, undefined)
            }
          })
        row.parentUuid =
          rowNode.parent && rowNode.parent.data
            ? rowNode.parent.data.uuid
            : row.parentUuid
        const sibling = rowNode.parent?.childrenAfterGroup || []
        let prevSiblingIndex =
          sibling.findIndex(child => child.data.uuid === row.uuid) - 1
        row.prevSiblingUuid =
          prevSiblingIndex >= 0
            ? sibling[prevSiblingIndex].data.uuid
            : undefined
        if (row.isAdded) {
          added.push(row)
        } else {
          if (
            !(
              lowerLayer?.externalId ===
                APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM &&
              this.ctx.treeRoot &&
              this.ctx.treeRoot.uuid === row.uuid
            )
          ) {
            edited.push({
              before: this.initialData[row.uuid],
              after: { ...row },
            })
          }
        }
      }
    } else if (row && row.isAdded) {
      // added row but not edited
      added.push(row)
    }

    return {
      added: added,
      edited: edited,
      deleted: this.getRemovedRows(),
    }
  }

  getFlatDescendants = (uuid: string): R[] => {
    let rows: R[] = []
    const target = this.getDataById(uuid)
    if (!target) {
      return rows
    }
    rows.push(target)
    const children = this.getChildren(uuid)
    children &&
      children.forEach(child => {
        const descendants = this.getFlatDescendants(child.uuid)
        rows = [...rows, ...descendants]
      })
    return rows
  }
}
