import { styled } from '@mui/system'
import { FontSize } from '../../../../styles/commonStyles'
import { intl } from '../../../../i18n'
import TimerStopButton from '../../../containers/commons/AgGrid/components/cell/custom/timerStopButton'
import { Avatar, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { TimerMeasuringTaskOfUser } from '../../../../domain/entity/TimerEntity'
import { useEffect, useState } from 'react'
import { formatDateTimeWithDay } from '../../../../utils/date'
import { convertUnixToHMS } from '../../../../utils/time'

const INTERVAL_FOR_RENDERING = 1000

interface TimerMeasuringTasksForDisplay {
  userUuid: string
  userName: string
  userIconUrl: string
  taskUuid: string
  taskName: string
  startTime: string
  elapsedTime: string
}

interface Props {
  timerMeasuringTaskListData: TimerMeasuringTaskOfUser[]
  onClickStopButton: (userUuid: string, taskUuid: string) => void
}

const RootDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 7px)',
})

const StyledTrackTimerBpdy = styled('div')({
  marginTop: '-15px',
})

const StyledTrackTimerRow = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  borderBottom: `1px solid ${colorPalette.monotone[2]}`,
})

const StyledUserIcon = styled(Avatar)({
  width: 25,
  height: 25,
  marginLeft: '10px',
  marginTop: '10px',
})

const StyledUserNameCell = styled(Typography)({
  width: '150px',
  margin: '15px 0px',
  fontSize: FontSize.MEDIUM,
})

const StyledTaskNameCell = styled(Typography)({
  width: '240px',
  margin: '15px 0px 0px -13px',
  fontSize: FontSize.MEDIUM,
})

const StyledStartTimeCell = styled(Typography)({
  width: '170px',
  margin: '15px 0px 0px 10px',
  fontSize: FontSize.MEDIUM,
})

const StyledElapsedTimeCell = styled(Typography)({
  width: '100px',
  margin: '15px 0px 0px 5px',
  fontSize: FontSize.MEDIUM,
})

const StyledStopButtonCell = styled(Typography)({
  marginTop: '6px',
  marginLeft: '-12px',
})

const TimerMeasuringTaskList = ({
  timerMeasuringTaskListData,
  onClickStopButton,
}: Props) => {
  const [timerMeasuringTasksForDisplay, setTimerMeasuringTasksForDisplay] =
    useState<TimerMeasuringTasksForDisplay[]>([])

  const nameFormat = (Name: string) => {
    return Name.length > 8 ? Name.slice(0, 8) + '...' : Name
  }

  useEffect(() => {
    const updateTimerMeasuringTaskList = () => {
      const formattedList = timerMeasuringTaskListData.map(item => {
        const startTime = new Date(item.startTime)
        const elapsedTime = new Date().getTime() - startTime.getTime()
        const formattedDate = formatDateTimeWithDay(item.startTime)

        return {
          userUuid: item.userUuid,
          userName: nameFormat(item.userName),
          userIconUrl: item.userIconUrl,
          taskUuid: item.taskUuid,
          taskName: `${nameFormat(item.parentDeliverableName)} / ${nameFormat(
            item.taskName
          )}`,
          startTime: formattedDate!.toString(),
          elapsedTime: convertUnixToHMS(elapsedTime),
        }
      })
      setTimerMeasuringTasksForDisplay(formattedList)
    }

    updateTimerMeasuringTaskList()
    const intervalId = setInterval(
      updateTimerMeasuringTaskList,
      INTERVAL_FOR_RENDERING
    )
    return () => clearInterval(intervalId)
  }, [timerMeasuringTaskListData])

  return timerMeasuringTaskListData.length === 0 ? (
    <RootDiv>
      <div>
        {intl.formatMessage({
          id: 'timerMeasuringList.header.inoccupation',
        })}
      </div>
    </RootDiv>
  ) : (
    <RootDiv>
      <StyledTrackTimerBpdy>
        {timerMeasuringTasksForDisplay.map(timerMeasuringTaskList => (
          <StyledTrackTimerRow
            key={`${timerMeasuringTaskList.userUuid}-${timerMeasuringTaskList.taskUuid}`}
          >
            <StyledUserIcon src={timerMeasuringTaskList.userIconUrl} />
            <StyledUserNameCell>
              {timerMeasuringTaskList.userName}
            </StyledUserNameCell>
            <StyledTaskNameCell>
              {timerMeasuringTaskList.taskName}
            </StyledTaskNameCell>
            <StyledStartTimeCell>
              {timerMeasuringTaskList.startTime}
            </StyledStartTimeCell>
            <StyledElapsedTimeCell>
              {timerMeasuringTaskList.elapsedTime}
            </StyledElapsedTimeCell>
            <StyledStopButtonCell>
              <TimerStopButton
                onClick={() =>
                  onClickStopButton(
                    timerMeasuringTaskList.userUuid,
                    timerMeasuringTaskList.taskUuid
                  )
                }
                iconHeight={35}
                iconWidth={35}
              />
            </StyledStopButtonCell>
          </StyledTrackTimerRow>
        ))}
      </StyledTrackTimerBpdy>
    </RootDiv>
  )
}

export default TimerMeasuringTaskList
