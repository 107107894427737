import store from '../../store'
import { applyDiff } from '../../store/singleSheet'
import { MultiEntitySearchItem } from '../../vo/MultiEntitySearchVO'
import API, { APIResponse } from '../commons/api'
import Auth from '../commons/auth'

export interface GetWatchListParams {
  projectUuid: string
  userUuid: string
}

export type WatchList = {
  wbsItemUuid: string
  userUuid: string
}[]

export interface GetWatchWbsItemsParams {
  projectUuid: string
}

export interface isWatchedParams {
  wbsItemUuid: string
}

export interface AddWbsItemToWatchListParams {
  wbsItemUuid: string
}

export interface RemoveWbsItemFromWatchListParams {
  wbsItemUuid: string
}

export const toggleWatchers = (
  items: MultiEntitySearchItem[],
  storeKey: string,
  checked: boolean
) => {
  let newItems: MultiEntitySearchItem[] = []
  const currentUser = Auth.getCurrentTenant()?.user
  if (checked) {
    newItems = items.filter(v => v.uuid !== currentUser?.uuid)
  } else {
    newItems = items
    if (currentUser && !items.some(v => v.uuid === currentUser.uuid)) {
      const addUser: MultiEntitySearchItem = {
        ...currentUser,
      }
      newItems.push(addUser)
    }
  }
  const diff = {}
  diff[storeKey] = newItems
  store.dispatch(applyDiff({ ...diff }))
}

class WatchWbsItemAPI {
  getWatchList(params: GetWatchListParams): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/watch_wbs_items',
      params
    )
  }
  isWatched(params: isWatchedParams): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/watch_wbs_items/is_watched',
      params
    )
  }
  addWbsItemToWatchList(
    params: AddWbsItemToWatchListParams
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/watch_wbs_items',
      params
    )
  }
  removeWbsItemFromWatchList(
    params: RemoveWbsItemFromWatchListParams
  ): Promise<APIResponse> {
    return API.functional.request(
      'DELETE',
      '/api/v1/projects/watch_wbs_items',
      params
    )
  }
}

export default new WatchWbsItemAPI()
