import { Tree } from '../commons/tree'
import store from '../../store'
import { FunctionProperty, CustomEnumValue } from '../commons/appFunction'
import EntitySearch from '../commons/entitySearch'
import { WbsItemDeltaInput, WbsItemDetail, WbsItemInput } from './wbsItem'
import { ProjectPlanBatchInput, ProjectPlanCumulation } from './projectPlan'
import { UserBasic } from './user'
import API, { APIResponse } from '../commons/api'
import { intl } from '../../i18n'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

export const getTicketListTypeLabel = (
  type: string,
  types: CustomEnumValue[]
) => {
  const value = types.find(t => t.value === type)
  return value
    ? `${value.name}${intl.formatMessage({ id: 'ticketList.name.suffix' })}`
    : intl.formatMessage({ id: 'ticketList' })
}

export interface TicketListDetail extends Tree<TicketListDetail> {
  wbsItem: WbsItemDetail
  ticketType: string
  projectPlanCumulation: ProjectPlanCumulation
  ticketCumulation: TicketCumulation
  createdAt: number
  createdBy: UserBasic
  updatedAt: number
  updatedBy: UserBasic
}

export interface TicketListBasic {
  uuid: string
  code: string
  wbsItemUuid: string
  ticketType: string
  projectUuid: string
  displayName: string
}

export interface GetTicketListsProps {
  projectUuid: string
  ticketType?: string
}

export interface TicketListUpdateBatchDeltaRequest {
  added: TicketListInput[]
  edited: TicketListDeltaInput[]
  deleted: {
    uuid?: string
    lockVersion?: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
}

export interface CreateTicketListInput {
  projectUuid: string
  prevSiblingUuid?: string
  ticketType?: string
  projectPlan: ProjectPlanBatchInput
}

export interface TicketListInput {
  uuid?: string
  lockVersion?: number
  projectUuid?: string
  prevSiblingUuid?: string
  ticketType?: string
  wbsItem: WbsItemInput
}

export interface TicketListDeltaInput {
  uuid: string
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: WbsItemDeltaInput
}

export interface PutProjectPlanByTicketListInput {
  projectUuid: string
  uuid: string
  wbsItemUuid?: string
  parentWbsItemUuid: string
}

export interface TicketCumulation {
  ticketListUuid: string
  countTicket: number
  countTicketDone: number
  sumEstimatedHour: number
  sumActualHour: number
}

class TicketList extends EntitySearch {
  public search = async (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: store.getState().project.selected,
        displayName: query,
        ticketType: searchOptions?.ticketType,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => this.search('')

  toResponse = (response: any) => {
    return {
      uuid: response.uuid,
      name: `[${response.wbsItem?.code}] ${response.wbsItem?.displayName}`,
      code: response.wbsItem?.code,
      displayName: response.wbsItem?.displayName,
      ticketType: response.ticketType,
    }
  }

  entitySearchApi = (
    props: GetTicketListsProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/ticket_lists/list',
      props,
      true,
      signal
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/ticket_lists/basic',
      { uuid: uuid }
    )
  }

  getAll(props: GetTicketListsProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/ticket_lists/list',
      props
    )
  }

  findByUuid = async (uuid: string): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/projects/ticket_lists', {
      uuid,
    })
  }

  updateBatchDelta(
    request: TicketListUpdateBatchDeltaRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/ticket_lists/delta/batch',
      request
    )
  }

  getUuidByWbsItemUuid = (wbsItemUuid: string) => {
    return API.functional.request('GET', '/api/v1/projects/ticket_lists/uuid', {
      wbsItemUuid,
    })
  }
}

export default new TicketList()
