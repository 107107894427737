import { useCallback, useRef, useState } from 'react'
import { intl } from '../../../../../i18n'
import FileImportIcon from '../../../../components/icons/FileImportIcon'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import ExcelImportConfirmDialog from '../../../../components/dialogs/ExcelImportConfirmDialog'

interface FileImportProps {
  workloadUnit?: WorkloadUnit
  onClickImport?: (data: Uint8Array) => Promise<any> | void
}

const FileImportButton = ({ workloadUnit, onClickImport }: FileImportProps) => {
  const importInput = useRef<HTMLInputElement>()

  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const openConfirmDialog = useCallback(
    () => setOpenConfirm(true),
    [setOpenConfirm]
  )
  const closeConfirmDialog = useCallback(
    () => setOpenConfirm(false),
    [setOpenConfirm]
  )
  const executeImport = useCallback(() => {
    if (importInput && importInput.current) {
      importInput.current.click()
    }
    setOpenConfirm(false)
  }, [setOpenConfirm])

  return (
    <>
      <BootstrapTooltip
        arrow={true}
        title={intl.formatMessage({ id: 'wbs.header.file.import' })}
      >
        <button
          style={{
            background: 'transparent',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (workloadUnit) openConfirmDialog()
            else executeImport()
            // if (importInput && importInput.current) {
            //   importInput.current.click()
            // }
          }}
        >
          <FileImportIcon height={'15px'} width={'15px'} />
          <input
            type="file"
            aria-hidden="true"
            style={{ display: 'none' }}
            ref={ref => {
              importInput.current = ref || undefined
            }}
            onChange={e => {
              const { files } = e.target
              if (!files || !files[0]) return
              const reader = new FileReader()
              reader.onload = e => {
                const data = new Uint8Array(e.target!['result'] as ArrayBuffer)
                onClickImport && onClickImport(data)
              }
              reader.readAsArrayBuffer(files[0])
              e.target.value = ''
            }}
          />
        </button>
      </BootstrapTooltip>
      {workloadUnit && (
        <ExcelImportConfirmDialog
          open={openConfirm}
          workloadUnit={workloadUnit}
          onClose={closeConfirmDialog}
          onConfirm={executeImport}
        />
      )}
    </>
  )
}

export default FileImportButton
