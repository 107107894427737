import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  MyWbsItemData,
  MyWbsItemRow,
  MyWbsItemRowBody,
  fetchMyTask,
  fetchMyDeliverable,
  fetchMyWatcher,
  ActualWork,
  countTask,
} from '../myWbsItems'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import {
  MyWbsItemSearchConditionApiRequest,
  useMyWbsItemSearchConditionApiRequestTransformService,
} from '../../../../services/model/myWbsItemSearchConditionApiRequest'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { hasDiffDateTerm } from '../../../../utils/date'
import {
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../../lib/functions/sprintProductItem'
import SprintBacklog, {
  UpdateBatchSprintBacklogProps,
} from '../../../../lib/functions/sprintBacklog'
import TaskActualWorkApi, {
  TaskActualWorkUpdateBatchDeltaRequest,
} from '../../../../lib/functions/taskActualWork'
import WbsItemApi, {
  WbsItemDeltaInput,
  WbsItemUpdateBatchDeltaRequest,
} from '../../../../lib/functions/wbsItem'
import { round } from '../../../../lib/functions/workload'
import { APIResponse, successDummyResponse } from '../../../../lib/commons/api'
import { fetchTagsByProject } from '../../../../store/tag'
import store from '../../../../store'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import { useIntl } from 'react-intl'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'

export enum MyWbsItemTabType {
  TASK = 0,
  DELIVERABLE = 1,
  WATCHER = 2,
}

export const useMyWbsItemsData = (
  initialized: boolean,
  taskTabSearchCondition: WbsItemSearchConditionVO,
  functionUuid: string
) => {
  const [taskTabData, setTaskTabData] = useState<MyWbsItemRow[]>([])
  const [deliverableTabData, setDeliverableTabData] = useState<MyWbsItemRow[]>(
    []
  )
  const [watcherTabData, setWatcherTabData] = useState<MyWbsItemRow[]>([])
  const [taskCount, setTaskCount] = useState<number | undefined>(undefined)
  const originalTaskData = useRef<
    Map<string, Pick<MyWbsItemRowBody, 'wbsItem'>>
  >(new Map())
  const originalDeliverableData = useRef<
    Map<string, Pick<MyWbsItemRowBody, 'wbsItem'>>
  >(new Map())
  const originalWatcherData = useRef<
    Map<string, Pick<MyWbsItemRowBody, 'wbsItem'>>
  >(new Map())
  const dispatch = useDispatch()
  const sleep = () => new Promise(resolve => setTimeout(resolve, 500))
  const { toApiRequest } =
    useMyWbsItemSearchConditionApiRequestTransformService()

  const fetchTagRecords = useCallback(async (rows: MyWbsItemRow[]) => {
    const projectUuids = Array.from(
      new Set(
        rows
          .map(v => v.body?.project?.uuid)
          .filter(
            (uuid): uuid is string =>
              uuid !== undefined && typeof uuid === 'string'
          )
      )
    )
    projectUuids.forEach(projectUuid => {
      dispatch(fetchTagsByProject(projectUuid))
    })
    // Wait until all tags are retrieved before sorting for the first time
    let tagProjectUuids: string[] = []
    do {
      tagProjectUuids = Object.keys(store.getState().tag)
      await sleep()
    } while (!projectUuids.every(uuid => tagProjectUuids.includes(uuid)))
  }, [])

  const countSearchConditionTask = useCallback(async () => {
    // User UUID is set in API.
    const response = await countTask(toApiRequest(taskTabSearchCondition))
    setTaskCount(response.json.total)
  }, [taskTabSearchCondition])

  useEffect(() => {
    countSearchConditionTask()
  }, [taskTabSearchCondition])

  const intl = useIntl()

  const fetchMyTaskRecords = useCallback(async () => {
    // User UUID is set in API.
    if (!initialized) return
    if (taskCount !== undefined && taskCount > 500) {
      store.dispatch(
        addScreenMessage(functionUuid, {
          type: MessageLevel.WARN,
          title: intl.formatMessage(
            {
              id: 'bulksheet.warning.search.limit.parameterized',
            },
            { n: 500 }
          ),
          text: intl.formatMessage(
            {
              id: 'bulksheet.warning.search.limit.parameterized.detail',
            },
            { n: 500 }
          ),
        })
      )
      return
    }
    const response = await fetchMyTask(toApiRequest(taskTabSearchCondition))
    let source: MyWbsItemData[] = response.json.data
    const rows: MyWbsItemRow[] = source.map(v => {
      const w = {
        uuid: v.uuid,
        lockVersion: v.lockVersion,
        body: new MyWbsItemRowBody(v),
      } as MyWbsItemRow
      return w
    })
    await fetchTagRecords(rows)
    setTaskTabData(rows)
    originalTaskData.current.clear()
    rows
      .filter(row => !!row.body?.wbsItem)
      .forEach(row => {
        originalTaskData.current.set(
          row.uuid,
          _.cloneDeep({
            wbsItem: row.body?.wbsItem,
          })
        )
      })
  }, [initialized, taskTabSearchCondition, taskCount])

  const fetchMyDeliverableRecords = useCallback(async () => {
    // User UUID is set in API.
    const request: MyWbsItemSearchConditionApiRequest = {
      status: [WbsItemStatus.TODO, WbsItemStatus.DOING, WbsItemStatus.REVIEW],
      types: [WbsItemType.DELIVERABLE],
    }
    const response = await fetchMyDeliverable(request)
    const source: MyWbsItemData[] = response.json.data
    const rows: MyWbsItemRow[] = source.map(v => {
      const w = {
        uuid: v.uuid,
        lockVersion: v.lockVersion,
        body: new MyWbsItemRowBody(v),
      } as MyWbsItemRow
      return w
    })
    await fetchTagRecords(rows)
    setDeliverableTabData(rows)
    originalDeliverableData.current.clear()
    rows
      .filter(row => !!row.body?.wbsItem)
      .forEach(row => {
        originalDeliverableData.current.set(
          row.uuid,
          _.cloneDeep({
            wbsItem: row.body?.wbsItem,
          })
        )
      })
  }, [])

  const fetchMyWatcherRecords = useCallback(async () => {
    // User UUID is set in API.
    const request: MyWbsItemSearchConditionApiRequest = {}
    const response = await fetchMyWatcher(request)
    const source: MyWbsItemData[] = response.json.data
    const rows: MyWbsItemRow[] = source.map(v => {
      const w = {
        uuid: v.uuid,
        lockVersion: v.lockVersion,
        body: new MyWbsItemRowBody(v),
      } as MyWbsItemRow
      return w
    })
    await fetchTagRecords(rows)
    setWatcherTabData(rows)
    originalWatcherData.current.clear()
    rows
      .filter(row => !!row.body?.wbsItem)
      .forEach(row => {
        originalWatcherData.current.set(
          row.uuid,
          _.cloneDeep({
            wbsItem: row.body?.wbsItem,
          })
        )
      })
  }, [])

  const refresh = useCallback(
    async (tabType: MyWbsItemTabType) => {
      switch (tabType) {
        case MyWbsItemTabType.TASK:
          await fetchMyTaskRecords()
          break
        case MyWbsItemTabType.DELIVERABLE:
          await fetchMyDeliverableRecords()
          break
        case MyWbsItemTabType.WATCHER:
          await fetchMyWatcherRecords()
          break
      }
      dispatch(doNotRequireSave())
    },
    [fetchMyTaskRecords, fetchMyDeliverableRecords, fetchMyWatcherRecords]
  )

  useEffect(() => {
    fetchMyTaskRecords()
  }, [initialized])

  const getOriginalDataByTabType = (tabType: MyWbsItemTabType) => {
    switch (tabType) {
      case MyWbsItemTabType.TASK:
        return originalTaskData
      case MyWbsItemTabType.DELIVERABLE:
        return originalDeliverableData
      case MyWbsItemTabType.WATCHER:
        return originalWatcherData
    }
  }

  const getDataByTabType = (tabType: MyWbsItemTabType) => {
    switch (tabType) {
      case MyWbsItemTabType.TASK:
        return taskTabData
      case MyWbsItemTabType.DELIVERABLE:
        return deliverableTabData
      case MyWbsItemTabType.WATCHER:
        return watcherTabData
    }
  }

  const generateUpdateWbsItemDeltaRequest = useCallback(
    (
      row: MyWbsItemRow,
      tabType: MyWbsItemTabType
    ): Partial<WbsItemDeltaInput> => {
      if (!row.edited) return {}
      const originalBody = getOriginalDataByTabType(tabType).current.get(
        row.uuid
      )
      const original = originalBody!.wbsItem
      const wbsItem = row.body?.wbsItem
      if (!wbsItem) return {}

      return {
        uuid: wbsItem.uuid,
        type: wbsItem.type,
        status: createDelta(original.status, wbsItem.status),
        substatus: createDelta(original.substatus, wbsItem.substatus),
        displayName: createDelta(original.displayName, wbsItem.displayName),
        description: createDelta(original.description, wbsItem.description),
        teamUuid: createDelta(original.team?.uuid, wbsItem.team?.uuid),
        accountableUuid: createDelta(
          original.accountable?.uuid,
          wbsItem.accountable?.uuid
        ),
        responsibleUuid: createDelta(
          original.responsible?.uuid,
          wbsItem.responsible?.uuid
        ),
        assigneeUuid: createDelta(
          original.assignee?.uuid,
          wbsItem.assignee?.uuid
        ),
        estimatedStoryPoint: createDelta(
          original.estimatedStoryPoint,
          wbsItem.estimatedStoryPoint
        ),
        estimatedHour: createDelta(
          original.estimatedHour,
          wbsItem.estimatedHour
        ),
        priority: createDelta(original.priority, wbsItem.priority),
        difficulty: createDelta(original.difficulty, wbsItem.difficulty),
        scheduledDate: hasDiffDateTerm(
          original.scheduledDate,
          wbsItem.scheduledDate
        )
          ? {
              oldValue: original.scheduledDate,
              newValue: wbsItem.scheduledDate,
            }
          : undefined,
        actualDate: hasDiffDateTerm(original.actualDate, wbsItem.actualDate)
          ? {
              oldValue: original.actualDate,
              newValue: wbsItem.actualDate,
            }
          : undefined,
        estimatedAmount: createDelta(
          original.estimatedAmount,
          wbsItem.estimatedAmount
        ),
        actualAmount: createDelta(original.actualAmount, wbsItem.actualAmount),
        sprintUuid: createDelta(original.sprint?.uuid, wbsItem.sprint?.uuid),
      } as WbsItemDeltaInput
    },
    []
  )

  const updateWbsItems = useCallback(
    async (
      edited: MyWbsItemRow[],
      tabType: MyWbsItemTabType
    ): Promise<APIResponse> => {
      if (_.isEmpty(edited)) return successDummyResponse
      const wbsItemBatchRequest: WbsItemUpdateBatchDeltaRequest = {
        projectUuid: '',
        added: [],
        edited: edited.map(
          row =>
            generateUpdateWbsItemDeltaRequest(row, tabType) as WbsItemDeltaInput
        ),
        deleted: [],
      }
      const watcherRequest = edited.map(row => {
        return {
          wbsItemUuid: row.body?.wbsItem.uuid!,
          userUuids: row.body?.wbsItem.watchers
            ? row.body.wbsItem.watchers.map(row => row.uuid)
            : [],
        }
      })
      const tagRequest = edited.map(row => {
        return {
          wbsItemUuid: row.body?.wbsItem.uuid!,
          tagUuids: row.body?.tags?.map(v => v.uuid) ?? [],
        }
      })
      return WbsItemApi.updateMyWbsItemsDelta({
        wbsItems: wbsItemBatchRequest,
        watchers: watcherRequest,
        tags: tagRequest,
      })
    },
    [generateUpdateWbsItemDeltaRequest]
  )

  const updateSprintProduct = useCallback(
    async (
      edited: MyWbsItemRow[],
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number },
      tabType: MyWbsItemTabType
    ): Promise<APIResponse> => {
      const deliverables = edited.filter(v =>
        v.body?.wbsItem?.wbsItemType.isDeliverable()
      )
      if (_.isEmpty(deliverables)) return successDummyResponse
      const sprintProductRequest: UpdateSprintProductItemProps = {
        added: [],
        deleted: [],
      }
      deliverables.forEach(row => {
        const wbsItem = row.body?.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = getOriginalDataByTabType(tabType).current.get(
          row.uuid
        )?.wbsItem.sprint
        if (!wbsItem?.uuid) return
        if (
          !!sprint &&
          !!sprint.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintProductRequest.added.push({
            deliverableUuid: wbsItem.uuid,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (
          row.edited &&
          (!sprint || !sprint.uuid) &&
          !!originalSprint
        ) {
          sprintProductRequest.deleted.push({
            deliverableUuid: wbsItem.uuid,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintProductRequest.added) &&
        _.isEmpty(sprintProductRequest.deleted)
      ) {
        return successDummyResponse
      }
      return updateSprintProductItems(sprintProductRequest)
    },
    []
  )

  const updateSprintBacklog = useCallback(
    async (
      edited: MyWbsItemRow[],
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number },
      tabType: MyWbsItemTabType
    ): Promise<APIResponse> => {
      const tasks = edited.filter(v => v.body?.wbsItem?.wbsItemType.isTask())
      if (_.isEmpty(tasks)) return successDummyResponse
      const sprintBacklogRequest: UpdateBatchSprintBacklogProps = {
        sprintBacklogItems: [],
        sprintPlans: {
          added: [],
          deleted: [],
        },
      }
      tasks.forEach(row => {
        const wbsItem = row.body?.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = getOriginalDataByTabType(tabType).current.get(
          row.uuid
        )?.wbsItem.sprint
        if (!wbsItem?.uuid) return
        if (
          !!sprint &&
          !!sprint.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintBacklogRequest.sprintPlans.added.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (
          row.edited &&
          (!sprint || !sprint.uuid) &&
          !!originalSprint
        ) {
          sprintBacklogRequest.sprintPlans.deleted.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintBacklogRequest.sprintPlans.added) &&
        _.isEmpty(sprintBacklogRequest.sprintPlans.deleted)
      ) {
        return successDummyResponse
      }
      return SprintBacklog.updateBatchSprintBacklog(sprintBacklogRequest)
    },
    []
  )

  const updateTaskActualWork = useCallback(
    async (
      edited: MyWbsItemRow[],
      dateTerm: string[],
      tabType: MyWbsItemTabType
    ): Promise<APIResponse> => {
      if (tabType !== MyWbsItemTabType.TASK) return successDummyResponse
      const actualWorkBatchRequest: TaskActualWorkUpdateBatchDeltaRequest = {
        taskActualWorks: [],
      }
      edited.forEach((row: MyWbsItemRow) => {
        dateTerm.forEach(date => {
          const actualWork: ActualWork | undefined =
            row.body?.actualWorks!.find(
              (v: ActualWork) => v.actualDate === date
            )
          const getActualDateTime = (date: string) => {
            return {
              startDateTime: Date.parse(`${date} 00:00:00`),
              endDateTime: Date.parse(`${date} 00:00:00`),
            }
          }
          if (actualWork && actualWork.isEdited) {
            actualWorkBatchRequest.taskActualWorks.push({
              taskUuid: row.uuid,
              hour:
                actualWork.hour && actualWork.hour >= 0.01
                  ? round(actualWork.hour)
                  : 0,
              actualDate: date.replace(/\//g, '-'),
              actualDateTime: getActualDateTime(date),
            })
          }
        })
      })
      return TaskActualWorkApi.updateBatchDelta(actualWorkBatchRequest)
    },
    []
  )

  const save = useCallback(
    async (
      dateTerm: string[],
      tabType: MyWbsItemTabType
    ): Promise<APIResponse[]> => {
      const edited = getDataByTabType(tabType).filter(row => row.edited)

      // Wbs items
      const response = await updateWbsItems(edited, tabType)
      if (!response.json?.edited) return []

      const wbsItemLockVersionMap = Object.fromEntries(
        [...response.json?.edited].map(v => [v.uuid, v.lockVersion])
      )
      // Sprint product (sprint of deliverables)
      const sprintProductResponse = await updateSprintProduct(
        edited,
        wbsItemLockVersionMap,
        tabType
      )
      // Sprint product (sprint of task)
      const sprintBacklogResponse = await updateSprintBacklog(
        edited,
        wbsItemLockVersionMap,
        tabType
      )
      // Actual Work
      const actualWorkResponse = await updateTaskActualWork(
        edited,
        dateTerm,
        tabType
      )
      return [
        response,
        sprintProductResponse,
        sprintBacklogResponse,
        actualWorkResponse,
      ]
    },
    [taskTabData, deliverableTabData, watcherTabData]
  )

  return {
    taskTabData,
    deliverableTabData,
    watcherTabData,
    refresh,
    save,
    generateUpdateWbsItemDeltaRequest,
  }
}
