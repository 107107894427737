import _ from 'lodash'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import { ProjectMemberProps } from '../../../../lib/functions/projectMember'
import { SprintDetail } from '../../../../lib/functions/sprint'
import { TAG_DELIMITER, TagForWbsItem } from '../../../../lib/functions/tag'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import Workload from '../../../../lib/functions/workload'
import store from '../../../../store'
import { toInteger, toNumber } from '../../../../utils/number'
import DateVO from '../../../../vo/DateVO'
import { dateValueParser } from '../../../containers/commons/AgGrid'
import { NewWbsItemRow } from '../../ProjectPlanNew/projectPlanNew'
import { TicketDataManager } from '../dataManager'
import { createNewTicketRow } from '../dataManager/general'
import {
  MULTI_PROPERTY_DELIMITER,
  TicketRow,
  TicketWbsItemRow,
} from '../tickets'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import { WbsItemCodeGenerator } from '../../../../applications/ports/wbsItemCodeService'

export abstract class ExcelParserBase {
  abstract getColumnMap(columnKeyMap: { [key: string]: string }): {
    [key: string]: string
  }
  abstract mergeUpdatedRow(
    embodied: TicketRow,
    excelSrc: object,
    columnKeyMap: { [key: string]: string }
  ): TicketRow

  private initialized: boolean = false
  private data: TicketRow[]
  private context: object

  private source: object[]
  private cols: { [key: string]: string }

  private updatedData = new Map<string, TicketRow>()
  private addedData: TicketRow[] = []

  private dailyWorkHours: number = 8
  private monthlyWorkDays: number = 20

  private dataManager: TicketDataManager | undefined = undefined
  private defaultTikcetList: TicketListBasic | undefined = undefined
  private generateCode: WbsItemCodeGenerator

  public initialize = (
    source: object[],
    {
      data,
      context,
    }: {
      data: TicketRow[]
      context: object
    },
    dataManager: TicketDataManager | undefined,
    defaultTikcetList: TicketListBasic | undefined,
    generateCode: WbsItemCodeGenerator
  ) => {
    this.data = data
    this.context = context
    this.dataManager = dataManager
    this.defaultTikcetList = defaultTikcetList
    this.generateCode = generateCode

    const importHeader = source[0]
    const headers = Object.fromEntries(
      Object.entries(importHeader).map(([k, v]) => [v, k])
    )
    this.cols = this.getColumnMap(headers)
    // Filter Header and Total rows
    // TODO: If the displayName and ticketList are null, it will be considered a total row.
    this.source = source
      .slice(1)
      .filter(
        src =>
          src &&
          (src[this.cols.code] ||
            src[this.cols.displayName] ||
            src[this.cols.ticketList])
      )

    const organization = store.getState().tenant.organization
    this.dailyWorkHours = organization!.dailyWorkHours
    this.monthlyWorkDays = organization!.monthlyWorkDays
    this.initialized = true
  }

  public parse = (): {
    rows: TicketRow[]
    errorMessages: string[]
  } => {
    if (!this.initialized) {
      return {
        rows: [],
        errorMessages: ['not initialized'],
      }
    }
    const messages: string[] = []
    const addMessage = (index, messageId, option?) => {
      const message = intl.formatMessage({ id: messageId }, option)
      messages.push(
        intl.formatMessage(
          { id: 'projectPlan.import.message.format' },
          { index, message }
        )
      )
    }
    const embodiedRows = _.cloneDeep(this.data.filter(v => v.wbsItem?.code))
    this.source.forEach((src, i) => {
      const displayName = src[this.cols.displayName]?.toString()
      const ticketList: TicketListBasic =
        this.findFromContext('ticketList', src[this.cols.ticketList]) ??
        this.defaultTikcetList
      // Validation
      // displayName required
      if (!displayName) {
        addMessage(i + 1, 'projectPlan.import.displayName.required')
        return
      }
      // ticketList required
      if (!ticketList) {
        addMessage(i + 1, 'tickets.import.displayName.required')
        return
      }
      const isAdded = !src[this.cols.code]
      if (isAdded) {
        const createRandomCode = this.generateCode()
        src[this.cols.code] = createRandomCode
      }
      if (messages.length) return

      // Prepare import
      if (!isAdded) {
        // Edited data
        const embodiedRow: TicketRow | undefined = embodiedRows.find(
          v => v.wbsItem.code === src[this.cols.code]?.toString()
        )
        if (!embodiedRow?.wbsItem) {
          return
        }
        const mergedRow = this.mergeUpdatedRow(embodiedRow, src, this.cols)
        const newRow = {
          ...mergedRow,
          edited: true,
          ticketList: ticketList,
        } as TicketRow
        if (this.hasDiff(embodiedRow, newRow)) {
          this.updatedData.set(embodiedRow.uuid, newRow)
        }
      } else {
        // Added data
        const newRow = this.dataManager
          ? this.dataManager.createNewRow(ticketList, this.generateCode)
          : createNewTicketRow(ticketList, this.generateCode)
        const mergedRow = this.mergeUpdatedRow(newRow, src, this.cols)
        const newTicketRow = {
          ...mergedRow,
          ticketList: ticketList,
        } as TicketRow
        this.addedData.push(newTicketRow)
      }
    })
    const newData: TicketRow[] = []
    this.data.forEach(row => {
      const updated = this.updatedData.get(row.uuid)
      if (updated) {
        newData.push(updated)
      } else {
        newData.push(row)
      }
    })
    return {
      rows: newData.concat(this.addedData),
      errorMessages: messages,
    }
  }

  protected getCommonColumnKeyMap = (columnKeyMap: {
    [key: string]: string
  }): {
    [key: string]: string
  } => {
    return {
      code: columnKeyMap[this.columnName('projectPlan.code')],
      ticketType: columnKeyMap[this.columnName('projectPlan.ticketType')],
      status: columnKeyMap[this.columnName('projectPlan.status')],
      substatus: columnKeyMap[this.columnName('projectPlan.substatus')],
      tags: columnKeyMap[this.columnName('projectPlan.tags')],
      ticketList: columnKeyMap[this.columnName('tickets.ticket.list')],
      displayName: columnKeyMap[this.columnName('projectPlan.displayName')],
      priority: columnKeyMap[this.columnName('projectPlan.priority')],
      difficulty: columnKeyMap[this.columnName('projectPlan.difficulty')],
      description: columnKeyMap[this.columnName('projectPlan.description')],
      team: columnKeyMap[this.columnName('projectPlan.team')],
      accountable: columnKeyMap[this.columnName('projectPlan.accountable')],
      responsible: columnKeyMap[this.columnName('projectPlan.responsible')],
      assignee: columnKeyMap[this.columnName('projectPlan.assignee')],
      watchers: columnKeyMap[this.columnName('projectPlan.watcher')],
      estimatedStoryPoint:
        columnKeyMap[this.columnName('projectPlan.estimatedStoryPoint')],
      estimatedHour: columnKeyMap[this.columnName('tickets.estimated.task')],
      sprint: columnKeyMap[this.columnName('projectPlan.sprint')],
      scheduledStartDate:
        columnKeyMap[this.columnName('projectPlan.scheduledDate.start')],
      scheduledEndDate:
        columnKeyMap[this.columnName('projectPlan.scheduledDate.end')],
      actualStartDate:
        columnKeyMap[this.columnName('projectPlan.actualDate.start')],
      actualEndDate:
        columnKeyMap[this.columnName('projectPlan.actualDate.end')],
    }
  }

  private getWorkLoadHour(value?: string) {
    const num = toNumber(value)
    if (!num) return undefined
    return num * (this.context['workloadUnitState']?.hoursPerSelectedUnit || 1)
  }

  protected mergeWbsItemColumnUpdatedRow = (
    embodied: TicketWbsItemRow,
    excelSrc: object,
    columnKeyMap: { [key: string]: string }
  ): TicketWbsItemRow => {
    const estimatedHour = columnKeyMap.estimatedHour
      ? this.getWorkLoadHour(excelSrc[columnKeyMap.estimatedHour])
      : embodied.estimatedHour

    return {
      ...embodied,
      displayName: excelSrc[columnKeyMap.displayName].toString(),
      code: excelSrc[columnKeyMap.code]?.toString(),
      status: columnKeyMap.status
        ? this.findFromContext('status', excelSrc[columnKeyMap.status])
            ?.value ?? WbsItemStatus.TODO
        : embodied.status,
      substatus: columnKeyMap.substatus
        ? this.findFromContext('substatus', excelSrc[columnKeyMap.substatus])
            ?.value
        : embodied.substatus,
      ticketListUuid: columnKeyMap.ticketList
        ? this.findFromContext('ticketList', excelSrc[columnKeyMap.ticketList])
            ?.uuid
        : embodied.ticketListUuid,
      priority: columnKeyMap.priority
        ? this.findFromContext('priority', excelSrc[columnKeyMap.priority])
            ?.value
        : embodied.priority,
      difficulty: columnKeyMap.difficulty
        ? this.findFromContext('difficulty', excelSrc[columnKeyMap.difficulty])
            ?.value
        : embodied.difficulty,
      description: columnKeyMap.description
        ? (excelSrc[columnKeyMap.description] ?? '').toString()
        : embodied.description,
      team: columnKeyMap.team
        ? this.findFromContext('team', excelSrc[columnKeyMap.team])
        : embodied.team,
      accountable: columnKeyMap.accountable
        ? this.findFromContext('member', excelSrc[columnKeyMap.accountable])
        : embodied.accountable,
      responsible: columnKeyMap.responsible
        ? this.findFromContext('member', excelSrc[columnKeyMap.responsible])
        : embodied.responsible,
      assignee: columnKeyMap.assignee
        ? this.findFromContext('member', excelSrc[columnKeyMap.assignee])
        : embodied.assignee,
      watchers: columnKeyMap.watchers
        ? excelSrc[columnKeyMap.watchers]
            ?.toString()
            ?.split(MULTI_PROPERTY_DELIMITER)
            .map(v => this.findFromContext('member', v))
            .filter(v => v)
        : embodied.watchers,
      estimatedStoryPoint: columnKeyMap.estimatedStoryPoint
        ? toInteger(excelSrc[columnKeyMap.estimatedStoryPoint])
        : embodied.estimatedStoryPoint,
      estimatedHour,
      scheduledDate: {
        startDate: columnKeyMap.scheduledStartDate
          ? this.parseDate(excelSrc[columnKeyMap.scheduledStartDate]) ||
            undefined
          : embodied.scheduledDate?.startDate,
        endDate: columnKeyMap.scheduledEndDate
          ? this.parseDate(excelSrc[columnKeyMap.scheduledEndDate]) || undefined
          : embodied.scheduledDate?.endDate,
      },
      actualDate: {
        startDate: columnKeyMap.actualStartDate
          ? this.parseDate(excelSrc[columnKeyMap.actualStartDate]) || undefined
          : embodied.actualDate?.startDate,
        endDate: columnKeyMap.actualEndDate
          ? this.parseDate(excelSrc[columnKeyMap.actualEndDate]) || undefined
          : embodied.actualDate?.endDate,
      },
      tags: columnKeyMap.tags
        ? this.getTagFromSource(
            embodied.projectUuid,
            embodied.tags,
            excelSrc[columnKeyMap.tags]
          )
        : embodied.tags,
      sprint: columnKeyMap.sprint
        ? this.getSprintFromSource(
            { wbsItem: embodied, sprint: embodied.sprint },
            excelSrc
          )
        : embodied.sprint,
      estimatedWorkload: Workload.from({
        hour: estimatedHour,
        standard: {
          dailyWorkHours: this.dailyWorkHours,
          monthlyWorkDays: this.monthlyWorkDays,
        },
      }),
    } as NewWbsItemRow
  }

  protected columnName = id => intl.formatMessage({ id })

  protected findFromCustomEnumValues = (
    enumValues: CustomEnumValue[],
    value: string
  ) => {
    return enumValues.find(v =>
      [v.value, v.name, getLabel(v.nameI18n)].filter(v => !!v).includes(value)
    )
  }

  protected findFromContext = (key, value) => {
    return this.context[key]?.find(v =>
      [
        v.uuid,
        v.value,
        v.name,
        v.displayName,
        v.officialName,
        v.code,
        getLabel(v.nameI18n),
      ]
        .filter(v => !!v)
        .includes(value)
    )
  }

  protected parseDate = (value?: string): string | undefined => {
    if (!value) return undefined
    return new DateVO(dateValueParser(value?.toString())).serialize()
  }

  private getSprintFromSource = (
    embodied: {
      wbsItem: NewWbsItemRow
      sprint: SprintDetail | undefined
    },
    src
  ): SprintDetail | undefined => {
    const w = embodied.wbsItem
    const sprint = embodied.sprint
    if (!this.cols.sprint) return sprint
    const team = this.cols.team
      ? this.findFromContext('team', src[this.cols.team] ?? w.team?.uuid)
      : w.team
    return this.context['sprint'].find(
      v =>
        v.teamUuid === team?.uuid &&
        v.name === src[this.cols.sprint]?.toString()
    )
  }

  private getTagFromSource = (
    projectUuid: string | undefined,
    embodiedTags: TagForWbsItem[] | undefined,
    srcTags: string
  ) => {
    const targetProjectUuid = projectUuid ?? store.getState().project.selected
    if (!targetProjectUuid) return embodiedTags
    const names = srcTags?.split(TAG_DELIMITER).filter(v => !!v)
    const tags = store.getState().tag[targetProjectUuid]
    return tags?.filter(v => names?.includes(v.name)) ?? embodiedTags
  }

  private hasDiff = (ab: TicketRow, bb: TicketRow): boolean => {
    const a = ab.wbsItem!
    const b = bb.wbsItem!
    return (
      a.status !== b.status ||
      a.substatus !== b.substatus ||
      a.displayName !== b.displayName ||
      a.team?.uuid !== b.team?.uuid ||
      a.sprint?.uuid !== b.sprint?.uuid ||
      a.accountable?.uuid !== b.accountable?.uuid ||
      a.responsible?.uuid !== b.responsible?.uuid ||
      a.assignee?.uuid !== b.assignee?.uuid ||
      this.watcherNameToString(a.watchers) !==
        this.watcherNameToString(b.watchers) ||
      (a.estimatedStoryPoint ?? 0) !== (b.estimatedStoryPoint ?? 0) ||
      (a.estimatedHour ?? 0) !== (b.estimatedHour ?? 0) ||
      a.scheduledDate?.startDate !== b.scheduledDate?.startDate ||
      a.scheduledDate?.endDate !== b.scheduledDate?.endDate ||
      a.actualDate?.startDate !== b.actualDate?.startDate ||
      a.actualDate?.endDate !== b.actualDate?.endDate ||
      a.priority !== b.priority ||
      a.difficulty !== b.difficulty ||
      a.description !== b.description ||
      a.tags !== b.tags ||
      ab.ticketList?.code !== bb.ticketList?.code ||
      ab.ticketList?.displayName !== bb.ticketList?.displayName ||
      ab.ticketList?.projectUuid !== bb.ticketList?.projectUuid ||
      ab.ticketList?.ticketType !== bb.ticketList?.ticketType ||
      ab.ticketList?.uuid !== bb.ticketList?.uuid ||
      ab.ticketList?.wbsItemUuid !== bb.ticketList?.wbsItemUuid
    )
  }

  private watcherNameToString = (watchers?: ProjectMemberProps[]): string => {
    if (!watchers) return ''
    return (
      watchers
        .map(v => v?.name ?? '')
        .sort()
        .join(MULTI_PROPERTY_DELIMITER) ?? ''
    )
  }
}
