import { useEffect, useMemo } from 'react'
import { ColDef } from 'ag-grid-community'
import {
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'
import { QuickFilterKeys } from '../..'
import { intl } from '../../../../../i18n'
import { ColumnFilterButtonGroup } from '../../../../components/buttons/ColumnFilterButtonGroup'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../../components/toggleGroups'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import FavoriteColumnFilterButton from '../../../../containers/BulkSheetView/components/header/FavoriteColumnFilterButton'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import GanttChartParameter from '../../../../containers/BulkSheetView/components/header/GanttChartParameter'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import ProgressFilter from './ProgressFilter'
import FileImportButton from '../../../../containers/BulkSheetView/components/header/FileImportButton'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import {
  AggregateField,
  AggregateTarget,
} from '../../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import ReportIcon from '../../../../components/icons/ReportIcon'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'
import ReloadButton from '../../../../containers/BulkSheetView/components/header/ReloadButton'
import { AiChat } from '../AiChat'
import {
  ColumnQuickFilterKey,
  ProjectPlanNewRow,
  ProjectPlanRowBody,
  SelectedColumnQuickFilterKeyType,
} from '../../projectPlanNew'
import Auth from '../../../../../lib/commons/auth'

type Props = {
  onChangeAggregateTarget: (value: AggregateTarget) => void
  aggregateTarget: AggregateTarget
  onChangeAggregateField: (value: AggregateField) => void
  aggregateField: AggregateField
  onChangeWorkloadUnit: (_: WorkloadUnit) => void
  workloadUnit: WorkloadUnit
  quickFilters: QuickFilterKeys | undefined
  onChangeQuickFilters: (
    key: QuickFilterKeys | undefined
  ) => void | Promise<void>
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
  onSwitchingGanttChart: (value: boolean) => void
  gantt: boolean
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  onClickImportExcel: ((data: Uint8Array) => void | Promise<any>) | undefined
  onClickExportExcel: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  onReload: () => void
  onClickColumnSettingButton: (e) => void
  onClickFavoriteColumnFilterButton: () => void
  sortColumnsState: SortedColumnState[]
  currentFormat?: string | undefined
  onChangeDateFormat: (value: string) => void
  onChangeOpenReport: (_: boolean) => void
  openReport: boolean
  rootWbsItem?: string | undefined
  columnSettingOpen: boolean
  data: ProjectPlanNewRow[]
  setData: (data: ProjectPlanNewRow[]) => void
  setProjectPlanBody: (
    row: ProjectPlanNewRow,
    body: ProjectPlanRowBody
  ) => ProjectPlanNewRow
  selectedColumnQuickFilterKey: SelectedColumnQuickFilterKeyType
}

const ProjectPlanNewWbsHeader = ({
  aggregateTarget,
  onChangeAggregateTarget,
  onChangeAggregateField,
  aggregateField,
  onChangeWorkloadUnit,
  workloadUnit,
  quickFilters,
  onChangeQuickFilters,
  onChangeColumnFilter,
  onSwitchingGanttChart,
  rowHeight,
  gantt,
  onChangeHeight,
  onClickImportExcel,
  onClickExportExcel,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  onReload,
  onClickColumnSettingButton,
  onClickFavoriteColumnFilterButton,
  sortColumnsState,
  currentFormat,
  onChangeDateFormat,
  onChangeOpenReport,
  openReport,
  rootWbsItem,
  columnSettingOpen,
  data,
  setData,
  setProjectPlanBody,
  selectedColumnQuickFilterKey,
}: Props) => {
  // TODO: Delete after the release.
  const displayAiChat = useMemo(() => {
    if (
      process.env.FLAGXS_ENV === 'development' ||
      process.env.FLAGXS_ENV === 'staging'
    ) {
      return true
    }
    const tenant = Auth.getCurrentTenant()
    if (tenant?.organization?.alias === 'demo') {
      return true
    }
    return false
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: !rootWbsItem ? '10px 10px 5px 10px' : '15px 10px 10px 10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <ReloadButton onReload={onReload} />
      <BootstrapTooltip
        arrow={true}
        title={
          openReport
            ? intl.formatMessage({ id: 'wbs.header.close.report' })
            : intl.formatMessage({ id: 'wbs.header.open.report' })
        }
      >
        <Button
          onClick={() => onChangeOpenReport(!openReport)}
          sx={{
            background: colorPalette.monotone[2],
            borderRadius: '50%',
            height: '28px',
            width: '28px',
            marginRight: '10px',
            padding: '5px 8px',
            cursor: 'pointer',
            '&:hover': { background: colorPalette.monotone[2] },
          }}
        >
          <ReportIcon />
        </Button>
      </BootstrapTooltip>
      <AggregateTargetToggleGroup
        value={aggregateTarget}
        onChange={onChangeAggregateTarget}
      />
      <AggregateFieldToggleGroup
        value={aggregateField}
        onChange={onChangeAggregateField}
      />
      <WorkloadUnitToggleGroup
        value={workloadUnit}
        refresh={onChangeWorkloadUnit}
      />
      <ProgressFilter
        selectedFilterGroups={quickFilters}
        onChangeQuickFilters={onChangeQuickFilters}
      />
      <FilterButton
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
      />
      <SortButton
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortColumnsState}
      />
      <ColumnSettingButton
        onClick={onClickColumnSettingButton}
        open={columnSettingOpen}
      />
      <ColumnFilterButtonGroup
        options={[
          {
            label: intl.formatMessage({
              id: 'bulksheet.toolbar.columnQuickFilter.label.planning',
            }),
            value: ColumnQuickFilterKey.PLANNING,
          },
        ]}
        selectedValue={selectedColumnQuickFilterKey}
        onChange={value => onChangeColumnFilter(value as ColumnQuickFilterKey)}
      />
      <ColumnFilterButtonGroup
        options={[
          {
            label: intl.formatMessage({
              id: 'bulksheet.toolbar.columnQuickFilter.label.workResult',
            }),
            value: ColumnQuickFilterKey.WORK_RESULT,
          },
        ]}
        selectedValue={selectedColumnQuickFilterKey}
        onChange={value => onChangeColumnFilter(value as ColumnQuickFilterKey)}
      />
      <ColumnFilterButtonGroup
        options={[
          {
            label: intl.formatMessage({
              id: 'bulksheet.toolbar.columnQuickFilter.label.progress',
            }),
            value: ColumnQuickFilterKey.PROGRESS,
          },
        ]}
        selectedValue={selectedColumnQuickFilterKey}
        onChange={value => onChangeColumnFilter(value as ColumnQuickFilterKey)}
      />
      <ColumnFilterButtonGroup
        options={[
          {
            label: intl.formatMessage({
              id: 'bulksheet.toolbar.columnQuickFilter.label.productivity' + '',
            }),
            value: ColumnQuickFilterKey.PRODUCTIVITY,
          },
        ]}
        selectedValue={selectedColumnQuickFilterKey}
        onChange={value => onChangeColumnFilter(value as ColumnQuickFilterKey)}
      />
      <FavoriteColumnFilterButton onClick={onClickFavoriteColumnFilterButton} />
      <GanttChartParameter
        onSwitchingGanttChart={onSwitchingGanttChart}
        value={gantt}
      />
      <DisplayFormatButton
        height={rowHeight}
        onChangeHeight={onChangeHeight}
        currentFormat={currentFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <FileImportButton
          workloadUnit={workloadUnit}
          onClickImport={onClickImportExcel}
        />
        <FileExportButton onClickExport={onClickExportExcel} />
      </Box>
      {displayAiChat && (
        <AiChat
          data={data}
          setData={setData}
          setProjectPlanBody={setProjectPlanBody}
        />
      )}
    </div>
  )
}

export default ProjectPlanNewWbsHeader
