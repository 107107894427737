import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  getParentNode,
  refreshAncestors,
} from '../../containers/commons/AgGrid'
import ProjectApi from '../../../lib/functions/project'
import {
  addCumulation,
  addDirectCumulation,
  getAll,
  getEarliestScheduledDate,
  getLatestScheduledDate,
  getTree,
  getUpdatedRowAncestors,
  ProductBacklogItemInput,
  ProjectPlanBatchDeltaInput,
  ProjectPlanBatchInput,
  ProjectPlanCumulation,
  ProjectPlanDetail,
  ProjectPlanUpdateBatchDeltaRequest,
  RequestOfUpdateBatchDelta,
  SprintItemInput,
  subtractCumulation,
  subtractDirectCumulation,
  TicketInput,
  TicketListInput,
  updateBatchDelta,
} from '../../../lib/functions/projectPlan'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRequestByRow,
  createRowByResponse,
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
  succeedAccountableToAddRow,
  succeedResponsibleToAddRow,
  succeedScheduledDateToAddRow,
  taskActualResultExists,
  WbsItemDetail,
} from '../../../lib/functions/wbsItem'
import ViewMeta from '../../containers/meta/ViewMeta'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetOptions,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import { RowDataSpec } from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import Workload, { WorkloadUnit } from '../../../lib/functions/workload'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { dateTermToViewConfig, openProgressReport } from '../ProgressReport'
import {
  copyRow,
  copyWbsItemOnlySelectedFields,
  createWatchers,
  duplicateWbsItem,
  endDelayDaysValueGetter,
  generateGanttChartCellRendererParams,
  getPasteColumnsCandidate,
  pastable,
  ProjectPlanBulkSheetContext,
  ProjectPlanProps,
  ProjectPlanRow,
  ProjectPlanState,
  startDelayDaysValueGetter,
  wbsActualDateValueSetter,
  wbsScheduledDateValueSetter,
} from '../ProjectPlan/projectPlanOptions'
import {
  openWbsItemSearch,
  SearchConditionEndDelayed,
} from '../WbsItemSearch/wbsItemSearchOptions'
import formatter from '../../containers/commons/AgGrid/lib/formatter'
import { SprintStatus } from '../../../lib/functions/sprint'
import { CSSProperties } from 'react'
import { TicketListDetail } from '../../../lib/functions/ticketList'
import store from '../../../store'
import { requireSave } from '../../../store/requiredSaveData'
import {
  addDeliverableStatusCount,
  addTaskStatusCount,
  addTaskStatusCountOfDirectChildren,
  subtractDeliverableStatusCount,
  subtractTaskStatusCount,
  subtractTaskStatusCountOfDirectChildren,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatusServerSide/valueSetter'
import { intl } from '../../../i18n'
import { openTicketList } from '../TicketLists/TicketListsOptions'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import { AddMultipleTicketDialog } from '../../components/dialogs/AddMultipleTicketDialog'
import DateVO from '../../../vo/DateVO'
import { openComment } from '../../../store/information'
import { open } from '../../router'
import estimatedHourCellRenderer from './estimatedHourCellRenderer'
import actualWorkCellRenderer from './actualWorkCellRenderer'
import { SpeedDialActionProps } from '../../containers/commons/AgGrid/components/cell/custom/detail/cellRenderer'
import { canParseDate, removeDateWhenPast } from '../../../utils/date'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { getSelectedWorkloadUnit } from '../../components/toggleGroups'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { List } from 'immutable'
import { Comment, commentListToSummary } from '../../../store/comments'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { KeyBindListener } from '../../model/keyBind'
import moment from 'moment'
import {
  SprintProductItemProp,
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../lib/functions/sprintProductItem'

export class ProjectPlanRowDataSpec extends RowDataSpec<
  ProjectPlanDetail,
  ProjectPlanRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      estimatedWorkloadColumn: {
        editable: params =>
          !params.data.wbsItem.wbsItemType.isWorkgroup() &&
          !params.data.wbsItem.wbsItemType.isProcess() &&
          !params.data.wbsItem.wbsItemType.isDeliverableList() &&
          params.data.wbsItem,
      },
      currentSprintColumn: {
        editable: params =>
          !(
            params.data.wbsItem.wbsItemType.isWorkgroup() &&
            params.data.wbsItem.wbsItemType.isProcess()
          ),
      },
      deliverableWorkloadColumn: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isDeliverable(),
      },
      taskWorkload: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
        cellRenderer: estimatedHourCellRenderer,
      },
      actualResultColumn: {
        valueFormatter: formatter.format,
        cellRenderer: actualWorkCellRenderer,
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      estimatedAmountDeliverable: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isDeliverable(),
      },
      estimatedAmountTask: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
      },
      actualAmount: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
      },
    }
  }

  createNewRow(
    ctx: ProjectPlanBulkSheetContext,
    params: {
      parent?: ProjectPlanRow
      type: WbsItemTypeVO
      ticketListUuid?: string
    } = {
      parent: undefined,
      type: store.getState().project.wbsItemTypes.workgroup,
      ticketListUuid: undefined,
    }
  ): ProjectPlanRow {
    const { parent, type, ticketListUuid } = params
    const parentWbsItem = parent?.wbsItem
    const parentType = parent?.wbsItem.wbsItemType
    return {
      uuid: generateUuid(),
      type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
      wbsItem: {
        uuid: '',
        type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
        wbsItemType: type,
        baseWbsItemType: type,
        displayName: '',
        status: WbsItemStatus.TODO,
        team: parentWbsItem?.team ? { ...parentWbsItem.team } : undefined,
        accountable:
          parentWbsItem?.accountable && succeedAccountableToAddRow(parentType)
            ? { ...parentWbsItem.accountable }
            : undefined,
        responsible:
          parentWbsItem?.responsible && succeedResponsibleToAddRow(type)
            ? { ...parentWbsItem.responsible }
            : undefined,
        scheduledDate:
          parentWbsItem?.scheduledDate &&
          succeedScheduledDateToAddRow(type, parentType)
            ? removeDateWhenPast(parentWbsItem.scheduledDate)
            : { startDate: undefined, endDate: undefined },
        actualDate: { startDate: undefined, endDate: undefined },
        ticketType: type.isTicket()
          ? type.code.replace('_LIST', '')
          : undefined,
      },
      cumulation: new ProjectPlanCumulation(),
      productBacklogItem: false,
      numberOfChildren: 0,
      parentUuid: parent?.uuid ?? ctx.treeRoot?.uuid,
      ticketListUuid: ticketListUuid,
      isAdded: false,
      isEdited: false,
    }
  }

  overwriteRowItemsWithParents(
    params: {
      child: ProjectPlanRow
      parent: ProjectPlanRow
    },
    selectedColIds?: string[]
  ): ProjectPlanRow {
    const { child, parent } = params
    const childType = child.wbsItem.wbsItemType
    const overwrited = { ...child }
    overwrited.wbsItem.status = selectedColIds?.includes('wbsItem.status')
      ? overwrited.wbsItem.status
      : WbsItemStatus.TODO
    overwrited.wbsItem.team = selectedColIds?.includes('wbsItem.team')
      ? overwrited.wbsItem.team
      : parent?.wbsItem?.team
      ? { ...parent.wbsItem.team }
      : undefined
    overwrited.wbsItem.accountable = selectedColIds?.includes(
      'wbsItem.accountable'
    )
      ? overwrited.wbsItem.accountable
      : parent?.wbsItem?.accountable &&
        succeedAccountableToAddRow(parent?.wbsItem.wbsItemType)
      ? { ...parent.wbsItem.accountable }
      : undefined
    overwrited.wbsItem.responsible = selectedColIds?.includes(
      'wbsItem.responsible'
    )
      ? overwrited.wbsItem.responsible
      : parent?.wbsItem?.responsible && succeedResponsibleToAddRow(childType)
      ? { ...parent.wbsItem.responsible }
      : undefined
    overwrited.wbsItem.scheduledDate = selectedColIds?.some(v =>
      v.match(/wbsItem\.scheduledDate.*/)
    )
      ? overwrited.wbsItem.scheduledDate
      : parent?.wbsItem?.scheduledDate &&
        succeedScheduledDateToAddRow(childType, parent?.wbsItem.wbsItemType)
      ? removeDateWhenPast(parent.wbsItem.scheduledDate)
      : { startDate: undefined, endDate: undefined }
    return overwrited
  }

  createRowByResponse(
    response: ProjectPlanDetail,
    viewMeta: ViewMeta
  ): ProjectPlanRow {
    const base = {
      uuid: response.uuid,
      lockVersion: response.lockVersion,
      type: response.type,
      displayName: '',
      wbsItem: {},
      productBacklogItem: response.productBacklogItem,
      numberOfChildren: response.numberOfChildren,
      parentUuid: response.parentUuid,
      prevSiblingUuid: response.prevSiblingUuid,
      ticketCumulation: response.ticketCumulation,
      ticketListUuid: response.ticketListUuid,
      commentSummary: response.commentSummary,
      deliverableAttachmentSummary: response.deliverableAttachmentSummary,
      extensions: viewMeta.deserializeEntityExtensions(
        response.wbsItem?.extensions || []
      ),
    }
    base.wbsItem = response.wbsItem
      ? {
          ...createRowByResponse(response.wbsItem, response.cumulation),
          estimatedHour: response.wbsItem.estimatedHour,
        }
      : {}
    const cumulation = response.cumulation
    return { ...base, cumulation }
  }

  duplicateRow(original: ProjectPlanRow): ProjectPlanRow {
    return {
      ...original,
      wbsItem: duplicateWbsItem(original.wbsItem),
      cumulation: new ProjectPlanCumulation(),
      numberOfChildren: 0,
      children: [],
    }
  }

  duplicateRowWithSelectedCols(
    original: ProjectPlanRow,
    selectedColIds: string[]
  ): ProjectPlanRow {
    const wbsItemFields = selectedColIds
      .map(colId => colId.split('wbsItem.')[1])
      .filter(field => !!field)
    const copiedWbsItem = copyWbsItemOnlySelectedFields(
      original.wbsItem,
      wbsItemFields
    )
    return {
      ...original,
      wbsItem: duplicateWbsItem(copiedWbsItem),
      cumulation: new ProjectPlanCumulation(),
      numberOfChildren: 0,
      children: [],
    }
  }

  replaceRow(original: ProjectPlanRow, row: ProjectPlanRow): boolean {
    if (original.wbsItem.type !== row.wbsItem.type) {
      // Remove the alert
      alert(
        `タイプの付け替えはできません。コード=${row.wbsItem.code}, 前=${original.wbsItem.type}, 後=${row.wbsItem.type}`
      )
      return false
    }
    row.wbsItem.uuid = original.wbsItem.uuid
    row.wbsItem.lockVersion = original.wbsItem.lockVersion
    row.wbsItem.revision = original.wbsItem.revision
    row.wbsItem.updatedAt = original.wbsItem.updatedAt
    row.wbsItem.updatedBy = original.wbsItem.updatedBy
    return true
  }

  addChild(parent: ProjectPlanRow, child: ProjectPlanDetail): boolean {
    return [
      WbsItemType.PROCESS,
      WbsItemType.DELIVERABLE_LIST,
      WbsItemType.DELIVERABLE,
      WbsItemType.TASK,
    ].includes(child.wbsItem.type)
  }

  hasDiff(row: ProjectPlanRow, newRow: ProjectPlanRow) {
    return (
      row.lockVersion !== newRow.lockVersion ||
      row.wbsItem.lockVersion !== newRow.wbsItem.lockVersion
    )
  }
}

export class ProjectPlanLazyLoadOptions extends BulkSheetOptions<
  ProjectPlanProps,
  ProjectPlanDetail,
  ProjectPlanRow,
  ProjectPlanState
> {
  addable = true
  groupColumnWidth = 404
  getRowsToRemove = (
    nodes: RowNode[],
    ctx: ProjectPlanBulkSheetContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    // TODO Remove without fetching descendants
    const resultOfCanRemovable = (reasonId: string | undefined = undefined) => {
      return {
        removable: !reasonId,
        unremovableReasonMessageId: reasonId,
      }
    }
    const canRemovable = (parent: ProjectPlanRow, target: RowNode[]) => {
      const parentNode = ctx.gridApi!.getRowNode(parent.uuid)
      if (!parentNode) {
        return resultOfCanRemovable(
          'bulksheet.contextMenu.remove.disabled.not.fetched'
        ) // Node is not created.
      }
      if (parent.ticketCumulation?.countTicket) {
        return resultOfCanRemovable(
          'bulksheet.contextMenu.remove.disabled.reason.exists.ticket'
        )
      }
      if (parent.numberOfChildren === 0) {
        if (parent.cumulation?.sumActualHour) {
          return resultOfCanRemovable(
            'bulksheet.contextMenu.remove.disabled.reason.actual.registerd'
          )
        }
        target.push(parentNode)
        return resultOfCanRemovable() // No children and actual hour is 0.
      }
      const children = parent.children
      if (!children || children.length !== parent.numberOfChildren) {
        return resultOfCanRemovable(
          'bulksheet.contextMenu.remove.disabled.not.fetched'
        )
      }
      if (!parent.cumulation || parent.cumulation.sumActualHour) {
        return resultOfCanRemovable(
          'bulksheet.contextMenu.remove.disabled.reason.actual.registerd'
        )
      } // Not fetched children yet
      target.push(parentNode)
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        const result = canRemovable(child, target)
        if (!result.removable) {
          return result // Not fetched grand children yet
        }
      }
      return resultOfCanRemovable()
    }
    let removeTargetRows: RowNode[] = []
    const ancestors = nodes.filter(node => !nodes.some(n => n === node.parent))
    for (const ancestor of ancestors) {
      let expandedChildren = []
      const { removable, unremovableReasonMessageId } = canRemovable(
        ancestor.data,
        expandedChildren
      )
      if (!removable) {
        return {
          rows: [],
          unremovableReasonMessageId,
        }
      }
      removeTargetRows.push(...expandedChildren)
    }
    return { rows: removeTargetRows }
  }

  displayNameField = 'wbsItem.displayName'
  draggable = true
  enableExcelExport = true
  canAddChild = (
    row: ProjectPlanRow,
    parentRow: ProjectPlanRow | undefined,
    ctx: ProjectPlanBulkSheetContext
  ) => {
    const wbsItem = row.wbsItem
    if (
      !parentRow &&
      !!wbsItem &&
      !wbsItem.wbsItemType?.isWorkgroup() &&
      !wbsItem.wbsItemType?.isProcess()
    ) {
      return false
    }
    return !!row.wbsItem.wbsItemType?.canBeChildOf(
      parentRow?.wbsItem.wbsItemType!
    )
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ProjectPlanColumnAndFilterState}-${ctx.state.uuid}`
  fieldRefreshedAfterMove = ['wbsItem.status']
  rowDataSpec = new ProjectPlanRowDataSpec()
  getRowDataUuidForFilter = rowNode => rowNode.data.wbsItem.uuid
  pinnedColumns = [
    'projectPlan.lazyLoad.wbsItem.code',
    'projectPlan.lazyLoad.wbsItem.type',
    'projectPlan.lazyLoad.wbsItem.ticketType',
    'projectPlan.lazyLoad.wbsItem.status',
    'projectPlan.lazyLoad.wbsItem.substatus',
    'projectPlan.lazyLoad.action',
    'projectPlan.lazyLoad.attachment',
    'projectPlan.lazyLoad.wbsItem.displayName',
    'projectPlan.lazyLoad.wbsItem.priority',
  ]
  lockedColumns = [
    'projectPlan.lazyLoad.wbsItem.code',
    'projectPlan.lazyLoad.wbsItem.type',
    'projectPlan.lazyLoad.wbsItem.ticketType',
    'projectPlan.lazyLoad.wbsItem.status',
    'projectPlan.lazyLoad.wbsItem.substatus',
    'projectPlan.lazyLoad.action',
    'projectPlan.lazyLoad.attachment',
  ]
  customRenderers = {
    'projectPlan.lazyLoad.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  customColumnTypes = {
    'projectPlan.lazyLoad.wbsItem.status': [ColumnType.wbsItemStatusServerSide],
    'projectPlan.lazyLoad.wbsItem.estimatedStoryPoint': [
      'estimatedWorkloadColumn',
    ],
    'projectPlan.lazyLoad.wbsItem.estimatedWorkload.deliverable': [
      'deliverableWorkloadColumn',
    ],
    'projectPlan.lazyLoad.wbsItem.estimatedWorkload.task': ['taskWorkload'],
    'projectPlan.lazyLoad.wbsItem.actualHour': ['actualResultColumn'],
    'projectPlan.lazyLoad.wbsItem.currentSprint': ['currentSprintColumn'],
    'projectPlan.lazyLoad.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'projectPlan.lazyLoad.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'projectPlan.lazyLoad.wbsItem.actualDate.startDate': [
      ColumnType.wbsItemActualDate,
    ],
    'projectPlan.lazyLoad.wbsItem.actualDate.endDate': [
      ColumnType.wbsItemActualDate,
    ],
    'projectPlan.lazyLoad.commentSummary.latestComment': [ColumnType.comment],
    'projectPlan.lazyLoad.wbsItem.priority': ['priorityColumn'],
    sequenceNo: [ColumnType.wbsItemSequenceNo],
    'projectPlan.lazyLoad.wbsItem.estimatedAmount.deliverable': [
      'estimatedAmountDeliverable',
    ],
    'projectPlan.lazyLoad.wbsItem.estimatedAmount.task': [
      'estimatedAmountTask',
    ],
    'projectPlan.lazyLoad.wbsItem.actualAmount': ['actualAmount'],
  }
  copyRow = copyRow
  getPasteColumnsCandidate = getPasteColumnsCandidate
  getRowStyle = (params): CSSProperties | undefined => {
    if (
      params.data &&
      params.data.wbsItem.wbsItemType &&
      !params.data.wbsItem.wbsItemType.isTask()
    ) {
      return {
        fontWeight: 'bold',
      }
    }
  }
  getDefaultContext = (): any => ({
    wbsItemType: WbsItemType.DELIVERABLE,
    aggregateTargetType: AggregateField.WBS_ITEM_COUNT,
    workloadUnit: WorkloadUnit.DAY,
  })

  private canAddRow = (
    type: WbsItemTypeVO,
    parentRow: ProjectPlanRow | undefined,
    targetRowData: ProjectPlanRow,
    canAddChild: boolean,
    multipleRowsContainingTopSelected: boolean | null,
    above?: boolean
  ): boolean => {
    return above
      ? !!parentRow &&
          type.canBeChildOf(parentRow.wbsItem.wbsItemType!) &&
          !multipleRowsContainingTopSelected
      : canAddChild &&
          !!targetRowData.wbsItem.wbsItemType &&
          type.canBeChildOf(targetRowData.wbsItem.wbsItemType)
  }

  private addAbove = (
    ctx: ProjectPlanBulkSheetContext,
    selectedNodes: RowNode[],
    type: WbsItemTypeVO,
    ticketListUuid?: string
  ): void => {
    const firstRow: ProjectPlanRow = selectedNodes[0].data
    const parent = ctx.rowDataManager.getParent(firstRow.uuid)
    const rows = selectedNodes.map(_ =>
      this.rowDataSpec.createNewRow(ctx, {
        parent,
        type,
        ticketListUuid,
      })
    )
    ctx.rowDataManager.addRowsTo(rows, {
      parentUuid: parent?.uuid,
      prevSiblingUuid: firstRow.uuid,
    })
  }

  private addRow = (
    ctx: ProjectPlanBulkSheetContext,
    selectedNodes: RowNode[],
    targetRow: RowNode | null,
    type: WbsItemTypeVO,
    options?: {
      above?: boolean
      ticketListUuid?: string
    }
  ): void => {
    const { above, ticketListUuid } = options
      ? options
      : {
          above: false,
          ticketListUuid: undefined,
        }
    if (above) {
      this.addAbove(ctx, selectedNodes, type, ticketListUuid)
      return
    }

    if (!targetRow) return
    const newRowData = this.rowDataSpec.createNewRow(ctx, {
      parent: type.isWorkgroup() ? undefined : targetRow.data,
      type,
      ticketListUuid,
    })
    // Update parent cumulation
    this.beforeAdd(ctx, [newRowData], targetRow.data.uuid)
    ctx.rowDataManager.addRows(
      [newRowData],
      0,
      type.isWorkgroup() ? undefined : targetRow.id
    )
    store.dispatch(requireSave())
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: ProjectPlanBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data) return
    const wbsItemTypes = store.getState().project.wbsItemTypes
    const row = params.node.data
    const addMenuSuffix = (above?: boolean) =>
      above
        ? intl.formatMessage({ id: 'bulksheet.contextMenu.above' })
        : intl.formatMessage({ id: 'bulksheet.contextMenu.toChild' })
    let selectedNodes = params.api.getSelectedNodes()
    if (
      selectedNodes.length === 0 ||
      !selectedNodes.map(v => v.id).includes(params.node.id) ||
      !selectedNodes[0].data
    ) {
      selectedNodes = [params.node]
    }
    const parent = ctx.rowDataManager.getParent(selectedNodes[0].data.uuid)
    const canAddChild = row.wbsItem?.type && !!row.wbsItem?.displayName.trim()
    const cellRanges = params.api.getCellRanges()
    // selectedNodes do not contain top row
    const multipleRowsContainingTopSelected =
      cellRanges &&
      cellRanges.some(
        range => range.startRow?.rowIndex === 0 || range.endRow?.rowIndex === 0
      ) &&
      cellRanges.some(
        range =>
          (range.endRow?.rowIndex || 0) - (range.startRow?.rowIndex || 0) !== 0
      )

    const canAddRowInContextMenu = (
      type: WbsItemTypeVO,
      above?: boolean
    ): boolean => {
      return this.canAddRow(
        type,
        parent,
        row,
        canAddChild,
        multipleRowsContainingTopSelected,
        above
      )
    }

    const addChildren = (
      addRowCount: number = 1,
      type: WbsItemTypeVO,
      ticketListUuid?: string
    ) => {
      if (!params.node) return
      const newRowData = Array.from({ length: addRowCount }).map(_ =>
        this.rowDataSpec.createNewRow(ctx, {
          parent: type.isWorkgroup() ? undefined : params.node!.data,
          type,
          ticketListUuid,
        })
      )
      // Update parent cumulation
      this.beforeAdd(ctx, newRowData, params.node.data.uuid)
      ctx.rowDataManager.addRows(
        newRowData,
        0,
        type.isWorkgroup() ? undefined : params.node.id
      )
      store.dispatch(requireSave())
      return newRowData
    }

    const addRowWithContextMenu = (
      type: WbsItemTypeVO,
      options?: {
        root?: boolean
        above?: boolean
        ticketType?: string
        ticketListUuid?: string
      }
    ): void => {
      this.addRow(ctx, selectedNodes, params.node, type, options)
    }

    const closeDialog = () => {
      ctx.setState({
        addRowCountInputDialogState: { open: false },
      })
    }
    const getDialogState = (name: string, action: (count: number) => void) => ({
      open: true,
      title: intl.formatMessage({ id: 'projectPlan.add' }, { name }),
      submitHandler: addRowCount => {
        action(addRowCount)
        closeDialog()
      },
      closeHandler: closeDialog,
    })

    const { ticketTypes, ticketListTypes } = store.getState().project
    // Ticket list menu
    const ticketListSubMenu = ticketListTypes.map(ticketType => ({
      name: ticketType.name,
      icon: `<img src="${ticketType.iconUrl}" />`,
      disabled: !canAddRowInContextMenu(ticketType),
      action: () => addChildren(1, ticketType),
    }))

    const addTicketListDisabled =
      ticketListSubMenu.every(
        ticketListMenuItem => ticketListMenuItem.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addTicketDisabled =
      ticketTypes.every(
        ticketType => !canAddRowInContextMenu(ticketType) || row.isAdded
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const getEarliestDate = async () => {
      const fromApi = (
        await getEarliestScheduledDate(ctx.props.projectUuid!, row.uuid)
      ).json
      const fromGrid =
        ctx.rowDataManager
          .getFlatDescendants(row.uuid)
          .filter(v => v.uuid !== row.uuid)
          .filter(v => v.isEdited)
          .map(v => v.wbsItem.scheduledDate?.startDate)
          .filter(v => !!v)
          .filter(v => canParseDate(v)) ?? []
      if (!canParseDate(fromApi) && fromGrid.length === 0) {
        store.dispatch(
          addScreenMessage(ctx.props.projectUuid!, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'projectPlan.getPlanFromChildren.has.no.earliest.date',
            }),
          })
        )
        return undefined
      }
      const apiDate = canParseDate(fromApi)
        ? moment(fromApi)
        : moment('9999/12/31')
      const uiDate =
        fromGrid.length > 0
          ? moment.min(...fromGrid.map(v => moment(v)))
          : moment('9999/12/31')
      return moment.min(apiDate, uiDate).toISOString(true)
    }
    const getLatestDate = async () => {
      const fromApi = (
        await getLatestScheduledDate(ctx.props.projectUuid!, row.uuid)
      ).json
      const fromGrid =
        ctx.rowDataManager
          .getFlatDescendants(row.uuid)
          .filter(v => v.uuid !== row.uuid)
          .filter(v => v.isEdited)
          .map(v => v.wbsItem.scheduledDate?.endDate)
          .filter(v => !!v)
          .filter(v => canParseDate(v)) ?? []
      if (!canParseDate(fromApi) && fromGrid.length === 0) {
        store.dispatch(
          addScreenMessage(ctx.props.projectUuid!, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'projectPlan.getPlanFromChildren.has.no.latest.date',
            }),
          })
        )
        return undefined
      }
      const apiDate = canParseDate(fromApi)
        ? moment(fromApi)
        : moment('1970/01/01')
      const uiDate =
        fromGrid.length > 0
          ? moment.max(...fromGrid.map(v => moment(v)))
          : moment('1970/01/01')
      return moment.max(apiDate, uiDate).toISOString(true)
    }
    const updateScheduledDate = (start, end) => {
      if (!start && !end) return
      const oldValue = row.wbsItem.scheduledDate
      if (!!start && start !== oldValue.startDate) {
        row.isEdited = true
        row.editedData = {
          ...row.editedData,
          'wbsItem.scheduledDate.startDate': oldValue.startDate,
        }
        row.wbsItem.scheduledDate.startDate = start
      }
      if (!!end && end !== oldValue.endDate) {
        row.isEdited = true
        row.editedData = {
          ...row.editedData,
          'wbsItem.scheduledDate.endDate': oldValue.endDate,
        }
        row.wbsItem.scheduledDate.endDate = end
      }
      ctx.rowDataManager.updateRow(row)
      store.dispatch(requireSave())
    }

    const addRowSubMenu = (above?: boolean) => {
      return wbsItemTypes
        .getAll()
        .filter(t => !t.isWorkgroup())
        .map(wbsItemType => getAddRowMenuItem(wbsItemType, above))
    }

    const getShortcutKeyLabel = (wbsItemType: WbsItemType, above?: boolean) => {
      let key: string | undefined = undefined
      switch (wbsItemType) {
        case WbsItemType.PROCESS:
          key = 'H'
          break
        case WbsItemType.DELIVERABLE_LIST:
          key = 'J'
          break
        case WbsItemType.DELIVERABLE:
          key = 'K'
          break
        case WbsItemType.TASK:
          key = 'L'
          break
      }
      return above
        ? intl.formatMessage(
            { id: 'bulksheet.contextMenu.shortcut.alt.shift' },
            { shortcutKey: key }
          )
        : intl.formatMessage(
            { id: 'bulksheet.contextMenu.shortcut.ctrl.shift' },
            { shortcutKey: key }
          )
    }

    const getAddRowMenuItem = (wbsItem: WbsItemTypeVO, above?: boolean) => {
      return {
        name: wbsItem.name,
        icon: `<img src="${wbsItem.iconUrl}" />`,
        disabled: !canAddRowInContextMenu(wbsItem, above),
        action: () => {
          addRowWithContextMenu(wbsItem, { above })
        },
        tooltip: disabledWbsItemTypeMessage(wbsItem, above),
        shortcut: getShortcutKeyLabel(wbsItem.rootType, above),
      }
    }

    const disabledWbsItemTypeMessage = (
      wbsItemType: WbsItemTypeVO,
      above?: boolean
    ): string | undefined => {
      let message: string | undefined = undefined
      if (!canAddRowInContextMenu(wbsItemType, above)) {
        switch (wbsItemType.rootType) {
          case WbsItemType.PROCESS:
            message = 'wbsItemType.process'
            break
          case WbsItemType.DELIVERABLE_LIST:
            message = 'wbsItemType.deliverableList'
            break
          case WbsItemType.DELIVERABLE:
            message = 'wbsItemType.deliverable'
            break
          case WbsItemType.TASK:
            message = 'wbsItemType.task'
            break
        }
      }
      return message
        ? above
          ? intl.formatMessage(
              { id: 'bulksheet.contextMenu.disabled.insert.above.rows' },
              { wbsItemType: intl.formatMessage({ id: message }) }
            )
          : intl.formatMessage(
              { id: 'bulksheet.contextMenu.disabled.insert.toChild.rows' },
              { wbsItemType: intl.formatMessage({ id: message }) }
            )
        : undefined
    }

    const getAddMultipleMenuItem = (
      type: WbsItemTypeVO
    ): MenuItemDef | undefined => {
      return {
        name: type.name,
        icon: `<img src="${type.iconUrl}" />`,
        action: () => {
          ctx.setState({
            addRowCountInputDialogState: {
              open: true,
              title: intl.formatMessage(
                { id: 'projectPlan.add' },
                { name: type.name }
              ),
              submitHandler: (addRowCount: number | undefined) => {
                addRowCount && addChildren(addRowCount, type)
                ctx.setState({ addRowCountInputDialogState: { open: false } })
              },
              closeHandler: () =>
                ctx.setState({ addRowCountInputDialogState: { open: false } }),
            },
          })
        },
        disabled: !canAddRowInContextMenu(type, undefined),
        tooltip: disabledWbsItemTypeMessage(type, false),
      }
    }

    const addMultipleMenu = wbsItemTypes
      .getAll()
      .filter(t => !t.isWorkgroup())
      .map(wbsItemType => getAddMultipleMenuItem(wbsItemType))

    const insertAboveRowDisabled =
      addRowSubMenu(true).every(
        addRowSubMenuItem => addRowSubMenuItem?.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const insertToChildRowDisabled =
      addRowSubMenu(false).every(
        addRowSubMenuItem => addRowSubMenuItem?.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addMultipleRowsDisabled =
      addMultipleMenu.every(addRowSubMenuItem => addRowSubMenuItem?.disabled) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const openTicketListDisabled = !(
      params.node.data.wbsItem.wbsItemType.isDeliverable() &&
      params.node.data.wbsItem.wbsItemType.isTicket()
    )

    return new ContextMenu(
      [
        // Add row
        {
          id: 'ADD_ROW_GROUP',
          items: [
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.above.row',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              subMenu: addRowSubMenu(true),
              disabled: insertAboveRowDisabled,
              tooltip: insertAboveRowDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.toChild.row',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              subMenu: addRowSubMenu(false),
              disabled: insertToChildRowDisabled,
              tooltip: insertToChildRowDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({
                id: 'addMultipleRows.wbsItem',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
              subMenu: addMultipleMenu,
              disabled: addMultipleRowsDisabled,
              tooltip: addMultipleRowsDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.workgroup',
              }),
              icon: `<img src="${wbsItemTypes.workgroup.iconUrl}" />`,
              action: () => {
                addRowWithContextMenu(wbsItemTypes.workgroup)
              },
              disabled:
                selectedNodes.length !== 1 || multipleRowsContainingTopSelected,
              tooltip:
                selectedNodes.length !== 1 || multipleRowsContainingTopSelected
                  ? intl.formatMessage({
                      id: 'bulksheet.contextMenu.disabled.multiple.row.selected',
                    })
                  : undefined,
            },
          ],
        },
        // Add ticket
        {
          id: 'ADD_TICKET',
          items: [
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.ticketList',
              }),
              subMenu: ticketListSubMenu,
              disabled: addTicketListDisabled,
              tooltip: addTicketListDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.ticketList',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({ id: 'ticket' }),
              disabled: addTicketDisabled,
              tooltip: addTicketDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.ticket',
                  })
                : undefined,
              action: () => {
                ctx.setState({
                  children: (
                    <AddMultipleTicketDialog
                      open={true}
                      projectUuid={ctx.props.projectUuid!}
                      title={intl.formatMessage({
                        id: 'bulksheet.contextMenu.ticket',
                      })}
                      submitHandler={(
                        addRowCount: number | undefined,
                        ticketTypeCode: string | undefined,
                        ticketList: TicketListDetail | undefined
                      ) => {
                        const ticketType = ticketTypes.find(
                          v => v.code === ticketTypeCode
                        )
                        if (!addRowCount || !ticketType || !ticketList) {
                          throw new Error('Illegal arguments.')
                        }
                        Array.from({ length: addRowCount }).forEach(() =>
                          addRowWithContextMenu(ticketType, {
                            ticketListUuid: ticketList.uuid,
                          })
                        )
                        ctx.setState({ children: undefined })
                      }}
                      closeHandler={() => ctx.setState({ children: undefined })}
                      initialValue={1}
                    />
                  ),
                })
              },
            },
          ].filter(v => !!v),
        },
        ctx.generateEditContextMenu(params, [
          ContextMenuItemId.REMOVE_ROW,
          ContextMenuItemId.COPY_ROW,
          ContextMenuItemId.BULK_COPY_ROW,
          ContextMenuItemId.PASTE_ROW_AS_CHILD,
          ContextMenuItemId.CUT_ROW,
          ContextMenuItemId.INSERT_CUT_ROW,
        ]),
        ctx.generateUtilityContextMenu(params),
        {
          id: 'OTHER',
          items: [
            {
              name: intl.formatMessage({ id: 'openProgressReport' }),
              disabled: !params.node.data.numberOfChildren,
              action: () => {
                const wbsItem = params.node!.data.wbsItem
                const searchCondition = {
                  rootWbsItemUuid: wbsItem.uuid,
                }
                openProgressReport(
                  ctx.props.projectUuid!,
                  searchCondition,
                  dateTermToViewConfig(wbsItem.scheduledDate)
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_PROGRESS_REPORT),
              tooltip: !params.node.data.numberOfChildren
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({ id: 'openSprintReport' }),
              disabled:
                !(
                  params.node.data.wbsItem.wbsItemType.isWorkgroup() ||
                  params.node.data.wbsItem.wbsItemType.isProcess()
                ) || params.node.data.isAdded,
              action: async () => {
                const wbsItem = params.node!.data.wbsItem
                const projectResponse = await ProjectApi.getDetail({
                  uuid: ctx.props.projectUuid!,
                })
                open(
                  `/sprintReport/${projectResponse.json.code}?rootWbsItem=${wbsItem.uuid}`,
                  undefined,
                  undefined,
                  true
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_SPRINT_REPORT),
              tooltip:
                params.node.data.wbsItem.type !== WbsItemType.PROCESS ||
                params.node.data.isAdded
                  ? intl.formatMessage({
                      id: 'bulksheet.contextMenu.disabled.openSprintReport',
                    })
                  : undefined,
            },
            {
              name: intl.formatMessage({ id: 'searchDelayedWbs' }),
              disabled: !params.node.hasChildren(),
              action: () => {
                const wbsItem = params.node!.data.wbsItem
                openWbsItemSearch(
                  ctx.props.projectUuid!,
                  SearchConditionEndDelayed.with({ rootUuid: wbsItem.uuid })
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.SEARCH_DELAYED_WBS),
              tooltip: !params.node.hasChildren()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({ id: 'openTicketList' }),
              disabled: openTicketListDisabled,
              action: () => {
                openTicketList(params.node!.data.wbsItem.uuid)
              },
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_TICKET_LIST),
              tooltip: openTicketListDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.openTicketList',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({ id: 'getPlanFromChildren' }),
              subMenu: [
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.bothDate',
                  }),
                  action: async () =>
                    updateScheduledDate(
                      await getEarliestDate(),
                      await getLatestDate()
                    ),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_BOTH_DATE
                  ),
                },
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.earliestDate',
                  }),
                  action: async () =>
                    updateScheduledDate(await getEarliestDate(), undefined),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_EARLIEST_DATE
                  ),
                },
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.latestDate',
                  }),
                  action: async () =>
                    updateScheduledDate(undefined, await getLatestDate()),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_LATEST_DATE
                  ),
                },
              ],
              disabled: row.numberOfChildren === 0,
              icon: getMenuIconHtml(ContextMenuItemId.GET_PLAN_FROM_CHILDREN),
              tooltip:
                row.numberOfChildren === 0
                  ? intl.formatMessage({
                      id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                    })
                  : undefined,
            },
          ],
        },
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  // Prevent copy multiple parent-child
  checkRowCopiable = (data: ProjectPlanRow[]): boolean => {
    const parentUuids = Array.from(new Set(data.map(v => v.parentUuid))).filter(
      v => !!v
    ) as string[]
    return parentUuids.every(uuid => {
      const children = data.filter(d => d.parentUuid === uuid)
      const parentChildren = children.filter(c =>
        data.some(d => d.parentUuid === c.uuid)
      )
      return !(children.length > 1 && parentChildren.length > 0)
    })
  }

  checkRowBulkCopiable = (data: ProjectPlanRow): boolean => {
    return !data.isAdded && !data.wbsItem.wbsItemType?.isWorkgroup()
  }

  checkRowPastable = (
    parent: ProjectPlanRow[],
    selected: ProjectPlanRow[],
    clipboard: ProjectPlanRow[]
  ): boolean => pastable(selected, clipboard)
  getOpenDetailSpec = (row: ProjectPlanRow): Promise<OpenDetailSpec> => {
    return getOpenWbsItemDetailSpec(true, row.wbsItem)
  }
  getUpdatedRowAncestors = async (
    uuid: string,
    treeRootUuid?: string
  ): Promise<ProjectPlanDetail> => {
    return getUpdatedRowAncestors(uuid, treeRootUuid)
  }

  async getAll(state: ProjectPlanState) {
    return getAll(state.uuid)
  }

  onSubmit = async (
    ctx: ProjectPlanBulkSheetContext,
    data: {
      added: ProjectPlanRow[]
      edited: {
        before: ProjectPlanRow
        after: ProjectPlanRow
      }[]
      deleted: ProjectPlanRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const projectPlanInput: ProjectPlanUpdateBatchDeltaRequest = {
      projectUuid: ctx.props.projectUuid!,
      rootProjectPlanUuid: ctx.treeRoot.uuid!,
      rootProjectPlanLockVersion: ctx.treeRoot.lockVersion!,
      added: data.added.map(v => this.createRequestByRow(v, viewMeta)),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v, viewMeta)),
      deleted: data.deleted.map(v =>
        this.createDeleteRequestByRow(v, viewMeta)
      ),
    }
    const request: RequestOfUpdateBatchDelta = {
      projectPlans: projectPlanInput,
      productBacklogItems: this.createProductBacklogItemInput(
        data.added,
        data.edited
      ),
      sprintProductItems: this.createSprintProductItemInput(
        data.added,
        data.edited
      ),
      ticketLists: this.createTicketListInput(data.added),
      tickets: this.createTicketInput(data.added),
      watchers: createWatchers(data.added, data.edited),
    }
    const response = await updateBatchDelta(request)

    // Update sprint backlogs
    const addedUuidMap = Object.fromEntries(
      response.json?.added.map(v => [v.uuid, v.wbsItemUuid])
    )
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      [
        ...data.added
          .filter(v => v.wbsItem.wbsItemType?.isTask())
          .map(v => ({ ...v.wbsItem, uuid: addedUuidMap[v.uuid] })),
        ...data.edited
          .filter(v => v.after.wbsItem.wbsItemType?.isTask())
          .map(v => v.after.wbsItem),
      ],
      Object.fromEntries(
        [...response.json?.added, ...response.json?.edited].map(v => [
          v.wbsItemUuid,
          v.wbsItemLockVersion,
        ])
      )
    )
    await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    const sprintProductItemsRequest: UpdateSprintProductItemProps = {
      added: [],
      deleted: [
        ...data.edited
          .filter(
            v =>
              v.after.wbsItem.wbsItemType?.isDeliverable() &&
              !v.after.wbsItem.currentSprint &&
              v.before.wbsItem.currentSprint
          )
          .map(
            v =>
              ({
                deliverableUuid: v.after.wbsItem.uuid,
                deliverableLockVersion: v.after.wbsItem.lockVersion,
                sprintUuid: v.before.wbsItem.currentSprint?.uuid,
              } as SprintProductItemProp)
          ),
      ],
    }
    await updateSprintProductItems(sprintProductItemsRequest)

    return response
  }

  flattenRows = (rows: ProjectPlanRow[]) => {
    let flatRows: ProjectPlanRow[] = []
    rows.forEach(row => {
      flatRows.push(row)
      if (row.children && row.children.length > 0) {
        flatRows = flatRows.concat(this.flattenRows(row.children))
      }
    })
    return flatRows
  }

  beforeAdd = (
    ctx: ProjectPlanBulkSheetContext,
    rows: ProjectPlanRow[],
    parentUuid?: string
  ) => {
    const allRows = this.flattenRows(rows)
    this.refreshCumulations(ctx, allRows, true, parentUuid)
  }

  beforeRemove = (
    ctx: ProjectPlanBulkSheetContext,
    rows: ProjectPlanRow[],
    parentUuid?: string
  ) => {
    const allRows = this.flattenRows(rows)
    this.refreshCumulations(ctx, allRows.reverse(), false, parentUuid)
  }

  private refreshCumulations = (
    ctx: ProjectPlanBulkSheetContext,
    rows: ProjectPlanRow[],
    add: boolean,
    parentUuid?: string
  ) => {
    // Refresh workload
    if (parentUuid) {
      const parentNode = ctx.gridApi!.getRowNode(parentUuid) || undefined
      parentNode &&
        rows.forEach(row => {
          this.refreshParentWorkload({
            oldValue: add ? 0 : row.wbsItem.estimatedWorkload?.hour || 0,
            newValue: add ? row.wbsItem.estimatedWorkload?.hour || 0 : 0,
            data: row,
            api: ctx.gridApi!,
            parentNode,
          })
          this.refreshParentEstimatedAmount({
            oldValue: add ? 0 : row.wbsItem.estimatedAmount || 0,
            newValue: add ? row.wbsItem.estimatedAmount || 0 : 0,
            data: row,
            api: ctx.gridApi!,
            parentNode,
          })
          this.refreshParentActualAmount({
            oldValue: add ? 0 : row.wbsItem.actualAmount || 0,
            newValue: add ? row.wbsItem.actualAmount || 0 : 0,
            data: row,
            api: ctx.gridApi!,
            parentNode,
          })
        })
    }
    // Refresh status count
    const updateTaskStatusCount = add
      ? addTaskStatusCount
      : subtractTaskStatusCount
    const updateTaskStatusCountOfDirectChildren = add
      ? addTaskStatusCountOfDirectChildren
      : subtractTaskStatusCountOfDirectChildren
    const updateDeliverableStatusCount = add
      ? addDeliverableStatusCount
      : subtractDeliverableStatusCount
    if (parentUuid) {
      const parentNode = ctx.gridApi!.getRowNode(parentUuid) || undefined
      rows.forEach(row => {
        let directParent = true
        refreshAncestors(
          ctx.gridApi!,
          this.fieldRefreshedAfterMove,
          parentNode,
          (node: RowNode) => {
            if (node && node.data && node.data.cumulation) {
              // Get parent data from master data
              const parentRow = ctx.rowDataManager
                .getAllRows()
                .find(v => v.uuid === node.data.uuid)
              if (!parentRow) return
              if (row.wbsItem.wbsItemType?.isTask()) {
                updateTaskStatusCount(parentRow, row.wbsItem.status!)
                if (
                  parentRow.wbsItem.wbsItemType?.isDeliverable() &&
                  directParent
                ) {
                  parentRow.cumulation!.countTaskOfDirectChildren += add
                    ? 1
                    : -1
                  updateTaskStatusCountOfDirectChildren(
                    parentRow,
                    row.wbsItem.status!
                  )
                  directParent = false
                }
              } else if (row.wbsItem.wbsItemType?.isDeliverable()) {
                updateDeliverableStatusCount(parentRow, row.wbsItem.status!)
              }
              ctx.rowDataManager.updateRow(parentRow)
            }
          }
        )
      })
    }
  }

  private createRequestByRow = (
    row: ProjectPlanRow,
    viewMeta: ViewMeta
  ): ProjectPlanBatchInput => {
    const u = row.wbsItem
    let wbsItemInput = createRequestByRow(
      u,
      viewMeta,
      'projectPlan.lazyLoad.wbsItem',
      row.extensions
    )
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      parentUuid: row.parentUuid,
      prevSiblingUuid: row.prevSiblingUuid,
      type: row.type!,
      wbsItem: wbsItemInput,
      productBacklogItem: row.productBacklogItem,
      currentSprintUuid:
        row.wbsItem.currentSprint && row.wbsItem.currentSprint.uuid,
      ticketType: u.ticketType,
    }
  }

  private createDeltaRequestByRow = (
    editedRow: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    },
    viewMeta: ViewMeta
  ): ProjectPlanBatchDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    const wbsItemDeltaInput = createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.lazyLoad.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
    return {
      uuid: editedRowAfter.uuid,
      type: editedRowAfter.wbsItem.type!,
      parentUuid: {
        oldValue: editedRowBefore.parentUuid,
        newValue: editedRowAfter.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: editedRowBefore.prevSiblingUuid,
        newValue: editedRowAfter.prevSiblingUuid,
      },
      wbsItem: wbsItemDeltaInput,
    }
  }

  private createDeleteRequestByRow = (
    row: ProjectPlanRow,
    viewMeta: ViewMeta
  ) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
      wbsItemUuid: row.wbsItem.uuid!,
      wbsItemLockVersion: row.wbsItem.lockVersion!,
    }
  }

  private createProductBacklogItemInput = (
    added: ProjectPlanRow[],
    edited: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    }[]
  ): ProductBacklogItemInput[] => {
    let input: ProductBacklogItemInput[] = []
    added.forEach(row =>
      input.push(this.createProductBacklogItemInputByRow(row))
    )
    edited.forEach(row =>
      input.push(this.createProductBacklogItemInputByRow(row.after))
    )
    return input
  }

  private createSprintProductItemInput = (
    added: ProjectPlanRow[],
    edited: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    }[]
  ): SprintItemInput[] => {
    let input: SprintItemInput[] = []
    added
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isDeliverable() && row.wbsItem.currentSprint
      )
      .forEach(row => input.push(this.createSprintItemInput(row)))
    edited
      .filter(
        row =>
          row.after.wbsItem.wbsItemType?.isDeliverable() &&
          row.after.wbsItem.currentSprint
      )
      .forEach(row => input.push(this.createSprintItemInput(row.after)))
    return input
  }

  private createSprintItemInput = (row: ProjectPlanRow): SprintItemInput => ({
    uuid: row.uuid,
    wbsItemUuid: row.wbsItem.uuid,
    wbsItemLockVersion: row.wbsItem.lockVersion,
    sprintUuid: row.wbsItem.currentSprint?.uuid,
  })

  private createProductBacklogItemInputByRow = (
    row: ProjectPlanRow
  ): ProductBacklogItemInput => ({
    uuid: row.uuid,
    wbsItemUuid: row.wbsItem.uuid,
    teamUuid: row.wbsItem.team?.uuid,
    productBacklogItem: row.productBacklogItem,
  })

  private createTicketListInput = (
    rows: ProjectPlanRow[]
  ): TicketListInput[] => {
    return rows
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isDeliverable() &&
          row.wbsItem.wbsItemType?.isTicket()
      )
      .map(row => ({
        uuid: row.uuid,
        ticketType: row.wbsItem.ticketType!,
      }))
  }

  private createTicketInput = (rows: ProjectPlanRow[]): TicketInput[] => {
    return rows
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isTask() &&
          row.wbsItem.wbsItemType?.isTicket()
      )
      .map(row => ({
        uuid: row.uuid,
        ticketType: row.wbsItem.ticketType!,
        ticketListUuid: row.ticketListUuid!,
      }))
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (field === 'wbsItem.type') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType?.getNameWithSuffix()
      }
    }
    if (field === 'wbsItem.ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (field === 'wbsItem.estimatedWorkload.deliverable') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (data.wbsItem.wbsItemType?.isTask()) return undefined
        const deliverableEstimatedHour = getDeliverableEstimatedHour(data)
        return formatWorkload(
          deliverableEstimatedHour,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.estimatedWorkload.task') {
      return (params: ValueGetterParams) => {
        if (!params.data) return 0
        const data: ProjectPlanRow = params.data
        const taskEstimatedHour = getTaskEstimatedHour(data)
        return formatWorkload(
          taskEstimatedHour,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.actualHour') {
      return (params: ValueGetterParams) => {
        if (!params.data) return 0
        const data: ProjectPlanRow = params.data
        return formatWorkload(
          getActualHour(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'estimatedProgressRate') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          const deliverablePlannedValue = getDeliverablePlannedValue(data)
          const deliverableEstimatedHour = getDeliverableEstimatedHour(data)
          if (deliverablePlannedValue === undefined) return '-'
          return getRate(deliverablePlannedValue, deliverableEstimatedHour)
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getRate(
            getDeliverablePlannedCount(data)!,
            getDeliverableTotalCount(data)
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getRate(getTaskPlannedValue(data), getTaskEstimatedHour(data))
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getRate(getTaskPlannedCount(data), getTaskTotalCount(data))
        }
      }
    }
    if (field === 'progressRate') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data

        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return getRate(
            getDeliverableEarnedValue(data),
            getDeliverableEstimatedHour(data)
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getRate(
            getDeliverableEarnedValueCount(data)!,
            getDeliverableTotalCount(data)
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
            return ''
          }

          return getRate(getTaskEarnedValue(data), getTaskEstimatedHour(data))
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getRate(getTaskEarnedValueCount(data), getTaskTotalCount(data))
        }
      }
    }
    if (field === 'total') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data

        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isDeliverable() && isDiscard(data)) {
            return 0
          }

          return formatEvm(
            getDeliverableEstimatedHour(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          if (data.wbsItem.wbsItemType?.isDeliverable() && !isDiscard(data)) {
            return 1
          }

          return getDeliverableTotalCount(data)
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isTask() && isDiscard(data)) return 0
          return formatEvm(
            getTaskEstimatedHour(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask() && !isDiscard(data)) return 1
          return getTaskTotalCount(data)
        }
      }
    }
    if (field === 'plannedValue') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getDeliverablePlannedValue(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getDeliverablePlannedCount(data)
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getTaskPlannedValue(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getTaskPlannedCount(data)
        }
      }
    }
    if (field === 'earnedValue') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return formatEvm(
            getDeliverableEarnedValue(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getDeliverableEarnedValueCount(data)
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isTask() && !isDone(data)) return ''
          return (
            getTaskEarnedValue(data) /
              params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getTaskEarnedValueCount(data)
        }
      }
    }
    if (field === 'preceding') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getPrecedingDeliverableWorkload(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getPrecedingDeliverableCount(data)
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getPrecedingTaskWorkload(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getPrecedingTaskCount(data)
        }
      }
    }
    if (field === 'delayed') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getDelayedDeliverableWorkload(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          return getDelayedDeliverableCount(data)
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getDelayedTaskWorkload(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          return getDelayedTaskCount(data)
        }
      }
    }
    if (field === 'remaining') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          return formatEvm(
            getDeliverableEstimatedHour(data) - getDeliverableEarnedValue(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.DELIVERABLE &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) return '-'
          const deliverableCount =
            getDeliverableCount(data)! - getDeliverableEarnedValueCount(data)!
          if (deliverableCount < 0) {
            return 0
          } else {
            return deliverableCount
          }
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType ===
            AggregateField.WBS_ITEM_WORKLOAD
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) {
            return isDone(data) || isDiscard(data)
              ? 0
              : formatEvm(
                  getTaskEstimatedHour(data),
                  params.context.workloadUnitState?.hoursPerSelectedUnit || 1
                )
          }
          return formatEvm(
            getTaskEstimatedHour(data) - getTaskEarnedValue(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (
          params.context.wbsItemType === WbsItemType.TASK &&
          params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ) {
          if (data.wbsItem.wbsItemType?.isTask()) {
            return isDone(data) || isDiscard(data) ? 0 : 1
          }

          return getTaskCount(data) - getTaskEarnedValueCount(data)
        }
      }
    }
    if (field === 'costVariance') {
      return (params: ValueGetterParams) => {
        if (!params.data) return 0
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return formatEvm(
            getDeliverableEarnedValue(data) - getActualHour(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return formatEvm(
          getTaskEarnedValue(data) - getActualHour(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'costPerformanceIndex') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return formatRate(getDeliverableCostPerformanceIndex(data))
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return formatRate(getTaskCostPerformanceIndex(data))
      }
    }
    if (field === 'scheduleVariance') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return formatEvm(
            getDeliverableEarnedValue(data) - getDeliverablePlannedValue(data)!,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return formatEvm(
          getTaskEarnedValue(data) - getTaskPlannedValue(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'schedulePerformanceIndex') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return getRate(
            getDeliverableEarnedValue(data),
            getDeliverablePlannedValue(data)
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return getRate(getTaskEarnedValue(data), getTaskPlannedValue(data))
      }
    }
    if (field === 'estimateToComplete') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return formatEvm(
            getDeliverableEstimateToComplete(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return formatEvm(
          getTaskEstimateToComplete(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'estimateAtCompletion') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return formatEvm(
            getDeliverableEstimateAtCompletion(data),
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        return formatEvm(
          getTaskEstimateAtCompletion(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'varianceAtCompletion') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          const bac = getDeliverableEstimatedHour(data)
          const eac = getDeliverableEstimateAtCompletion(data)
          return formatEvm(
            bac - eac,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        if (!data || (data.wbsItem.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }

        const bac = getTaskEstimatedHour(data)
        const eac = getTaskEstimateAtCompletion(data)
        return formatEvm(
          bac - eac,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.estimatedAmount.deliverable') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (data.wbsItem.wbsItemType?.isTask()) {
          return undefined
        }
        return getDeliverableEstimatedAmount(data)
      }
    }
    if (field === 'wbsItem.estimatedAmount.task') {
      return (params: ValueGetterParams) => {
        if (!params.data) return 0
        const data: ProjectPlanRow = params.data
        return getTaskEstimatedAmount(data)
      }
    }
    if (field === 'wbsItem.actualAmount') {
      return (params: ValueGetterParams) => {
        if (!params.data) return 0
        const data: ProjectPlanRow = params.data
        return getActualAmount(data)
      }
    }
    if (field === 'completionAmountRate') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return getRate(
            getActualAmount(data),
            getDeliverableEstimatedAmount(data)
          )
        }
        if (params.context.wbsItemType === WbsItemType.TASK) {
          return getRate(getActualAmount(data), getTaskEstimatedAmount(data))
        }
      }
    }
    if (field === 'ganttChart') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const wbsItem: WbsItemDetail = params.data.wbsItem
        return JSON.stringify([
          wbsItem.status,
          wbsItem.scheduledDate,
          wbsItem.actualDate,
        ])
      }
    }
    if (field === 'startDelayDays') {
      return startDelayDaysValueGetter
    }
    if (field === 'endDelayDays') {
      return endDelayDaysValueGetter
    }
    return undefined
  }

  workloadColumnNames = [
    'wbsItem.estimatedWorkload.deliverable',
    'wbsItem.estimatedWorkload.task',
    'earnedValue',
    'progressRate',
    'costPerformanceIndex',
    'schedulePerformanceIndex',
  ]

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (this.workloadColumnNames.includes(field)) {
      return (params: ValueSetterParams) => {
        const data: ProjectPlanRow = params.data
        if (!params.api || params.oldValue === params.newValue) {
          return false
        }
        const oldValue = data.wbsItem.estimatedWorkload?.hour || 0
        let workload: Workload
        const unit = getSelectedWorkloadUnit()
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        if (unit === WorkloadUnit.MONTH) {
          workload = Workload.from({
            month: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        } else if (unit === WorkloadUnit.DAY) {
          workload = Workload.from({
            day: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        } else if (unit === WorkloadUnit.HOUR) {
          workload = Workload.from({
            hour: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        } else {
          workload = new Workload(0, 0, 0, { dailyWorkHours, monthlyWorkDays })
        }
        data.wbsItem.estimatedWorkload = workload
        // Refresh current row
        params.node &&
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: this.workloadColumnNames,
            force: true,
          })

        this.refreshParentWorkload({
          oldValue: oldValue,
          newValue: workload.hour,
          data: params.data,
          api: params.api,
          parentNode: getParentNode(params.node),
        })
        return true
      }
    }
    if (
      [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
      ].includes(field)
    ) {
      return wbsScheduledDateValueSetter(field)
    }
    if (
      ['wbsItem.actualDate.startDate', 'wbsItem.actualDate.endDate'].includes(
        field
      )
    ) {
      return wbsActualDateValueSetter(field)
    }
    if (
      [
        'wbsItem.estimatedAmount.deliverable',
        'wbsItem.estimatedAmount.task',
      ].includes(field)
    ) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const oldValue = params.data.wbsItem.estimatedAmount || 0
        params.data.wbsItem.estimatedAmount = Number(params.newValue)
        // Refresh current row
        params.node &&
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [
              'wbsItem.estimatedAmount.deliverable',
              'wbsItem.estimatedAmount.task',
            ],
            force: true,
          })
        this.refreshParentEstimatedAmount({
          oldValue: oldValue,
          newValue: params.data.wbsItem.estimatedAmount,
          data: params.data,
          api: params.api,
          parentNode: getParentNode(params.node),
        })
        return true
      }
    }
    if ('wbsItem.actualAmount' === field) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const oldValue = params.data.wbsItem.actualAmount || 0
        params.data.wbsItem.actualAmount = Number(params.newValue)
        params.node &&
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: ['wbsItem.actualAmount'],
            force: true,
          })
        this.refreshParentActualAmount({
          oldValue: oldValue,
          newValue: params.data.wbsItem.actualAmount,
          data: params.data,
          api: params.api,
          parentNode: getParentNode(params.node),
        })
        return true
      }
    }
    return undefined
  }

  refreshParentWorkload = (params: {
    oldValue: number
    newValue: number
    data: ProjectPlanRow
    api: GridApi
    parentNode?: RowNode
  }) => {
    // Refresh parent nodes
    const diff = params.newValue - params.oldValue
    refreshAncestors(
      params.api,
      this.workloadColumnNames,
      params.parentNode,
      (node: RowNode) => {
        const parentData: ProjectPlanRow = node.data
        if (
          !parentData.cumulation ||
          !(
            params.data.wbsItem.wbsItemType?.isTask() ||
            params.data.wbsItem.wbsItemType?.isDeliverable()
          )
        ) {
          return
        }
        if (params.data.wbsItem.wbsItemType?.isDeliverable()) {
          parentData.cumulation.sumDeliverableEstimatedHour += diff
          parentData.cumulation.sumDeliverableEstimatedHourDone += isDone(
            params.data
          )
            ? diff
            : 0
          parentData.cumulation.sumDeliverableEstimatedHourDiscard += isDiscard(
            params.data
          )
            ? diff
            : 0
        } else if (params.data.wbsItem.wbsItemType?.isTask()) {
          parentData.cumulation.sumTaskEstimatedHour += diff
          parentData.cumulation.sumTaskEstimatedHourDone += isDone(params.data)
            ? diff
            : 0
          parentData.cumulation.sumTaskEstimatedHourDiscard += isDiscard(
            params.data
          )
            ? diff
            : 0
        }
        node.setData(parentData)
      }
    )
    // Refresh cumulation of direct parent deliverable
    if (params.data.wbsItem.wbsItemType?.isTask()) {
      let parent = params.parentNode
      while (parent && parent.data && parent.data.cumulation) {
        const parentData: ProjectPlanRow = parent.data
        if (!parentData.wbsItem.wbsItemType?.isDeliverable()) {
          parent = parent.parent || undefined
          continue
        }
        const deliverableData = parent.data
        deliverableData.cumulation.sumTaskEstimatedHourOfDirectChildren += diff
        deliverableData.cumulation.sumTaskEstimatedHourDoneOfDirectChildren +=
          isDone(params.data) ? diff : 0
        deliverableData.cumulation.sumTaskEstimatedHourDiscardOfDirectChildren +=
          isDiscard(params.data) ? diff : 0
        parent.setData(deliverableData)
        break
      }
    }
  }

  refreshParentEstimatedAmount = (params: {
    oldValue: number
    newValue: number
    data: ProjectPlanRow
    api: GridApi
    parentNode?: RowNode
  }) => {
    // Refresh parent nodes
    const diff = params.newValue - params.oldValue
    refreshAncestors(
      params.api,
      ['wbsItem.estimatedAmount.deliverable', 'wbsItem.estimatedAmount.task'],
      params.parentNode,
      (node: RowNode) => {
        const parentData: ProjectPlanRow = node.data
        if (
          !parentData.cumulation ||
          !(
            params.data.wbsItem.wbsItemType?.isTask() ||
            params.data.wbsItem.wbsItemType?.isDeliverable()
          ) ||
          isDiscard(params.data)
        ) {
          return
        }
        if (params.data.wbsItem.wbsItemType?.isDeliverable()) {
          parentData.cumulation.sumDeliverableEstimatedAmount += diff
        } else if (params.data.wbsItem.wbsItemType?.isTask()) {
          parentData.cumulation.sumTaskEstimatedAmount += diff
        }
        node.setData(parentData)
      }
    )
    // Refresh cumulation of direct parent deliverable
    if (params.data.wbsItem.wbsItemType?.isTask()) {
      let parent = params.parentNode
      while (parent && parent.data && parent.data.cumulation) {
        const parentData: ProjectPlanRow = parent.data
        if (!parentData.wbsItem.wbsItemType?.isDeliverable()) {
          parent = parent.parent || undefined
          continue
        }
        const deliverableData = parent.data
        deliverableData.cumulation.sumTaskEstimatedAmountOfDirectChildren +=
          diff
        parent.setData(deliverableData)
        break
      }
    }
  }

  refreshParentActualAmount = (params: {
    oldValue: number
    newValue: number
    data: ProjectPlanRow
    api: GridApi
    parentNode?: RowNode
  }) => {
    // Refresh parent nodes
    const diff = params.newValue - params.oldValue
    refreshAncestors(
      params.api,
      ['wbsItem.actualAmount'],
      params.parentNode,
      (node: RowNode) => {
        const parentData: ProjectPlanRow = node.data
        if (
          !parentData.cumulation ||
          !params.data.wbsItem.wbsItemType?.isTask() ||
          isDiscard(params.data)
        ) {
          return
        }
        parentData.cumulation.sumTaskActualAmount += diff
        node.setData(parentData)
      }
    )
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        refreshFieldNames: [
          'wbsItem.estimatedWorkload.deliverable',
          'wbsItem.estimatedWorkload.task',
        ],
      }
    }
    if (field === 'productBacklogItem') {
      return {
        hide: (rowNode: RowNode) =>
          !rowNode.data.wbsItem.wbsItemType?.isDeliverable(),
      }
    }
    if (field === 'wbsItem.currentSprint') {
      return {
        validatePaste: value =>
          [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
            value.status!
          ),
      }
    }
    return undefined
  }

  getCellRendererParams = (
    field: string,
    ctx?: ProjectPlanBulkSheetContext
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: ProjectPlanRow) =>
          row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    if (field === 'ganttChart') {
      if (!ctx) return {}
      return generateGanttChartCellRendererParams(
        ctx,
        ctx.props.ganttParameter!
      )
    }
    return undefined
  }

  getAttachmentCellRendererParams = (
    bulkSheet: ProjectPlanBulkSheetContext
  ) => {
    return {
      getAttachmentList: async (
        row: ProjectPlanRow,
        bulkSheet: ProjectPlanBulkSheetContext
      ) => {
        if (row.wbsItem.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: ProjectPlanRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = (ctx: ProjectPlanBulkSheetContext) => {
    return {
      path: 'wbsItem.description',
      openComment: (row: ProjectPlanRow, ctx: ProjectPlanBulkSheetContext) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      getCommentSummary: (row: ProjectPlanRow) => row.commentSummary,
      getSpeedDialActions: (row: ProjectPlanRow): SpeedDialActionProps[] => {
        const wbsItemTypes = store.getState().project.wbsItemTypes
        const wbsItem = row.wbsItem
        const parentUuid = row.uuid
        const addChild = (params: {
          parentUuid: string
          type: WbsItemTypeVO
          parent?: ProjectPlanRow
        }): void => {
          const { parentUuid, type, parent } = params
          const newRowData = [
            this.rowDataSpec.createNewRow(ctx, { parent, type }),
          ]
          // Update parent cumulation
          this.beforeAdd(ctx, newRowData, parentUuid)
          ctx.rowDataManager.addRows(
            newRowData,
            0,
            type.isWorkgroup() ? undefined : parentUuid
          )
          store.dispatch(requireSave())
        }

        const speedDialActionProps: SpeedDialActionProps[] = []
        if (wbsItemTypes.isEmpty()) {
          return speedDialActionProps
        }
        if (wbsItemTypes.task.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.task.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.task',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.task,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.deliverable.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.deliverable.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.deliverable',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.deliverable,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.deliverableList.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.deliverableList.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.deliverableList',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.deliverableList,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.process.canBeChildOf(wbsItem.wbsItemType!)) {
          speedDialActionProps.push({
            icon: <img src={wbsItemTypes.process.iconUrl} />,
            tooltipTitle: intl.formatMessage({
              id: 'bulksheet.speedDial.add.process',
            }),
            onClick: () => {
              addChild({
                parentUuid: parentUuid,
                type: wbsItemTypes.process,
                parent: row,
              })
            },
          })
        }
        return speedDialActionProps
      },
    }
  }

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: ProjectPlanRow = params.data
    if (row && taskActualResultExists(row.wbsItem)) {
      return row.wbsItem.uuid
    }
    return undefined
  }

  isServerSideRowModel = true
  isSetKeyBind = true
  getChildren = async (
    state: ProjectPlanState,
    treeRootUuid?: string,
    maxDepth?: number
  ) => {
    return getTree(
      state.uuid,
      Object.values(WbsItemType),
      treeRootUuid,
      maxDepth || 1
    )
  }

  getCopiedRowDataWithChildren = async (
    ctx: ProjectPlanBulkSheetContext,
    treeRootUuid?: string
  ) => {
    let rows: ProjectPlanRow[] = []
    const response = await getTree(
      ctx.state.uuid,
      Object.values(WbsItemType),
      treeRootUuid
    )
    const data = response.json
    const row = ctx.rowDataManager.createRowByResponse(data, ctx.viewMeta)
    // not copy comment
    row.commentSummary = undefined
    rows.push(row)
    if (data.children.length > 0) {
      const children = this.createAllChildrenRow(data, ctx)
      rows = rows.concat(children)
    }
    return rows
  }

  private createAllChildrenRow = (
    response: ProjectPlanDetail,
    ctx: ProjectPlanBulkSheetContext
  ): ProjectPlanRow[] => {
    let rows: ProjectPlanRow[] = []
    response.children.forEach(child => {
      const childRow = ctx.rowDataManager.createRowByResponse(
        child,
        ctx.viewMeta
      )
      // not copy comment
      childRow.commentSummary = undefined
      rows.push(childRow)
      if (child.children.length > 0) {
        const childRows = this.createAllChildrenRow(child, ctx)
        rows = rows.concat(childRows)
      }
    })
    return rows
  }

  customColumnWidth = (field: string): number | undefined => {
    if (['productBacklogItem'].includes(field)) {
      return 45
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    if (
      [
        'wbsItem.accountable',
        'wbsItem.responsible',
        'wbsItem.assignee',
        'wbsItem.difficulty',
        'wbsItem.priority',
      ].includes(field)
    ) {
      return 65
    }
    if (['wbsItem.revision'].includes(field)) {
      return 75
    }
    if (
      [
        'wbsItem.estimatedStoryPoint',
        'storyPoint.earnedValue',
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
        'wbsItem.actualHour',
        'wbsItem.difficulty',
        'total',
        'earnedValue',
        'progressRate',
        'costPerformanceIndex',
        'schedulePerformanceIndex',
        'remaining',
        'preceding',
        'costVariance',
        'scheduleVariance',
        'estimateToComplete',
        'estimateAtCompletion',
        'varianceAtCompletion',
        'delayed',
        'plannedValue',
      ].includes(field)
    ) {
      return 80
    }
    if (['wbsItem.currentSprint'].includes(field)) {
      return 85
    }
    if (
      [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
        'estimatedProgressRate',
      ].includes(field)
    ) {
      return 90
    }
    if (
      [
        'wbsItem.code',
        'wbsItem.type',
        'action',
        'startDelayDays',
        'endDelayDays',
      ].includes(field)
    ) {
      return 100
    }
    if (field === 'action') {
      return 135
    }
    if (['wbsItem.substatus'].includes(field)) {
      return 110
    }
    if (['wbsItem.createdAt', 'wbsItem.updatedAt'].includes(field)) {
      return 135
    }
    if (['wbsItem.status'].includes(field)) {
      return 145
    }
    if (field === 'ganttChart') {
      return 560
    }
    if (
      [
        'wbsItem.estimatedAmount.deliverable',
        'wbsItem.estimatedAmount.task',
        'wbsItem.actualAmount',
        'completionAmountRate',
      ].includes(field)
    ) {
      return 105
    }
    return undefined
  }

  beforeParentChange = (nodes: RowNode[], overNode?: RowNode) => {
    nodes.forEach(node => {
      if (!node.data || !node.data.cumulation) return
      let parent = node.parent
      // Reset old parent cumulation
      let updateDirectChildren = node.data.wbsItem?.wbsItemType?.isTask()
      while (parent && parent.data) {
        const parentData: ProjectPlanRow = parent.data
        parentData.cumulation = subtractCumulation(
          parent.data.cumulation,
          node.data.cumulation,
          node.data.wbsItem
        )
        if (
          parentData.wbsItem.wbsItemType?.isDeliverable() &&
          updateDirectChildren
        ) {
          parentData.cumulation = subtractDirectCumulation(
            parent.data.cumulation,
            node.data.cumulation,
            node.data.wbsItem
          )
          updateDirectChildren = false
        }
        parent.setData(parentData)
        parent = parent.parent
      }
      // Reset new parent cumulation
      if (!overNode) return
      let overParent: RowNode | null = overNode
      let updateOverDirectChildren = node.data.wbsItem?.wbsItemType?.isTask()
      while (overParent && overParent.data) {
        const parentData: ProjectPlanRow = overParent.data
        parentData.cumulation = addCumulation(
          overParent.data.cumulation,
          node.data.cumulation,
          node.data.wbsItem
        )
        if (
          parentData.wbsItem.wbsItemType?.isDeliverable() &&
          updateOverDirectChildren
        ) {
          parentData.cumulation = addDirectCumulation(
            overParent.data.cumulation,
            node.data.cumulation,
            node.data.wbsItem
          )
          updateOverDirectChildren = false
        }
        overParent.setData(parentData)
        overParent = overParent.parent
      }
    })
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  mergeRowCommentSummary = (
    targetRow: ProjectPlanRow,
    comments: List<Comment> | undefined
  ): ProjectPlanRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }
  getKeyBindListeners = (
    ctx: ProjectPlanBulkSheetContext
  ): KeyBindListener[] => {
    const canAddRowOnKeyPress = (
      ctx: ProjectPlanBulkSheetContext,
      selectedNodes: RowNode[] | undefined,
      type: WbsItemTypeVO,
      above?: boolean
    ): boolean => {
      if (!ctx.gridApi || !selectedNodes || selectedNodes.length <= 0) {
        return false
      }
      const row = selectedNodes[0].data
      const parent = ctx.rowDataManager.getParent(selectedNodes[0].data.uuid)
      const canAddChild = row.wbsItem?.type && !!row.wbsItem?.displayName.trim()
      const cellRanges = ctx.gridApi.getCellRanges()
      const multipleRowsContainingTopSelected =
        cellRanges &&
        cellRanges.some(
          range =>
            range.startRow?.rowIndex === 0 || range.endRow?.rowIndex === 0
        ) &&
        cellRanges.some(
          range =>
            (range.endRow?.rowIndex || 0) - (range.startRow?.rowIndex || 0) !==
            0
        )
      if (
        !above &&
        (selectedNodes.length !== 1 || multipleRowsContainingTopSelected)
      ) {
        return false
      }
      return this.canAddRow(
        type,
        parent,
        row,
        canAddChild,
        multipleRowsContainingTopSelected,
        above
      )
    }

    const addRowOnKeyPress = (
      ctx: ProjectPlanBulkSheetContext,
      type: WbsItemTypeVO,
      above?: boolean
    ) => {
      const selectedNodes = ctx.gridApi?.getSelectedNodes()
      if (!canAddRowOnKeyPress(ctx, selectedNodes, type, above)) {
        return
      }
      this.addRow(ctx, selectedNodes!, selectedNodes![0], type, {
        above,
      })
    }

    return [
      {
        key: 'alt+shift+h',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.process,
            true
          )
        },
      },
      {
        key: 'alt+shift+j',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverableList,
            true
          )
        },
      },
      {
        key: 'alt+shift+k',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverable,
            true
          )
        },
      },
      {
        key: 'alt+shift+l',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.task,
            true
          )
        },
      },
      {
        key: 'mod+shift+h',
        fn: () => {
          addRowOnKeyPress(ctx, store.getState().project.wbsItemTypes.process)
        },
      },
      {
        key: 'mod+shift+j',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverableList
          )
        },
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+shift+k',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverable
          )
        },
      },
      {
        key: 'mod+shift+l',
        fn: () => {
          addRowOnKeyPress(ctx, store.getState().project.wbsItemTypes.task)
        },
      },
      {
        key: 'alt+shift+d',
        fn: ctx.removeRowKeyEventListener,
      },
      {
        key: 'alt+shift+c',
        fn: ctx.copyRowKeyEventListener,
      },
      {
        key: 'alt+shift+x',
        fn: ctx.cutRowKeyEventListener,
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+i',
        fn: () => {
          ctx.pasteRowKeyEventListener(true, false)
        },
      },
      {
        key: 'mod+shift+i',
        fn: () => {
          ctx.pasteRowKeyEventListener(true, true)
        },
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+shift+v',
        fn: ctx.insertCutRowKeyEventListener,
      },
    ]
  }
}

// TODO: use functions which are defined in WbsItemRowApi.
export const isDone = (data: ProjectPlanRow) =>
  data.wbsItem.status === WbsItemStatus.DONE
export const isDiscard = (data: ProjectPlanRow) =>
  data.wbsItem.status === WbsItemStatus.DISCARD
export const isScheduledToBeDone = (data: ProjectPlanRow) =>
  !isDiscard(data) &&
  data.wbsItem.scheduledDate &&
  data.wbsItem.scheduledDate.endDate &&
  new DateVO(data.wbsItem.scheduledDate.endDate).isSameOrBefore(DateVO.now())
const isDelayed = (data: ProjectPlanRow): boolean =>
  !isDone(data) &&
  !isDiscard(data) &&
  !!data.wbsItem.scheduledDate &&
  new DateVO(data.wbsItem.scheduledDate.endDate).isBefore(DateVO.now())
export const isPreceding = (data: ProjectPlanRow) =>
  data.wbsItem.scheduledDate &&
  new DateVO(data.wbsItem.scheduledDate.endDate).isAfter(DateVO.now()) &&
  data.wbsItem.status === WbsItemStatus.DONE

export const formatWorkload = (
  value: number | undefined,
  denominator: number = 1
) => Number((value || 0) / denominator)
export const getDeliverableEstimatedHour = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.wbsItem.estimatedWorkload?.hour || 0
  }
  return (
    data.cumulation!.sumDeliverableEstimatedHour -
    data.cumulation!.sumDeliverableEstimatedHourDiscard
  )
}
const getTaskEstimatedHour = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return data.wbsItem.estimatedWorkload?.hour || 0
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return (
      data.cumulation!.sumTaskEstimatedHourOfDirectChildren -
      data.cumulation!.sumTaskEstimatedHourDiscardOfDirectChildren
    )
  }
  return (
    data.cumulation!.sumTaskEstimatedHour -
    data.cumulation!.sumTaskEstimatedHourDiscard
  )
}
const getDeliverableCount = (data: ProjectPlanRow): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (!isDone(data)) {
      return 1
    } else {
      return 0
    }
  }
  return (
    data.cumulation!.countStatusDeliverableTodo +
    data.cumulation!.countStatusDeliverableDoing +
    data.cumulation!.countStatusDeliverableReview +
    data.cumulation!.countStatusDeliverableDone
  )
}
const getTaskCount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return 1
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return (
      data.cumulation!.countTaskOfDirectChildren -
      data.cumulation!.countTaskDiscardOfDirectChildren
    )
  }
  return (
    data.cumulation!.countStatusTaskTodo +
    data.cumulation!.countStatusTaskDoing +
    data.cumulation!.countStatusTaskReview +
    data.cumulation!.countStatusTaskDone
  )
}
const getTaskTotalCount = (data: ProjectPlanRow): number => {
  return (
    data.cumulation!.countStatusTaskTodo +
    data.cumulation!.countStatusTaskDoing +
    data.cumulation!.countStatusTaskReview +
    data.cumulation!.countStatusTaskDone
  )
}
const getDeliverableTotalCount = (data: ProjectPlanRow): number => {
  return (
    data.cumulation!.countStatusDeliverableTodo +
    data.cumulation!.countStatusDeliverableDoing +
    data.cumulation!.countStatusDeliverableReview +
    data.cumulation!.countStatusDeliverableDone
  )
}
const getActualHour = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return data.wbsItem.actualHour || 0
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumActualHourOfDirectChildren
  }
  return data.cumulation!.sumActualHour
}
const getDeliverableEarnedValue = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) return 0
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return isDone(data) ? data.wbsItem.estimatedWorkload?.hour || 0 : 0
  }
  return data.cumulation!.sumDeliverableEstimatedHourDone
}
const getTaskEarnedValue = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return isDone(data) ? data.wbsItem.estimatedWorkload?.hour || 0 : 0
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumTaskEstimatedHourDoneOfDirectChildren
  }
  return data.cumulation!.sumTaskEstimatedHourDone
}
const getDeliverablePlannedValue = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isScheduledToBeDone(data)) {
      return getDeliverableEstimatedHour(data)
    }
    return undefined
  }
  return data.cumulation!.sumDeliverableToBeCompleted
}
const getTaskPlannedValue = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    if (isScheduledToBeDone(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumTaskPlannedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskToBeCompleted
}
const getDeliverablePlannedCount = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return isScheduledToBeDone(data) ? 1 : 0
  }
  return data.cumulation!.countDeliverableToBeCompleted
}
const getTaskPlannedCount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return isScheduledToBeDone(data) ? 1 : 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.countTaskPlannedItemOfDirectChildren
  }
  return data.cumulation!.countTaskToBeCompleted
}

const getDelayedDeliverableCount = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isDelayed(data)) {
      return 1
    } else {
      return 0
    }
  }
  return data.cumulation!.countDeliverableEndDelayed
}
const getDelayedTaskCount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return isDelayed(data) ? 1 : 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.countDelayedTaskOfDirectChildren
  }
  return data.cumulation!.countTaskEndDelayed
}
const getPrecedingDeliverableCount = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isPreceding(data)) {
      return 1
    } else {
      return 0
    }
  }
  return data.cumulation!.countDeliverableEndPreceding
}
const getPrecedingTaskCount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return isPreceding(data) ? 1 : 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.countTaskPrecedingOfDirectChildren
  }
  return data.cumulation!.countTaskEndPreceding
}
const getDeliverableEarnedValueCount = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isDone(data)) {
      return 1
    } else {
      return 0
    }
  }
  return data.cumulation!.countStatusDeliverableDone
}
const getTaskEarnedValueCount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return isDone(data) ? 1 : 0
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.countTaskDoneOfDirectChildren
  }
  return data.cumulation!.countStatusTaskDone
}
const getDelayedDeliverableWorkload = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isDelayed(data)) {
      return getDeliverableEstimatedHour(data)
    }
    return undefined
  }
  return data.cumulation!.sumDeliverableEndDelayed
}
const getDelayedTaskWorkload = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    if (isDelayed(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumDelayedTaskEstimatedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskEndDelayed
}
const getPrecedingDeliverableWorkload = (
  data: ProjectPlanRow
): number | undefined => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    if (isPreceding(data)) {
      return getDeliverableEstimatedHour(data)
    }
    return undefined
  }
  return data.cumulation!.sumDeliverableEndPreceding
}
const getPrecedingTaskWorkload = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    if (isPreceding(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumPrecedingTaskEstimatedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskEndPreceding
}

const getDeliverableCostPerformanceIndex = (data: ProjectPlanRow): number => {
  const ev = getDeliverableEarnedValue(data)
  const ac = getActualHour(data)
  return ev / ac
}
const getTaskCostPerformanceIndex = (data: ProjectPlanRow): number => {
  const ev = getTaskEarnedValue(data)
  const ac = getActualHour(data)
  return ev / ac
}

const getDeliverableEstimateToComplete = (data: ProjectPlanRow): number => {
  const bac = getDeliverableEstimatedHour(data)
  const ev = getDeliverableEarnedValue(data)

  const cpi = getDeliverableCostPerformanceIndex(data)
  return (bac - ev) / cpi
}
const getTaskEstimateToComplete = (data: ProjectPlanRow): number => {
  const bac = getTaskEstimatedHour(data)
  const ev = getTaskEarnedValue(data)

  const cpi = getTaskCostPerformanceIndex(data)
  return (bac - ev) / cpi
}
const getDeliverableEstimateAtCompletion = (data: ProjectPlanRow): number => {
  const ac = getActualHour(data)
  const etc = getDeliverableEstimateToComplete(data)
  return ac + etc
}
const getTaskEstimateAtCompletion = (data: ProjectPlanRow): number => {
  const ac = getActualHour(data)
  const etc = getTaskEstimateToComplete(data)
  return ac + etc
}

const getRate = (numerator: number, denominator?: number) => {
  if (!denominator) return '-'
  return numerator / denominator
}

const formatEvm = (
  value: number | undefined,
  hoursPerUnit: number
): number | string => {
  if (!Number.isFinite(value)) return '-'
  return (value || 0) / hoursPerUnit
}

const formatRate = (value: number): number | string => {
  if (!Number.isFinite(value)) return '-'
  return value
}

const getDeliverableEstimatedAmount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return 0
  }
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.wbsItem.estimatedAmount || 0
  }
  return data.cumulation!.sumDeliverableEstimatedAmount
}
const getTaskEstimatedAmount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return data.wbsItem.estimatedAmount || 0
  } else if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumTaskEstimatedAmountOfDirectChildren
  }
  return data.cumulation!.sumTaskEstimatedAmount
}
const getActualAmount = (data: ProjectPlanRow): number => {
  if (data.wbsItem.wbsItemType?.isTask()) {
    return data.wbsItem.actualAmount || 0
  }
  return data.cumulation!.sumTaskActualAmount
}
