import Mousetrap from 'mousetrap'
import { KeyBindListener, KeyBindListeners } from '../view/model/keyBind'

const mousetrap = new Mousetrap()
mousetrap.stopCallback = () => false

type KeyBindListenersStack = {
  id?: string | undefined
  listeners: KeyBindListeners
}[]

type State = {
  stack: KeyBindListenersStack
}

type ActionType =
  | 'ADD_KEY_BIND_LISTENERS'
  | 'UPDATE_KEY_BIND_LISTENERS'
  | 'REMOVE_KEY_BIND_LISTENERS'

type Action = {
  type: ActionType
} & any

export const addKeyBindListeners = (
  listeners: KeyBindListeners,
  id?: string | undefined
) => ({
  type: 'ADD_KEY_BIND_LISTENERS',
  listeners,
  id,
})

export const updateKeyBindListeners = (
  listeners: KeyBindListeners,
  id: string | undefined
) => ({
  type: 'UPDATE_KEY_BIND_LISTENERS',
  listeners,
  id,
})

export const removeKeyBindListeners = (id?: string | undefined) => ({
  type: 'REMOVE_KEY_BIND_LISTENERS',
  id,
})

const bindListener = (listener: KeyBindListener) => {
  const { key, fn, stopDefaultBehavior } = listener
  if (!fn) return
  if (stopDefaultBehavior) {
    mousetrap.bind(key, (e: KeyboardEvent) => {
      e.stopImmediatePropagation && e.stopImmediatePropagation()
      e.preventDefault && e.preventDefault()
      e.stopPropagation && e.stopPropagation()
      fn()
    })
  } else {
    mousetrap.bind(key, fn)
  }
}

const refreshKeyBindListeners = (stack: KeyBindListenersStack) => {
  mousetrap.reset()
  if (stack.length === 0) return
  const currentListeners = stack[0]
  currentListeners.listeners.forEach(listener => {
    bindListener(listener)
  })
}

const removeListener = (
  id: string | undefined,
  srcStack: KeyBindListenersStack
) => {
  if (id) {
    const targetIndex = srcStack.findIndex(k => k.id === id)
    if (0 <= targetIndex) {
      srcStack.splice(targetIndex, 1)
      return srcStack
    }
    return srcStack
  }
  return srcStack.slice(1)
}

export const reducer = (
  state: State = { stack: [] },
  action: Action
): State => {
  switch (action.type) {
    case 'ADD_KEY_BIND_LISTENERS':
      const added = [
        {
          id: action.id,
          listeners: action.listeners,
        },
        ...state.stack,
      ]
      refreshKeyBindListeners(added)
      return { stack: added }
    case 'UPDATE_KEY_BIND_LISTENERS':
      const targetIndex = action.id
        ? state.stack.findIndex(k => k.id === action.id)
        : state.stack.findIndex(k => !k.id)
      if (0 <= targetIndex) {
        state.stack.at(targetIndex)!.listeners = action.listeners
      }
      refreshKeyBindListeners(state.stack)
      return state
    case 'REMOVE_KEY_BIND_LISTENERS':
      const removed = removeListener(action.id, state.stack)
      refreshKeyBindListeners(removed)
      return { stack: removed }
    default:
      return state
  }
}
