import { styled, Typography } from '@mui/material'
import microsofticon from '../../../assets/external/microsoft_icon.png'
import {
  LoginInput,
  LoginButton,
  LoginLink,
  LoginContainer,
  LoginTitle,
} from './components'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { intl } from '../../../i18n'
import { extractDomainName } from '../../../utils/urls'
import { useHistory } from 'react-router'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  KeyboardEvent,
} from 'react'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'
import { AuthError } from '../../../error/AuthError'
import { useIdProviderRepository } from '../../../services/adaptors/idProviderRepositoryAdaptor'
import { IdProviderEntity } from '../../../domain/entity/IdProviderEntity'
import { ErrorMessage } from './components/AuthErrorMessage'

const ForgotPassword = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  fontsize: '14px',
  textAlign: 'left',
  color: '#7B8CAA',
  width: '100%',
  padding: '0 12px',
  gap: '4px',
  alignItems: 'center',
  height: '32px',
})

const OrGrid = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontsize: '14px',
  color: '#7B8CAA',
})

const SelectedTenant = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '72px',
  padding: '0 16px',
  gap: '12px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
  backgroundColor: '#F7F8FA',
  width: '444px',
})

const SelectedTenantTitle = styled('div')({
  display: 'flex',
  color: '#7B8CAA',
  fontSize: '14px',
  fontWeight: '400',
})

const SelectedTenantUrl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '296px',
})

const DomainName = styled('div')({
  display: 'flex',
  fontSize: '18px',
  color: '#243050',
})

const ChangeTenantButton = styled(LoginButton)({
  width: '104px',
})

const LoginTextInput = styled(LoginInput)({
  width: '444px',
})

const LoginInputArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  padding: '16px',
})

const LoginButtonArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
})

const StyledOpenInNewIcon = styled(OpenInNewRoundedIcon)({
  width: '16px',
  height: '16px',
})

export const Login = () => {
  const history = useHistory()
  const { login, federatedLogin, signout } = useAuthenticateUseCase()
  const { getIdProviders } = useIdProviderRepository()
  const [idProviders, setIdProviders] = useState<IdProviderEntity[]>([])
  useEffect(() => {
    const fn = async () => {
      const fetchedProviders = await getIdProviders()
      setIdProviders(fetchedProviders)
    }
    fn()
  }, [getIdProviders])

  const [email, setEmail] = useState<string>('')
  const onEmailChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }, [])
  const [password, setPassword] = useState<string>('')
  const onPasswordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value)
    },
    []
  )

  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)

  const onSubmit = useCallback(async () => {
    setAuthError(undefined)
    if (!email || !password) {
      setAuthError(new AuthError('NO_EMAIL_OR_PASSWORD'))
      return
    }
    try {
      const result = await login(email, password)
      if (result.isTemporaryPassword) {
        history.push(`/changePasswordForNewUser/${email}`)
        return
      }
    } catch (e) {
      if (!(e instanceof AuthError)) {
        throw e
      }
      setAuthError(e)
    }
  }, [email, history, login, password])

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const onClickFederatedLoginButton = useCallback(
    (idProvider: IdProviderEntity) => {
      federatedLogin(idProvider.code)
    },
    [federatedLogin]
  )

  const toForgotPassword = useCallback(() => {
    history.push('/forgotPassword')
  }, [history])
  const toTenantLogin = useCallback(() => {
    signout()
    history.push('/login')
  }, [signout, history])

  return (
    <LoginContainer>
      <LoginTitle>{intl.formatMessage({ id: 'login' })}</LoginTitle>
      {authError && <ErrorMessage authError={authError} />}
      <LoginInputArea>
        <SelectedTenant>
          <SelectedTenantUrl>
            <SelectedTenantTitle>
              {intl.formatMessage({ id: 'login.selectedTenant' })}
            </SelectedTenantTitle>
            <DomainName>{extractDomainName()}</DomainName>
          </SelectedTenantUrl>
          <ChangeTenantButton color={'skyBlue'} onClick={toTenantLogin}>
            {intl.formatMessage({ id: 'login.changeTenant' })}
          </ChangeTenantButton>
        </SelectedTenant>
        <LoginTextInput
          label={intl.formatMessage({ id: 'login.email' })}
          onChange={onEmailChanged}
          onKeyDown={onEnter}
        />
        <LoginTextInput
          label={intl.formatMessage({ id: 'login.password' })}
          type="password"
          autoComplete="current-password"
          onChange={onPasswordChanged}
          onKeyDown={onEnter}
        />
        <ForgotPassword>
          <LoginLink onClick={toForgotPassword}>
            {intl.formatMessage({ id: 'login.forgotPassword' })}
          </LoginLink>
          <StyledOpenInNewIcon />
        </ForgotPassword>
      </LoginInputArea>
      <LoginButtonArea>
        <LoginButton color={'skyBlue'} onClick={onSubmit}>
          {intl.formatMessage({ id: 'login' })}
        </LoginButton>
        {idProviders.length > 0 && (
          <>
            <OrGrid>{intl.formatMessage({ id: 'login.Or' })}</OrGrid>
            {idProviders.map((idProvider, i) => (
              <LoginButton
                key={i}
                color={'monotone'}
                onClick={() => onClickFederatedLoginButton(idProvider)}
              >
                <img src={microsofticon} />
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  {intl.formatMessage(
                    { id: 'login.loginWith' },
                    {
                      externalService: 'Microsoft',
                      idProviderName: idProvider.name,
                    }
                  )}
                </Typography>
              </LoginButton>
            ))}
          </>
        )}
      </LoginButtonArea>
    </LoginContainer>
  )
}
