import { Avatar, Box, Typography } from '@mui/material'
import styled from 'styled-components'
import { FontSize, FontWeight } from '../../../../styles/commonStyles'
import { colorPalette } from '../../../style/colorPallete'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTimerRepository } from '../../../../services/adaptors/timerRepositoryAdapter'
import { stopTimer } from '../../../../store/measuringTimer'
import { convertUnixToHMS } from '../../../../utils/time'
import TimerStopButton from '../../commons/AgGrid/components/cell/custom/timerStopButton'
import FolderIcon from '../../../../assets/folder_icon.svg'

const UPDATE_INTERVAL = 1000

const MeasuringTimerBox = styled(Box)({
  width: '310px',
  height: '55px',
  color: colorPalette.monotone[1],
  overflow: 'hidden',
})

const StopButton = styled(Typography)({
  position: 'absolute',
  marginLeft: '5px',
  marginTop: '10px',
})

const Timer = styled(Typography)({
  fontSize: FontSize.X_LARGE,
  fontWeight: FontWeight.BOLD,
  position: 'absolute',
  color: colorPalette.monotone[10],
  marginLeft: '45px',
  marginTop: '17px',
})

const StyledFolderIcon = styled(Avatar)({
  width: 18,
  height: 18,
  position: 'absolute',
  marginLeft: '126px',
  marginTop: '9px',
})

const ParentWbsItemNameText = styled(Typography)({
  fontSize: FontSize.MEDIUM,
  color: colorPalette.monotone[5],
  position: 'absolute',
  marginLeft: '149px',
  marginTop: '10px',
})

const StyledTaskIcon = styled(Avatar)({
  width: 23,
  height: 23,
  position: 'absolute',
  marginLeft: '128px',
  marginTop: '29px',
})

const TaskNameText = styled(Typography)({
  fontSize: FontSize.LARGE,
  color: colorPalette.monotone[10],
  position: 'absolute',
  marginLeft: '155px',
  marginTop: '30px',
  width: '150px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
})

interface Props {
  taskName: string
  parentWbsItemName: string
  startTime: number
  wbsItemType: string
  taskUuid: string
}

const MeasuringTimer = ({
  taskName,
  parentWbsItemName,
  startTime,
  wbsItemType,
  taskUuid,
}: Props) => {
  const [timerDiff, setTimerDiff] = useState(0)

  const updateTimerDiff = useCallback(() => {
    const nowTime = new Date().getTime()
    const diffMilliSeconds = nowTime - startTime
    if (diffMilliSeconds < 0) {
      setTimerDiff(0)
      return
    }
    setTimerDiff(diffMilliSeconds)
  }, [startTime])

  const truncatedParentWbsItemName =
    parentWbsItemName.length > 10
      ? `${parentWbsItemName.substring(0, 10)}` + '... /'
      : `${parentWbsItemName}` + ' /'

  useEffect(() => {
    const timerId = setInterval(updateTimerDiff, UPDATE_INTERVAL)
    updateTimerDiff()
    return () => clearInterval(timerId)
  }, [updateTimerDiff])

  const dispatch = useDispatch()
  const { stop } = useTimerRepository()

  const handleClick = useCallback(async () => {
    await stop(taskUuid)
    dispatch(stopTimer())
  }, [taskUuid])

  return (
    <MeasuringTimerBox>
      <StopButton>
        <TimerStopButton onClick={handleClick} iconHeight={35} iconWidth={35} />
      </StopButton>
      <Timer>{`${convertUnixToHMS(timerDiff)}`}</Timer>
      <StyledFolderIcon variant="square" src={FolderIcon} />
      <ParentWbsItemNameText>
        {truncatedParentWbsItemName}
      </ParentWbsItemNameText>
      <StyledTaskIcon variant="square" src={wbsItemType} />
      <TaskNameText>{taskName}</TaskNameText>
    </MeasuringTimerBox>
  )
}

export default MeasuringTimer
