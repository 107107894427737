import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { getSelectedNode } from '../../../containers/BulkSheetView/lib/gridApi'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import { TicketRow } from '../tickets'

export const addRowMenuItems = (
  params: GetContextMenuItemsParams,
  onClickAddRowMenu: () => void
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.insert.row',
    }),
    action: onClickAddRowMenu,
    icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
    shortcut: intl.formatMessage(
      { id: 'bulksheet.contextMenu.shortcut.ctrl.shift' },
      { shortcutKey: intl.formatMessage({ id: 'L' }) }
    ),
  }
}

export const addMultipleRowsMenuItems = (
  params: GetContextMenuItemsParams,
  onClickAddMultipleRowsMenu: () => void
) => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.insert.multipleRow',
    }),
    action: onClickAddMultipleRowsMenu,
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
  }
}

export const addDeleteMenuItems = (
  params: GetContextMenuItemsParams,
  onClickDeleteMenu: () => void
) => {
  const selectedNodes: RowNode<TicketRow>[] = getSelectedNode(params.api)
  const isActualHourRegistered = selectedNodes.some(
    (node: RowNode<TicketRow>) => {
      const row: TicketRow | undefined = node.data
      return (
        !!row?.cumulation &&
        (0 < row.cumulation.sumActualHour || 0 < (row.wbsItem?.actualHour || 0))
      )
    }
  )
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.delete' },
      { count: selectedNodes.length }
    ),
    action: onClickDeleteMenu,
    disabled: isActualHourRegistered,
    tooltip: isActualHourRegistered
      ? intl.formatMessage({
          id: 'bulksheet.contextMenu.remove.disabled.reason.actual.registerd',
        })
      : undefined,
    icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
    shortcut: intl.formatMessage({
      id: 'bulksheet.contextMenu.shortcut.row.delete',
    }),
  }
}
