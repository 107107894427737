import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import { APIResponse } from '../../../lib/commons/api'
import ViewMeta from '../../containers/meta/ViewMeta'
import {
  CellStyle,
  CellStyleFunc,
  ColDef,
  ICellEditorParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import WbsItemAPI, {
  createDeltaRequestByRow,
  createRequestByRow,
  createRowByResponse,
  WbsItemDeltaInput,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import ProjectAPI from '../../../lib/functions/project'
import store from '../../../store'
import { ColumnType, columnTypes } from '../../containers/commons/AgGrid'
import { WorkloadUnit } from '../../../lib/functions/workload'
import TicketListApi, {
  TicketCumulation,
  TicketListDeltaInput,
  TicketListDetail,
  TicketListInput,
  TicketListUpdateBatchDeltaRequest,
} from '../../../lib/functions/ticketList'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { open } from '../../router'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { dateTermToViewConfig, openProgressReport } from '../ProgressReport'
import { WbsItemIcon } from '../../components/icons/WbsItemIcon'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import { intl } from '../../../i18n'
import { dateVoService } from '../../../domain/value-object/DateVO'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class TicketListsRow extends RowData {
  wbsItem: WbsItemRow = {}
  ticketType?: string
  projectPlanCumulation?: ProjectPlanCumulation
  ticketCumulation?: TicketCumulation
}

export interface TicketListsState extends BulkSheetState {}

interface TicketListsBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    TicketListDetail,
    TicketListsRow,
    TicketListsState
  > {}

class TicketListsRowDataSpec extends RowDataSpec<
  TicketListDetail,
  TicketListsRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
        },
      },
    }
  }

  createNewRow(): TicketListsRow {
    throw new Error('Not implemented.')
  }

  overwriteRowItemsWithParents(params: {
    child: TicketListsRow
    parent: TicketListsRow
  }): TicketListsRow {
    throw new Error('overwriteRowItemsWithParents is not implemented.')
  }

  createRowByResponse(
    response: TicketListDetail,
    viewMeta: ViewMeta
  ): TicketListsRow {
    const wbsItem = createRowByResponse(response.wbsItem)
    return {
      uuid: response.uuid,
      lockVersion: response.lockVersion,
      wbsItem: wbsItem,
      ticketType: response.ticketType,
      projectPlanCumulation: response.projectPlanCumulation,
      ticketCumulation: response.ticketCumulation,
    }
  }
}

export default class TicketListsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  TicketListDetail,
  TicketListsRow,
  TicketListsState
> {
  addable = false
  draggable = false
  enableExcelExport = true
  displayNameField = 'wbsItem.displayName'
  getRowsToRemove = (
    nodes: RowNode[],
    ctx: TicketListsBulkSheetContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    let result: RowNode[] = []
    const canRemoveRow = (node: RowNode) => {
      const row: TicketListsRow = node.data
      return (
        !row.ticketCumulation?.sumActualHour &&
        !row.projectPlanCumulation?.sumActualHour
      )
    }
    nodes.forEach(node => {
      if (canRemoveRow(node)) {
        result.push(node)
      }
    })
    return { rows: result }
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.TicketListSearchColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new TicketListsRowDataSpec()
  pinnedColumns = [
    'ticketLists.action',
    'ticketLists.wbsItem.code',
    'ticketLists.ticketType',
    'ticketLists.wbsItem.status',
    'ticketLists.wbsItem.displayName',
  ]
  lockedColumns = [
    'ticketLists.action',
    'ticketLists.wbsItem.code',
    'ticketLists.ticketType',
    'ticketLists.wbsItem.status',
    'ticketLists.wbsItem.displayName',
  ]
  customColumnTypes = {
    'ticketLists.wbsItem.status': [ColumnType.wbsItemStatus],
    'ticketLists.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticketLists.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticketList.search.wbsItem.actualDate.startDate': [
      ColumnType.wbsItemActualDate,
    ],
    'ticketList.search.wbsItem.actualDate.endDate': [
      ColumnType.wbsItemActualDate,
    ],
    'ticketLists.wbsItem.priority': ['priorityColumn'],
  }
  getOpenDetailSpec = (
    row: TicketListsRow
  ): Promise<OpenDetailSpec> | undefined => {
    if (!row.ticketType) {
      throw new Error('The row has no ticketType.')
    }
    if (row.wbsItem.uuid) {
      openTicketList(row.wbsItem.uuid)
    }
    return
  }
  getUpdatedRowAncestors = async (uuid: string): Promise<TicketListDetail> => {
    return (await TicketListApi.findByUuid(uuid)).json as TicketListDetail
  }

  async getAll(state: TicketListsState): Promise<APIResponse> {
    return TicketListApi.getAll({
      projectUuid: store.getState().project.selected!,
    })
  }

  onSubmit = async (
    ctx: TicketListsBulkSheetContext,
    data: {
      added: TicketListsRow[]
      edited: {
        before: TicketListsRow
        after: TicketListsRow
      }[]
      deleted: TicketListsRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const request: TicketListUpdateBatchDeltaRequest = {
      added: data.added.map(row => {
        return this.createRequestByRow(row, viewMeta)
      }),
      edited: data.edited.map(row => {
        return this.createRequestDeltaByRow(row, viewMeta)
      }),
      deleted: data.deleted.map(row => this.createDeleteRequestByRow(row)),
    }
    return TicketListApi.updateBatchDelta(request)
  }

  updateDefaultState = async (
    s: TicketListsState,
    applicationFunctionUuid: string
  ) => {
    let pageState
    const pageStateProp = await this.getPageState(applicationFunctionUuid)
    if (pageStateProp && pageStateProp.value) {
      pageState = JSON.parse(pageStateProp.value)
    }
    return {
      ...s,
      workloadUnit: pageState ? pageState.workloadUnit : WorkloadUnit.HOUR,
    }
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  private createRequestByRow(
    row: TicketListsRow,
    viewMeta: ViewMeta
  ): TicketListInput {
    const u = row.wbsItem
    const wbsItemInput = createRequestByRow(u, viewMeta, 'ticketLists.wbsItem')
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      projectUuid: store.getState().project.selected,
      ticketType: row.ticketType,
      prevSiblingUuid: row.prevSiblingUuid,
      wbsItem: wbsItemInput,
    } as TicketListInput
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: TicketListsRow
      after: TicketListsRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  private createRequestDeltaByRow(
    edited: {
      before: TicketListsRow
      after: TicketListsRow
    },
    viewMeta: ViewMeta
  ): TicketListDeltaInput {
    const wbsItemInput = createDeltaRequestByRow(
      {
        before: edited.before.wbsItem,
        after: edited.after.wbsItem,
      },
      viewMeta,
      'ticketLists.wbsItem',
      {
        before: edited.before.extensions,
        after: edited.after.extensions,
      }
    )
    return {
      uuid: edited.after.uuid,
      prevSiblingUuid:
        edited.before.prevSiblingUuid !== edited.after.prevSiblingUuid
          ? {
              oldValue: edited.before.prevSiblingUuid,
              newValue: edited.after.prevSiblingUuid,
            }
          : undefined,
      wbsItem: wbsItemInput,
    }
  }

  private createDeleteRequestByRow = (row: TicketListsRow) => {
    return {
      wbsItemUuid: row.wbsItem.uuid!,
      wbsItemLockVersion: row.wbsItem.lockVersion!,
    }
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (field === 'ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (field === 'wbsItem.estimatedWorkload') {
      return (params: ValueGetterParams) => {
        const sumEstimatedHour =
          params.data.ticketCumulation?.sumEstimatedHour || 0
        return (
          sumEstimatedHour /
          (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
        )
      }
    }
    if (field === 'wbsItem.actualHour') {
      return (params: ValueGetterParams) => {
        const sumActualHour = params.data.ticketCumulation?.sumActualHour || 0
        return (
          sumActualHour /
          (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
        )
      }
    }
    return undefined
  }

  getCellEditorParams = (field): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        refreshFieldNames: ['wbsItem.estimatedWorkload'],
      }
    }
    if (field === 'wbsItem.estimatedStoryPoint') {
      return {
        wbsItemTypeFieldName: 'wbsItem.type',
        wbsItemStatusFieldName: 'wbsItem.status',
        storyPointWorkloadFieldName: 'wbsItem.estimatedStoryPoint',
      }
    }
    if (field === 'wbsItem.scheduledDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.scheduledDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.actualDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.actualDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        countAll: (node: any) => node.data.ticketCumulation.countTicket,
        countDone: (node: any) => {
          if (!node.data.ticketCumulation) {
            return 0
          }
          return node.data.ticketCumulation.countTicketDone
        },
      }
    }
    return undefined
  }

  getDetailColumnCellRendererParams = () => {
    return {
      icon: (
        <WbsItemIcon
          type={store.getState().project.wbsItemTypes.deliverableList}
        />
      ),
      iconTooltip: intl.formatMessage({ id: 'openTicketList' }),
      onClickProgressReportIcon: (row: TicketListsRow) => {
        openProgressReport(
          store.getState().project.selected!,
          { ticketListUuid: row.uuid },
          dateTermToViewConfig(row.wbsItem.scheduledDate)
        )
      },
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (['wbsItem.priority'].includes(field)) {
      return 65
    }
    if (
      [
        'wbsItem.estimatedStoryPoint',
        'wbsItem.estimatedWorkload',
        'wbsItem.actualHour',
      ].includes(field)
    ) {
      return 80
    }
    if (field === 'wbsItem.code') {
      return 100
    }
    if (field === 'wbsItem.status') {
      return 150
    }
    if (field === 'action') {
      return 65
    }
    return undefined
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getDefaultContext = (): any => ({
    workloadUnit: WorkloadUnit.HOUR,
  })
}

export const openTicketList = async (wbsItemUuid: string, event?: any) => {
  const [ticketListUuid, wbsItem] = await Promise.all([
    TicketListApi.getUuidByWbsItemUuid(wbsItemUuid),
    WbsItemAPI.getByUuid(wbsItemUuid),
  ])
  const project = await ProjectAPI.getDetail({ uuid: wbsItem.projectUuid })
  open(
    `/ticketsNew/${project.json.code}?ticketList=${ticketListUuid.json}`,
    event,
    undefined,
    true
  )
}
