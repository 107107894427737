import {
  Avatar,
  Box,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  styled,
  Typography,
  Popper,
} from '@mui/material'
import { format } from 'date-fns'
import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { NewWbsItemRow } from '../../pages/ProjectPlanNew/projectPlanNew'
import {
  BorderColor,
  FontWeight,
  TextColor,
} from '../../../styles/commonStyles'
import { TaskTimerDailySummary } from '../../pages/MyWbsItems/myWbsItems'
import { intl } from '../../../i18n'
import {
  convertDecimalHourToHM,
  convertDecimalHourToHMS,
} from '../../../utils/time'
import DateVO from '../../../vo/DateVO'
import { useMemo } from 'react'

const Header = styled(Box)({
  height: '32px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: TextColor.WHITE,
  backgroundColor: '#888888',
  borderRadius: '5px 5px 0 0',
})
const HeaderDate = styled(Typography)({
  marginLeft: '10px',
  fontSize: '12px',
})
const TaskName = styled(List)({
  padding: '0 0 0 10px',
  height: '30px',
  overflowX: 'hidden',
  overflowY: 'scroll',
  boxSizing: 'border-box',
}) as typeof List
const TimerActualWorkRow = styled(ListItem)({
  height: '30px',
  padding: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const TimerList = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  margin: '0 20px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '32px',
  borderBottom: `1px solid ${BorderColor.GREY}`,
})
const StartTime = styled(Typography)({
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  marginRight: 'auto',
})
const Endtime = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  marginRight: 'auto',
})
const ElapsedTime = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  display: 'flex',
})
const TotalRow = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '32px',
})
const Total = styled(Typography)({
  display: 'flex',
  fontWeight: FontWeight.BOLD,
  justifyContent: 'flex-end',
})
const PaperStyle = styled(Paper)({
  width: '360px',
  maxHeight: '380px',
  overflow: 'auto',
  borderRadius: '5px',
  fontSize: '11px',
  zIndex: 10,
  boxSizing: 'border-box',
})
const TaskNameBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '190px',
  height: '100%',
  alignItems: 'center',
})
const StyledAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '0 5px 0 0',
})
const TaskNameText = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
})

interface Props {
  timer?: TaskTimerDailySummary
  anchorEl?: EventTarget
  wbsItem?: WbsItemRow | NewWbsItemRow
  onClose: () => void
  targetDate?: string
}

export const TimerListPopper = ({
  onClose,
  timer,
  wbsItem,
  anchorEl,
  targetDate,
}: Props) => {
  const date = useMemo(() => new DateVO(targetDate).format(), [targetDate])

  if (!timer || !wbsItem || !anchorEl || timer.histories.length === 0) {
    return null
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl as HTMLElement}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [-320, 0],
          },
        },
      ]}
      style={{ zIndex: 1300 }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <PaperStyle>
          <Header>
            <HeaderDate>{date}</HeaderDate>
          </Header>
          <TaskName>
            <TimerActualWorkRow>
              {wbsItem?.displayName && (
                <TaskNameBox>
                  <StyledAvatar
                    variant="circular"
                    src={wbsItem.wbsItemType?.iconUrl}
                  />
                  <TaskNameText>{wbsItem?.displayName}</TaskNameText>
                </TaskNameBox>
              )}
            </TimerActualWorkRow>
          </TaskName>
          {timer.histories.map(timerRecord => (
            <TimerList key={timerRecord.timerStartDateTime}>
              <StartTime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.startTime',
                })}: ${format(
                  new Date(timerRecord.timerStartDateTime),
                  'HH:mm:ss'
                )}`}
              </StartTime>
              <Endtime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.endTime',
                })}: ${format(
                  new Date(timerRecord.timerEndDateTime),
                  'HH:mm:ss'
                )}`}
              </Endtime>
              <ElapsedTime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.workingHours',
                })}: ${convertDecimalHourToHMS(timerRecord.timerElapsedTime)}`}
              </ElapsedTime>
            </TimerList>
          ))}
          <TotalRow>
            <Total>{`Total: ${convertDecimalHourToHM(
              timer?.elapsedTimeSum
            )}`}</Total>
          </TotalRow>
        </PaperStyle>
      </ClickAwayListener>
    </Popper>
  )
}
