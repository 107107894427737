import {
  Box,
  Button as MuiButton,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  ClickAwayListener,
} from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'

import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { MyWbsItemsTaskTabSearchCondition } from '../../hooks/useSearchCondition'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'
import { SelectOption } from '../../../../model/selectOption'
import { WbsItemStatus } from '../../../../../domain/entity/WbsItemEntity'
import { colorPalette } from '../../../../style/colorPallete'
import { getWbsItemStatusDeepColorCode } from '../../../../../lib/functions/wbsItem'
import WbsItemSearchCheckboxSelectConditionPopper from '../../../WbsItemSearch/WbsItemSearchTypesCheckboxSelectCondition'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { DateTermVO } from '../../../../../domain/value-object/DateTermVO'
import { DateVO } from '../../../../../domain/value-object/DateVO'
import DateInput from '../../../../components/editors/input/DateInput'
import { useOnSingleValueChangedGenerator } from '../../hooks/useOnSingleValueChangedGenerator'

type Props = {
  open: boolean
  onClose: () => void
  searchCondition: MyWbsItemsTaskTabSearchCondition
  onChangeCondition: (
    updated: Partial<MyWbsItemsTaskTabSearchCondition>
  ) => void
  onEnter: (() => void) | undefined
  resetCondition: () => void
  search: () => void
  filteringInput: string
  setFilteringInput: (newValue: string) => void
}
const MyWbsItemsTaskTabSearchConditionDialog = ({
  open,
  onClose,
  onChangeCondition,
  searchCondition,
  resetCondition,
  search,
}: Props) => {
  const { onChangeValueGenerator } =
    useOnSingleValueChangedGenerator(onChangeCondition)

  const statusOptions = useMemo(() => {
    return Object.keys(WbsItemStatus).map(k => ({
      name: k,
      value: k,
    }))
  }, [])

  const normalizeValue = (
    newValue: string | string[] | undefined
  ): string[] | undefined => {
    if (Array.isArray(newValue)) return newValue
    if (newValue) return [newValue]
    return undefined
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiPaper-root': { maxWidth: '800px' },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            height: '350px',
            gap: '10px',
          }}
        >
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.status}
            options={statusOptions}
            onChange={(newValue: string | string[] | undefined) => {
              const value = normalizeValue(newValue)
              onChangeValueGenerator('status')(
                value?.map(v => v as WbsItemStatus)
              )
            }}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.status',
            })}
          />
          <SearchConditionDate
            value={searchCondition.scheduledStartDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledStartDate',
            })}
            onChange={onChangeValueGenerator('scheduledStartDate')}
          />
          <SearchConditionDate
            value={searchCondition.scheduledEndDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledEndDate',
            })}
            onChange={onChangeValueGenerator('scheduledEndDate')}
          />
          <SearchConditionDate
            value={searchCondition.actualStartDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualStartDate',
            })}
            onChange={onChangeValueGenerator('actualStartDate')}
          />
          <SearchConditionDate
            value={searchCondition.actualEndDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualEndDate',
            })}
            onChange={onChangeValueGenerator('actualEndDate')}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '20px 10px', justifyContent: 'center' }}>
        <Button
          size="m"
          colorPattern="monotone"
          variant="outlined"
          sx={{
            cursor: 'pointer',
          }}
          onClick={resetCondition}
        >
          <ClearRoundedIcon />
          <Typography>
            {intl.formatMessage({ id: 'wbsItemSearchFilterPanel.reset' })}
          </Typography>
        </Button>
        <Button
          size="m"
          colorPattern="skyBlue"
          variant="filled"
          onClick={search}
          sx={{ cursor: 'pointer' }}
        >
          <Typography>
            {intl.formatMessage({ id: 'wbsItemSearchFilterPanel.search' })}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type SelectedConditionProps = {
  options: SelectOption[]
  value: string[] | undefined
  onChange: (newValue: string[] | string | undefined) => void
}

const SelectedConditionItem = ({
  options,
  value,
  onChange,
}: SelectedConditionProps) => {
  const onChangeValue = useCallback(
    (v: any) => {
      const newValue = [...(value || [])]
      if (newValue.includes(v)) {
        newValue.splice((value || []).indexOf(v), 1)
      } else {
        newValue.push(v)
      }
      onChangeNewValue(newValue)
    },
    [value]
  )

  const onChangeNewValue = useCallback((newValueStr: string[] | undefined) => {
    if (!newValueStr) {
      onChange && onChange(undefined)
      return
    }

    onChange && onChange(newValueStr)
  }, [])

  const clear = useCallback(() => {
    if (onChange) {
      onChange('')
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {options.map(option => (
        <Box
          key={option.value}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {(value || []).some(v => v === option.value) ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  padding: '3px 5px',
                  borderRadius: '15px',
                  margin: '3px 5px',
                  flexWrap: 'wrap',
                  background:
                    getWbsItemStatusDeepColorCode(
                      option.value as WbsItemStatus
                    ) || colorPalette.monotone[1],
                }}
              >
                <img src={option.iconUrl} />
                <Typography
                  key={option.value}
                  sx={{
                    margin: 'auto 5px',
                    color: colorPalette.monotone[5],
                  }}
                >
                  {option.name}
                </Typography>
                <Button
                  colorPattern="monotone"
                  sx={{
                    border: 'none',
                    background: colorPalette.monotone[0],
                    width: '10px',
                    height: '24px',
                    borderRadius: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => onChangeValue(option.value)}
                >
                  <ClearRoundedIcon
                    sx={{
                      color: colorPalette.monotone[4],
                      height: '13px',
                      width: '13px',
                    }}
                  />
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      ))}
      <MuiButton
        sx={{ minWidth: '20px', margin: '3px auto 3px 0' }}
        onClick={clear}
      >
        <ClearRoundedIcon sx={{ color: colorPalette.monotone[4] }} />
      </MuiButton>
    </Box>
  )
}

type SearchConditionSelectBoxCheckmarksProps = {
  value: string[] | undefined
  options: SelectOption[]
  title: string
  onChange: (newValue: string[] | string | undefined) => void
}

const SearchConditionSelectBoxCheckmarks = ({
  value,
  options,
  title,
  onChange,
}: SearchConditionSelectBoxCheckmarksProps) => {
  const [openPullDown, setOpenPullDown] = useState<boolean>(false)
  const [pullDownAnchorEl, setPullDownAnchorEl] = useState<Element>()
  const openPullDownRef = useRef<HTMLSpanElement>(null)
  const [openExpandIconPullDown, setOpenExpandIconPullDown] =
    useState<boolean>(false)
  const [expandIconAnchorEl, setExpandIconAnchorEl] = useState<Element>()
  const openExpandIconTypeRef = useRef<HTMLSpanElement>(null)

  const handlePullDownClose = () => {
    setPullDownAnchorEl(undefined)
    setOpenPullDown(false)
  }

  const handleExpandIconPullDownClose = () => {
    setExpandIconAnchorEl(undefined)
    setOpenExpandIconPullDown(false)
  }

  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <ClickAwayListener onClickAway={handlePullDownClose}>
        <span
          ref={openPullDownRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '416px',
          }}
        >
          {value && value.length >= 1 ? (
            <SelectedConditionItem
              options={options}
              value={value}
              onChange={onChange}
            />
          ) : (
            <Button
              colorPattern="monotone"
              size="s"
              sx={{
                borderRadius: '15px',
                border: `1px dashed ${colorPalette.monotone[2]}`,
                background: colorPalette.monotone[0],
                color: colorPalette.monotone[3],
                margin: '4px auto 4px 15px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (openPullDown) {
                  setPullDownAnchorEl(undefined)
                  setOpenPullDown(false)
                } else {
                  setPullDownAnchorEl(openPullDownRef.current || undefined)
                  setOpenPullDown(true)
                }
              }}
            >
              <Typography>
                {intl.formatMessage({
                  id: 'wbsItemSearchFilterPanel.click.select',
                })}
              </Typography>
              <AddRoundedIcon sx={{ height: '12px', width: '12px' }} />
            </Button>
          )}
          {openPullDown ? (
            <WbsItemSearchCheckboxSelectConditionPopper
              open={openPullDown}
              anchorEl={pullDownAnchorEl}
              options={options}
              value={value}
              onChange={onChange}
              offset={20}
              placement={'bottom-start'}
            />
          ) : null}
        </span>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleExpandIconPullDownClose}>
        <span
          ref={openExpandIconTypeRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '450px',
            margin: 'auto 0 auto auto',
          }}
        >
          <MuiButton
            sx={{ minWidth: '20px', margin: '0 0 0 auto' }}
            onClick={() => {
              if (openExpandIconPullDown) {
                setExpandIconAnchorEl(undefined)
                setOpenExpandIconPullDown(false)
              } else {
                setExpandIconAnchorEl(
                  openExpandIconTypeRef.current || undefined
                )
                setOpenExpandIconPullDown(true)
              }
            }}
          >
            {openExpandIconPullDown ? (
              <ExpandLess sx={{ color: colorPalette.monotone[4] }} />
            ) : (
              <ExpandMore sx={{ color: colorPalette.monotone[4] }} />
            )}
          </MuiButton>
          {openExpandIconPullDown ? (
            <WbsItemSearchCheckboxSelectConditionPopper
              open={openExpandIconPullDown}
              anchorEl={expandIconAnchorEl}
              options={options}
              value={value}
              onChange={onChange}
              offset={18}
              placement={'bottom-end'}
            />
          ) : null}
        </span>
      </ClickAwayListener>
    </Box>
  )
}

type SearchConditionDateProps = {
  value: DateTermVO | undefined
  title: string
  onChange: (value: DateTermVO | undefined) => void
}

const SearchConditionDate = ({
  value,
  title,
  onChange,
}: SearchConditionDateProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <SearchConditionDateCell value={value} onChange={onChange} />
    </Box>
  )
}

type SearchConditionDateCellProps = {
  value: DateTermVO | undefined
  onChange: (value: DateTermVO | undefined) => void
}

const SearchConditionDateCell = ({
  value,
  onChange,
}: SearchConditionDateCellProps) => {
  const clearFromDate = useCallback(() => {
    if (!value?.to) {
      onChange(undefined)
      return
    }
    const newValue = {
      ...value,
      from: undefined,
    }
    onChange(newValue)
  }, [onChange, value])

  const clearToDate = useCallback(() => {
    if (!value?.from) {
      onChange(undefined)
      return
    }
    const newValue = {
      ...value,
      to: undefined,
    }
    onChange(newValue)
  }, [onChange, value])

  const clear = useCallback(() => {
    onChange(undefined)
  }, [onChange])

  const onChangeFrom = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.to) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        from: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )
  const onChangeTo = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.from) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        to: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )

  return (
    <Box
      sx={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box sx={{ maxWidth: '110px' }}>
        <DateInput
          value={value && value.from}
          onChange={onChangeFrom}
          isWbsSearchCondition={false}
          placeholder={'YYYY/MM/DD'}
        />
      </Box>
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearFromDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <Box sx={{ maxWidth: '110px' }}>
        <DateInput
          value={value && value.to}
          onChange={onChangeTo}
          isWbsSearchCondition={false}
          placeholder={'YYYY/MM/DD'}
        />
      </Box>
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearToDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
    </Box>
  )
}

export default MyWbsItemsTaskTabSearchConditionDialog
