import { useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions, DialogTitle, styled, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import TimerMeasuringTaskList from '../tables/TimerMeasuringTaskList'
import { FontSize } from '../../../styles/commonStyles'
import { formatDate } from '../../../utils/date'
import { colorPalette } from '../../style/colorPallete'
import { DialogContentText } from '.'
import { useCallback, useEffect, useState } from 'react'
import { TimerMeasuringTaskOfUser } from '../../../domain/entity/TimerEntity'
import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../../store'
import { useTimerRepository } from '../../../services/adaptors/timerRepositoryAdapter'
import { stopTimer } from '../../../store/measuringTimer'

const INTERVAL_FOR_API_REQUEST = 30000

const StyledTrackTimerTitle = styled(DialogTitle)({
  fontSize: FontSize.LARGE,
  color: colorPalette.monotone[10],
  fontWeight: 'bold',
  marginBottom: '10px',
})

const StyledTrackTimerHeader = styled(DialogContentText)({
  width: '755px',
  marginTop: '-15px',
  marginLeft: '22px',
  display: 'flex',
  fontSize: FontSize.MEDIUM,
  backgroundColor: colorPalette.monotone[1],
})

const StyledHeaderUserNameCell = styled(Typography)({
  padding: '10px',
  fontWeight: 'bold',
})

const StyledHeaderTaskNameCell = styled(Typography)({
  padding: '10px',
  marginLeft: '107px',
  fontWeight: 'bold',
})

const StyledHeaderStartTimeCell = styled(Typography)({
  padding: '10px',
  marginLeft: '147px',
  fontWeight: 'bold',
})

const StyledHeaderElapsedTimeCell = styled(Typography)({
  padding: '10px',
  marginLeft: '107px',
  fontWeight: 'bold',
})

const StyledHeaderStopButtonCell = styled(Typography)({
  padding: '10px',
  marginLeft: '28px',
  fontWeight: 'bold',
})

interface Props {
  onCloseDialog: () => void
}

const TimerMeasuringTaskListDialog = ({ onCloseDialog }: Props) => {
  const intl = useIntl()
  const today = formatDate(new Date())
  const [timerMeasuringTaskListData, setTimerMeasuringTaskListData] = useState<
    TimerMeasuringTaskOfUser[]
  >([])

  const { projectUuid, me } = useSelector<
    AllState,
    { projectUuid: string | undefined; me: string | undefined }
  >(state => ({
    projectUuid: state.project.selected,
    me: state.user.user?.uuid,
  }))
  const { stopMemberTimer, getTimerMeasuringTaskList } = useTimerRepository()
  const dispatch = useDispatch()
  const fetchTimerMeasuringTasks = useCallback(async () => {
    if (!projectUuid) return
    const newTimerMeasuringTaskList = await getTimerMeasuringTaskList(
      projectUuid
    )
    setTimerMeasuringTaskListData(newTimerMeasuringTaskList)
  }, [projectUuid, getTimerMeasuringTaskList])

  const onClickStopButton = useCallback(
    async (userUuid: string, taskUuid: string) => {
      try {
        await stopMemberTimer(userUuid, taskUuid)
        userUuid === me && dispatch(stopTimer())
      } finally {
        fetchTimerMeasuringTasks()
      }
    },
    [stopMemberTimer, me, dispatch, fetchTimerMeasuringTasks]
  )

  useEffect(() => {
    if (projectUuid) {
      fetchTimerMeasuringTasks()
      const intervalId = setInterval(
        fetchTimerMeasuringTasks,
        INTERVAL_FOR_API_REQUEST
      )
      return () => clearInterval(intervalId)
    }
  }, [projectUuid, fetchTimerMeasuringTasks])

  return (
    <Dialog open={true} onClose={onCloseDialog} maxWidth={'xl'}>
      <StyledTrackTimerTitle>{today}</StyledTrackTimerTitle>
      {timerMeasuringTaskListData.length > 0 && (
        <StyledTrackTimerHeader>
          <StyledHeaderUserNameCell>
            {intl.formatMessage({
              id: 'timerMeasuringList.header.user',
            })}
          </StyledHeaderUserNameCell>
          <StyledHeaderTaskNameCell>
            {intl.formatMessage({
              id: 'timerMeasuringList.header.task',
            })}
          </StyledHeaderTaskNameCell>
          <StyledHeaderStartTimeCell>
            {intl.formatMessage({
              id: 'timerMeasuringList.header.start',
            })}
          </StyledHeaderStartTimeCell>
          <StyledHeaderElapsedTimeCell>
            {intl.formatMessage({
              id: 'timerMeasuringList.header.elapsed',
            })}
          </StyledHeaderElapsedTimeCell>
          <StyledHeaderStopButtonCell>
            {intl.formatMessage({
              id: 'timerMeasuringList.header.stopbutton',
            })}
          </StyledHeaderStopButtonCell>
        </StyledTrackTimerHeader>
      )}
      <DialogContent sx={{ width: '800px' }}>
        <TimerMeasuringTaskList
          timerMeasuringTaskListData={timerMeasuringTaskListData}
          onClickStopButton={onClickStopButton}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="primary">
          {intl.formatMessage({ id: 'dialog.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimerMeasuringTaskListDialog
