import { AggregateField } from './../../../../../domain/entity/WbsItemEntity/index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  AggregateColumn,
  getWbsProgressLog,
  WbsProgressLogPerDim,
  WbsProgressLogResponse,
} from '../../../../../lib/functions/wbsProgressLog'
import { TimeGrain } from '../../../../../lib/functions/report'
import { WbsItemStatus } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  AggregateTarget,
  WbsItemType,
} from '../../../../../domain/entity/WbsItemEntity'
import DateVO from '../../../../../vo/DateVO'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { intl } from '../../../../../i18n'
import { AggregateRoot } from '../../hooks/useMasterScheduleParameter'
import { DateTickIntervalType } from '../../hooks/chart/useXTicks'
import * as d3 from 'd3'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { useWorkloadUnit } from '../../../../hooks/useWorkloadUnit'

type SprintReportDataFetchingStatus =
  | 'BEFORE_FETCH'
  | 'CONFIRM_FETCH'
  | 'FETCHING'
  | 'FETCHED'

export type SprintBarSource = {
  date: Date
  value: { [key: string]: number }
}

export type SprintBarItem = {
  date: Date
  total: number
  [WbsItemStatus.TODO]?: number
  [WbsItemStatus.DOING]?: number
  [WbsItemStatus.REVIEW]?: number
  [WbsItemStatus.DONE]?: number
}

export const useSprintReportData = (
  project: ProjectDetail,
  xTicks: Date[],
  root: AggregateRoot | undefined,
  aggregateTarget: AggregateTarget,
  aggregateField: AggregateField,
  workloadUnit: WorkloadUnit,
  teamUuid?: string
): {
  data: SprintBarItem[] | undefined
  fetchingStatus: SprintReportDataFetchingStatus
  confirmFetch: () => void
} => {
  const [fetchingStatus, setFetchingStatus] =
    useState<SprintReportDataFetchingStatus>('BEFORE_FETCH')
  // Data
  const [progressLog, setProgressLog] = useState<WbsProgressLogResponse>([])
  const [data, setData] = useState<SprintBarItem[]>()

  const endFetching = useCallback(
    () =>
      _.debounce(() => {
        setFetchingStatus('FETCHED')
      }, 300),
    []
  )

  const fetch = useCallback(
    async (
      project: ProjectDetail,
      root: AggregateRoot | undefined,
      teamUuid: string | undefined
    ) => {
      setFetchingStatus('FETCHING')
      const response = await getWbsProgressLog({
        projectUuid: project.uuid,
        rootWbsItemUuid: root?.wbsItemUuid,
        teamUuid,
        aggregate: false,
        wbsItemStatusList: [
          WbsItemStatus.TODO,
          WbsItemStatus.DOING,
          WbsItemStatus.REVIEW,
          WbsItemStatus.DONE,
        ],
        aggregateBy: [
          {
            wbsItemType: WbsItemType.TASK,
            aggregateColumn: [AggregateColumn.ACTUAL_HOUR_RECORDED_AT],
          },
          {
            wbsItemType: WbsItemType.DELIVERABLE,
            aggregateColumn: [AggregateColumn.ACTUAL_HOUR_RECORDED_AT],
          },
        ],
        timeGrain: TimeGrain.DAY,
        time: {
          from: new DateVO(
            root?.scheduledDate.startDate || project.scheduledDate.startDate
          ).toNumberValue(),
          to: new DateVO(
            root?.scheduledDate.endDate || project.scheduledDate.endDate
          ).toNumberValue(),
        },
      })
      const progressLog = response.json as WbsProgressLogResponse
      setProgressLog(progressLog)
      setFetchingStatus('FETCHED')
    },
    []
  )

  const fetchingDataSize = useMemo(() => {
    return d3.timeDay.count(xTicks[0], xTicks[xTicks.length - 1])
  }, [xTicks])

  useEffect(() => {
    if (fetchingDataSize > 100) {
      setFetchingStatus('CONFIRM_FETCH')
      return
    }
    fetch(project, root, teamUuid)
  }, [project, root, teamUuid, fetch, fetchingDataSize])

  const confirmFetch = useCallback(() => {
    fetch(project, root, teamUuid)
  }, [project, root, teamUuid, fetch])

  const { convertWorkloadFromHourToSelectedUnit } =
    useWorkloadUnit(workloadUnit)
  useEffect(() => {
    const mapProgressLogToSource = (d: WbsProgressLogPerDim) => {
      const date = new Date(d.dimTime)
      switch (aggregateField) {
        case AggregateField.WBS_ITEM_WORKLOAD:
          const estimatedBy = {
            [WbsItemStatus.TODO]: convertWorkloadFromHourToSelectedUnit(
              d.estimatedBy[WbsItemStatus.TODO] || 0
            ),
            [WbsItemStatus.DOING]: convertWorkloadFromHourToSelectedUnit(
              d.estimatedBy[WbsItemStatus.DOING] || 0
            ),
            [WbsItemStatus.REVIEW]: convertWorkloadFromHourToSelectedUnit(
              d.estimatedBy[WbsItemStatus.REVIEW] || 0
            ),
            [WbsItemStatus.DONE]: convertWorkloadFromHourToSelectedUnit(
              d.estimatedBy[WbsItemStatus.DONE] || 0
            ),
          }
          return {
            date,
            value: estimatedBy,
          } as SprintBarSource
        case AggregateField.WBS_ITEM_COUNT:
          return {
            date,
            value: d.countBy,
          } as SprintBarSource
      }
    }
    const source: SprintBarSource[] = progressLog
      .filter(l => l.key.type.value === aggregateTarget)
      .flatMap(l =>
        l.data.filter(d => 0 < d.dimTime).flatMap(mapProgressLogToSource)
      )
    if (source.length === 0) {
      return
    }

    const result: SprintBarItem[] = []
    for (let x of xTicks) {
      const beforeOrEqual = source.filter(v => v.date <= x)
      if (beforeOrEqual.length === 0) continue
      const latest = beforeOrEqual[beforeOrEqual.length - 1]
      result.push({
        date: x,
        total:
          (latest.value[WbsItemStatus.TODO] || 0) +
          (latest.value[WbsItemStatus.DOING] || 0) +
          (latest.value[WbsItemStatus.REVIEW] || 0) +
          (latest.value[WbsItemStatus.DONE] || 0),
        [WbsItemStatus.TODO]: latest.value[WbsItemStatus.TODO] || 0,
        [WbsItemStatus.DOING]: latest.value[WbsItemStatus.DOING] || 0,
        [WbsItemStatus.REVIEW]: latest.value[WbsItemStatus.REVIEW] || 0,
        [WbsItemStatus.DONE]: latest.value[WbsItemStatus.DONE] || 0,
      })
    }
    setData(result)
  }, [
    progressLog,
    xTicks,
    aggregateTarget,
    aggregateField,
    convertWorkloadFromHourToSelectedUnit,
  ])

  return {
    data,
    fetchingStatus,
    confirmFetch,
  }
}
