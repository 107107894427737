import API, { APIResponse } from '../commons/api'
import { WbsItemDetail, WbsItemRow } from './wbsItem'
import {
  ProjectPlanCumulation,
  ProjectPlanUpdateBatchDeltaRequest,
} from './projectPlan'
import { ActualWorkDetail } from './taskActualWork'
import { Tree } from '../commons/tree'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'

export interface GetSprintBacklogResponse {
  sprintBacklogItems: SprintBacklogItem[]
}

export interface SprintBacklogItem extends Tree<SprintBacklogItem> {
  type: WbsItemType
  ticketType: string
  projectPlanPath: string
  productBacklogItem: boolean
  productBacklogItemUuid: string
  wbsItem: WbsItemDetail
  cumulation: ProjectPlanCumulation
  actualWorks?: ActualWorkDetail[]
}

export interface GetSprintBacklogProps {
  teamUuid: string
}

export interface UpdateBatchSprintPlanProps {
  added: {
    taskUuid: string
    taskLockVersion?: number
    sprintUuid: string
  }[]
  deleted: {
    taskUuid: string
    taskLockVersion?: number
    sprintUuid: string
  }[]
}

export interface UpdateBatchSprintBacklogProps {
  sprintBacklogItems: ProjectPlanUpdateBatchDeltaRequest[]
  sprintPlans: UpdateBatchSprintPlanProps
}

export const createSprintBacklogUpdateBatchRequest = (
  tasks: WbsItemRow[],
  taskLockVersionMap?: { [uuid: string]: number },
  tasksBefore?: WbsItemRow[]
) => {
  const batchRequest: UpdateBatchSprintBacklogProps = {
    sprintBacklogItems: [],
    sprintPlans: {
      added: [],
      deleted: [],
    },
  }
  tasks.forEach(task => {
    const updatedTaskLockVersion = taskLockVersionMap?.[task.uuid!]
    const sprint = task.sprint || { uuid: undefined }
    const taskBefore = tasksBefore?.find(t => t.uuid === task.uuid)
    const sprintBefore = taskBefore?.sprint || { uuid: undefined }
    if (sprint.uuid) {
      batchRequest.sprintPlans.added.push({
        taskUuid: task.uuid!,
        taskLockVersion: updatedTaskLockVersion ?? task.lockVersion,
        sprintUuid: sprint.uuid,
      })
    } else if (!sprint.uuid && sprintBefore.uuid) {
      // currentSprint is deleted
      batchRequest.sprintPlans.deleted.push({
        taskUuid: task.uuid!,
        taskLockVersion: updatedTaskLockVersion ?? task.lockVersion,
        sprintUuid: sprintBefore.uuid,
      })
    }
  })
  return batchRequest
}

class SprintBacklogApi {
  updateBatchSprintBacklog(
    requestBody: UpdateBatchSprintBacklogProps
  ): Promise<APIResponse> {
    if (
      requestBody.sprintPlans.added.length === 0 &&
      requestBody.sprintPlans.deleted.length === 0
    ) {
      return Promise.resolve(new Response())
    }
    return API.functional.request(
      'POST',
      '/api/v1/projects/scrums/sprint_backlogs/batch',
      requestBody
    )
  }
}

export default new SprintBacklogApi()
