import { CSSProperties } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import * as WorkReportAPI from '../../../lib/functions/workReport'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import { CellClickedEvent, ColDef, ValueGetterParams } from 'ag-grid-community'
import {
  DateTerm,
  formatDate,
  formatDateWithDay,
  formatMonth,
  isCurrentDate,
  isSaturday,
} from '../../../utils/date'
import { TeamProps } from '../../../lib/functions/team'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import store from '../../../store'
import { UserDetail } from '../../../lib/functions/user'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendar,
} from '../../../lib/functions/organizationWorkingDayCalendar'
import { open } from '../../router'
import { intl } from '../../../i18n'
import TaskActualWorkTaskDialog from '../../components/dialogs/TaskActualWorkTaskDialog'
import DateVO from '../../../vo/DateVO'
import {
  BackgroundColor,
  Color,
  FontSize,
  FontWeight,
  IconColor,
  TextColor,
} from '../../../styles/commonStyles'
import { maxWidth, minWidth, styled, width } from '@mui/system'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { CURRENT_MONTH_BACKGROUND_COLOR } from '../../containers/commons/AgGrid'
import { TimerWorkReportPopper } from '../../components/poppers/TimerWorkReportPopper'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface WorkReportState extends BulkSheetState {
  dateTerm: {
    startDate?: string
    endDate?: string
  }
  isOpen: boolean
}

export class WorkReportRow extends RowData {
  user?: UserDetail
  team?: TeamProps
  status?: WbsItemStatus
  deliverableCode?: string
  deliverableName?: string
  taskCode?: string
  taskName?: string
  estimatedHour?: number
  totalActualHour?: number
  totalScheduledHour?: number
  work?: {
    date: string
    actualHour: number
    dayTotalElapsedTime: number
    scheduledHour: number
    taskCodeList: string
    actualWorks: {
      taskCode: string
      taskName: string
      taskStatus: string
      deliverableName: string
      hour: number
      ticketType: string
      wbsItemType?: WbsItemTypeVO
      baseWbsItemType?: WbsItemTypeVO
      path: string
    }[]
    timerHistorySumByTasks: {
      taskName: string
      iconUrl: string
      timerHistories: {
        taskUuid: string
        userUuid: string
        timerDate: string
        timerStartDateTime: number
        timerEndDateTime: number
        timerElapsedTime: number
      }[]
      taskTotalElapsedTime: number
    }[]
  }[]
  isTotal?: boolean
}

export interface WorkReportDetailForExport {
  userName: string
  date: string
  ticketType: string
  taskCode: string
  taskName: string
  path: string
  hour: number
}

interface WorkReportBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    WorkReportAPI.WorkReport,
    WorkReportRow,
    WorkReportState
  > {}

class WorkReportRowDataSpec extends RowDataSpec<
  WorkReportAPI.WorkReport,
  WorkReportRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      hourColumn: {
        cellStyle: {
          justifyContent: 'flex-end',
        },
      },
      totalColumn: {
        onCellClicked: async (event: CellClickedEvent) => {
          if (event.node.data.isTotal) {
            return
          }
          let workCodeList = new Set<number>()
          event.node.data.work.forEach(work => {
            work.actualWorks.forEach(actualWork => {
              workCodeList.add(actualWork.taskCode)
            })
          })
          if (workCodeList) {
            const workCode = Array.from(workCodeList).join(',')
            const projectCode = window.location.pathname.split('/')[2]
            open(
              `/actualWork/${projectCode}?status=all&code=${workCode}`,
              event.event,
              undefined,
              true
            )
          }
        },
        cellRenderer: (params: any) => {
          const data = params.data
          const actualHour = params.value
          let scheduledHour = 0
          if (data.isTotal) {
            params.api &&
              params.api.forEachNode(rowNode => {
                if (!rowNode.data.isTotal) {
                  scheduledHour += Number(rowNode.data.totalScheduledHour)
                }
              })
          } else {
            scheduledHour = data ? data.totalScheduledHour : 0
          }
          return (
            <div
              className="sevend-ag-cell"
              style={{
                cursor: !data.isTotal ? 'pointer' : undefined,
              }}
            >
              <span
                style={{
                  fontWeight: `${FontWeight.BOLD}`,
                  color: !data.isTotal ? Color.MAIN : undefined,
                }}
              >
                {actualHour}
              </span>
              <span
                style={{
                  margin: '0 3px',
                }}
              >
                /
              </span>
              <span>{`${numeral(scheduledHour).format('0,0.00')}`}</span>
            </div>
          )
        },
      },
    }
  }

  createNewRow(): WorkReportRow {
    return new WorkReportRow(generateUuid())
  }

  overwriteRowItemsWithParents(params: {
    child: WorkReportRow
    parent: WorkReportRow
  }): WorkReportRow {
    return params.child
  }

  createRowByResponse(response: WorkReportAPI.WorkReport): WorkReportRow {
    return {
      uuid: generateUuid(),
      user: response.user,
      team: response.team,
      totalActualHour: response.totalActualHour,
      totalScheduledHour: response.totalScheduledHour,
      work: response.work.map(v => ({
        ...v,
        actualWorks: v.actualWorks.map(w => ({
          ...w,
          wbsItemType: w.wbsItemType
            ? new WbsItemTypeVO(w.wbsItemType)
            : undefined,
          baseWbsItemType: w.baseWbsItemType
            ? new WbsItemTypeVO(w.baseWbsItemType)
            : undefined,
        })),
      })),
    }
  }
}

const WorkCell = styled('span')((props: { color?: string }) => ({
  fontSize: `${FontSize.SMALL}px`,
  fontWeight: `${FontWeight.BOLD}`,
  border: `1px solid ${IconColor.GREY}`,
  width: '16px',
  height: '16px',
  borderRadius: '8px',
  marginRight: '4px',
  lineHeight: '14px',
  paddingLeft: '1px',
  color: props.color,
}))

const RecordsWithDifferences = styled('span')({
  margin: '0 3px',
  color: Color.ALERT,
  fontSize: `${FontSize.SMALL}px`,
  fontWeight: `${FontWeight.BOLD}`,
})

const HeaderIcon = styled('span')({
  margin: '0 12px',
})

export default class WorkReportOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  WorkReportAPI.WorkReport,
  WorkReportRow,
  WorkReportState
> {
  rowHeight = ROW_HEIGHT.MEDIUM
  addable = false
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.WorkReportColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new WorkReportRowDataSpec()
  updateDefaultState = async (s: WorkReportState) => s
  pinnedColumns = [
    'project.workReport.user.code',
    'project.workReport.user',
    'project.workReport.user.division.displayName',
    'project.workReport.user.position.displayName',
    'project.workReport.team',
    'project.workReport.total',
  ]

  customColumnTypes = {
    'project.workReport.total': ['hourColumn', 'totalColumn'],
  }
  getRowStyle = (params: any) => {
    if (params.data.isTotal) {
      return {
        backgroundColor: '#F5F5F5',
      }
    }
  }

  focusColumn = (ctx: WorkReportBulkSheetContext) => {
    return moment
      .min(
        moment(ctx.state.dateTerm.endDate),
        moment().startOf('week').add(7, 'days')
      )
      .format('YYYY-MM-DD') // added 1 week to offset the scroll.
  }

  dynamicColumns = {
    'project.workReport.works': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: WorkReportBulkSheetContext
      ): Promise<ColDef[]> => {
        const start =
          ctx.state.dateTerm && ctx.state.dateTerm.startDate
            ? ctx.state.dateTerm.startDate
            : moment().startOf('month').format('YYYY-MM-DD')
        const end =
          ctx.state.dateTerm && ctx.state.dateTerm.endDate
            ? ctx.state.dateTerm.endDate
            : moment().endOf('month').format('YYYY-MM-DD')
        ctx.setState({
          dateTerm: {
            startDate: start,
            endDate: end,
          },
        })
        let dateList: string[] = []
        let currentDate = moment(start)
        let endDate = moment(end)
        while (currentDate <= endDate) {
          dateList.push(currentDate.format('YYYY-MM-DD'))
          currentDate = currentDate.add(1, 'days')
        }
        const response = await getOrganizationWorkingDayCalendar({
          startDate: new Date(start),
          endDate: new Date(end),
        })
        const workingDayCalendar = new OrganizationWorkingDayCalendar(
          response.json
        )
        const workColumns = dateList.flatMap(date => {
          let headerClass = ''
          if (workingDayCalendar.isHoliday(date)) {
            // for sunday or holiday, set color red
            headerClass = 'sevend-ag-grid-date-header-holiday'
          }
          if (isSaturday(date)) {
            // for saturday, set color blue
            headerClass = 'sevend-ag-grid-date-header-saturday'
          }
          const ActualTaskcolumn = {
            ...baseColumnDef,
            colId: date,
            headerName: formatDateWithDay(date),
            lockPosition: true,
            minWidth: 65,
            maxWidth: 65,
            editable: false,
            field: date,
            resizable: false,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) {
                let totalActualHour = 0
                params.api &&
                  params.api.forEachNode(rowNode => {
                    if (!rowNode.data.isTotal) {
                      const work = rowNode.data.work.find(
                        v => v.date === params.colDef.field
                      )
                      if (work) {
                        totalActualHour += Number(work.actualHour)
                      }
                    }
                  })
                return `${numeral(totalActualHour).format('0,0.00')}`
              }
              const data: WorkReportRow = params.data
              if (!data.work) {
                return 0
              }
              const work = data.work.find(v => v.date === date)

              if (moment(date).isAfter(moment(), 'day')) {
                return `${numeral(work ? work.scheduledHour : 0).format(
                  '0,0.00'
                )}`
              } else {
                return `${numeral(work ? work.actualHour : 0).format('0,0.00')}`
              }
            },
            cellRendererParams: {
              ...baseColumnDef.cellRendererParams,
              highlightForZero: true,
            },
            onCellClicked: async (event: CellClickedEvent) => {
              if (
                event.node.data.isTotal ||
                !(event.event instanceof PointerEvent)
              ) {
                let workCodeList = new Set<number>()
                event.api.forEachNode(rowNode => {
                  rowNode.data.work.forEach(work => {
                    if (work.date === event.colDef.field) {
                      work.actualWorks.forEach(actualWork => {
                        workCodeList.add(actualWork.taskCode)
                      })
                    }
                  })
                })
                if (workCodeList.size !== 0) {
                  const workCode = Array.from(workCodeList).join(',')
                  const projectCode = window.location.pathname.split('/')[2]
                  open(
                    `/actualWork/${projectCode}?status=all&code=${workCode}`,
                    event.event,
                    undefined,
                    true
                  )
                }
                return
              }
              const work = event.node.data.work.find(
                v => v.date === event.colDef.field
              )
              if (work && work.actualHour) {
                const openDetail = () => {
                  const projectCode = window.location.pathname.split('/')[2]
                  open(
                    `/actualWork/${projectCode}?code=${work.taskCodeList}`,
                    event.event,
                    undefined,
                    true
                  )
                }
                ctx.setState({
                  children: (
                    <TaskActualWorkTaskDialog
                      anchorEl={event.event.target}
                      date={new DateVO(event.colDef.field)}
                      actualWorks={work.actualWorks}
                      actualHour={work.actualHour}
                      event={event.event}
                      onClose={() => ctx.setState({ children: null })}
                      openDetail={openDetail}
                    />
                  ),
                })
              }
            },
            cellStyle: params => {
              const data: WorkReportRow = params.data
              const work = data.work
                ? data.work.find(v => v.date === params.colDef.field)
                : null
              let style: CSSProperties = { justifyContent: 'flex-end' }
              if (params.data.isTotal) {
                if (params.value !== '0.00') {
                  style.cursor = 'pointer'
                  style.color = Color.MAIN
                }
                return style
              }
              if (work && work.actualHour) {
                style.cursor = 'pointer'
                style.color = Color.MAIN
                if (work.scheduledHour > 0) {
                  const rate = work.actualHour / work.scheduledHour
                  if (rate > 1.5) {
                    style.backgroundColor = BackgroundColor.ALERT_TOO_HIGH
                  } else if (1.25 < rate && rate <= 1.5) {
                    style.backgroundColor = BackgroundColor.ALERT_HIGH
                  } else if (0.5 <= rate && rate < 0.75) {
                    style.backgroundColor = BackgroundColor.ALERT_LOW
                  } else if (0 < rate && rate < 0.5) {
                    style.backgroundColor = BackgroundColor.ALERT_TOO_LOW
                  }
                }
              } else if (work && work.scheduledHour) {
                if (moment(work.date).isBefore(moment(), 'day')) {
                  style.color = Color.ALERT
                } else {
                  style.color = TextColor.GREY
                }
              } else {
                style.color = TextColor.GREY
              }

              if (isCurrentDate(params.colDef.field)) {
                style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
              }

              return style
            },
            cellRenderer: (params: any) => {
              const work = params.data.work
                ? params.data.work.find(v => v.date === params.colDef.field)
                : null
              if (!params.data.isTotal && !work) {
                return <></>
              }
              const isDayOff =
                !params.data.isTotal &&
                !(isSaturday(date) || workingDayCalendar.isHoliday(date)) &&
                work &&
                !work.actualHour &&
                work.scheduledHour === 0
              const isScheduled =
                !params.data.isTotal &&
                moment(date).isAfter(moment(), 'day') &&
                work &&
                work.scheduledHour &&
                !work.actualHour
              return (
                <div className="sevend-ag-cell">
                  {isDayOff && <WorkCell>休</WorkCell>}
                  {!isDayOff && isScheduled && (
                    <WorkCell color={TextColor.GREY}>予</WorkCell>
                  )}
                  <span>{params.value}</span>
                </div>
              )
            },
            headerClass: headerClass,
          }
          if (!ctx.state.isOpen) {
            return [ActualTaskcolumn]
          }
          const timerRecordColumn = {
            ...baseColumnDef,
            colId: `${date}-timer`,
            headerName: '',
            headerComponentFramework: () => (
              <HeaderIcon>
                <AccessTimeIcon sx={{ height: '16px', width: '16px' }} />
              </HeaderIcon>
            ),
            lockPosition: true,
            editable: false,
            minWidth: 65,
            maxWidth: 65,
            field: date,
            filter: false,
            resizable: false,
            valueGetter: (params: ValueGetterParams) => {
              const data: WorkReportRow = params.data
              if (params.data.isTotal) {
                let totalElapsedTime = 0
                params.api?.forEachNode(rowNode => {
                  if (!rowNode.data.isTotal) {
                    const work = rowNode.data.work.find(
                      v => v.date === params.colDef.field
                    )
                    if (work) {
                      totalElapsedTime += Number(work.dayTotalElapsedTime)
                    }
                  }
                })
                return `${numeral(totalElapsedTime).format('0,0.00')}`
              }
              const work = data.work?.find(v => v.date === date)
              return moment(date).isAfter(moment(), 'day')
                ? `${numeral(work?.scheduledHour || 0).format('0,0.00')}`
                : `${numeral(work?.dayTotalElapsedTime || 0).format('0,0.00')}`
            },
            onCellClicked: async (event: CellClickedEvent) => {
              if (
                event.node.data.isTotal ||
                !(event.event instanceof PointerEvent)
              ) {
                return
              }
              const work = event.node.data.work.find(
                v => v.date === event.colDef.field
              )
              if (work?.timerHistorySumByTasks?.length && event.event.target) {
                ctx.setState({
                  children: (
                    <TimerWorkReportPopper
                      anchorEl={event.event.target}
                      closePoper={() => ctx.setState({ children: null })}
                      targetDate={event.colDef.field}
                      timerHistorySumByTasks={work.timerHistorySumByTasks}
                      timerElapsedTimeSum={work.dayTotalElapsedTime}
                    />
                  ),
                })
              }
            },
            cellStyle: params => {
              const data: WorkReportRow = params.data
              const work = data.work?.find(v => v.date === params.colDef.field)
              let style: CSSProperties = { justifyContent: 'flex-end' }
              if (params.data.isTotal) {
                if (params.value !== '0.00') {
                  style.cursor = 'pointer'
                  style.color = Color.MAIN
                }
                return style
              }
              if (work?.dayTotalElapsedTime) {
                style.cursor = 'pointer'
                style.color = Color.MAIN
                if (work.scheduledHour > 0) {
                  const rate = work.dayTotalElapsedTime / work.scheduledHour
                  if (rate > 1.5) {
                    style.backgroundColor = BackgroundColor.ALERT_TOO_HIGH
                  } else if (rate > 1.25) {
                    style.backgroundColor = BackgroundColor.ALERT_HIGH
                  } else if (rate < 0.75) {
                    style.backgroundColor = BackgroundColor.ALERT_LOW
                  } else if (rate < 0.5) {
                    style.backgroundColor = BackgroundColor.ALERT_TOO_LOW
                  }
                }
              } else if (work?.scheduledHour) {
                style.color = moment(work.date).isBefore(moment(), 'day')
                  ? Color.ALERT
                  : TextColor.GREY
              } else {
                style.color = TextColor.GREY
              }
              if (isCurrentDate(params.colDef.field)) {
                style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
              }
              return style
            },
            cellRenderer: (params: any) => {
              const work = params.data.work?.find(
                v => v.date === params.colDef.field
              )
              if (
                !params.data.isTotal &&
                !work?.timerHistorySumByTasks?.length
              ) {
                return <></>
              }
              const isTimeGap =
                work &&
                (work.dayTotalElapsedTime ?? 0) > 0 &&
                work.actualHour !== work.dayTotalElapsedTime
              return (
                <div className="sevend-ag-cell">
                  <span>
                    {isTimeGap ? (
                      <>
                        <RecordsWithDifferences>＊</RecordsWithDifferences>
                        {params.value}
                      </>
                    ) : (
                      params.value
                    )}
                  </span>
                </div>
              )
            },
            headerClass: headerClass,
          }
          return [ActualTaskcolumn, timerRecordColumn]
        })

        let month = moment(start).startOf('month')
        const endMonth = moment(end).endOf('month')
        const colGroupDef: any[] = []
        while (month.isBefore(endMonth)) {
          const monthWorkColumns = workColumns.filter(v =>
            month.isSame(moment(v.field).startOf('month'))
          )
          colGroupDef.push({
            ...baseColumnDef,
            colId: formatMonth(month),
            headerName: month.format(
              intl.formatMessage({
                id: 'dateFormat.yyyy.mm',
              })
            ),
            field: formatDate(month),
            lockPosition: true,
            children: monthWorkColumns,
          })
          month = month.add(1, 'month')
        }
        return colGroupDef
      },
    },
  }

  async getAll(state: WorkReportState): Promise<APIResponse> {
    if (
      !state.dateTerm ||
      !state.dateTerm.startDate ||
      !state.dateTerm.endDate
    ) {
      return Object.assign({}, successDummyResponse, { json: [] })
    }
    return WorkReportAPI.getWorkReport({
      projectUuid: store.getState().project.selected!,
      startDate: state.dateTerm.startDate,
      endDate: state.dateTerm.endDate,
    })
  }

  openTimerRecord(ctx: WorkReportBulkSheetContext, isOpen: boolean) {
    ctx.setState(
      {
        isOpen: isOpen,
      },
      () => ctx.refreshDataWithLoading(true)
    )
  }

  onSearch(ctx: WorkReportBulkSheetContext, dateTerm: DateTerm) {
    ctx.setState(
      {
        dateTerm: dateTerm,
      },
      ctx.refreshDataWithLoading
    )
  }

  getSearchCondition = (state: WorkReportState) => {
    return {
      projectUuid: state.uuid,
      dateTerm: state.dateTerm,
    }
  }

  restoreSearchCondition = (
    searchCondition: any,
    ctx: WorkReportBulkSheetContext
  ) => {
    if (searchCondition.projectUuid === ctx.state.uuid) {
      this.onSearch(ctx, searchCondition)
    }
  }

  refreshConditionAndColumn = (ctx: WorkReportBulkSheetContext) => {
    this.onSearch(ctx, {})
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (field === 'total') {
      return (params: ValueGetterParams) => {
        if (params.data.isTotal) {
          let totalActualHour = 0
          params.api &&
            params.api.forEachNode(rowNode => {
              if (!rowNode.data.isTotal) {
                totalActualHour += Number(rowNode.data.totalActualHour)
              }
            })
          return `${numeral(totalActualHour).format('0,0.00')}`
        }
        const data: WorkReportRow = params.data
        const actualHour = numeral(data ? data.totalActualHour : 0).format(
          '0,0.00'
        )
        return `${actualHour}`
      }
    }
    return undefined
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'user.name') {
      return {
        labelField: 'user.name',
        iconUrlField: 'user.iconUrl',
      }
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (field === 'works') {
      return 90
    }
    return undefined
  }

  pinnedTopRowData: WorkReportRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      treeValue: [],
      isTotal: true,
      isViewOnly: true,
    },
  ]
}
