import _ from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { GridOptions } from 'ag-grid-community'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import store from '../../../../store'
import { BaseWbsItemType } from '../../../../store/project'
import {
  ActionPopperAddChildButtonProps,
  ActionPopperButtonProps,
} from '../components/ActionPopper'
import { NewWbsItemRow } from '../projectPlanNew'
import { intl } from '../../../../i18n'
import { fromSnakeToCamel } from '../../../../utils/string'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'

export const useActionPopper = ({
  gridOptions,
  wbsItemTypes,
  addRowAbove,
  addRowBelow,
  addToChildRow,
}: {
  gridOptions: GridOptions
  wbsItemTypes: BaseWbsItemType
  addRowAbove: (
    type: WbsItemTypeVO,
    ticketListUuid?: string,
    targetRowUuid?: string | undefined,
    focusNewRow?: boolean
  ) => void
  addRowBelow: (
    type: WbsItemTypeVO,
    ticketListUuid?: string,
    targetRowUuid?: string | undefined,
    focusNewRow?: boolean
  ) => void
  addToChildRow: (
    type: WbsItemTypeVO,
    ticketListUuid?: string,
    targetRowUuid?: string | undefined
  ) => void
}) => {
  const [offsetX, setOffsetX] = useState<number>(0)
  const [offsetY, setOffsetY] = useState<number>(0)
  const [anchor, setAnchor] = useState<HTMLElement | undefined>()
  const [addAboveRowButtonProps, setAddAboveRowButtonProps] = useState<
    ActionPopperButtonProps | undefined
  >(undefined)
  const [addBelowRowButtonProps, setAddBelowRowButtonProps] = useState<
    ActionPopperButtonProps | undefined
  >(undefined)
  const [addChildRowButtonProps, setAddChildRowButtonProps] = useState<
    ActionPopperAddChildButtonProps[] | undefined
  >(undefined)

  const targetRowUuidRef = useRef<string | undefined>()

  const getTargetRow = useCallback(() => {
    if (!gridOptions?.api || !targetRowUuidRef.current) return
    return gridOptions?.api.getRowNode(targetRowUuidRef.current)
  }, [gridOptions?.api, targetRowUuidRef])

  const getTargetRowHeight = useCallback(() => {
    const targetNode = getTargetRow()
    const height = targetNode?.rowHeight ?? undefined
    return height
  }, [getTargetRow])

  const getTargetRowData = useCallback(() => {
    const targetNode = getTargetRow()
    const row = targetNode?.data
    const wbsItem: NewWbsItemRow | undefined = row?.body?.wbsItem
    return {
      wbsItemType: wbsItem?.baseWbsItemType,
      ticketType: wbsItem?.ticketType,
      ticketListUuid:
        row.body?.ticketCumulation?.ticketListUuid ?? wbsItem?.ticketListUuid,
      targetNode,
    }
  }, [getTargetRow])

  // Unuse addRowAbove because continuous clicks are not possible
  // const onClickButtonAddAbove = useCallback(() => {
  //   const {
  //     wbsItemType: selectedRowType,
  //     ticketType,
  //     ticketListUuid,
  //   } = getTargetRowData()
  //   if (!selectedRowType) return

  //   const addRowType = selectedRowType.isTicket()
  //     ? store
  //         .getState()
  //         .project.ticketTypes.find(type => type.code === ticketType)
  //     : selectedRowType

  //   if (!addRowType) return
  //   addRowAbove(
  //     addRowType,
  //     selectedRowType.isTicket() ? ticketListUuid : undefined,
  //     targetRowUuidRef.current,
  //     false
  //   )
  // }, [getTargetRowData, addRowAbove])

  const onClickButtonAddBelow = useCallback(() => {
    const {
      wbsItemType: selectedRowType,
      ticketType,
      ticketListUuid,
      targetNode,
    } = getTargetRowData()
    if (!selectedRowType) return

    const addRowType = selectedRowType.isTicket()
      ? store
          .getState()
          .project.ticketTypes.find(type => type.code === ticketType)
      : selectedRowType

    if (!addRowType) return
    addRowBelow(
      addRowType,
      selectedRowType.isTicket() ? ticketListUuid : undefined,
      targetRowUuidRef.current,
      false
    )
    if (targetNode && targetNode.expanded) {
      targetNode?.setExpanded(false)
    }
  }, [getTargetRowData, addRowBelow])

  const getAddRowTypeToChild = useCallback(
    (
      typeCode: string | undefined,
      selectedRowType: WbsItemTypeVO | undefined,
      ticketTypeCode: string | undefined
    ): WbsItemTypeVO | undefined => {
      if (!!typeCode) {
        const addType = [
          wbsItemTypes.task,
          wbsItemTypes.deliverable,
          wbsItemTypes.deliverableList,
          wbsItemTypes.process,
        ].filter(type => type.code === typeCode)

        if (!_.isEmpty(addType)) return addType[0]
      }
      if (!selectedRowType) return
      const addRowType =
        selectedRowType.isTicket() && selectedRowType.isDeliverable()
          ? store
              .getState()
              .project.ticketTypes.find(type => type.code === ticketTypeCode)
          : selectedRowType.isWorkgroup()
          ? wbsItemTypes.process
          : selectedRowType.isProcess()
          ? wbsItemTypes.deliverableList
          : selectedRowType.isDeliverableList()
          ? wbsItemTypes.deliverable
          : selectedRowType.isDeliverable()
          ? wbsItemTypes.task
          : selectedRowType.isTask()
          ? wbsItemTypes.task
          : undefined
      return addRowType
    },
    [wbsItemTypes]
  )

  const onClickButtonAddChild = useCallback(
    (addTypeCode?: string) => {
      const {
        wbsItemType: selectedRowType,
        ticketType,
        ticketListUuid,
      } = getTargetRowData()
      if (!selectedRowType) return

      const addRowType = getAddRowTypeToChild(
        addTypeCode,
        selectedRowType,
        ticketType
      )
      if (!addRowType) return
      addToChildRow(
        addRowType,
        selectedRowType.isTicket() && selectedRowType.isDeliverable()
          ? ticketListUuid
          : undefined,
        targetRowUuidRef.current
      )
      // To allow continuous clicking, the focus does not move.
    },
    [getTargetRowData, addToChildRow, getAddRowTypeToChild]
  )

  const open = useCallback(
    ({
      offsetX,
      offsetY,
      anchor,
      targetRowUuid,
      wbsItem,
    }: {
      offsetX: number
      offsetY: number
      anchor: HTMLElement
      targetRowUuid: string | undefined
      wbsItem?: NewWbsItemRow
    }) => {
      setOffsetX(offsetX)
      setOffsetY(offsetY)
      setAnchor(anchor)
      targetRowUuidRef.current = targetRowUuid

      const wbsItemType: WbsItemTypeVO | undefined = wbsItem?.baseWbsItemType
      const rootType: WbsItemType | undefined = wbsItemType?.rootType
      const isTicket: boolean = wbsItem?.wbsItemType.isTicket() ?? false

      const visibleAddBelowButton =
        WbsItemType.WORKGROUP !== rootType &&
        !(WbsItemType.DELIVERABLE === rootType && isTicket)

      // Hide addRowAbove because continuous clicks are not possible

      setAddBelowRowButtonProps(
        visibleAddBelowButton
          ? ({
              onClick: onClickButtonAddBelow,
            } as ActionPopperButtonProps)
          : undefined
      )

      if (!isTicket && wbsItemType) {
        const addableToChildTypes:
          | ActionPopperAddChildButtonProps[]
          | undefined = [
          wbsItemTypes.task,
          wbsItemTypes.deliverable,
          wbsItemTypes.deliverableList,
          wbsItemTypes.process,
        ]
          .filter(type => type.canBeChildOf(wbsItemType))
          .map(
            type =>
              ({
                type: type.code,
                iconUrl: type.iconUrl,
                tooltipTitle: intl.formatMessage({
                  id: `action.add.row.child.${fromSnakeToCamel(type.code)}`,
                }),
                onClick: () => onClickButtonAddChild(type.code),
              } as ActionPopperAddChildButtonProps)
          )
        setAddChildRowButtonProps(addableToChildTypes)
      } else {
        const isDeliverableList = WbsItemType.DELIVERABLE === rootType
        const iconUrl = isDeliverableList
          ? wbsItem!.wbsItemType.iconUrl
          : wbsItemTypes.task.iconUrl

        const tooltipTitle = isDeliverableList
          ? intl.formatMessage(
              {
                id: `action.add.row.child.ticket`,
              },
              {
                ticketType: wbsItemType?.name,
              }
            )
          : intl.formatMessage({
              id: `action.add.row.child.${fromSnakeToCamel(
                wbsItemTypes.task.code
              )}`,
            })

        setAddChildRowButtonProps([
          {
            iconUrl,
            tooltipTitle,
            onClick: () => onClickButtonAddChild(),
          },
        ])
      }
    },
    [wbsItemTypes, onClickButtonAddBelow, onClickButtonAddChild]
  )

  const close = useCallback(() => {
    if (!!anchor) {
      setAnchor(undefined)
      setAddAboveRowButtonProps(undefined)
      setAddBelowRowButtonProps(undefined)
      setAddChildRowButtonProps(undefined)
    }
  }, [anchor])

  const returnVal = useMemo(
    () => ({
      offsetX,
      offsetY,
      anchor,
      addAboveRowButtonProps,
      addBelowRowButtonProps,
      addChildRowButtonProps,
      targetRowUuid: targetRowUuidRef.current,
      open,
      close,
      isOpen: !!anchor,
      getTargetRowHeight,
    }),
    [
      offsetX,
      offsetY,
      anchor,
      addAboveRowButtonProps,
      addBelowRowButtonProps,
      addChildRowButtonProps,
      targetRowUuidRef,
      open,
      close,
      getTargetRowHeight,
    ]
  )
  return returnVal
}
