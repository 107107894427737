import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import {
  Cumulation,
  progressDetailByEnd,
  progressDetailByStart,
  ProjectOverviewRow,
} from './projectOverview'
import {
  fetchProjectPlanBody,
  fetchProjectPlanSkeleton,
  ProjectPlanSkeleton,
} from '../ProjectPlanNew/projectPlanNew'
import { AgGridTreeHelper } from '../../containers/BulkSheetView/lib/tree'
import { createRowByResponse } from '../../../lib/functions/wbsItem'
import DateVO from '../../../vo/DateVO'

export const useData = ({
  projectUuid,
  treeRootUuid,
  useCache,
}: {
  projectUuid: string
  treeRootUuid?: string
  useCache?: boolean
}) => {
  const [rows, setRows] = useState<ProjectOverviewRow[]>()
  const queue = useRef<string[]>([])
  const [count, setCount] = useState<number>(0)
  const [isFetchingBody, setIsFetchingBody] = useState<boolean>(false)
  const today = useMemo(() => DateVO.now(), [])
  const prevTreeRootUuid = useRef<string>()
  const cache = useRef<ProjectOverviewRow[]>([])

  const fetchSkeleton = useCallback(
    async (fetchBodyUuid?: string[]) => {
      let response
      try {
        response = await fetchProjectPlanSkeleton({
          projectUuid,
          rootProjectPlanUuid: treeRootUuid,
          fetchBodyUuid,
        })
      } catch (e: any) {
        if (e.code === 'NOT_FOUND') {
          setRows([])
          return
        }
        throw e
      }
      const source: ProjectPlanSkeleton = response.json
      let rowNumber = 1
      const rows = (treeRootUuid ? [source] : source.children)
        .map(c =>
          AgGridTreeHelper.convert(
            c,
            (s: ProjectPlanSkeleton): ProjectOverviewRow => {
              return {
                rowNumber: rowNumber++,
                uuid: s.uuid,
                wbsItemUuid: s.wbsItemUuid,
                ...(s.body
                  ? convertSourceToData(s.body, today)
                  : { wbsItem: {} }),
              } as ProjectOverviewRow
            }
          )
        )
        .flat()
      setRows(rows)
      if (useCache) {
        cache.current = rows
      }
    },
    [treeRootUuid]
  )

  const fetchBody = useCallback(
    async (uuids: string[]) => {
      setIsFetchingBody(true)
      const response = await fetchProjectPlanBody(projectUuid, uuids)
      const uuidToBody = new Map<string, any>()
      response.json.forEach(body => uuidToBody.set(body.wbsItem.uuid, body))
      const newRows = (rows ?? []).map(row => {
        const fetched = uuidToBody.get(row.wbsItemUuid)
        if (!!row.progress || !fetched) return row
        return {
          ...row,
          ...convertSourceToData(fetched, today),
        }
      })
      setRows(newRows)
      embodyCache(newRows)
      setIsFetchingBody(false)
    },
    [projectUuid, rows, today]
  )

  const embodyCache = useCallback(async (newRows: ProjectOverviewRow[]) => {
    if (!useCache) return
    newRows.forEach(newRow => {
      const index = cache.current.findIndex(row => row.uuid === newRow.uuid)
      if (0 <= index) {
        cache.current.splice(index, 1, {
          ...newRow,
          treeValue: cache.current[index].treeValue,
        })
      }
    })
  }, [])

  const enqueue = useCallback((list: string[]) => {
    const added = list.filter(uuid => !queue.current.includes(uuid))
    if (added.length === 0) return
    queue.current = [...queue.current, ...added]
    setCount(queue.current.length)
  }, [])

  const dequeue = useCallback(() => {
    if (queue.current.length === 0) return []
    const dequeued = queue.current.slice(0, 100)
    queue.current = queue.current.slice(100)
    setCount(queue.current.length)
    return dequeued
  }, [])

  useEffect(() => {
    prevTreeRootUuid.current = treeRootUuid
    fetchSkeleton()
  }, [])

  const drillDownByCache = useCallback(
    async (newRoot: ProjectOverviewRow) => {
      const treeValueString = newRoot.treeValue.join()
      const allChildren = cache.current.filter(row =>
        row.treeValue.join().includes(treeValueString)
      )
      // Embody
      const rootLevel = newRoot.treeValue.length
      const needEmbodied = allChildren
        .filter(
          c => c.treeValue.length <= rootLevel + 2 && _.isEmpty(c.wbsItem)
        )
        .map(v => v.uuid)
      if (0 < needEmbodied.length) {
        const response = await fetchProjectPlanBody(projectUuid, needEmbodied)
        response.json.forEach(body => {
          const index = allChildren.findIndex(
            c => c.wbsItemUuid === body.wbsItem.uuid
          )
          allChildren.splice(index, 1, {
            ...allChildren[index],
            ...convertSourceToData(body, today),
          })
        })
      }
      setRows(
        allChildren.map(c => ({
          ...c,
          treeValue: c.treeValue.slice(rootLevel - 1),
        }))
      )
    },
    [projectUuid]
  )

  useEffect(() => {
    if (treeRootUuid !== prevTreeRootUuid.current) {
      setRows([])
      const newRoot = cache.current.find(row => row.uuid === treeRootUuid)
      setTimeout(() => {
        if (!newRoot) {
          setRows(cache.current)
        } else {
          drillDownByCache(newRoot)
        }
      }, 300)
      prevTreeRootUuid.current = treeRootUuid
    }
  }, [treeRootUuid, rows])

  useEffect(() => {
    if (isFetchingBody) return
    const fetchBodyUuid = dequeue()
    if (fetchBodyUuid.length === 0) return
    fetchBody(fetchBodyUuid)
  }, [count, isFetchingBody])

  return { rows, enqueue, refresh: fetchSkeleton }
}

const convertSourceToData = (fetched, today) => {
  const cumulation = new Cumulation(fetched.cumulation)
  const wbsItem = createRowByResponse(fetched.wbsItem)
  return {
    wbsItem,
    progress: {
      sumDeliverableStart: cumulation.progressBySumDeliverableStart(),
      sumDeliverableEnd: cumulation.progressBySumDeliverableEnd(),
      sumTaskStart: cumulation.progressBySumTaskStart(),
      sumTaskEnd: cumulation.progressBySumTaskEnd(),
      countDeliverableStart: cumulation.progressByCountDeliverableStart(),
      countDeliverableEnd: cumulation.progressByCountDeliverableEnd(),
      countTaskStart: cumulation.progressByCountTaskStart(),
      countTaskEnd: cumulation.progressByCountTaskEnd(),
    },
    progressDetail: {
      sumStart: progressDetailByStart(wbsItem, 'sum', today),
      sumEnd: progressDetailByEnd(wbsItem, 'sum', today),
      countStart: progressDetailByStart(wbsItem, 'count', today),
      countEnd: progressDetailByEnd(wbsItem, 'count', today),
    },
    evm: {
      deliverable: cumulation.evmByDeliverable(),
      task: cumulation.evmByTask(),
    },
    cumulation: {
      actualHour: fetched.wbsItem?.actualHour,
      maxScheduledEndDate: fetched.cumulation?.maxScheduledEndDate,
    },
  }
}
