import {
  Button,
  ClickAwayListener,
  Popper,
  SpeedDial,
  SpeedDialAction,
  styled,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material'
import AddIcon from '../../../../assets/add.svg'
import ArrowIcon from '../../../../assets/subdirectory_arrow_right.svg'
import { colorPalette } from '../../../style/colorPallete'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { intl } from '../../../../i18n'

const BUTTON_SIZE: number = 14
const BUTTON_MARGIN: number = 1
const BUTTON_MARGIN_WITH_SECOND_ICON: number = 6
const ADD_BUTTON_X: number = -BUTTON_SIZE * 1.5
const TOP_BUTTON_Y: number = -BUTTON_SIZE / 2
const CHILD_BUTTON_X: number = 0
const SPEED_DIAL_ACTION_SIZE: number = 24
const SPEED_DIAL_ACTION_MARGIN: number = 5
const SPEED_DIAL_BUTTON_OFFSET_Y: number = (-1 * SPEED_DIAL_ACTION_SIZE) / 2

const StyledIcon = styled('img')({
  width: '10px',
  height: '10px',
})

const StyledSpeedDialActionIcon = styled('img')({
  width: '14px',
  height: '14px',
})

type StyledButtonProps = PropsWithChildren<{
  numOfIcon: number
}>

const BUTTON_BASE_STYLE: SxProps = {
  width: `${BUTTON_SIZE}px`,
  height: `${BUTTON_SIZE}px`,
  gap: '10px',
  borderRadius: '50%',
  border: `1px solid ${colorPalette.monotone[1]}`,
  opacity: '0px',
  color: colorPalette.monotone[4],
  background: colorPalette.monotone[0],
  minWidth: `${BUTTON_SIZE}px`,
  cursor: 'pointer',
  boxShadow: `0px 2px 4px 0px #7B8CAA4D`,
  '&:hover': {
    background: colorPalette.skyBlue[1],
    border: `1px solid ${colorPalette.skyBlue[1]}`,
  },
}

const StyledButton = styled(Button)<StyledButtonProps>(({ numOfIcon }) => ({
  ...BUTTON_BASE_STYLE,
  position: 'absolute',
  padding: '2px 0px 0px 0px',
  width: `${BUTTON_SIZE * numOfIcon} px`,
  gap: '0px',
  borderRadius: 1 < numOfIcon ? '25%' : '50%',
}))

export type ActionPopperButtonProps = {
  iconUrl: string
  onClick: () => void
}
export type ActionPopperAddChildButtonProps = ActionPopperButtonProps & {
  tooltipTitle: string
  onClick: (type?: string) => void
}

type Props = {
  anchorEl: HTMLElement | undefined
  originX?: number
  originY?: number
  height?: number | undefined
  addAboveButtonProps?: ActionPopperButtonProps
  addBelowButtonProps?: ActionPopperButtonProps
  addChildButtonProps?: ActionPopperAddChildButtonProps[]
  onClose: () => void
}

const ActionSpeedDial = ({
  actionProps,
  posX,
  posY,
  tooltipLabelId,
  tooltipPlacement,
  iconSource,
  zIndex,
}: {
  actionProps: ActionPopperAddChildButtonProps[]
  posX: number
  posY: number
  tooltipLabelId: string
  tooltipPlacement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  iconSource: string
  zIndex?: number
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const onSpeedDialClick = useCallback(() => setOpen(!open), [open])

  return (
    <SpeedDial
      classes={{
        actions: 'sevend-ag-cell-detail__action-popper-dial-actions',
      }}
      open={open}
      onClick={onSpeedDialClick}
      icon={
        <Tooltip
          arrow={true}
          title={intl.formatMessage({
            id: tooltipLabelId,
          })}
          placement={tooltipPlacement}
        >
          <StyledIcon src={iconSource} />
        </Tooltip>
      }
      ariaLabel={''}
      direction={'right'}
      sx={{
        top: SPEED_DIAL_BUTTON_OFFSET_Y + posY,
        left: posX,
        position: 'absolute',
        zIndex: zIndex,
      }}
      FabProps={{
        sx: {
          ...BUTTON_BASE_STYLE,
          minHeight: `${BUTTON_SIZE}px`,
        },
      }}
    >
      {actionProps.map(action => (
        <SpeedDialAction
          arrow={true}
          key={action.tooltipTitle}
          icon={<StyledSpeedDialActionIcon src={action.iconUrl} />}
          tooltipTitle={action.tooltipTitle}
          onClick={(e: MouseEvent) => {
            action.onClick()
            e.stopPropagation()
          }}
          sx={{
            margin: 0,
            padding: 0,
          }}
          FabProps={{
            sx: {
              margin: `0 ${SPEED_DIAL_ACTION_MARGIN}px`,
              minHeight: `${SPEED_DIAL_ACTION_SIZE}px`,
              width: `${SPEED_DIAL_ACTION_SIZE}px`,
              height: `${SPEED_DIAL_ACTION_SIZE}px`,
            },
          }}
        />
      ))}
    </SpeedDial>
  )
}

const ActionButton = ({
  tooltipLabelId,
  tooltipPlacement,
  onClick,
  posX,
  posY,
  iconSource,
  iconSx,
  subIconSource,
  subIconSx,
}: {
  tooltipLabelId: string
  tooltipPlacement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  onClick: () => void
  posX: number
  posY: number
  iconSource: string
  iconSx?: SxProps<Theme>
  subIconSource?: string
  subIconSx?: SxProps<Theme>
}) => {
  return (
    <Tooltip
      arrow={true}
      title={intl.formatMessage({
        id: tooltipLabelId,
      })}
      placement={tooltipPlacement}
    >
      <StyledButton
        numOfIcon={subIconSource ? 2 : 1}
        onClick={onClick}
        sx={{
          top: `${posY}px`,
          left: `${posX}px`,
        }}
      >
        <StyledIcon src={iconSource} sx={iconSx} />
        {subIconSource && <StyledIcon src={subIconSource} sx={subIconSx} />}
      </StyledButton>
    </Tooltip>
  )
}

const ActionPopper = ({
  anchorEl,
  originX = 0,
  originY = 0,
  height = ROW_HEIGHT.SMALL,
  addAboveButtonProps,
  addBelowButtonProps,
  addChildButtonProps,
  onClose,
}: Props) => {
  const bottomButtonY = useMemo(() => height - BUTTON_SIZE / 2, [height])

  if (!anchorEl) return <></>
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={'top-start'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [originX, -originY - height],
            },
          },
        ]}
        sx={{
          height: `${height}px`,
          zIndex: 9999,
        }}
      >
        {addAboveButtonProps && (
          <ActionButton
            tooltipLabelId={'action.add.row.above'}
            tooltipPlacement={'top'}
            onClick={addAboveButtonProps.onClick}
            posX={
              ADD_BUTTON_X -
              (addAboveButtonProps.iconUrl
                ? BUTTON_MARGIN_WITH_SECOND_ICON
                : BUTTON_MARGIN)
            }
            posY={TOP_BUTTON_Y}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
            subIconSource={addAboveButtonProps.iconUrl}
          />
        )}
        {addBelowButtonProps && (
          <ActionButton
            tooltipLabelId={'action.add.row.below'}
            tooltipPlacement={'top'}
            onClick={addBelowButtonProps.onClick}
            posX={
              ADD_BUTTON_X -
              (addBelowButtonProps.iconUrl
                ? BUTTON_MARGIN_WITH_SECOND_ICON
                : BUTTON_MARGIN)
            }
            posY={bottomButtonY}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
            subIconSource={addBelowButtonProps.iconUrl}
            subIconSx={{
              margin: '0 2px 2px 0',
            }}
          />
        )}
        {addChildButtonProps && (
          <ActionSpeedDial
            actionProps={addChildButtonProps}
            tooltipLabelId={'action.add.row.child'}
            tooltipPlacement={'top'}
            posX={CHILD_BUTTON_X}
            posY={height}
            iconSource={ArrowIcon}
          />
        )}
      </Popper>
    </ClickAwayListener>
  )
}

export default ActionPopper
